horus-duplicated-lead{
  display: block;
  width: 100%;
}
.duplicated-lead {
  background-color: $rk_semaphoreRed;
  width: 100%;
  padding: 10px;
  font-family: special, Arial, sans-serif;
  border-radius: 10px;
  margin: 10px 0;
  font-weight: bold;
  &__selection {
    width: 23px;
    cursor: pointer;
    filter: brightness(3);
    opacity: .6;
    &:hover {
      opacity: 1;
    }
  }
  &.--warning {
    background-color: $rk_appraiser-yellow2;
    color: $rk_step5;
  }
  &.--mine {
    background-color: $rk_step5;
    color: $rk_white;
  }
  ul{
    margin: 10px 0 0 0;
  }
  li{
    display: flex;
    align-items: center;
    margin: 5px;
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $rk_white;
    }
  }
  em {
    font-size: .9em;
  }
  a{
    font-family: headers, Arial, sans-serif;
    padding: 0 5px;
    color: $rk_white;
    &:hover {
      color: $rk_semaphoreOrange;
    }
  }
}
