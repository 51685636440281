.--base {
  opacity: 0;
  position: fixed;
  z-index: z(level5);
  display: flex;
  text-transform: lowercase;
  align-items: center;
  justify-content: center;
  animation: appear .2s linear forwards;
  background: rgba( $rk_black , .7);
  user-select: none;
  img{
    max-height: 40px;
    margin-right: 10px;
  }
  figcaption {
    color: $rk_white;
    font-family: headers, Arial, sans-serif;
  }
}

.--footer {
  @extend .--base;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 15vh;
  font-size: 2rem;
}
.--popup {
  @extend .--base;
  width: 250px;
  height: 60px;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  border-radius: 10px;
  img{
    max-height: 30px;
  }
 }

.loader{
  &__container{
    position: absolute;
    top:0; left: 0;
    width:100%; height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top:-10vh;
    &.--cover,
    &.--submit{
      z-index: z(level5);
      background-color: $rk_transparent;
    }
    &.--cover {
      margin-top: 0;
    }
    &.--fixed{
      position: fixed;
      margin: 0;
    }
    &.--white {
      background-color: $rk_white;
    }
  }

  &__spinner{
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    &-item {
      height: 40%;
      width: calc(120px / 13);
      animation: spinner5 1s ease-in-out infinite;
      opacity: .6;
      &:nth-child(1) {
        background-color: $rk_step2;
      }
      &:nth-child(2) {
        background-color: $rk_step3;
        animation-delay: .1s;
      }
      &:nth-child(3) {
        background-color:$rk_step4;
        animation-delay: .2s;
      }
      &:nth-child(4) {
        background-color: $rk_step5;
        animation-delay: .3s;
      }
      &:nth-child(5) {
        background-color: $rk_step1;
        animation-delay: .4s;
      }
    }
  }


  &__saving{
    @extend .--popup;
    box-shadow: 0 -2px 10px $rk_loader1;
    img{
      filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(313deg) brightness(104%) contrast(117%);
    }
    figcaption {
      font-family: headers, Arial, sans-serif;
    }
  }

  &__invalid {
    @extend .--footer;
    box-shadow: 0 -2px 10px $rk_copper3;
    img {
      filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(357deg) brightness(104%) contrast(117%);
    }
    figcaption{
      color: $rk_copper3;
    }
  }

  &__deleting{
    @extend .--footer;
    box-shadow: 0 -2px 10px $rk_black;
    background: rgba($rk_horus08, .95);
    img {
      filter: invert(61%) sepia(92%) brightness(0);
    }
  }


  &__description {
    padding-top: 5px;
    font-size: .8em;
    font-family: basic, Arial, sans-serif;
    color: $rk_grey05;
  }
}


