$max : 130px;
$size: 100px;
$min : 60px;

.upload-box-content {
  display: flex;
  height: 100%;
  align-items: stretch;
}
.horus-uploader-img {
  position: relative;
  display: block;
  border-radius: 50%;
  min-width: $max;
  width: $max;
  height: $max;
  border: 1px solid $rk_grey1;
  background: $rk_white;
  filter: hue-rotate(-55deg);
  overflow: hidden;

  &.--disabled {
    opacity: .5;
    .image-uploader__actions {
      display: none;
    }
  }
}

horus-uploader{
  display: block;
  border-radius: 50%;
  position: relative;
  background: $rk_grey8 url("/assets/img/icons/camera.svg") no-repeat center;
  background-size: 60%;
  min-width: $max;
  width: $max;
  height: $max;
  .drop-container {
    width: 100%;
  }
  &.--type-pdf {
    background-image: url("/assets/img/icons/pdf.svg");
  }
  &.--big {
    background: transparent;
    border: 3px dashed $rk_grey3;
    border-radius: 0;
    margin: 10px 10px 10px 0;
    max-width: 40%;
    min-width: 200px;
    min-height: 100px;
    height: fit-content;
    @include mq(tablet) {
      max-width: 100%;
      width: 100%;
    }
    p {
      position: relative;
      opacity: .6;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }
  &.--attached {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-width: 1px;
    color: $rk_grey6;
    background: url("/assets/img/icons/clip.svg") no-repeat 10px center;
    background-size: 40px;
    padding-left: 50px;
    filter: saturate(0);
  }
  &.--mini {
    max-width: 100px;
    max-height: 100px;
  }
  &.--uploader-contact {
    background-image: url("/assets/img/icons/contacto.svg");
  }
  &.--uploader-signature{
    min-width: 100%;
    width: 100%;
    border-radius: 0;
    min-height: 100%;
    background: $rk_grey1;
    p {
      border-radius: 0;
    }
    strong  {
      display: block;
    }
    &.--uploader-loaded:before{
      display: none;
    }
    &:before {
      position: absolute;
      content: "firma para el mail";
      font-family: special, Arial, sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      padding:30px;
    }
  }
  &.--uploader-file{
    background-image: url("/assets/img/icons/clip.svg");
    background-color: $rk_white;
    p{
      padding: 0        ;
    }
  }
  &.--uploader-loaded{
    position: absolute;
    top: 0;
    background: $rk_transparent;
  }
  &.--disabled {
    opacity: .5;
    .drop-container {
      display: none;
      position: fixed;
      top: -1000px;
      left: -8000px;
    }
  }
  &:hover, &:focus {
    p {
      opacity: 1;
    }
  }
  p{
    transition: opacity .2s linear;
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
    font-family: basic, Arial, sans-serif;
    padding: 10px;
    opacity: 0;
    border-radius: 50%;
    background: rgba($rk_white, .8);
    word-break: break-all;
  }
  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

}

.upload-item {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: rgba($rk_white, .7);
  flex-direction: column;
  word-break: break-all;
  padding: 10px;
  .is-done {
    color: $rk_semaphoreGreen;
  }
}

.uploaded-image{
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.image-uploader__figure-no-image {
  display: flex;
  height: 100%;
}

.no-image {
  width: 80%;
  margin: auto;
}

.upload-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

horus-cropper {
  padding: 0;
  margin: 5px;
  max-width: 50%;
  &.--profile {
    @include mq(sm) {
      margin-bottom: 20px;
    }
  }
  .page-modal__back {
    background: rgba($rk_white, .8);

  }
  figure {
    min-width: $max;
    width: $max;
    height: $max;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(desktop) {
      max-width: $size;
      min-width: $size;
      max-height: $size;
    }
    @include mq(tablet-small) {
      min-width: $min;
      max-width: $min;
      max-height: $min;
    }
    &.--rounded {
      border-radius: 50%;
      border: 1px solid $rk_grey2;
    }
  }
}


image-cropper {
  width: auto !important;
  min-height: $min;
  max-height: 80vh !important;
}


.image-uploader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: z(level3);
  }
  &__action {
    font-size: 1.1rem;
    padding: 0 35px 0 0 ;
    display: flex;
    align-items: center;
    width: 50px;
    margin-left: 10px;
    border-left: 1px solid $rk_grey1;
    height: 40px;
    cursor: pointer;
    transition: filter .2s linear;
    filter: saturate(0);
    @include mq('phablet') {
      font-size: 0;
      width: 20px;
      color: $rk_transparent;
    }
    &:hover, &:focus{
      color: $rk_horus04;
      filter: none;
    }
    &.--modify{
      background: url(/assets/img/icons/hecho.svg) no-repeat 5px center;
      background-size: 40px;
    }
    &.--delete{
      background: url(/assets/img/icons/close.svg) no-repeat 8px center;
      background-size: 20px;
    }
    &.--center{
      background: url(/assets/img/icons/focus.svg) no-repeat 8px center;
      background-size: 20px;
    }
  }
}


