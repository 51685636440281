.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder; }

.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  color: $rk_grey6;
  font-family: special, Arial, sans-serif;
  font-size: 1.2rem;
}

.cal-month-view .cal-days {
  border: 1px solid;
  border-bottom: 0; }

.cal-month-view .cal-cell-top {
  min-height: 78px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  > span {
    display: flex;
    justify-content: space-between;
  }
}

.cal-month-view .cal-cell-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.cal-month-view .cal-cell {
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
.cal-month-view .cal-cell .cal-event {
  pointer-events: all !important; }

.cal-month-view .cal-day-cell {
  position: relative;
  min-height: 80px;
  min-width: calc(100% / 7);
}
@media all and (-ms-high-contrast: none) {
  .cal-month-view .cal-day-cell {
    display: block; } }

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid; }

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid; }

.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px; }

.cal-month-view .cal-day-number {
  font-size: 1.9em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 10px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
  flex-grow: 2;
  text-align: right;

  @include mq('phablet'){
    font-size: 1.5em !important;
    margin-right: 5px;
    margin-top: 5px;
  }
}

.cal-month-view .cal-events {
  flex: 1;
  align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: flex;
  flex-wrap: wrap;
  }

.cal-month-view .cal-event {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: 5px 10px; }

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer; }

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  font-size: 1.9em;
  opacity: 0.1;
  cursor: default; }

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em;
  font-weight: bold;
  color: $rk_black;
}

.cal-month-view .cal-open-day-events {
  max-height: 50vh;
  overflow-y: auto !important;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1400px;
    width: 96%;
    margin: auto;
    padding: 5px 0;

  }
}

/*.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px; }*/

.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3; }

.cal-month-view .cal-draggable {
  cursor: move; }


.cal-month-view .cal-drag-active * {
  pointer-events: none; }
.cal-month-view mwl-calendar-event-title{
  flex-grow: 2;
  padding: 0 20px 0 0;
}
.cal-month-view .cal-event-title {
  line-height: 1.1;
  font-size: 1.4rem;
  min-width: 200px;
  display: flex;
  align-items: center;
  width: 100%;
  @include mq(tablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.2rem;
  }
}

.cal-month-view {
  background-color: #fff; }
.cal-month-view .cal-cell-row:hover {
  background-color: #fafafa; }
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #ededed; }
.cal-month-view .cal-days {
  border-color: #e1e1e1; }
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #e1e1e1; }
.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #e1e1e1; }
.cal-month-view .cal-day-badge {
  background: url(/assets/img/icons/alarmas.svg) no-repeat;
  background-size: 30px;
  margin: 5px;
  width: 33px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $rk_black;
}
.cal-month-view .cal-event {
  border-color: #d1e8ff;
  color: #fff; }
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #8b0000; }
.cal-month-view .cal-day-cell.cal-today {
  background-color: #e8fde7; }
.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #e0e0e0 !important; }
.cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #555;
  -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: z(level1);
  padding-top: 5vh;
  padding-bottom: 5vh;
  @include mq('tablet'){
    padding: 10px;
  }
}


.cal-event-actions{
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 0 10px;
  border-left: 1px solid $rk_grey5 ;
  img{
    margin: 0 5px;
    width: 25px;
  }
}


.calendar {
  &__close{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: z(floor);
  }
  &__header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 30px 0;
    z-index: z(level1);
    @include mq('tablet'){
      padding: 10px 0;
    }
    h2{
      @include mq('tablet'){
        font-size: 1.5rem;
        color:$rk_grey6;
      }
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-of-type(2){
      @include mq('tablet'){
        display: none;
      }
    }
  }
  &__menu {
    padding: 0 5px;
    cursor: pointer;
    filter: saturate(0);
    opacity: .5;
    &.active {
      filter: none;
      opacity: 1;
    }
    img {
      width: 40px;
    }
  }
  &__today {
    font-size: 1.2rem;
    @include mq('tablet'){
      display: none;
    }
    &:hover{
      font-weight: bold;
    }
  }
  &__body{
    position: relative;
    z-index: z(level1);
    margin-bottom: 150px;
  }
  &__eventNav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    .cal-day-badge{
      flex-basis: 60%;
      height: 100%;
      background-position: center;
      background-size: 30%;
      transition: transform .2s linear;
      &:hover{
        transform: scale(1.2);
      }
      @include mq(tablet){
        background: $rk_semaphoreRed;
        border-radius: 50%;
        position: absolute;
        width: 20px;
        height: 20px;
        border: 0;
        color: $rk_white;
        left: 0;
        top: 17px;
      }
    }
  }
  &__addEvent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 40%;
    flex-grow: 2;
    height: 100%;
    padding-top: 14%;
    .cal-disabled & {
      display: none;
    }
/*    .cal-disabled.cal-today & {
      display: flex;
    }*/
    &.--withAlarms {
      @include mq('tablet') {
        height: 30px;
        bottom: 0;
        position: absolute;
        width: 100%;
      }
      &:before {
        content: '';
        height: 50%;
        width: 1px;
        top: 25%;
        position: absolute;
        background: $rk_grey3;
        left: -1px;
        @include mq('tablet') {
          display: none;
        }
      }
    }
    img {
      height: 50%;
      transition: background-color .1s linear;
      @include mq('tablet'){
        width: 20px;
        top: unset;
        bottom: 5px;
        position: absolute;
        right: 5px;
        height: unset;
      }
    }
  }
}

