horus-step3-activo{
  display: block;
  padding-bottom: 100px;
  textarea {
    width: 100%;
    min-height: 100px;
    font-size: 1.2rem;
    color: $rk_grey7;
  }
  horus-uploader {
    @include mq('tablet') {
      min-width: 100% !important;
    }
  }
}

.step-activo {

  &__title {
    margin: 0;
    font-size: 2.5rem;
    color: $rk_step3;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    img {
      width: 50px;
      margin-left: 10px;
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    margin:0 0 40px 0;
  }
  &__field {
    width: 30%;
    margin:1% 3% 1% 0;
    @include mq(tablet) {
      width: 48%;
      margin: 1%;
    }
    @include mq(tablet-small) {
      width: 100%;
    }
  }
  .basic-plan h2 {
    margin-bottom: 20px;
  }
  &__urls{
    &-title {
      width: 100%;
      margin-bottom:20px;
    }
  }
  &__checks {
    margin: 30px 0;
  }
  &__offers {
    margin: 10px 0 30px;
    &__title{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $rk_step3;
      filter: saturate(.7);
    }
    &__disabled {
      font-size: 1.2rem;
      font-family: "special", Arial, sans-serif;
      color: $rk_semaphoreRed;
      letter-spacing: 0;
      margin: 0 10px;
      padding: 0 10px;
      border-left: 1px solid $rk_grey4;
    }
    &__new {
      width: 30px;
      transition: color .2s linear;
      font-family: 'special', Arial, sans serif;
      font-size: 1.2rem;
      display: flex;
      color: $rk_step3;
      margin: 0 10px;
      padding: 0 10px;
      border-left: 1px solid $rk_grey4;
      cursor: pointer;
      filter: saturate(0) brightness(1.2);
      &:hover {
        filter: saturate(1);
      }
      figcaption {
        padding: 0 5px;
      }
    }
    &-list{
      &__item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding:10px;
        font-size: 1.2rem;
        font-family: special, Arial, sans-serif;
        cursor: pointer;
        color: $rk_grey6;
        border-bottom: 1px solid $rk_white;
        &:hover {
          background: $rk_grey02;
        }
        &.--approved {
          color: $rk_black;
          border-radius: 3px;
          background-color: rgba($rk_role_agente, .2) ;
          strong {
            color: $rk_step4;
          }
          border: 1px solid rgba($rk_step3, .5);
        }
        &.--cancelled {
          strong {
            color: $rk_grey3;
          }
        }
        &.--pending {
          strong {
            color: $rk_action2;
          }
        }
      }
      &__title {
        padding: 0 10px 0 0;
        @include mq(desktop) {
          width:  100%;
        }
        span {
          font-family: headers, Arial, sans-serif;
        }
      }
      &__buyers {
        font-size: .8em;
        margin: 0 10px 0 0;
      }
      &__status {
        font-family: headers, Arial, sans-serif;
        flex-grow: 2;
        text-align: right;
        img{
          width: 20px;
          margin-right: -10px;
        }
      }

    }

  }
  &__extra{
    margin-bottom: 50px;
    input {
      font-family: 'headers', Arial, sans-serif;
      font-size: 1.5rem;
      color: $rk_grey7;
    }
    &__field {
      display: flex;
      font-size: 1.5rem;
      h4 {
        margin: 0;
        flex-grow: 2;
        display: flex;
        align-items: center;
        color: $rk_grey6;
      }
      .mat-mdc-form-field {
        max-width: 50%;
        text-align: right;
      }
    }
  }
}
