@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes show {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  5%{
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}


@keyframes appear {
  0% {
    display: none;
    opacity: 0;
  }
  5%{
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes spinner5 {
  25% {
    transform: scaleY(2);
  }

  50% {
    transform: scaleY(1);
  }
}

@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


@keyframes upriseModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes showRouter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
