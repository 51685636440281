.reports {
  min-height: 100vh;
  padding-bottom: 300px;
  h1{
    color: $rk_step4;
    font-size:2rem;
    img{
      margin:0 0 0 20px;
      width: 30px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    width: 100% ;
    margin-bottom: 3vh;
    min-height: 100%;
    height: auto;
    h2 {
      min-width: 100%;
    }
  }
  .-no-reports {
    font-size: 1.5rem;
  }
  &__graph{
    width: 100%;
    margin:0 auto 5vh;
    height: 500px;
    max-height: 60vh;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: 1px solid $rk_grey1;
      padding: 0 10px;
      h2 {
        color: $rk_grey8;
        font-size: 2.3rem;
        margin: 0 0 20px;
      }
    }
    &__nav{
      display: flex;
      align-items: center;
      flex-grow: 2;
      justify-content: flex-end;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: right;
      color: $rk_copper4;
      font-size:1.2rem;
      font-weight: bold;
      border-left: 1px solid $rk_grey1;
      padding:5px 10px;
      &:hover {
        figcaption {
          color :$rk_copper3;
        }
      }
      img{
        width: 20px;
        margin-right: 10px;
      }
      figcaption {
        max-width: 120px;
        color: $rk_grey3;
      }
    }
    &__info{
      font-size:1.2rem;
      margin-bottom: 30px;
      li{
        margin-bottom: 5px;
      }
      strong {
        margin-right: 10px;
      }
    }
  }
  &__params{
    min-width: 20%;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    &__title{
      display: flex;
      margin-bottom: 30px;
      img{
        margin: -20px 10px 0 -20px;
        width: 55px;
        filter: saturate(0);
      }
    }
    .mat-mdc-checkbox{
      min-width: 50%;
    }
    &__navs{
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 50%;
      @include mq(tablet-small) {
        flex-direction: column;
      }
    }
    &__remove{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 2;
      visibility: hidden;
      &.--show {
        visibility: visible;
      }
      @include mq(tablet-small) {
        margin:10px auto;
      }
      img{
        width: 20px;
      }
      /*&__text{
        font-size: 1rem;
        color: $rk_grey4;
        text-align: right;
        max-width: 180px;
        padding-right: 10px;
        @include mq(tablet-small) {
         max-width: unset;
        }
      }*/
    }
    &__apply{
      margin-top:30px;
    }
  }
  /*&__icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }*/
  &__list{
    &.--noReports{
      color: $rk_copper4;
      font-size:2rem;
    }
    &__item{
      width: 18%;
      margin: 1% 1% 3%;
      min-height: 90px;
      display: block;
      min-width: 225px;
    }
    &__avatar{
      display: flex;
      align-items: center;
      &__img {
        max-width: 90px;
        width: 30%;
      }
    }
    &__data{
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $rk_grey2;
      height: 100%;
      h3{
        margin: 0 0 5px;
      }
      h4 {
        color: $rk_grey5;
        margin:0;
        font-family: basic, Arial, sans-serif;
        font-weight: lighter;
        font-size: 1.1rem;
      }
      span{
        display: block;
      }
    }

  }
  &__dialog{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .mat-mdc-form-field {
      font-size:1.5rem;
    }
  }
}

