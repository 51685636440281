.goals {
  &__content  {
    width: 100%;
    margin:30px auto;
    display: flex;
    flex-wrap: wrap;
  }
  &__item{
    width: 18%;
    margin: 1% 1% 3%;
    min-height: 90px;
    display: block;
    min-width: 225px;
  }
  &__avatar{
    display: flex;
    align-items: center;
    &__img {
      max-width: 90px;
      width: 30%;
    }
  }
  &__data{
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid $rk_grey2;
    height: 100%;
    h3{
      margin: 10px 0 5px;
    }
    h4 {
      color: $rk_grey5;
      margin:0;
    }
  }
  &__list{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}


.goal-manager {
  &__title {
    margin:20px 0 5px;
  }
  &__subtitle {
    margin: 20px 0 10px;
    color: $rk_grey5 !important;
  }
  &__one-to-one {
    margin: 5px 0 10px 0;
    font-style: italic;
    span {
      font-size: 1.2rem;
    }
  }
  &__status{
    display: flex;
  }
  &__score{
    display: flex;
    @include mq(tablet-small){
      flex-direction: column;
    }
    h4{
      margin-top: 0;
      color: $rk_grey3;
    }
    &__min{
      padding: 0 20px 0 0;
      border-right: 1px solid $rk_grey1;
    }
    &__max{
      padding: 0 0 0 20px;
    }
    &__min, &__max{
      width: 50%;
      @include mq(tablet-small){
        border:0 ;
        width: 100%;
        padding: 0;
      }
    }
    .basic-filters__valoration{
      justify-content: center;
    }
  }
  &__icon{
    width: 30px;
    height: 30px;
    &.--main {
      margin-right: 20px;
    }
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-mdc-form-field {
      max-width: 60px;
    }
    input{
      text-align: right;
      font: 2rem/2rem headers, Arial, sans-serif;
    }
  }
}



.goals-list {
  width: 100%;
  height: calc( 100% - 50px);
  display: flex;
  flex-wrap: wrap;
  &__goal{
    max-width: 45%;
    margin: 0 auto;
    min-width: 220px;
    &__title {
      text-align: center;
      margin-bottom: 0;
    }
    &__container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__trophy {
      position: absolute;
      height: 40%;
      top: 30%;
      left: 28%;
    }
    horus-custom-charts{
      width: 100%;
      height: 100%;
      opacity: .4;
      filter: saturate(0) brightness(3.5);
      .label {
        display: none;
      }
    }
    .goals-list__goal__target{
      position: absolute;
      opacity: .8;
      filter: saturate(1);
      .pie-grid-arcs g:nth-of-type(2) {
        display: none;
      }
      .label {
        display: unset;
      }
    }
    .pie-grid .percent-label {
      font-size: 23px;
      font-weight: bold;
      font-family: 'special', Arial, sans-serif;
    }
  }
}
