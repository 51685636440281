horus-wysiwyg{
  font-size: .9em;
  font-family: 'basic', Arial, sans-serif;
  font-weight: lighter;
  line-height: 1.2;
  color: $rk_horusgrey;
  margin: 20px auto;
  position: relative;
  a{
    color:$rk_loader3;
    text-decoration: underline;
  }
  em{
    display: block;
    color: $rk_gradient1;
    font-size: .8em;
  }
  .mat-mdc-form-field{
    position: absolute;
    top: -44px;
    width: 200px;
    max-width: 50%;
    right: 0;
    .mat-mdc-form-field-label-wrapper {
      display: none;
    }
  }
  .mat-mdc-form-field-underline{
    background-color: $rk_transparent !important;
  }
  .marker-yellow {
    user-modify: read-only;
    user-select: none;
    -moz-user-modify: read-only;
    -webkit-user-modify: read-only;
  }
}
