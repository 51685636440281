
.config {
  h1{
    color: $rk_horus00;
    font-size:2rem;
    transition: font-size .05s linear;
    span {
      @include  mq(tablet){
        font-size:2rem;
      }
      @include  mq(phablet){
        font-size:1.3rem;
      }
      &:nth-of-type(1) {
        padding-left: 0;
      }
    }
    img{
      margin:0 0 0 20px;
      @include  mq(phablet){
        margin-left: 10px;
      }
    }
  }
  &-header{
    display: flex;
    &.--header{
      @include  mq(tablet){
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__nav{
      display: flex;
      align-items: center;
      min-width: 100px;
    }
    nav {
      width: 42vw;
      max-width: 90%;
      @include  mq(desktop-wide){
        width: 52vw;
      }
      @include  mq(tablet){
        position: relative;
        order: -1;
        margin: 10px auto 20px;
        width: 600px;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  &-area {
    flex-direction: column;
    h1{
      width: 100%;
      font-size:3rem;
      order: -20;
    }
    a{
      text-decoration: none;
      color: $rk_grey7;
      font-size: 1.2rem;
      margin-top: 2.5vh;
    }
    figure {
      position: relative;
    }
  }
  &__alert {
    position: absolute;
    top:10px;
    right: 10px;
    height: 20px;
    width: 20px;
    filter: none !important;
  }
}
