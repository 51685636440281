.users{
  &__role{
    border-width: 3px 0 0 0;
    border-style: solid;
    padding: 6px 0 0;
    border-radius: 2px;
    font-size: 10px;
    .reports & {
      color: transparent;
      font-size: 0;
    }
  }
}

.user-manager{
  &__name {
    border-left: 1px solid $rk_grey2;
    margin-left:10px;
    padding-left: 10px;
    @include  mq(phablet) {
      width: 100%;
    }
    .mat-mdc-form-field {
      margin: 0;
    }
  }
  &__calendar {
    display: flex;
    align-items: center;
    margin: 10px 0 20px;
    &__img{
      width: 30px;
      margin-right: 10px;
    }
    &__revert {
      display: block;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: $rk_gradient3;
      }
    }
  }
  &__msgs {
    margin: 10px 0;
    display: block;
    font-family: "special", Arial, sans-serif;
    font-weight: bold;
    font-size: 1.3rem;
    color: $rk_step4;
    strong {
      font-family: "headers", Arial, sans-serif;
    }
  }
  &__nosharing {
    color: $rk_progress;
  }
  .--clickable {
    cursor: pointer;
    color: $rk_loader1;
    transition: color .2s linear;
    &:hover {
      color: $rk_horus00;
    }
  }

}
