
@use '@angular/material' as mat;
@include mat.core();
$mat-horus: (
  50: #f9f871, 100: #e7c14e, 200: #ff8080, 300: #c5380f,
  400: #0c99ff, 500: #008fa1, 600: #006390, 700: #133866,
  800: #006390, 900: #005265, A100: #bff3ff, A200: #3365ff,
  A400: #133866, A500: #247be8, A700: #b397ff);

$horus-primary: mat.define-palette(mat.$indigo-palette, 500);
$horus-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$horus-warn: mat.define-palette(mat.$red-palette);
$horus-typography: mat.define-typography-config();
$horus-theme: mat.define-light-theme((
  color: (
    primary: $horus-primary,
    accent: $horus-accent,
    warn: $horus-warn,
  ),
  typography: $horus-typography,
  density: 0,
));
@include mat.core-theme($horus-theme);
@include mat.form-field-theme($horus-theme);
@include mat.autocomplete-theme($horus-theme);
@include mat.input-theme($horus-theme);
@include mat.checkbox-theme($horus-theme);
@include mat.radio-theme($horus-theme);
@include mat.select-theme($horus-theme);
@include mat.slider-theme($horus-theme);
@include mat.datepicker-theme($horus-theme);
@include mat.tooltip-theme($horus-theme);



:root {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mat-select-trigger-text-size: 1.3rem;
  --mat-form-field-container-text-size: 1.3rem;
  --mdc-filled-text-field-label-text-size: 1.3rem;
  --mat-select-enabled-trigger-text-color: $rk_grey7;
  --mdc-filled-text-field-container-color: $rk_white;
  --mat-select-disabled-trigger-text-color: $rk_grey6;
  --mat-optgroup-label-text-color: $rk_grey3;
  --mat-optgroup-label-text-font: headers, Arial, sans-serif;
  --mdc-filled-text-field-label-text-font:  basic, Arial, sans-serif;
  --mdc-filled-text-field-input-text-color:  basic, Arial, sans-serif;
  --mdc-filled-text-field-input-text-placeholder-color:  $rk_grey4;
  --mat-form-field-state-layer-color: $rk_white;
  --mat-form-field-container-vertical-padding: 0;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0;
  --mat-select-trigger-text-tracking: -.02rem;
  --mdc-checkbox-state-layer-size: 30px;
  --mdc-filled-text-field-label-text-size: 1.1rem;
}


//--------------------------------------------------------BASIC FORM
form {
  font-size: 1.2rem !important;
  font-family: special, Arial, sans-serif !important;
  padding: 0 2vw;
  border-left: 5px solid;

  .Berror {
    position: absolute;
    bottom: -17px;
    left: 0;
    padding: 0;
    font-size: .75em;
  }

  &.ng-valid {
    border-color: $rk_horus03;
  }

  &.ng-invalid {
    border-color: $rk_horus08;
  }
}

.form{
  &__warning{
    color:$rk_horus08;
    display: inline-block;
    padding: 25px 20px;
    font-weight: bold;
    margin: auto;
  }
  &__showpass{
    cursor:pointer;
    position: absolute;
    right: 2px;
    top: -3px;
    width: 30px;
    transition: transform .2s linear;
    &:hover, &:focus{
      transform: scale(1.15);
    }
  }
}


.readonly, [readonly] {
  color: $rk_grey3 !important;
  text-decoration: line-through;
}

.textarea{
  color: $rk_grey6;
  border: 1px solid $rk_grey1 !important;
  border-radius: 5px !important;
  font-size: 1.1rem !important;
  padding: 5px !important;
  font-family: basic, Arial, sans-serif;
  width: 100%;
  min-height: 50px;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 10px 0;
  &.center {
    text-align: center;
  }
}


//--------------------------------------------------------BASIC MAT
.mat-mdc-form-field {
  width: 100%;
}
.mdc-text-field {
  padding: 0 !important;
}
.mat-mdc-form-field-infix {
  min-height: 0 !important;
}
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mdc-line-ripple{
  opacity: .5;
  &:before,
  &:after {
    border-color: $rk_grey3 !important;
  }
}
.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  .--inactive & {
    filter: saturate(0);
    opacity: .5;
  }
}
.mat-mdc-optgroup-label {
  border-bottom: 1px solid $rk_horus01;
  min-height: 25px !important;
  padding-left: 0 !important;
  opacity: .8;
  font-size:1.6rem;
  color: $rk_grey6;
  letter-spacing: -.02em;
  margin: 20px 10px 0;
}
// LABEL


.mat-mdc-floating-label {
  top: 23px !important;
}
.mdc-floating-label--float-above {
  top: 19px !important;
}
.mdc-floating-label {
  color: $rk_grey6;
  font-size: 1.2rem;
  overflow: visible !important;
}


// radio

.mat-mdc-radio-group {
  display: flex;
  width: auto;
}
.mat-mdc-radio-button {
  margin:10px;
}
.mat-label{
  font-family: basic, Arial, sans-serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $rk_grey5;
}
.mdc-label{
  cursor: pointer;
}


// TOOLTIP
.mdc-tooltip {
  white-space: pre-line;
  &__surface {
    font-size: 1.1rem !important;
    padding: 10px !important;
  }
}
.--tooltip{
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  transition: transform .2s linear;
  position: absolute;
  margin: -5px 10px;
  @include mq('tablet'){
    transform: scale(.8);
  }
  &:hover, &:focus{
    transform: scale(1.3);
  }
}



// INPUT
.cdk-text-field-autofilled {
  box-shadow: inset 5em 7em $rk_white !important;
}
.mat-mdc-input-element[disabled] {
  color: $rk_grey6 !important;
}
.mat-form-field-disabled .mdc-line-ripple {
  &:before {border-bottom:2px dotted $rk_grey4 !important;}
  &:after {display: none;}
}
.mdc-text-field--disabled {
  background: $rk_transparent !important;
}

input::placeholder{
  font-weight: normal !important;
  letter-spacing: 0 !important;
  font-size: 1.2rem !important;
  color: $rk_grey6 !important;
}

// checkbox
.mat-mdc-checkbox label {
  line-height: 1;
  font-size: 1.1rem;
  color: $rk_grey6;
}

.mat-custom-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
// AUTOCOMPLETE

horus-lead-autocomplete,
horus-tag-autocomplete,
horus-search-engine{
  width: 100%;
}
.--autocomplete{
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  width: 100%;
  max-height: 51px;
  flex-grow: 2;
  .mat-mdc-input-element{
    background: url(/assets/img/icons/buscar.svg) no-repeat right center;
    background-size: 20px;
  }
  .mdc-line-ripple {
    &:before {border-color: rgba($rk_grey5, .5) !important;}
    &:after {border-color: $rk_gradient2 !important;}
  }
  .mat-mdc-form-field-infix {
    width: 100px;
  }
  &__id {
    margin-right: 10px;
  }
  &__all {
    margin-left:10px;
    min-width: 40px;
    text-align: right;
    font-family: basic, Arial, sans-serif;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 0.8;
    &:hover{
      color: $rk_black;
    }
  }
  &__match{
    strong  {
      color: $rk_loader1;
    }
    margin-left: 15px;
  }
}

.cdk-overlay-pane {
  box-shadow: 1px 2px 10px $rk_grey2 !important;
  border-radius: 5px;
  width: auto !important;
  max-width: 100% !important;
  display: block;
  min-height: 0;
}
.mat-mdc-autocomplete-panel {
  width: calc(105% + 30px) !important;
  display: block;
  overflow-y: scroll !important;
  max-width: 200% !important;
  &.mat-mdc-autocomplete-hidden {
    display: none;
  }
  .mat-mdc-option {
    background: $rk_white;
    color: $rk_black;
    min-height: 0;
    @include mq(tablet) {
      min-height: 50px;
    }
    img{
      @extend .mat-custom-avatar;
    }
    @include mq('tablet'){
      font-size: 1.2rem !important;
    }
  }
}


// ------------------------------------------------------- BASIC FORMS



// BUTTONS

.button {
  padding: 15px 40px;
  font-size: 1.5rem;
  font-family: basicbold, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  border-radius: 10px 0 10px 0;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  background:  map-get($horus-accent, 500);
  color: $rk_white;
  transition: background-color .2s linear;
  &[disabled] {
    background: $rk_grey1;
    color: $rk_grey4;
    cursor: not-allowed;
    border-color: $rk_transparent;
    img{
      filter: saturate(0);
    }
    &:focus,
    &:active,
    &:hover {
      background: $rk_grey1 !important;
    }
  }
  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    background: map-get($mat-horus, A200);
  }
}

.invbutton{
  @extend .button;
  background:  $rk_white;
  border: 1px solid rgba($rk_horus08, .4);
  color: $rk_black;
  &:focus,
  &:active,
  &:hover {
    background:  $rk_horus08;
    color: $rk_white;
  }
}
.createbutton{
  @extend .button;
  background:  $rk_white;
  border: 1px solid rgba($rk_horus04, .4);
  color: $rk_black;
  &:focus,
  &:active,
  &:hover {
    background:  rgba($rk_horus04, .6);
    color: $rk_white;
  }
}
/*
// calendar
.--input-calendar { // en  mat-form-field o fieldset
  padding-right: 30px;
  background: url(/assets/img/icons/dia.svg) no-repeat center right;
  background-size: 25px;
  .mat-mdc-datepicker-input {
    color: $rk_grey5;
    text-align: right;
    cursor: pointer;
  }
}
*/

////////////////////////////////////////// PROFILE FORMS


.profile-form{
  display: flex;
  flex-wrap: wrap;
  padding: 20px 2vw;

  @include mq('tablet') {
    padding: 0 5vw;
  }
  &__errors{
    text-align: center;
    margin: 10px auto;
    color: $rk_grey5;
    cursor: pointer;
  }
  .form__warning{
    position: absolute;
    bottom: -40px;
    text-align: center;
    width: 100%;
    left: 0;
  }
  &--left{
    position: relative;
    flex-basis: 40%;
    flex-grow: 2;
    padding-right: 5%;
    border-right: 1px solid;
    @include mq('tablet') {
      border:0;
      flex-basis: 100%;
      max-width: 87vw;
      padding-right: 0;
      //padding-left: 30px;
    }
    @include mq('phablet') {
      max-width: 80vw;
    }
    &.--complete {
      min-width: 100% !important;
    }
    .ng-valid &{
      border-color:  $rk_horus04;
    }
    .ng-invalid &{
      border-color:  $rk_horus08;
    }
    &.--fifty{
      flex-basis: 50%;
      min-width: 50%;
      @include mq('tablet') {
        flex-basis: 100%;
        min-width: 100%;
      }
    }
  }
  &--right{
    flex-basis: 60%;
    @include mq('tablet') {
      flex-basis: 100%;
      max-width: 87vw;
      padding-left: 0;
    }
    &.--fifty{
      flex-basis: 50%;
      min-width: 50%;
      @include mq('tablet') {
        flex-basis: 100%;
        min-width: 100%;
      }
    }
  }
  &--unique{
    flex-basis: 100%;
    max-width: 700px;
  }
  &--base{
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3vh 0 1vh;
    button.button {
      margin: 10px auto;
    }
  }
  &__container{
    padding:2vh 0 10px 5%;
    position: relative;
    @include mq(tablet){
      padding-left: 0;
    }
    &.--nopadd{
      padding-bottom: 0;
    }
  }
  &__action-link{
    padding: 20px 10px 10px;
    text-align: center;
    cursor: pointer;
    &:hover, &:focus{
      color: $rk_copper1;
      text-decoration: underline;
    }
  }
  h3{
    margin: 10px 0;
    color: $rk_grey7;
  }
  &__mat-label {
    font-family: headers, Arial, sans-serif;
    color: $rk_grey6;
    font-size:1.2rem;
    margin: 10px 0;
    display: block;
  }
  .button{
    margin-top:30px;
  }
}

.edit{

  h3{
    color:rgba($rk_loader1, .6);
    border-bottom: 1px solid $rk_grey3;
  }
  .header2 .mat-mdc-input-element {
    font-family: headers, Arial, sans-serif;
    &.textarea {
      border: 0 !important;
      resize: none;
      min-height: 130px;
      line-height: 1;
      margin-top: -45px;
    }
  }
  .header2thin {
    margin-bottom: 30px;
    .mat-mdc-input-element {
      font-family: special, Arial, sans-serif;
    }
  }

  .header3thin{
    margin-bottom: 30px;
    .mat-mdc-input-element {
      font-family: special, Arial, sans-serif;
      font-size: 1.2rem;
    }
  }
  .header2, .header2thin {
    line-height: 1;

    .mdc-line-ripple {
      &:before, &:after {
        border-color: $rk_transparent !important;
      }
    }
    .mat-mdc-form-field{
      max-height: unset;
      margin: 0 0 10px;
    }
    .mat-mdc-input-element {
      letter-spacing: -.02em;
      padding: 0;
      font-size: 2.6rem !important;
      height: auto;
    }
    .mat-mdc-text-field-wrapper {
      padding-left: 0 !important;
    }
  }
  .manager__slider{
    font-size: 1.1rem;
    padding: 10px 1vw 10px 2vw;
  }
}

.manager {
  &__expand {
    top: 5px;
    right: -10px;
  }
  &__slider {
    display: flex;
    flex-direction: column;
    padding: 10px 1vw 10px 2vw;
    position: relative;
  }
}

.mat-mdc-input-element {
  &.--precio {
    font-family: 'headers', Arial, sans-serif !important;
  }
}

