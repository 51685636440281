.actions{
  &__content{
    margin:2vh auto 40px;
    &:after {
      content: "";
      flex-grow: 2;
    }
  }
  &-manager{
    &__owner {
      position: relative;
      bottom: unset;
      right: unset;
      left:-20px;
      display: inline-block;
      img {
        max-width: 44px;
      }
    }
    &__owner-name{
      width: 100%;
      font-size: 1.5rem;
      margin: -19px 0 0 -10px;
    }
    &__container{
      flex-direction: column;
      > div{
        width:100%;
      }
      .textarea {
        font-size: 1.7rem;
      }
      .--tooltip{
        right:0;
      }
    }
    &__score.header2{
      max-height: unset;
      text-align: center;
      .mat-mdc-input-element {
        color: $rk_copper2;
        margin: 0 auto 5px;
        text-align: center;
        width: 130px;
        height: 6rem !important;
        font-size: 6rem !important;
      }
      .mat-mdc-form-field-infix{
        display: flex;
      }
    }
    &__score-legend {
      color: $rk_grey3;
      font-size: 1.3rem;
      line-height: 1;
      font-weight: bold;
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin: -10px auto 20px;
      img{
        width: 20px;
      }
    }
  }
  &__item__circle{
    position: relative;
    min-height: 130px;
    margin: 30px 27px 30px 0;
    font-family: headers, Arial, sans-serif;
    padding: 10px;
    width: 130px;
    min-width: 130px;
    font-size:1.4rem;
    line-height: .8;
    border-radius: 50%;
    transition: all .1s linear;
    user-select: none;
    cursor: auto;
    @include mq('tablet') {
      max-width: 46%;
      margin: 0 2.35% 5vh;
    }
    &__bk{
      content: "";
      position: absolute;
      width: 138%;
      height: 124%;
      display: block;
      left: -15px;
      top: -30px;
      background-image: url(/assets/img/icons/accion_bola.svg);
      background-repeat: no-repeat;
      .--horus & {
        background-image: url(/assets/img/icons/accion-horus.svg);
      }
    }
    &__container{
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      color: $rk_grey5;
      font-size: 1.2rem;
      word-break: break-word  ;
      z-index: z(floor);
      position: relative;

    }
    &.editable:hover{
      transform: scale(1.05);
      filter:  saturate(0.8);
      cursor: pointer;
    }
    &:hover{
      a {
        color: $rk_black;
        .actions__item__score{
          color:$rk_black;
        }
      }
      img {
        filter: saturate(2);
      }
    }
    &__score{
      align-items: center;
      padding: 5px;
      margin: 0 auto 3px;
      line-height: 1;
      font-size: 2rem;
      color: $rk_grey4;
      min-height: 28px;
      small{
        font-size: 1rem;
        font-family: basic, Arial, sans-serif;
      }
    }

    &__owner{
      position: absolute;
      bottom:5px; right:5px;
      img {
        max-width: 35px;
      }
    }
   .circle-link {
      position: absolute;
      top:-10px;
      right:-25px;
      transform: scale(.6);
      background: $rk_grey3;
      &:hover{
        background: $rk_grey8;
        img{
          filter: none;
        }
      }
      img{
        filter: brightness(0);
      }
    }

  }
  &__item__group{
    position: relative;
    margin: 0 10px 10px;
    font-family: headers, Arial, sans-serif;
    font-size:1.4rem;
    line-height: .8;
    transition: all .1s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    cursor: auto;
    padding: 10px;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid $rk_grey2;
    @include  mq(tablet-small) {
      margin: 0 2vw 10px;
    }
    &:hover{
      border: 1px solid $rk_grey6;
      .actions__item__group__title   {
        opacity: 1;
      }
    }
    &__title {
      font-style: italic;
      text-transform: uppercase;
      font-size: 1.2rem;
      opacity: .4;
      transition: opacity .2s linear;
      text-align: center;
      max-width: 100px;
      margin: 0 0 20px;
      min-height: 25px;
      display: flex;
      align-items: center;
    }
    &__container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      margin: 0 10px;
      color: $rk_black;
      cursor: pointer;
      min-width: 85px;
      transition: transform .2s linear;
      &:hover{
        transform: scale(1.1);
        .actions__item__group__owner{
          opacity: 1;
        }
      }
      &.--done:hover .actions__item__group__channel {
        transform: none;
      }
    }
    &__options{
      display: flex;
    }
    &__channel{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      width: 40px;
      height: 40px;
      background: $rk_black;
      border-radius: 50%;
      .--done & {
        background: $rk_grey05;
      }
      &__icon{
        height: 50%;
        .--done & {
          filter: saturate(0);
        }
      }
    }
    &__bk {
      position: absolute;
      width: 40px;
      background-image: url(/assets/img/icons/accion_bola.svg);
      background-repeat: no-repeat;
      background-size: cover;
      top: -18px;
      left: -13px;
      height: 65px;
    }
    &__score {
      font-family: 'headers', Arial, sans-serif;
      font-size: 2rem;
      color: $rk_grey4;
      small {
        font-family: basicregular, Arial, sans-serif;
        font-size: .6em;
      }
    }
    &__done {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: -15%;
      left: 50%;
      font-size: .9rem;
      margin-left: 12px;
      figcaption{
        margin-top: 23px;
      }
      &:hover{
        img{
          filter: none;
        }
      }
      img{
        width: 15px;
        filter: none;
      }
    }
    &__owner{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-family: basic, Arial, sans-serif;
      text-align: left;
      margin: 11px 5px;
      opacity:.6;
      transition: opacity .2s linear;
      img {
        max-width: 25px;
        margin-right: 5px;
      }
      .--done {
        opacity: .4;
        &:hover & {
          opacity: .4;
        }
      }
    }

  }
}
horus-actions-list{
  .--no-results {
    margin: -30px 0 20px;
  }
}
