horus-alarms {
  user-select: none;
}
.alarms{
  &__type{
    opacity: .7;
    transition: opacity .2s linear;
    &:hover {
      opacity: 1;
    }
    strong {
      display: block;
      margin-right: 10px;
      color: $rk_white;
    }
    address{
      color: $rk_grey05;
      margin: 0 10px 0 0;
    }
    .cal-event-title {
      user-select: none;
      color: $rk_grey05;
      &:hover {
        cursor: default;
        text-decoration: none;
      }
      a {
        color: $rk_grey05;
        margin: 0 10px;
        font-size: 1.5rem;
        &:hover {
          color: $rk_gradient2;
        }
      }
    }
    &__calendario {
      border-bottom: 1px solid $rk_grey3;
      .cal-event {
        background: url("/assets/img/icons/calendar.svg") no-repeat center center;
        background-size: 90% auto;
      }
    }
    &__contacto {
      border-bottom: 1px solid $rk_grey8;
      .cal-event {
        background: url("/assets/img/icons/contacto.svg") no-repeat center center;
        background-size: 90% auto;
      }
    }
    &__acción {
      border-bottom: 1px solid  $rk_action2;
      .cal-event {
        filter:sepia(1) brightness(1.5) saturate(23.5) hue-rotate(-20deg);
        background: url("/assets/img/icons/accion_alarma.svg") no-repeat center center;
        background-size: 80% auto;
      }
    }
    &__plantilla {
      border-bottom: 1px solid $rk_action4;
      .cal-event {
        filter: sepia(1) saturate(2.5) hue-rotate(28deg) contrast(1.5);
        background: url("/assets/img/icons/doc.svg") no-repeat center center;
        background-size: 80% auto;
      }
    }
    &__refile {
      border-bottom: 1px solid $rk_grey8;
      .cal-event {
        background: url("/assets/img/icons/expediente.svg") no-repeat center center;
        background-size: 90% auto;
      }
    }
  }
  &-manager{
    .mat-mdc-radio-group {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      font-family: special, Arial, sans-serif;
    }
    .mat-mdc-radio-button {
      margin: auto 10px auto;
      cursor: pointer;
    }
    .mdc-form-field {
      height: 20px;
    }
    &__title{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      img{
        width: 30px;
        margin: -5px 8px 0 -30px;
        filter: saturate(0);
        @include mq('desktop') {
          margin: 0 8px 0 0;
        }
        @include mq('tablet') {
          display: none;
        }
      }
    }
    &__subtitle{
      padding: 10px 0 0;
      margin: 10px 0 0;
      width:100%;
      &-date{
        font-size: 2.3rem;
        font-family: headers, Arial, sans-serif;
        margin:10px auto 10px;
        color: $rk_gradient1;
        min-width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-wrap: wrap;
        @include mq(tablet) {
          font-size: 2rem;
        }
        span{
          padding-right: 10px;
          border-right: 1px solid $rk_grey4;
          display: inline-block;
          transition: color .2s linear;
          @include mq(phablet) {
            width: 100%;
          }
          &:hover{
            color: $rk_gradient3;
          }
        }
        figure{
          display: flex;
          font-size: 1.4rem;
          align-items: center;
          font-family: special, Arial, sans-serif;
          letter-spacing: 0;
          color:$rk_grey6;
        }
        img{
          margin:0 10px 0 20px;
          height: 30px;
          @include mq(tablet) {
            margin: 10px 10px 10px;
          }
        }
        .edit &{
          margin-top: 10px;
        }
      }
    }

    .page-modal__content {
      min-height: 550px;
    }


    &__asoc {
      margin-top:2vh;
      width: 100%;
      .edit & h3 {
        border-bottom: 0;
        margin: 20px 0 5px;
        color: $rk_black;
      }
    }
    &__typeselector {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mat-mdc-checkbox {
        margin: 10px auto;
        min-width: 200px;
      }
      .mdc-checkbox{
        display: none;
      }
      figure  {
        text-align: center;
        font-size:1.1rem;
        font-family: basic, Arial, sans-serif;
        transition: transform .2s linear;
        color: $rk_grey6;
        opacity: .7;
        display: flex;
        line-height: 1;
        align-items: center;
        &:hover, &.active {
          opacity: 1;
          color: $rk_black;
        }
      }
    }
    &__openplan{
      width: 100%;
      max-width:30px;
      margin: 5px;
    }
    &__names{
      width: 100%;
      .edit &{
        font-size: 2.5rem;
        .mat-mdc-input-element{
          font-size: 2.2rem;
          @include mq(tablet) {
            font-size: 2rem;
          }
        }
      }
      .header2thin {
        font-size: 1.3rem;
        .mat-mdc-input-element {
          font-size: 1.3rem;
          font-family: basic, Arial, sans-serif;
          min-height: 60px;
          padding: 10px 0;
        }
      }
    }
    &__description {
      margin: 15px 0 0;

      .textarea {
        min-height: 80px;
      }
    }
    &__dates{
      flex-wrap: wrap;
      justify-content: flex-start;
      position: relative;
      margin: 10px 0 0;
      .header2{
        margin-top:10px;
        color: $rk_gradient1;
      }
    }
    &__date{
      height: 0;
      visibility: hidden;
      padding: 0;
      margin: 0;
    }
    &__repeat{
      display: flex;
      align-items: center;
      padding:10px 0;
      .mat-mdc-checkbox{
        margin-right: 10px;
      }
      .mat-mdc-form-field{
        max-height: 20px;
        margin: 0 10px;
      }
      .mat-mdc-text-field-wrapper {
        max-width: 30px;
        text-align: center;
        border-top: 0;
        padding: 0 !important;
      }
      .mat-mdc-input-element{
        font-size: 1.1rem !important;
      }
    }
    .actions__item{
      margin-bottom:0;
    }
    .actions__content {
      margin: 10px 0;
    }
    .actions__item__circle {
      margin: 20px 10px 0;
    }
    .--squares{
      min-height: 0;
      .templates__item{
        margin-bottom:0;
      }
    }
  }
}
