@mixin font-face($font-name, $file-name) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.woff2') format('woff2');
  }
}

@include font-face('basic', '/assets/fonts/Roboto-Regular');
@include font-face('special', '/assets/fonts/Montserrat-Light');
@include font-face('headers', '/assets/fonts/Montserrat-Bold');
