
$h: 30cm;
$w: 21.5cm;

.v-report {
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: z(level5);
  .appraiser__dashboard__header{
    padding: 10px 20px;
    color: $rk_white;
    @include mq(tablet-small) {
      padding:10px 0 10px 10px;
    }
  }
  .appraiser__dashboard__icon{
    background-color: $rk_white;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    justify-content: center;
    display: flex;
    transform: scale(.6);
  }
  &__generate{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba($rk_white, .9);
    text-align: center;
    img {
      width:  120px;
      transition: transform .2s linear;
      margin: 20px auto;
    }
    figcaption  {
      font-family: headers, Arial, sans-serif;
      font-size: 3rem;
      color: #00efd1;
    }
  }
  &__link {
    font-size: 1.5rem;
    margin: 20px 0;
    a {
      cursor:pointer;
      color: $rk_gradient1;
      &:hover{
        color:  $rk_gradient3;
      }
    }
  }
  small {
    font-family: special, Arial, sans-serif;
  }
  &__pies {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__pie {
      height: 300px;
      min-width: 46%;
      margin:4% 2%;
    }
  }
  .--amarillo {
    background-color: $rk_appraiser-yellow;
    color: $rk_black;
  }
  .--verde {
    background-color: $rk_semaphoreGreen;
    color: $rk_white;
  }
  .--rojo {
    background-color: $rk_appraiser-red;
    color: $rk_white;
  }
}


#termometro {
  position: absolute;
  height: 600px;
  left: 55%;
  margin-left: -25px;
}

.priceRanges__precios-quizas{
  top: 482px;
}
.priceRanges__precios-visitas{
  top: 682px;
}
.--priceRanges{
  width: 60%;
  position: absolute;
  margin: 0 15%;
  background: $rk_grey2;
  height: 1px;
}

#page-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 100px 0;
  border: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: rgb(158, 158, 158 , 95%);
}


.page {
  width: 21cm;
  height: 29.7cm;
  margin: 13px auto;
  box-shadow: 1px 1px 3px 1px #333;
  border-collapse: separate;
  position: relative;
  background-color: $rk_white;
  overflow: hidden;
  border: 0;
  display: flex;
  flex-direction: column;
  transition: opacity .2s linear;
  &:nth-of-type(even) {
    .page__header__title{
      margin: 0 20% 0 0;
      padding-left: 20%;
    }
  }
  &.--hiddenPage:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($rk_black, .5);
    content: "";
    z-index: z(level2);
  }
  &.--withbk{
  background: linear-gradient(180deg, $rk_white 70%, $rk_grey3);
  }
  &.fullpage{
    justify-content: center;
    align-items: center;

  }
  &__hide {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: z(level3);
    margin: 0;
    height: 60px;
    width: 60px;
    cursor: pointer;
    filter: saturate(0);
    opacity: 0.5;
    transition: all .2s linear;
    &:hover {
      filter: saturate(1);
      opacity: 1 !important;
    }
  }
  &__fullpage {
    max-width: 101%;
    min-height: 100%;
  }
  &__header{
    width: 100%;
    position: relative;
    color: #FFF;
    text-shadow: 0 0 10px $rk_black;
    max-height: 300px;
    overflow: hidden;
    &.--mini {
      max-height: 250px;
    }
    &__back{
      margin: 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      &__img{
        height: auto;
        width: 100%;
      }
    }
    &__title{
      padding: 0 0 20px;
      margin: 0 0 0 20%;
      width: 80%;
      position: absolute;
      line-height: 1.1;
      border-bottom: 6px solid $rk_white;
      left:0;
      h2{
        margin: 0;
        font-size:4.05rem;
        letter-spacing: 0;
      }
      &__intro{
        display: block;
        font-size: 0.4em;
        margin: 0 0 10px;
      }
    }
  }
  &__intro {
    position: absolute;
    padding: 13% 50% 10% 10%;
    height: 100%;
    color: $rk_white;
    font-size: 1.2rem;
    line-height: 1.5;
    text-shadow: 0 0 30px rgba($rk_black, .4);
  }
  &__backmap {
    position: absolute;
    width: 100%;
    bottom: 60px;
  }
  &__content{
    margin: 4% 20%;
    font-size: 1.2rem;
    line-height: 1.3;
    flex-grow: 2;
    text-shadow: none;
    display: flex;
    flex-direction: column;
    &.--portada {
      z-index: 1;
      margin: -80px auto 4%;
    }
    &.--fullwidth {
      margin: 4% 5%;
    }
    h2, h3 {
      font-family: 'special', Arial, sans-serif;
      text-transform: uppercase;
    }
    &__user{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $rk_grey6;
      &__avatar {
        max-height: 200px;
        margin-bottom: 20px;
        background-color: $rk_white;
      }
      &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $rk_grey6;
        &__name {
          font-size: 2.5rem;
        }
        strong {
          font-family: 'headers', Arial, sans-serif;
        }
        a {
          font-size: 2rem;
          &:hover{
            color: $rk_appraiser-red;
          }
        }
      }
    }
    &__title{
      color: $rk_appraiser-red;
      font-size: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
      font-family: 'headers', Arial, sans-serif;
      line-height: 1;
      small{
        color: #333;
        font-size: .4em;
        font-family: 'special', Arial, sans-serif;
      }
    }
    &__subtitle {
      margin: 0;
    }
    &__address{
      font-style: italic;
      font-family: 'headers', Arial, sans-serif;
      font-size: 1.8rem;
      text-align: center;
      max-width: 70%;
      margin: 0 auto;
      line-height: 1;
    }
    &__prop-info {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      background: rgb($rk_grey1, .3);
      border-radius: 15px;
      margin: 20px auto;
      max-width: 80%;
      border: 1px solid $rk_grey3;
    }
    &__prop-item {
      font-size: 1.2rem;
      padding: 0 5px;
      width: 50%;
      strong  {
        text-transform: uppercase;
        font-size: .8em;
        color: $rk_grey5;
      }
    }
    &__main {
      flex-grow: 2;
    }
    &__link{
      text-align: center;
      margin: auto;
      color: $rk_black;
      padding-bottom: 6%;
      strong  {
        display: block;
      }
    }
    &__qr {
      max-width: 120px;
    }
  }
  &__map {
    height: 300px;
  }
  &__poi {
    display: flex;
    h2{
      margin: 0 0 10px;
    }
    horus-map{
      img {
        min-width: 100%;
      }
    }
    &__list{
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      h3{
        margin: 0;
      }
    }
    &__item{
      padding: 0 1%;
      width: 48%;
      &:nth-of-type(2),
      &:nth-of-type(3){
        width: 26%;
      }
      ol{
        padding-left: 18px;
      }
    }
  }

  &__source{
    font-style: italic;
    margin: 5px 10%;
    color: $rk_grey5;
  }
  &__charts{
    min-width: 100%;
    position: relative;
    .total-value,
    .legend-title-text,
    .item-value {display: none !important}
    .item-label{
      margin-top: 0 !important;
    }
    &__legend{
      border-top: 1px solid $rk_grey3;
      padding: 10px 20px 10px 10px;
      margin: 10px 0 0 0;
      line-height: 1;
      width: 100%;
      position: relative;
      top: 629px;
      color: $rk_grey6;
      ul{
        display: flex;
        max-width: 90%;
        flex-wrap: wrap;
        margin-bottom: 0;
      }
      li {
        min-width: 40%;
        margin-left: 10%;
      }
    }
    &__recommended-price {
      font-size: 2rem;
      position: absolute;
      left: 490px;
      top: 490px;
      color: $rk_appraiser-green;
      font-family: headers, Arial, sans-serif;
      padding: 10px 0 0 10px;
      .page__charts__your-price__legend {
        top: 40px;
      }
    }
    &__your-price{
      font-size: 2.5rem;
      font-family: headers, Arial, sans-serif;
      position: absolute;
      left: 490px;
      padding: 5px 10px;
      border-radius: 10px;
      &__img{
        height: 26px;
        position: absolute;
        top: 14px;
        left: -68px;
        opacity: .5;
        filter: hue-rotate(-106deg);
        .--rojo & {
          filter: hue-rotate(-175deg);
        }
        .--amarillo & {
          filter: hue-rotate(-147deg);
        }
      }
      &__legend{
        position: absolute;
        top: 55px;
        font-family: special, Arial, sans-serif;
        font-weight: bold;
        color: $rk_black;
        font-size: 1.1rem;
        width: 200px;
        line-height: 1;
        left: 0;
      }
    }
  }
  &__witnessess {
    display: flex;
    align-items: center;
    max-height: 150px;
    overflow: hidden;
    border-bottom: 1px solid $rk_grey5;
    padding:20px 0;
    &:last-child{
      border: 0;
    }
    &__number{
      display: flex;
      min-width: .7cm;
      min-height: .7cm;
      max-height: .7cm;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-family: 'headers',Arial, sans-serif;
      position: absolute;
      left: 30px;
    }
    &__img-cont {
      overflow: hidden;
      max-width: 130px;
      min-width: 130px;
      margin:0 10px;
      border: 1px solid $rk_grey2;
      height: 100%;
    }
    &__img{
      min-height: 100%;
      width: auto;
      max-height: 100%;
    }
    &__data{
      flex-grow: 2;
      h3{
        margin: 0;
        color: $rk_grey6;
        display:  inline;
      }
      &__items{
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        text-align: center;
        font-family: 'special', Arial, sans-serif;
        font-weight: bold;
        font-size: 1rem;
        figure{
          padding: 0 5px;
          border-right: 1px solid $rk_grey3;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 70px;
          line-height: 1;
          &:last-child {
            border: 0;
          }
        }
        img{
          margin: 0 0 10px;
          &.--nope{
            filter: saturate(0);
          }
        }
      }
      &__price{
        font-family: 'headers', Arial, sans-serif;
        font-size: 1.6rem;
        min-width: 141px;
        text-align: right;
        padding-left: 10px;
        line-height: 1;
        small {
          font-size: .7em;
        }
      }
    }
  }
  &__advice{
    position: absolute;
    left: 5%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    min-height: 140px;
    filter: saturate(0) brightness(0);
    opacity: .2;
    &._active{
        filter: none;
        opacity: 1;
    }
    h2{
      font-family: 'headers',Arial, sans-serif;
      font-size: 2.5rem;
      line-height: 1;
      margin: 0 0 20px;
    }
    &._verde {
      top: 430px;
      h2 {
        color: $rk_semaphoreGreen;
      }
    }
    &._rojo {
      top: 24px;
      h2 {
        color: $rk_semaphoreRed;
      }
    }
    &._amarillo {
      top: 207px;
      height: 185px;
      h2 {
        color: $rk_semaphoreYellow;
      }
    }
    &__recommended {
      position: relative;
      top: 637px;
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1;
      font-family: headers, Arial, sans-serif;
      padding-bottom: 5px;
      color: $rk_grey7;
      &__price {
        font-size: 2.5rem;
        &:nth-of-type(2) {
          color: $rk_semaphoreGreen;
        }
      }
      span {
        flex-grow: 2;
        &:nth-of-type(3) {
          border-left: 1px solid $rk_grey5;
          padding-left: 20px;
        }
      }
    }
  }
  &__footer{
    position: relative;
    width: $w;
  }
  .--forcewhite {
    color: $rk_white !important;
  }
  .--white{
    color: $rk_white;
    border-color:$rk_white;
    text-shadow: none;
  }
  .--black{
    color: $rk_black;
    border-color: $rk_black;
    text-shadow: none;
  }
}

#sidebar{
  position: fixed;
  top: 50px;
  right: 28px;
  width: 40px;
  @include mq(tablet-small) {
    right: 8px;
  }
  span{
    border-radius: 50%;
    background-color: $rk_grey8;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    cursor:pointer;
    transition: background-color .2s linear;
    &:nth-of-type(-n+2){
      @include mq(tablet-small) {
        display: none;
      }
    }
    &:nth-of-type(3){
      display: none;
    }
    &.--disabled {
      filter: saturate(0);
      cursor: default;
      background-color: $rk_grey2;
      &:hover{
        background-color: $rk_grey2;
        img {
          max-height: 60% ;
        }
      }
    }
    &:hover{
      background-color: $rk_black;
      img {
        max-height: 70% ;
      }
    }
  }
  img{
    transition: max-height .2s linear;
    max-height: 60% ;
    filter: invert(1);
  }
}

@media screen {

  .--zoom {
    display: flex;
    flex-wrap: wrap;
    padding-right: 100px !important;
    @include mq(tablet-small) {
      padding-right: 50px !important;
      .page {
        margin: -325px -225px !important;
        transform: scale(.4) !important;
      }
    }
  }
  .--zoomtransition .page {
    opacity: 0;
  }
  .--zoom9 .page{
    margin:-25px -10px;
    transform: scale(.9);
  }
  .--zoom8 .page{
    margin: -90px -60px;
    transform: scale(.8);
  }
  .--zoom7 .page{
    margin: -150px -105px;
    transform: scale(.7);
  }
  .--zoom6 .page{
    margin: -200px -135px;
    transform: scale(.6);
  }
  .--zoom5 .page{
    margin: -265px -180px;
    transform: scale(.5);
  }
  .--zoom4 .page{
    margin: -325px -225px;
    transform: scale(.4);
  }
  .--zoom3 .page{
    margin: -385px -270px;
    transform: scale(.3);
  }
  .--zoom2 .page{
    margin: -442px -310px;
    transform: scale(.2);
  }
  .--zoom1 .page{
    margin: -500px -350px;
    transform: scale(.1);
  }
}
@media print {
  @page{
    height: $h;
    min-height: $h;
    max-height: $h;
    width: $w;
    min-width: $w;
    max-width: $w;
    margin:0 !important;
  }
  html{margin:0}
  body{margin:0;-webkit-print-color-adjust:exact}
  .v-report {
    position: relative;
    height: auto;
  }
  #sidebar, .v-report__generate, .page__hide {display:none}
  #page-container{
    width:auto;
    height:auto;
    overflow:visible;
    background-color:transparent;
    background-image: none;
    padding: 0;
  }
  .page{page: blank;
    height: $h;
    min-height: $h;
    max-height: $h;
    width: $w;
    min-width: $w;
    max-width: $w;
    margin: 0 !important;
    box-shadow:none;
    page-break-after:always;
    page-break-inside:avoid;
    position:relative;
    background-color:$rk_white;
    overflow:hidden;
    border:0;
    text-shadow: none;
    transform: unset !important;
    &.--hiddenPage {
      display: none;
    }
  }
  @-moz-document url-prefix(){
    .page{
      overflow:visible;
      border:1px solid $rk_white
    }
  }
  .appraiser__dashboard__header,
  .appraiser__dashboard__footer,
  .appraiser__dashboard__menu,
  .appraiser__dashboard__info,
  .appraiser__results{
    display: none;
  }
}

