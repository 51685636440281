horus-re-file-score {
  display: block;
  position: relative;
  h2 {
    margin-top: 0;
    padding-right: 45px;
  }
}

.stars__score{
  position: relative;
  width: 90%;
  max-width: 230px;
  margin: 0 0 20px;
  line-height: 0;
  overflow: hidden;
  img{
    position: relative;
    max-width: 100%;
    min-height: 100%;
    z-index: z(level1);
  }
  &__bk{
    display: block;
    position: absolute;
    background-color: $rk_grey05;
    z-index: z(floor);
    top: 0;
    height: 99%;
    width: 100%;
    left: 0;
  }
  &__value {
    transition: width .5s linear;
    background-image:  linear-gradient( to top, $rk_horus06 30%, $rk_horus05 50%);
    border-right: 1px solid rgba($rk_horus08, .4);
    top: 0;
    height: calc( 100% - 1px);
    width: 100%;
    left: 1px;
    position: absolute;
    opacity: 0;
    animation: upriseModal .5s linear .5s forwards;
  }
  &__edition {
    border-radius: 50%;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid $rk_grey3;
    position: absolute;
    right: 0;
    top: 0;
  }
}
