
horus-custom-lightbox {
  width:100%;
}

.custom-lightbox{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__img{
    width: 20%;
    margin: 1%;
    min-width: 150px;
  }
}
