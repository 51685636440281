.step {
  display: flex;
  flex-wrap: wrap;
  padding: 3vh 0 5vh;
  @include mq(tablet) {
    min-height: 0;
  }
  @include mq(tablet-small) {
    padding-top: 0;
  }
  &-row {
    width: 100%;
    padding: 20px 30px;
    border: 1px solid $rk_grey2;
    box-shadow:0 0 15px $rk_grey3;
    border-radius: 10px;
    margin: 20px 0;
  }

  &-left{
    width: 70%;
    padding: 30px;
    border: 1px solid $rk_grey2;
    box-shadow:0 0 15px $rk_grey3;
    border-radius: 20px;

    @include mq(tablet) {
      margin-bottom: 10px;
      width: 100%;
    }
    @include mq(tablet-small) {
      padding: 30px 20px;
    }
    &.--float {
      border: 0;
      box-shadow: none;
      padding: 0;
    }
  }
  &-right{
    width: 30%;
    padding: 0 0 0 1.5vw;
    font-size: 1.2rem;

    @include mq(desktop) {
      padding-left: 2.5vw;
    }
    @include mq('tablet') {
      width: 100%;
      padding: 0;
      border: 0;
    }
    .manager__slider {
      padding: 10px 0;
    }
  }
  &-title{
    font-size: 2.2rem;
    color: $rk_grey3;
  }
  &-description {
    font-size: 1.5rem;
    color: $rk_gradient1;
  }
  &__alarms {
    margin-bottom: 40px;
  }

  &__legal {
    margin-top: 0;
  }

  &__docs {
    margin-bottom: 20px;
  }


  &__last-fields {
    position: relative;
    border: 1px solid $rk_grey3;
    padding: 20px;
    margin: 1vw auto 150px;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 0 20px 0;
      border-bottom: 1px solid $rk_grey05;
    }
    &__subtitle {
      font-style: oblique;
      color: $rk_grey8;

    }

    &__help {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &__data {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 1.5rem;
      font-weight: bold;
      overflow: hidden;
      width: 30%;
      padding-left: 25px;
      border-left: 1px solid $rk_grey2;
      &:first-of-type {
        border: 0;
        padding-left:0;
      }
      @include mq('tablet') {
        width: 100%;
        font-size: 1.2rem;
        padding-left: 0;
        border: 0;
        margin: 10px 0;
      }
      > span {
        min-width: 110px;
        @include mq('tablet') {
          min-width: 100%;
        }
      }
      .mat-mdc-form-field{
        margin: 0;
        font-weight: bold;
        max-height: 40px;
        min-width: 100%;
        display: flex;
        align-items: center;
      }
    }
    &__price {
      font-size: 1.7rem;
      color: $rk_grey5;
    }
    &__100 {
      width: 40px;
      position: absolute;
      top: -20px;
      right: -10px;
    }
  }

  &__legal {
    width: 100%;
  }

}



//--------STEPS
.--step {
  color: $rk_grey3;
  transition: color .2s linear;
  img{
    filter: saturate(0);
  }
}
.--contacto {
  &:hover, &:focus, &.active {
    color: $rk_horus09;
    img {
      filter: invert(21%) sepia(96%) saturate(806%) hue-rotate(264deg) brightness(60%) contrast(83%);
    }
  }
}
.--prospecto {
  &:hover, &:focus, &.active {
    color: $rk_step1;
    img {
      filter: invert(48%) sepia(32%) saturate(1888%) hue-rotate(-32deg) brightness(92%) contrast(84%);
    }
  }
}
.--en-proceso {
  &:hover, &:focus, &.active {
    color: $rk_step2;
    img {
      filter: invert(46%) sepia(56%) saturate(472%) hue-rotate(145deg) brightness(93%) contrast(93%);
    }
  }
}
.--activo {
  &:hover, &:focus, &.active {
    color: $rk_step3;
    img {
      filter: invert(58%) sepia(80%) saturate(4758%) hue-rotate(134deg) brightness(95%) contrast(91%);
    }
  }
}
.--en-cierre {
  &:hover, &:focus, &.active {
    color: $rk_step4;
    img {
      filter: invert(50%) sepia(32%) saturate(832%) hue-rotate(68deg) brightness(92%) contrast(94%);
    }
  }
}
.--cerrado {
  &:hover, &:focus, &.active {
    color: $rk_step5;
    img {
      filter: invert(18%) sepia(82%) saturate(4237%) hue-rotate(74deg) brightness(100%) contrast(40%);
    }
  }
}

.--horus {
  &:hover, &:focus, &.active {
    color: $rk_copper4;
    img {
      filter: none;
    }
  }
}

.--prospecto_color {
  color: $rk_step1;
}
.--en-proceso_color {
  color: $rk_step2;
}
.--activo_color {
  color: $rk_step3;
}
.--en-cierre_color {
  color: $rk_step4;
}
.--cerrado_color {
  color: $rk_step5;
}

.--steps_back {
  &.--prospecto_bk {
    background-color: $rk_step1;
  }
  &.--en-proceso_bk {
    background-color: $rk_step2;
  }
  &.--activo_bk {
    background-color: $rk_step3;
  }
  &.--en-cierre_bk {
    background-color: $rk_step4;
  }
  &.--cerrado_bk {
    background-color: $rk_step5;
  }
}

.--rojo{
  border-color: $rk_semaphoreRed;
  &:hover, &:focus {
    border-color: $rk_semaphoreRed;
  }
}
.--amarillo{
  border-color: $rk_semaphoreYellow;
  &:hover, &:focus {
    border-color: $rk_semaphoreYellow;
  }
}
.--verde{
  border-color: $rk_semaphoreGreen;
  &:hover, &:focus {
    border-color: $rk_semaphoreGreen;
  }
}
