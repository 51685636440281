.alarmsList{
  position: relative;
  .--link {
    border: 0;
    width: 40px;
    height: auto;
    margin: 5px 0;
    justify-content: flex-end;

    img {
      width: 25px;
    }
  }
  &__title {
    font-size:1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
  &__noresults {
    display: block;
    margin-top: 10px;
    font-size: 1.2rem;
  }

  &__alarm {
    &__list {
      margin-bottom: 40px;
    }
    &__item{
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding:5px 0;
      border-bottom: 1px solid $rk_grey2;
      &.--acción {
        border-bottom: 2px solid $rk_gradient2;
      }
      &.--plantilla {
        border-bottom: 2px solid $rk_action4;
      }
    }
    &__icon {
      width: 20px;
      margin-right: 10px;
    }
    &__data {
      color: $rk_grey5;
      font-size: 1.2rem;
      flex-grow: 2;
      strong{
        display: block;
      }
    }
    &__actions{
      display: flex;
      figure{
        display: flex;
      }
    }
  }
}
