
//-------ROLES
.--admi {
  border-color: $rk_role_admin;
  .roleized {
    filter: saturate(0);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_admin !important;}
  }
}
.--equi {
  border-color: $rk_role_equipo;
  .roleized {
    filter: contrast(0) sepia(159)  hue-rotate(360deg) brightness(1.2) saturate(3.5);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_equipo !important;}
  }
}
.--soci {
  border-color: $rk_role_administrador;
  .roleized {
    filter: contrast(0) sepia(159)  hue-rotate(544deg) brightness(1.2) saturate(3.5);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_administrador !important;}
  }
}
.--lega {
  border-color: $rk_role_legal;
  .roleized {
    filter: contrast(0) sepia(159) hue-rotate(689deg) saturate(3.5);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_legal !important;}
  }
}
.--coor {
  border-color: $rk_role_coordinador;
  .roleized {
    filter: contrast(0) sepia(159) hue-rotate(617deg) saturate(3.5);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_coordinador !important;}
  }
}
.--tele {
  border-color: $rk_role_telemarketing;
  .roleized {
    filter: contrast(0) sepia(159) hue-rotate(214deg) saturate(3.5);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_telemarketing !important;}
  }
}
.--agen {
  border-color: $rk_role_agente;
  .roleized {
    filter: contrast(0) sepia(159) brightness(1.2) hue-rotate(391deg) saturate(3.5);
  }
  .mdc-line-ripple {
    &:after {border-color: $rk_role_agente !important;}
  }
}


.--admi-color {
  color: $rk_role_admin;
}
.--socio-color,
.--soci-color{
  color: $rk_role_administrador;
}
.--equipo-color ,
.--equi-color {
  color: $rk_role_equipo;
  filter: brightness(.9);
}
.--lega-color {
  color: $rk_role_legal;
}
.--coor-color {
  color: $rk_role_coordinador;
}
.--tele-color {
  color: $rk_role_telemarketing;
}
.--agen-color {
  color: $rk_role_agente;
}


.--admi-bk {
  background-color: $rk_role_admin;
}
.--socio-bk,
.--soci-bk{
  background-color: $rk_role_administrador;
}
.--equipo-bk ,
.--equi-bk {
  background-color: $rk_role_equipo;
  filter: brightness(.9);
}
.--lega-bk {
  background-color: $rk_role_legal;
}
.--coor-bk {
  background-color: $rk_role_coordinador;
}
.--tele-bk {
  background-color: $rk_role_telemarketing;
}
.--agen-bk {
  background-color: $rk_role_agente;
}


.--socio-dark,
.--soci-dark{
  color: $rk_role_administradordark;
}
.--equipo-dark ,
.--equi-dark {
  color: $rk_role_equipodark;
  filter: brightness(.9);
}
.--lega-dark {
  color: $rk_role_legaldark;
}
.--coor-dark {
  color: $rk_role_coordinadordark;
}
.--tele-dark {
  color: $rk_role_telemarketingdark;
}
.--agen-dark {
  color: $rk_role_agentedark;
}

.--role-code {
  font-size: .8em;
}
