.offers-manager {
  padding-bottom: 100px;
  &-cancelled{
    border-left: 3px solid $rk_semaphoreRed;

    .step-activo__offers-manager__section{
      opacity: .6;
    }
  }
  &-pending{border-left: 3px solid $rk_action2;}
  &-approved{border-left: 3px solid $rk_step3;}

  input.mat-mdc-input-element{
    font-family: headers, Arial, sans-serif !important;
    font-size: 1.5rem !important;
    color: $rk_grey7;
    text-align: right;
  }
  &__title{
    position: relative;
    font-size: 2rem;
    display: flex;
    align-items:center;
    margin: 0;
    img {
      width: 36px;
      min-width: 36px;
      height: 36px;
    }
    .--link{
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-family: special, Arial, sans-serif;
      color: $rk_grey5;
    }
  }

  &__owner {
    font-size: 1.2rem;
    color: $rk_grey7;
    margin: 0 20px;
  }
  &__status{
    padding: 0 10px;
    font-size: .9em;
    .--cancelled {
      color: $rk_semaphoreRed;
    }
    .--pending {
      color: $rk_action2;
    }
    .--approved {
      color: $rk_step3;
    }
  }
  &__icon {
    margin-right: 10px;
    &.--pending {
      filter: hue-rotate(217deg) brightness(1.5) saturate(2.5);
    }
    &.--cancelled {
      filter: hue-rotate(193deg) brightness(0.9);
    }
  }
  &__revoke{
    margin-left: 10px;
  }
  &__main-origin {
    top: 6px;
    left: -7px;
    margin-right: 20px;
    &.--unchecked {
      opacity: .5;
    }
    label {
      font-size: 1.3rem;
      font-weight: bold;
      letter-spacing: -.03rem;
      line-height: 1;
      padding-top: 3px;
    }
  }
  &__origins {
    flex-grow: 2;
    width: auto;
  }
  &__subtitle{
    font-size: 1.5rem;
    color: $rk_grey5;
    flex-basis: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__section {
    padding: 5px 0;
    margin: 5px 0;
    border-top: 1px solid $rk_grey2;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:nth-of-type(1) {
      border: 0;
      top: 11px;
      position: relative;
      min-height: 65px;
    }
    &:last-child {
      border: 0;
    }
    &.--med {
      width: 50%;
    }
    &.--files {
      justify-content: flex-start;
      horus-uploader {
        min-width: 0;
        margin-right: 10px;
      }
    }
    .createbutton {
      margin: auto;
    }
  }
  &__subsection {
    width: 100%;
    margin: 20px 0;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  &__msg {
    font-size: 1.35rem;
    color: $rk_step3;
    padding: 5px 0 10px;
    width: 100%;
    &.--big{
      font-family: headers, Arial, sans-serif;
      font-size: 2rem;
    }
    &.--center{
      text-align: center;
    }
    &.--error {
      color: $rk_semaphoreOrange;
    }
  }
  &__people {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &-sel {
      margin: 0 20px;
      max-width: calc(100% - 200px);
    }
  }
  &__buyer {
    padding: 0 2px;
    display: flex;
    align-items: center;
    margin: 5px 0 6px;
    &-lead {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 10px;
      transition: opacity .2s linear;
      font-size: 1.5rem;
      border-right: 1px solid $rk_grey2;
      color: $rk_grey6;
      &:hover,
      &.--editing{
        font-weight: bold;
        color: $rk_black;
      }
    }

    &-status {
      width: 20px;
      height: 20px;
      display: block;
      margin: 0 5px 0 0;
      border-radius: 50%;
      background-color: $rk_semaphoreOrange;
      &.--ok {
        background-color: $rk_step3;
      }
    }
    &-edition {
      display: flex;
      flex-wrap: wrap;
      min-width: 100%;
      margin: 10px 0;
      padding: 10px 20px;
      border: 1px solid $rk_grey2;
      border-radius: 10px;
      horus-uploader{
        &:before {
          content: 'DNI';
          font-family: 'headers', Arial, sans-serif;
          font-size: 2.5rem;
          margin: 10px 10px 0;
          z-index: z(level1);
          color: $rk_grey4;
          @include mq('tablet') {
            right: 0;
          }
        }
      }
    }
  }
  &__bed{
    &-name{
      margin: 0;
      em {
        font-size: .8em;
        padding: 0 0 0 10px;
        letter-spacing: 0.04em;
        font-family: special, Arial, sans-serif;
        font-weight: normal;
      }
    }
    &-files {
      min-width: 60%;
      display: flex;
    }
  }
  h4{
    font-size: 1.3rem;
    font-family: basicregular, Arial, sans-serif;
    font-weight: normal;
    margin: 0 10px 0 0;
    color: $rk_grey6;
    display: flex;
    align-items: center;
    @include mq(phablet) {
      width: 100%;
    }
  }
  &__field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 40%;
    flex-grow: 2;
    &:nth-child(even) {
      border-right: 1px solid $rk_grey1;
      margin-right: 20px;
      padding-right: 20px;
    }
    @include mq(tablet-small) {
      flex-wrap: wrap;
      flex-basis: 100%;
      &:nth-child(even) {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
    &.--final-fee {
      min-width: 48%;
      @include mq(tablet) {
        padding-right: 0;
        margin-right: 0;
        min-width: 100%;
        border:0;
      }
    }
    .--dates & {
      @include mq(tablet-small) {
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 4;
        }
        &:nth-child(4) {
          order: 3;
        }
        &:nth-child(5) {
          order: 5;
        }
      }
    }
    .--input-field {
      width: 150px;
      @include mq(tablet-small) {
        width: 100%;
      }
    }
    .--name{
      max-width: 92px;
      font-size: 1.2rem;
      padding-right: 5px;
      @include mq(tablet) {
        max-width: unset;
        width: 200px;
      }
    }
    .--fee-input {
      max-width: 120px;
      padding-right: 10px;
      @include mq(tablet) {
        max-width: calc(100% - 80px);
        flex-grow: 2;
        margin-right: 10px;
      }
    }
    .--fee {
      font-weight: bold;
      border: 1px solid grey;
      padding: 2px 3px;
      border-radius: 5px;
      margin: 0 5px;
      transition: background-color .2s linear;
      cursor: pointer;
      width: 35px;
      text-align: center;
      font-size: 1.5rem;
      &:hover,
      &.--sel {
        background-color: $rk_step3;
        color: $rk_white;
      }
    }
    .--note {
      font-family: "special", Arial, sans-serif;
      font-size: 1.3rem;
      color: $rk_grey8;
      max-width: 70px;
    }
    &-unique {
      min-width: 100%;
      .textarea {
        height: 80px;
      }
    }
    &-large{
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-grow: unset;
      &:nth-child(6) {
        order: 6;
      }
      h4 {
        max-width: 50%;
      }
      .mat-mdc-checkbox {
        height: 60px;
        display: flex;
        align-items: center;
      }
      &.--total {
        h4 {
          display: block;
          font-weight: bold;
          color: $rk_step3;
          font-size: 1.5rem;
          min-width: calc(100% - 250px);
          @include mq(tablet-small) {
            min-width: 100%;
          }
        }
        small {
          display: block;
          padding-top: 5px;
          color: $rk_grey4;
        }
        span {color: $rk_black;}
        .mat-mdc-input-element{
          text-align: right;
          font-size: 2rem !important;
          color: $rk_step3;
        }
      }
    }
  }
}
