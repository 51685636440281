horus-url-input {
  display: block;
  width: 100%;
}

.url-input {
  &__field {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 45px;
    font-size: 1.2rem;
    .mat-mdc-form-field {
      width: 0;
      flex-grow: 2;
    }
  }
  &__link {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
    @include mq(tablet-small) {
      margin:0 0 0 5px;
    }
    img {
      max-width: 25px;
    }
  }
}
