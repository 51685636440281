.reFiles {
  h1 {
    color: $rk_horus11;
    img {
      width: 30px;
    }
    .--link {
      padding: 0 15px 0 0;
    }
  }
  &__emoji {
    width: 30px;
    margin: 0 10px 0 0;
  }
  &-id,
  &-id .mat-mdc-input-element{
    border: solid $rk_loader1;
    border-width: 0 0 0 1px;
    padding:0 0 0 5px;
    margin: 0 10px 0 5px;
    letter-spacing: 1px;
    line-height: 1;
    font-size: .7em;
    max-width: 182px;
    color: $rk_horus11 !important;
    font-family: 'headers', Arial, sans-serif !important;
  }
  &__header {
    &__nav {
      a span {
        padding: 0;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    &__owner {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 1.1rem;
      font-weight: lighter;
      line-height: 1;
      &__avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  &__item {
    width: 100%;
    max-width: 170px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 1.3rem;
    color: $rk_black;
    overflow: visible;
    opacity: 0.9;
    border-width: 1px;
    background-color: $rk_white;
    margin: 1%;
    &:hover {
      transform: scale(1);
      border-color: $rk_black;
      box-shadow: 0 0 4px rgba($rk_loader1, 0.7);
      background: linear-gradient( 224deg , $rk_grey3 4%, $rk_transparent 13%);
      opacity: 1;
    }
    @include mq(tablet) {
      max-width: 29%;
      min-height: 180px;
      font-size: 1.5rem;
      margin: 2%;
    }
    @include mq(tablet-small) {
      font-size: 1.5rem;
      min-height: 130px;
      max-width: 100%;
      margin: 2% 0;
    }
    &.--no-link {
      cursor: auto;
      &:hover {
        transform: none;
      }
    }
    &__link {
      width: 100%;
      height: 100%;
      padding: 10px 18px 25px 10px;
    }
    &__name {
      color: $rk_horus00;
      display: flex;
      align-items: center;
      margin-left: -20px;
      text-transform: capitalize;
      .--squares & {
        align-items: flex-start;
      }
    }
    address {
      font-weight: normal;
      color: $rk_grey5;
      font-size: 0.8em;
      padding-top: 10px;
      @include mq(tablet-small) {
        font-size: 1.2rem;
      }
    }
    .stars__score {
      max-width: 115px;
      min-height: 24px;
      margin-bottom: 0;
    }
    &__step {
      position: absolute;
      width: 100%;
      height: 30px;
      left: 0;
      bottom: 0;
      padding: 5px;
      display: flex;
      align-items: center;
      color: $rk_grey7;
      font-size: 1.2rem;
      &-color {
        border-radius: 50%;
        max-width: 20px;
        min-width: 20px;
        height: 20px;
        margin-right: 5px;
        position: relative;
      }
      &-percent {
        padding: 0 10px;
        font-family: headers, Arial, sans-serif;
        font-size: 1.1em;
      }
    }
  }

  &__alert {
    width: 25px;
    height: 25px;
    background: $rk_semaphoreRed;
    border-radius: 50%;
    margin: 5px;
    &-block {
      position: absolute;
      right: 0;
      width: 17px;
      .--squares & {
        top: 25px;
      }
      .eagle & {
        top: -3px;
        @include mq(tablet) {
          top: 0;
          width: 25px;
        }
      }
    }
    &.--calendario {
      background: $rk_copper4;
    }
    &.--legal {
      top: 3px;
      right: -7px;
      background: none;
    }
    &.--regular {
      background: $rk_horus13;
    }
    &__icon {
      filter: brightness(2);
    }
  }

  &__eagle {
    min-height: unset;
    .reFiles {
      &__item {
        padding: 0;
        max-width: unset;
        height: auto;
        min-height: unset;
        margin: 0 auto 20px;
        border-width: 0 0 1px 5px;
        border-bottom-color: $rk_grey3;
        border-radius: 0;
        overflow: visible;
        &:hover{
          background: none;
        }
        &__link {
          position: relative;
          width: 100%;
          padding-bottom: 0;
        }
        &__name {
          margin-left: -20px;
          padding-right: 26px;
        }
        &__step {
          width: 55px;
          left: unset;
          right: 0;
          @include mq(tablet) {
            right: 20px;
          }
          div {
            display: none;
          }
          &-percent {
            padding: 0;
            background-color: transparent;
            color: $rk_grey4;
            font-size: .8rem;
            strong {
              font-size: 1.4rem;
            }
          }
          &-color {
            display: none;
          }
        }
      }
      &__alert {
        margin:-1px 2px 5px 2px;
        &.--legal {
          margin: -1x 0 5px 3px;
        }
      }
      &__msgs {
        border-radius: 50%;
        background: $rk_loader1;
        color: $rk_white;
        font-size: 1.1rem;
        position: absolute;
        top:- 1px;
        right: 18px;
        width: 25px;
        height: 25px;
      }
      &__list__owner {
        max-width: calc(100% - 40px);
      }
    }
    address {
      padding-top: 5px;
      font-size: 1rem;
    }
    .stars__score {
      margin-bottom: 5px;
      @include mq(desktop) {
        width: 68px;
        margin: 5px 0;
        min-height: 0;
        img{
          height:14px;
        }
      }
    }
  }

  .--table & {
    @include mq(tablet, min) {
      &__item {
        &:hover {
          background: none;
        }
      }
      &__list__owner{
        margin:0;
      }
      &__alert-block {
        top: unset;
        position: relative;
        right: unset;
        min-width: 90px;
        display: flex;
      }
      &__remove {
        border:0;
        top:5px;
        right: 0;
      }
    }
  }

}

horus-new-re-file {
  horus-property-manager {
    position: relative;
    z-index: z(level5);
  }
}

.new-reFile {
  &__main-title {
    width: 100%;
  }
  &__section {
    margin-bottom: 10px;
    padding: 10px 0;
    &.--right {
      margin-left: 2vw;
      @include mq(tablet) {
        margin-left: 0;
      }
    }

    small {
      font-family: special, Arial, sans-serif;
      font-size: 1.1rem;
      display: block;
      line-height: 1.1;
      letter-spacing: 0.025em;
      padding-top: 5px;
      width: 100%;
    }
  }
  .profile-form--left {
    flex-basis: 60%;
    max-width: 60%;
  }
  .profile-form--right {
    flex-basis: 40%;
  }
  .profile-form {
    padding-bottom: 0;
    &--left,
    &--right {
      @include mq(tablet) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
  .profile-form--base {
    width: 265px;
    margin-left: auto;
    margin-right: auto;
  }
  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 10px;
    color: $rk_horus11;
    font-size: 1.7rem;
    font-family: headers, Arial, sans-serif;
    small {
      color: $rk_grey6;
    }
  }
  &__subtitle {
    width: 100%;
  }
  &__name {
    fieldset {
      flex-grow: 2;
      margin:-16px 0 0 5%;
      @include mq(tablet) {
        width: 100%;
        margin: 0;
      }
    }
  }
  &__type {
    .mat-mdc-radio-button{
      margin-right: 20px;
      font-size: 1.3rem;
    }
  }
  &__contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    fieldset {
      flex-basis: 27%;
      min-width: 130px;
      @include mq(tablet-small) {
        flex-basis: 100%;
      }
    }
    horus-int-prefix {
      width: 40%;
      @include mq(tablet-small) {
        width: 100%;
      }
    }
  }
  &__owner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding:0 10px;
    filter: saturate(0);
    border: 1px solid $rk_grey2;
    border-radius: 12px;
    cursor: pointer;
    margin:0 10px 10px 0 ;
    strong {
      flex-grow: 2;
    }
    .mat-mdc-select {
      width: 100px;
    }
    &:hover{
      filter: saturate(1);
    }
    &__remove {
      width: 15px;
      margin: 0 5px;
    }
  }
  &__properties {
    margin-top: 10px;
    width: 100%;
    &__new {
      max-width: 25px;
      img {
        max-width: 100%;
        margin-left: 10px;
      }
    }
    &__list {
      margin: 20px 0 40px 0;
    }
  }
  &__property {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $rk_grey05;
    padding: 10px 0;
    em {
      color: $rk_horus01;
    }
    &:hover {
      border-color: $rk_horus11;
    }
    &__close {
      width: 15px;
    }
  }
  &__notes {
    padding-top: 0;
    margin-top: 16px;
    .textarea {
      height: 74px;
    }
  }
}

.reFile {
  &__error {
    font-size: 1.2rem;
    color: $rk_semaphoreRed;
    font-family: 'basic', Arial, sans-serif;
    margin: 10px 0;
    .--b{
      display: block;
    }
  }
  &__user-role {
    margin: 3px 1px;
    color: $rk_white;
    padding: 2px 3px;
    font-size: 1.1rem;
    background: $rk_grey8;
    &.--generado {
      background: $rk_role_telemarketingdark;
    }
    &.--captación {
      background: $rk_role_agentedark;
    }
    &.--administración{
      background: $rk_role_coordinadordark;
    }
  }
  &__summary {
    margin-top: 30px;
    border-bottom: 1px solid $rk_grey2;
    box-shadow: 2px 5px 6px rgba($rk_black, 0.25);
    @include mq(tablet-small) {
      margin-top: 22px;
    }
  }
  // MANAGER
  &-manager {
    &__owner {
      display: flex;
      font-size: 2rem;
      align-items: center;
      &__name {
        color: $rk_grey6;
        margin-right: 6px;
        font-family: basic, Arial, sans-serif;
        text-transform: none;
        font-weight: bold;
        font-size: .8em;
      }
    }
    &__header {
      position: relative;
      padding: 0 5vw;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mq(dekstop) {
        padding: 0 2vw 10px;
      }
      &-item{
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-end;
        @include mq(dekstop) {
          justify-content: flex-start;
        }
      }
    }
    &__name {
      font-family: 'headers', Arial, sans-serif;
      font-size: 2rem;
      font-weight: normal;
      display: flex;
      align-items: center;
      margin: 0 0 10px;
      @include mq(tablet-small) {
        width: 100%;
        font-size: 1.5rem;
      }
    }
    &__icon {
      margin: 3px 5px 3px 0;
      width: 30px !important;
      height: 30px !important;
      background: $rk_grey8;
      padding: 3px;
      border-radius: 50%;
      @include mq(tablet) {
        margin: 3px 5px 3px 0;
        width: 27px;
        min-height: 27px;
      }
    }
    &__summary-data {
      border-left: 1px solid $rk_grey3;
      display: flex;
      align-items: center;
      max-width: 40%;
      padding: 5px 10px;
      &.--blocked {
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        filter: saturate(0);
        transition: filter  .2s linear;
        &:hover, &.--selected {
          filter: none;
        }
      }
      &__txt-blocked{
        color: $rk_semaphoreRed;
        text-align: right;
        padding-right: 10px;
        width: 70px;
      }
      &.--property {
        @include mq(tablet) {
          max-width: 60%;
          flex-grow: 2;
        }
        @include mq(tablet-small) {
          padding-left: 0;
          border: 0;
        }
      }
      &-info {
        font-size: 1.3rem;
        color: $rk_grey8;
        display: flex;
        align-items: center;
      }
      address {
        display: inline;
        font-size: 1rem;
        padding-right: 5px;
      }
    }
    &__ad {
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 25px;
      }
    }
    &__edition {
      min-width: 30px;
      min-height: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 50%;
      border: 1px solid $rk_grey6;
      margin: 5px 0;
    }

    // MANAGER EDITION

    &__modal {
      position: relative;
      font-size :1.2rem;
      .--precio {
        font-size: 2rem !important;
      }
    }
    &__head {
      margin-bottom: 0;
      width: 100%;
    }
    &__header-edit{
      margin-top:  0;
      width: 100%;
      margin-bottom : 20px;
    }
    &__subtitle {
       font-family: "headers", Arial, sans serif;
       letter-spacing: -.08rem;
       width: 100%;
       color: $rk_horus11;
       font-size: 2rem;
       justify-content: space-between;
       margin: 30px 0 0;
      &:first-child {
        margin-top: 0;
      }
      &-help {
        font-size: 1.2rem;
        font-style: italic;
        color: $rk_grey6;
        &.--mb {
          margin-bottom: 15px;
        }
      }
     }
    &__content {
      padding: 0;
    }
    &__main {
      display: flex;
      max-width: 100%;
      padding: 0;
      border: 0;
      & > div {
        @include mq(tablet) {
          width: 100%;
          border: 0;
        }
      }
    }
    &__left {
      width: 40%;
      padding:0 30px 40px 0;
      border-right: 1px solid $rk_grey3;
    }
    &__right {
      width: 60%;
      padding:0 30px 40px;
    }

    .--section-line {
      display: flex;
      align-items: center;
      margin: 20px 0;
      &__label {
        margin-right: 10px;

      }
    }
    .--field-item {
      width: 100%;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $rk_grey1;
      &:hover {
        border-color: $rk_black;
      }
    }
    &__notes {
      width: 100%;
      margin-top: 20px;
      @include mq(tablet) {
        border-bottom: 1px solid $rk_grey1;
        margin: 10px 0 5px;
      }
      .textarea {
        min-width: 100%;
        min-height: 150px;
      }
    }
    &__property {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      &-type {
        font-size: .9em;
        color: $rk_grey6;
        display: block;
        margin-bottom: 3px;
      }
      &.--user {
        cursor: default;
        figcaption {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
        }
        div {
          flex-grow: 2;
        }
        .mat-mdc-form-field {
          max-width: 35%;
        }
      }
    }
    &__remove {
      height: 25px;
      width: 25px;
      margin-left: 20px;
    }
    &__add {
      display: flex;
      max-width: 115px;
      align-items: center;
      font-size: 1.1rem;
      line-height: 1;
      color: $rk_grey4;
      font-weight: normal;
      font-family: 'basic', Arial, sans-serif;
      text-align: right;
      letter-spacing: 0;
      margin: 0;
      &:hover {
        color: $rk_black;
      }
      img {
        margin-left: 10px;
      }
      figcaption {
        padding-top: 4px;
      }
    }
    &__contact-add {
      position: relative;
      margin:15px 0 20px 0;
      width: 100%;
      display: flex;
      align-items: center;
      @include mq(tablet-small) {
        flex-wrap: wrap;
      }
      > .mat-mdc-form-field {
        margin: 0 10px;
        color: $rk_grey8;
        width: 100px;
        @include mq(tablet-small) {
          width: 100%;
          margin: 0;
        }
      }
      .createbutton {
        margin-left: 10px;
        padding: 5px;
        z-index: z(floor);
        font-size: 1.3rem;
        @include mq(tablet-small) {
          margin:10px auto 20px;
          width: 100%;
        }
      }
    }
    &__pcontact__manager{
      .createbutton {
        margin: 10px auto;
      }
      fieldset {
        width: 100%;
        margin: 15px 0;
      }
    }
    &__contact-link {
      flex-grow: 2;
    }
    &__contact-type{
      margin-left: 20px;
      max-width: 150px;
    }
    &__lead-owner {
      font-size: 1rem;
      display: block;
      color: $rk_black;
      &-name{
        font-weight: bold;
      }
    }
    &__autocomplete {
      margin-right: 20px;
      color: $rk_grey8;
      flex-grow: 2;
      @include mq(tablet-small) {
        width: 100%;
        margin-right: 0;
      }
    }
    &__contact-tools {
      display: flex;
      flex-grow: 2;
      align-items: center;
    }
    &__nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 5px 0 10px;
      margin-right: 10px;
      border-right: 1px solid $rk_grey2;
      min-width: 110px;
      @include mq(tablet) {
        margin: 0;
        padding: 0;
        border: 0;
        min-width: 125px;
      }
      @include mq(tablet-small) {
        min-width: 60px;
      }
      img {
        margin: 3px 5px 0;
        border-radius: 0;
        background: none;
        width: 25px;
        @include mq(tablet-small) {
          margin: 0 5px 0 5px;
          width: 20px;
        }
      }
    }
  }
  // MANAGER
  &__nav {
    position: relative;
    min-height: 100px;
    max-width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @include mq(tablet) {
      width: 100%;
      max-width: 100%;
      min-height: 65px;
      align-items: flex-start;
      margin: 20px 0 50px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      width: 95%;
      background: $rk_grey3;
      left: 3%;
      z-index: z(bottom);
      top: 41px;
      @include mq(tablet) {
        top: 23px;
      }
    }
    &__step {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 65px;
      justify-content: space-around;
      align-items: center;
      color: $rk_grey4;
      cursor: pointer;
      &:hover {
        color: $rk_black;
      }
      @include mq(tablet) {
        width: 16.5%;
        height: 45px;
      }
      &.--blocked {
        padding-top: 56px;
        background: url(/assets/img/icons/blocked.svg) no-repeat center 5px;
        background-size: 45px;
        text-decoration: line-through;
        .--steps_back {
          display: none;
        }
      }

      &-percent {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        height: 45px;
        width: 45px;
        font-family: basic, Arial, sans-serif;
        color: $rk_white;
        filter: saturate(0);
        user-select: none;
        border: 3px solid $rk_transparent;

        strong {
          font-family: headers, Arial, sans-serif;
          font-size: 1.3rem;
        }
      }
      &-name {
        font-size: 1.3rem;
        @include mq(tablet) {
          position: absolute;
          font-size: 1.1rem;
          bottom: -20px;
          min-width: 66px;
          text-align: center;
        }
      }
      .--tooltip {
        top: -3px;
        left: calc(50% + 10px);
        display: none;
      }
      &.--active {
        color: $rk_copper1;
        .reFile__nav__step-percent {
          filter: saturate(1);
          border-color: rgba($rk_black, .4);
        }
        .--tooltip {
          display: block;
        }
      }
      &.--disabled {
        color: $rk_grey2;
        cursor: default;
        &:hover {
          color: $rk_grey2;
        }
        .reFile__nav__step-percent{
          color: $rk_transparent;
          background: $rk_grey2;
        }
      }
    }
  }
  &__nav-sub {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -25px;
    &__item {
      position: relative;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      margin: 0 5px;
      user-select: none;
      &:hover, &.--selected {
        background: $rk_grey6;
      }
    }
    &__img {
      max-width: 60%;
      margin: auto;
    }
  }
  &__nav-legal {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 1.1rem;
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      width: 95%;
      background: $rk_grey3;
      left: 3%;
      z-index: z(bottom);
      top: 22px;
    }
    &__item {
      position: relative;
      border-radius: 50%;
      background-color: $rk_role_legal;
      height: 45px;
      width: 45px;
      margin:0 10px;
      cursor:pointer;
      display: flex;
      justify-content: center;
      filter: saturate(0);
      user-select: none;
      &:hover, &.--selected {
        filter: none;
        font-weight: bold;
      }
      &.--disabled {
        cursor: default;
        color: $rk_grey5;
        &:hover {
          font-weight: normal;
          filter: saturate(0);
        }
      }
    }
    &__img {
      max-width: 60%;
      margin: auto;
    }
    &__name {
      margin-top: 50px;
    }
    &__step {
      position: absolute;
      right: 0;
      border-radius: 10px;
      color: $rk_white;
      padding: 5px 10px;
    }
  }
  .basic-plan__total-score {
    @include mq("tablet") {
      top: -56px;
      font-size: 2rem;
      width: 70px;
      height: 70px;
    }
  }
}
