
.report-user{
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-top: 0;
    font-size: 3rem;
    font-family: special, Arial, sans-serif;
    font-weight: lighter;
    margin-bottom: 20px;
    @include mq("tablet-small") {
      font-size: 2rem;
    }
    &__icon {
      display: flex;
      min-width: 70px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    &__text {
      strong {
        margin-right: 5px;
        font-family: headers, Arial, sans-serif;
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -10px 0 10px;
    flex-wrap: wrap;
    z-index: z(level1);
    @include mq("tablet") {
      margin: 0 0 20px;
    }
    &__item {
      padding: 0 10px;
      border-right: 1px solid $rk_grey4;
      font-size: 1.4rem;
      cursor: pointer;
      color: $rk_grey5;
      &:hover ,
      &.--selected{
        color: $rk_loader1;
      }
    }
    &__range {
      width: 175px;
      max-width: 175px;
      margin:-10px 0  0 10px;
      cursor: pointer;
      @include mq("tablet-small") {
        margin: 20px 0 10px 10px;
      }
      &__date{
        position: absolute;
      }
      &__input {
        opacity: 0;
        cursor: pointer;
        &[disabled] {
          cursor: not-allowed;
        }
        .mat-date-range-input & {
          opacity: 1;
          font-size: 1rem;
        }
      }
    }
  }
  &__charts {
    height: auto;
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    align-items: flex-end;
    @include mq("tablet-small") {
      height: auto;
      margin-top: 0;
    }
    &.--3m {
      margin-top: 0;
      height: auto;
      align-items: flex-start;
    }
    &.--leads,
    &.--act,{
      height: 350px;
    }
    &.--refile,{
      min-height: 0;
      height: auto;
    }
  }
  &__logs {
    margin-bottom: 200px;
    .basic-filters {
      margin-bottom: 30px;
    }
    .logs__title {
      font-size: 2rem;
      margin-top: 10px;
      color: $rk_grey3;
    }
  }
}
