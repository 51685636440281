horus-footer{
  position: relative;
  top: 0;
  background: $rk_black;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:$rk_semaphoreGreen;
  font-weight: bold;
  font-family: special, Arial, sans-serif;
  &.blocked {
    top: unset;
    position: fixed;
    bottom: 0;
  }
}

.footer__docs {
  color: $rk_grey5;
  margin-left: 10px;
  font-size: 1.3rem;
  border-left: 1px solid$rk_white;
  padding-left: 10px;;
  &:hover{
    color: $rk_white;
  }
}


