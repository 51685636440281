.social {
  &__edit {
    &__links {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 70px;
      flex-wrap: wrap;
      figure {
        margin: 5px 5px 5px 0;
      }
    }

    &__fields {
      display: block;
      height: 0;
      max-height: 0;
      overflow: hidden;
      margin: 20px 0 0;
      transition: max-height .2s linear;

      img {
        display: none;
      }

      &.--editing {
        height: auto;
        max-height: 300px;
      }
    }
  }
}
