
horus-appraiser{
  min-height: calc(100vh - 90px) !important;
  padding-bottom: 0 !important;
}
.appraiser {
  user-select: none;
  .loader__container{
    z-index: z(level3);
    margin-top: 0;
  }
  &__color__icon{
    filter: hue-rotate(-70deg);
  }
  &__welcome{
    display: flex;
    width: 100%;
    margin:60px auto 0;
    padding-bottom: 100px;
    justify-content: center;
    min-height:100vh;
    @include mq(tablet) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin:20px auto 0;
      min-height: calc(100vh - 70px);
    }
    &__logo {
      width: 50%;
      max-width: 450px;
      padding: 0 2%;
      text-align: center;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mq(desktop-wide) {
        font-size: 1.3rem;
        width: 30%;
      }
      @include mq(tablet) {
        font-size: 1.2rem;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        text-align: left;
      }
      @include mq(tablet-small) {
        font-size: 1.1rem;
      }
      @include mq(phablet) {
        font-size: 1.1rem;
        width: auto;
      }
      &__img{
        margin:0 auto 30px;
        max-height: 250px;
        height: 25vh;
        @include mq(tablet) {
          max-width: 100px;
          margin: 0 2% 30px;
          height: auto;
        }
        @include mq(phone) {
          max-width: 70px;
        }
      }
    }
    &__main{
      position: relative;
      padding: 0 2%;
      width: 50%;
      border: solid $rk_copper1;
      border-width: 0 0 0 1px;
      @include mq(desktop-wide) {
        width: 70%;
      }
      @include mq(tablet) {
        padding: 5vh 0;
        border-width: 1px 0 0;
        width: 90%;
        max-width: 100%;
      }
    }
    &__title{
      font-size: 2.3rem;
      color: $rk_grey4;
      margin: 0 0 5px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      span {
        font-size: 1.7rem;
      }
      strong  {
        display: block;
        color: $rk_black;
      }
      &__type{
        display: flex;
        font-size: 1.2rem;
        font-family: 'special', Arial, sans-serif;
        align-items: center;
        border-left: 1px solid $rk_black;
        margin: 10px;
        cursor: pointer;
        max-width: 125px;
        filter: saturate(0);
        transition: filter .2s linear;
        &:hover {
          color: $rk_black;
          filter: none;
        }
        img {
          width: 30px;
          margin: 0 10px;
        }
      }
    }
    horus-re-file-autocomplete {
      display: block;
      margin: 35px 0;
    }
    &__assessments {
      &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top :30px;
        margin-top :30px;
        border-top: 1px solid $rk_grey2;
        .pagination {
          margin: 0 auto;
          @include mq(tablet) {
            margin: 20px auto;
          }
        }
      }
    }
  }
  &__properties{
    position: relative;
    max-height: 0;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    &.--open{
      margin: 35px 0;
      min-height: 120px;
      max-height: unset;
      transition: all .2s linear;
    }
    &__edition {
      position: absolute;
      width: 20px;
      top: calc(50% - 10px);
      right: 0;
      cursor: pointer;
      filter: saturate(0);
      transition: filter .2s linear;
      &:hover{
        filter: hue-rotate(-80deg);
      }
    }
    &__data{
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      background: rgba($rk_grey3, .1);
    }
    &__subtitle{
      padding: 0 5px;
      text-align: left;
      font-size: 1.2rem;
      margin: 4px 0;
      color: $rk_grey4;
      font-family: headers, Arial, sans-serif;
      border-bottom: 1px solid $rk_grey1;
      width: 50%;
      @include  mq(tablet-small) {
        width: 100%;
      }
      span {
        min-width: 100px;
      }
      small{
        margin-left: 10px;
        font-family: basicregular, Arial, sans-serif;
        font-size: 1.3rem;
        color: $rk_black;
      }
    }
    .mat-mdc-radio-group{
      width: 100%;
    }
    .mat-mdc-radio-button{
      position: relative;
      text-align: left;
      width: 100%;
      border-bottom: 1px solid $rk_grey2;
      padding:5px 10px;
      margin: 0;
      display: block;
      background: rgba($rk_white, .8);
      @include mq(tablet-small) {
        padding:10px 0;
        width: 100%;
      }
      &.--link {
        color: $rk_grey6;
        opacity: .7;
      }
    }
    .mat-mdc-radio-label{
      min-height: 25px;
    }
    &__property{
      display: flex;
      align-items: center;
    }
    &__address {
      white-space: pre-line;
      font-size: 1.2rem;
      margin-right: 30px;
      line-height: 1;
    }
    .appraiser__icon,
    .appraiser__color__icon{
      width: 25px;
      margin-right: 10px;
    }
  }
  &__valoration-type {
    display: flex;
    width:  100%;
    margin: 10px 20px;
    min-width: 200px;
    &.--radius {
      border-image-source: linear-gradient(to left, rgba($rk_black, 0), rgba($rk_appraiser-fluor,.7), rgba($rk_black, 0));
      border-style: solid;
      border-image-slice: 1;
      border-width: 0 0 1px;
    }
    &__title {
      font-size: 1.6rem;
      text-align: left  ;
      color: $rk_grey4;
      width: 100%;
      &.--error {
        padding: 30px 0 0;
        color: $rk_semaphoreOrange;
      }
    }
    &__info {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0;
      font-size: 1.3rem;
      width: 100%;
      strong {
        display: block;
        font-size: 1.6rem;
      }
    }
    img {
      width: 70px;
      margin-right: 20px;
    }

    .createbutton{
      margin: 0 0 0 10px;
      padding: 5px;
      font-size: 1.4rem;
      max-width: 140px;
      line-height: 1;
      @include mq('tablet-small') {
        font-size: 1.3rem;
        min-width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }

  &__radio-selector {
    font-size: 1.2em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 10px;
    &__info {
      margin-right: 20px;
      min-width: 175px;
    }
    &__slider{
      flex-grow: 2;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &__warning {
      width: 80%;
      color: $rk_semaphoreOrange;
      opacity: 0;
      font-size:  1.1rem;
      transition: opacity .2s linear;
      @include mq('tablet-small') {
        width: 100%;
      }
      &.--warning {
        opacity: 1;
      }
    }
  }
  &__polygon-selector{
    &__info {
      max-width: calc(100% - 150px);
      flex-grow: 2;
      padding-bottom: 20px;
      @include  mq(tablet-small) {
        max-width: unset;
      }
    }
  }

  &__dashboard{
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    &__header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: $rk_grey8;
      z-index: z(level4);
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        @include  mq(tablet-small) {
          font-size: 1.2rem;
        }
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      font-family: basicregular, Arial, sans-serif;
      font-size: 1.3rem;
      color: $rk_grey2;
      &.--logo{
        @include mq(tablet-small){
          overflow: hidden;
          width: 60px;
          min-width: 60px;
        }
      }
      &.--property {
        flex-grow: 2;
        cursor: pointer;
        filter: saturate(0);
        &:hover{
          filter: brightness(1.5);
        }
        @include mq(tablet-small){
          font-size: 1rem;
        }
      }
      &__img {
        height: 40px;
        margin: 0 10px;
        cursor: default;
      }
      &__info {
        max-width: 210px;
        font-size: 1rem;
      }
    }
    .appraiser__color__icon{
      height: 35px;
      cursor: pointer;
      margin: 0 20px;
      transition: filter .2s linear;
      &.--nolink{
        filter: saturate(0);
        cursor: default;
        &:hover {
          filter: saturate(0);
        }
      }
      &--no-mobile {
        @include mq("tablet-small") {
          display: none;
        }
      }
      &:hover{
        filter: brightness(1.2) hue-rotate(-70deg);
      }
    }
    &__subheader{
      position: fixed;
      top: 50px;
      width: 100%;
      z-index: z(level3);
    }
    &__new-witness {
      width: 35px;
      margin: 0 0 0 10px;
      cursor: pointer;
      filter: saturate(.2);
      &:hover {
        filter: none;
      }
    }
    &__info {
      background: $rk_white;
      width: auto;
      display: flex;
      padding: 0 10px;
      min-height: 45px;
      position: absolute;
      margin: 10px 10px;
      border-radius: 10px;
      align-items: center;
      font-size: 1.2rem;
      font-family: 'basicregular', Arial, sans-serif;
      @include mq("tablet") {
        width: 100%;
        position: relative;
        margin: 0;
        border-radius: 0;
        padding: 5px;
      }
      &__title {
        margin: 0 0 0 20px;
        color: $rk_copper2;
        font-size: 2rem;
        line-height: 1;
        @include mq(tablet-small) {
          font-size: 1.5rem;
        }
        strong {
          @include mq(tablet-small) {
            display: none;
          }
        }
      }
      span {
        border-left: 2px solid $rk_grey5;
        margin:0 0 0 10px;
        padding: 0 0 0 10px;
        min-width: 100px;
        cursor: pointer;
        text-align: center;
        color: $rk_grey5;
        &.--active {
          color: $rk_loader1;
          font-weight: bold;
        }
      }
    }
    &__menu {
      position: absolute;
      top: 0;
      right: 10px;
      display: flex;
      @include mq('tablet') {
        top:44px;
        position: absolute;
        display: flex;
        width: 100%;
        max-height: 20px;
        justify-content: center;
        background: $rk_grey6;
        right: 0;
      }
      &__item {
        margin:5px;
        border-radius: 50%;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        background: $rk_grey8;
        display: flex;
        cursor: pointer;
        transition: filter .2s linear;
        align-items: center;
        justify-content: center;
        filter: saturate(0);
        @include mq('tablet-small') {
          margin: 2px 5px;
        }
        &:hover {
          filter: none;
        }
        &.--selected {
          filter: none;
          &:hover {
            filter: brightness(1.5);
          }
        }
        &.--filters {
          border: 1px solid $rk_appraiser-fluor;
          background: $rk_white;
        }
      }
      &__icon {
        height: 30px;
      }
    }

  }

  &__section {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 90px);
    height: auto;
    top: 0; left: 0;
    background-color: $rk_white;
    z-index: z(level3);
    display: block;
    &.--map {
      height: calc(100vh - 90px);
    }
    &__container {
      padding: 20px 30px 40px;
      @include mq(desktop) {
        padding: 20px 10px 40px;
      }
    }
  }

  &__results {
    position: fixed;
    width: 100%;
    padding: 1vh 5%;
    bottom: 0;
    background-color: $rk_grey8;
    border-top: 5px solid $rk_grey5;
    transition: border-color .2s linear;
    z-index: z(level3);
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(desktop-wide) {
      padding: 1vh 3%;
    }
    @include mq(tablet-small) {
      align-items: flex-end;
    }
    &.--v-verde {
      border-top: 5px solid $rk_appraiser-green;
    }
    &.--v-amarillo {
      border-color: $rk_appraiser-yellow;
    }
    &.--v-rojo {
      border-color: $rk_appraiser-red;
    }
    &__property {
      font-size: 1.3rem;
      font-family: 'special', Arial, sans-serif;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include mq('tablet') {
        flex-wrap: wrap;
        align-items: flex-end;
        padding-bottom: 10px;
        width: 100%;
      }
      @include mq('tablet-small') {
        flex-direction: column;
      }
      > div {
        @include mq('tablet') {
          width: 45%;
        }
        @include mq('tablet-small') {
          width: 100%;
        }
      }
      &__property {
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
        @include mq(desktop) {
          display: none;
        }
        @include mq(tablet) {
          display: flex;
          text-align: right;
          padding: 15px;
        }
        h3 {
          padding: 0 10px 0 0;
          width: 40%;
          margin: 0;
          font-size: 1.4rem;
          font-family: special, Arial, sans-serif;
          color: $rk_appraiser-green;
          letter-spacing: 0;
          min-width: 100px;
          transition: color .2s linear;
          .--v-amarillo & {
            color: $rk_appraiser-yellow;
          }
          .--v-rojo &{
            color: $rk_appraiser-red;
          }
          @include mq(desktop-wide) {
            font-size: 1.2rem;
            width: 100%;
          }
        }
        span {
          opacity: .7;
          font-size: 1.3rem;
          font-family: basicbold, Arial, sans-serif;
          margin-right: 10px;
        }
      }
      &__price,
      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 1vh 0;
        sup{
          font-size: .6em;
        }
      }
      &__info {
        padding: 5px 10px;
        border: solid $rk_appraiser-pink;
        border-width: 0 2px;
        margin-right: 20px;
        min-width: 250px;
        color: $rk_appraiser-purple;
        @include mq(tablet-small) {
          border-width: 2px 0;
          margin: 0;
          display: flex;
        }
        span {
          padding: 4px 0;
          text-align: right;
        }
      }
      &__price {
        background: $rk_white;
        max-width: 100%;
        min-width: 300px;
        padding:1vh;
        border-radius: 10px;
        margin: 2vh 0;
        @include mq('tablet-small') {
          margin: 0;
          border-radius: 0;
        }
        strong {
          font-family: 'headers', Arial, sans-serif;
          font-size: 2.4rem;
          @include mq('tablet-small') {
            font-size: 1.5rem;
          }
        }
      }
    }
    &__finalPrice {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 30px;
      margin: 0 20px;
      width: 100%;
      max-width: 300px;
      top: -10px;
      font-variant: tabular-nums;
      @include mq('tablet') {
        top: -30px;
      }
      @include mq('tablet-small') {
        padding-right: 15px;
        margin: 0 35px 0;
        min-height: 56px;
        top: -10px;
      }
      .--c {
        font-family: headers, Arial, sans-serif !important;
        transition: color .2s linear !important;
      }
      .--v-verde & *{
        color: $rk_appraiser-green !important;
      }
      .--v-amarillo & *{
        color: $rk_appraiser-yellow !important;
      }
      .--v-rojo & *{
        color: $rk_appraiser-red !important;
      }
      strong {
        font-size: 2rem;
        opacity: .7;
        @include mq('tablet-small') {
          font-size: 1.5rem;
        }
      }
      .mat-mdc-input-element{
        text-align: right;
        font-size: 3rem !important;
        @include mq('tablet-small') {
          font-size: 2rem !important;
        }
      }
      .mat-mdc-form-field-infix {
        padding-top: 0;
        @include mq('tablet-small') {
          border:none;
        }
      }
      .mat-mdc-form-field-wrapper{
        padding-bottom: .9em;
      }
      span {
        font-size: 1.2rem;
        position: absolute;
        bottom: -20px;
        right: 30px;
        @include mq('tablet') {
          font-size: 1rem;
          right: 35px;
        }
      }
    }
    &.accordion__content {
      @include mq(tablet) {
        margin-bottom: 0;
        padding: 0;
        min-height: 120px;
        max-height: unset;
        align-items: flex-end;
      }
      @include mq(tablet-small) {
        min-height: 124px;
      }
    }
  }
  .accordion__trigger {
    //display: none;
    position: fixed;
    bottom: 30px;
    left: -14px;
    z-index: z(level4);
    transform: scale(1.5);
    /*    @include mq('tablet-small') {
          display: block;
        }*/
  }
}

////////////////////////////////////////////////////////////// ASSESSMENT LIST

.assessment-list {
  margin-top: 20px;
  &__list{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
  &__assessment{
    position: relative;
    width: 100%;
    margin: 0 30px 0 0;
    border-bottom: 1px solid $rk_grey3;
    cursor: pointer;
    transition: background-color .2s linear;
    &:nth-of-type(odd) {
      background: rgba($rk_grey3, .1);
    }
    &:hover {
      background:  rgba($rk_action3, .1);
    }
    &__data {
      display: flex;
      padding: 8px 0;
    }
    &__icon{
      display: flex;
      align-items: center;
      flex-grow: 2;
      img {
        min-width: 25px;
        max-width: 25px;
        margin: 0 10px;
        @include mq('tablet-small'){
          margin: 0 3px;
        }
      }
      &.--rotate {
        filter: hue-rotate(-70deg);
        flex-grow: unset;
      }
    }
    &__info{
      font-size: 1.3rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include mq('tablet-small'){
        flex-wrap: wrap;
      }
      span {
        border-left: 1px solid $rk_gradient1;
        margin: 2px 0;
        display: flex;
        padding: 0 10px;
        justify-content: flex-end;
        align-items: center;
        @include mq('tablet-small'){
          border: none;
          display: inline;
          text-align: right;
        }
        &:nth-of-type(2) {
          @include mq('tablet-small') {
            flex-grow: 2;
          }
        }
      }
    }
  }
  &__price{
    @include mq('tablet-small') {
      text-align: right;
    }
  }
  &__address{
    flex-grow: 2;
    padding: 5px 10px;
    font-size: 1.1rem;
    line-height: 1;
  }
  &__date{
    font-family: special, Arial, sans-serif;
  }
  &__price{
    font-family: headers, Arial, sans-serif;
    min-width: 150px;
    text-align: right;
  }
  .loader__container {
    z-index: z(level2);
  }
}

////////////////////////////////////////////////////////////// MAP

.witnessess__infoWindow {
  display: flex;
  @include mq(tablet) {
    flex-direction: column;
  }
  &__picture {
    overflow: hidden;
    max-width: 200px;
    min-width: 200px;
    @include mq(tablet) {
      max-width: 95%;
      text-align: center;
      margin: 0 auto;
    }
    img{
      max-height: 150px;
    }
  }
  &__ads {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__ad{
    margin: 2px 5px;
    &:after {
      content: ' | ';
    }
    &:hover {
      color: $rk_loader1;
    }
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    font-size: 1.1rem;
    margin-left: 10px;
    padding:0 10px;
    font-family: special, Arial, sans-serif;
    border-left: 1px solid $rk_grey1;
    span {
      margin: 0;
      width: 50%;
      min-width: 50%;
    }
    strong {
      font-size: 1.2em;
      margin-left: 5px;
    }
    a{
      color: $rk_gradient1;
      margin: 5px 0 !important;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  &__desc{
    margin-bottom: 10px;
    min-width: 100%;
  }
  &__toolbar {
    border-top: 1px solid $rk_grey1;
    margin: 5px 0 0;
    padding-top: 5px;
    min-width: 100%;
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      cursor:pointer;
      min-width: 0;
      padding: 5px 10px 5px 0;
      &:hover {
        color: $rk_black;
      }
    }

    img {
      width: 20px;
      filter: saturate(0);
    }
  }
  &__selected {
    &:hover img,
    .--ok {
      filter: none;
    }
  }
  &__delete {
    &.--hide {
      display: none;
    }
    &:hover img {
      filter: hue-rotate(118deg) brightness(.25) saturate(12.5);
    }
  }
}


////////////////////////////////////////////////////////////// FILTERS

.witnessess__filters {
  position: relative;
  width: 90%;
  margin: 0 7% 0 3%;
  animation: appear .2s linear forwards;
  &__title {
    color: $rk_grey4;
    font-size: 2.5rem;
    margin: -20px 0 30px -5px;
    line-height: .8;
    display: flex;
    @include mq("tablet-small") {
      font-size: 2rem;
    }
  }
  &__notenough {
    color: $rk_appraiser-red;
  }
  &__disabled {
    display: flex;
    align-items: center;
    min-height: 45px;
    padding: 16px 0 0 0;
    font-size: 1.1rem;
    color: $rk_grey4;
  }
  &__item {
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 65px;
  }
  &__subtitle {
    margin: 10px 32px -4px 0;
    font-family: headers, Arial, sans-serif;
    font-size: 1.2rem;
    text-align: left;
    width: 46px;
    line-height: 1;
    &.--form {
      margin-top: 5px;
    }
  }
  &__slider{
    flex-grow: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items:center;
    font-family: basic, Arial, sans-serif;
    mat-slider{
      min-width: 100%;
    }
  }
  &__form-field {
    max-width: calc(100% - 80px);
    font-size: 1.4rem;
  }
  &__legend{
    color: $rk_grey9;
    font-size: 1rem;
    opacity: .6;
  }
  &__current {
    font-size: 1.2rem;
    font-weight: bold;
    color: $rk_semaphoreGreen;
    small {
      font-weight: lighter;
    }
  }
  &__reset {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    font-size: 1.3rem;
    font-family: basic, Arial, sans-serif;
    letter-spacing: 0;
    font-weight: lighter;
    width: 40px;
    line-height: 1;
    border-left: 1px solid $rk_grey5;
    filter: saturate(0);
    transition: all .2s linear;
    &:hover {
      color: $rk_black;
      filter: none;
      img {
        transform: scale(1.1);
      }
    }
    &__icon{
      width:  23px;
      margin: 0 7px;
    }
  }

  .--unused {
    filter: saturate(0) brightness(2);
  }
}

////////////////////////////////////////////////////////////// SCORECARD

$bpointScorecard : 'desktop-small';
horus-scorecard {
  display: block;
  min-height: 100%;
}
.scorecard {
  padding: 50px 25px 150px;
  @include mq(desktop-wide) {
    padding: 50px 5px 150px;
  }
  &__table {
    width: 100%;
    margin: 30px auto;
    max-width: 1750px;
    border-spacing: 0;
    border-collapse: collapse;
    @include mq($bpointScorecard){
      margin: 70px auto;
    }
    label{
      font-size:1rem;
      left: -5px;
      position: relative;
    }
    &__body {
      td:nth-child(n+4):nth-child(-n+6){
        background: $rk_grey05;
      }
      td:nth-of-type(5) {
        font-size: 1rem;
      }
      &.--k1 {
        td:nth-of-type(4) {
          background: $rk_appraiser-greygreen;
        }
      }
      &.--k2 {
        td:nth-of-type(5) {
          background: $rk_appraiser-greygreen;
        }
      }
      &.--k3 {
        td:nth-of-type(6) {
          background: $rk_appraiser-greygreen;
        }
      }
    }
    &__witness {
      transition: background .2s linear;
      cursor: pointer;
      td:first-child {
        padding-left: 0;
      }
      &:hover {
        td {
          color: $rk_black;
        }
      }
    }
    tr {
      height: 42px;
      &.--checked {
        background: rgba($rk_appraiser-yellow2, .3);
      }
      @include mq($bpointScorecard){
        position: relative;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        margin: 5px;
        padding: 5px 20px;
        border: 1px solid $rk_grey3;
        border-radius: 5px;
        justify-content: space-around;
        &:nth-of-type(1) {
          border: 1px solid $rk_grey1;
          flex-wrap: wrap;
        }
      }
    }
    th, td {
      position: relative;
      text-align: center;
      padding:  10px 5px;
      &:nth-child(3){
        color: $rk_grey7;
        cursor:default;
        width: 30%;
      }
    }
    td {
      padding: 5px;
      border-bottom: 1px solid $rk_grey1;
      font-weight: bold;
      font-family: 'special', Arial, sans serif;
      &.--no-data {
        color: $rk_loader1;
        font-style: italic;
        font-size: 1em;
        text-align: center;
      }

      &:first-child{
        @include mq($bpointScorecard){
          margin-left: -15px;
          width: 0;
          top: -5px;
        }
      }
      &:nth-child(2){
        @include mq($bpointScorecard){
          position: absolute;
          top: 30px;
          left: -8px;
          z-index: z(floor);
          width: 0;
        }
      }
      &:nth-child(3){
        @include mq($bpointScorecard) {
          width: 100%;
          padding: 10px;
        }
      }
      &:nth-child(n+4):nth-child(-n+10){
        @include mq($bpointScorecard){
          width: 12%;
          min-width: 60px;
          word-break: break-all;
          flex-grow: 2;
        }
      }
      &:last-child{
        @include mq($bpointScorecard){
          position: absolute;
          right: 7px;
          width: 0;
        }
      }
      @include mq($bpointScorecard){
        background: $rk_transparent !important;
        border-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:2px 5px;
        text-align: left;
        width: 20%;
        &:before {
          margin-right: 10px;
          content: attr(data-column);
          font-size: .9rem;
          color: $rk_grey8;
        }
      }
      &[data-column] {
        flex-direction: row;
        border-top: 1px solid $rk_grey1;
        @include mq($bpointScorecard){
          width: 33.33333333%;
          padding: 5px 0 5px 6%;
        }
        @include mq(phablet){
          width: 50%;
          padding: 5px 0 5px 10px;
        }
      }
    }
    th {
      border-bottom: 2px solid $rk_grey2;
      border-left: 1px solid $rk_grey1;
      font-size: .9rem;
      color: $rk_appraiser-fluor;
      cursor: pointer;
      border-radius: 9px 9px 0 0;
      &.scorecard__header__selector{
        padding: 0;
      }
      &:first-child,
      &:nth-child(n+12){
        border-left: 0;
      }
      &.--link {
        color: $rk_grey7;
      }
      &.--highlighted {
        color: $rk_black;
        border-radius: 9px 11px 0 0;
        font-family: 'headers', Arial, sans serif;
      }
      &.--final {
        border-radius: 11px 9px 0 0;
      }
    }
    .--amarillo {
      .scorecard__table__color {
        background-color: $rk_appraiser-yellow;
        color: $rk_black;
      }
      &:hover {
        td {
          border-bottom: 1px solid $rk_appraiser-yellow;
        }
      }
    }
    .--verde {
      .scorecard__table__color {
        background-color: $rk_appraiser-greend;
      }
      &:hover {
        td {
          border-bottom: 1px solid $rk_appraiser-greend;
        }
      }
    }
    .--rojo {
      .scorecard__table__color {
        background-color: $rk_appraiser-red;
      }
      &:hover {
        td {
          border-bottom: 1px solid $rk_appraiser-red;
        }
      }
    }
    &__score {
      position: absolute;
      top: 0;
      left: -5px;
      @include mq($bpointScorecard) {
        left: 10px;
      }
    }
    &__color {
      font-family: headers, Arial, sans-serif;
      color: $rk_white;
      font-size: 1.2rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      border-radius: 50%;
      letter-spacing: -.1rem;
    }
    .--highlighted{
      background: $rk_appraiser-brown;
      min-width: 130px;
      font-size: 1.2rem;
      border-bottom: 1px solid $rk_appraiser-brown;
      @include mq(desktop-wide) {
        min-width: 100px;
      }
      @include mq(desktop) {
        min-width: 80px;
      }
    }
    .--final {
      background: $rk_appraiser-brown2;
      border-left: 2px solid $rk_white !important;
      border-bottom: 1px solid $rk_appraiser-brown2;
      @include mq($bpointScorecard) {
        border-left: 0 !important;
      }
    }
    .--price-visita {
      background: $rk_appraiser-brown3;
      border-bottom: 1px solid $rk_appraiser-brown3;
    }
    .--price-venta {
      background: $rk_appraiser-yellow2;
      border-left: 2px solid $rk_white !important;
      border-bottom: 1px solid $rk_appraiser-yellow2;
      @include mq($bpointScorecard) {
        border-left: 0 !important;
      }
    }
    .--special {
      font-family: headers, Arial, sans-serif;
      font-size: 1.2rem;
      color: $rk_grey5;
    }
    a {
      transition: color .1s linear;
      margin: auto;
      font-size: .9rem;
      line-height: 1.2;
      display: block;
      @include mq($bpointScorecard) {
        display: inline;
        &:after {
          content: ' | ';
        }
      }
      &:hover {
        color: $rk_loader1;
      }
      &.--horus  {
        border: none;
        img {
          width: 30px;
        }
      }
    }
    &__delete {
      min-width: 40px;
      @include mq($bpointScorecard) {
        min-width: 20px;
        padding-right: 0;
      }
    }
  }
  &__header{
    padding: 10px 0;
    margin-bottom:10px;
    font-size: 1rem;
    line-height: 1;
    font-weight: normal;
    font-family: 'headers', Arial, sans-serif;
    @include mq($bpointScorecard) {
      display: none;
    }
    &__ico{
      display: block;
      margin: 0 auto 5px;
    }
    &__selector{
      line-height: 1.6;
      div {
        color: $rk_grey6;
        cursor: pointer;
        padding: 0 5px;
        &:hover,
        &.--active{
          color: $rk_appraiser-fluor;
        }
      }
    }
  }
  &__k {
    font-size: 1rem;
    min-width: 95px;
    text-align: left !important;
    @include mq(desktop){
      min-width: 60px;
    }
  }
  &__resp {
    width: 100% !important;
    &__tool {
      display: none !important;
      font-size: 1.1rem;
      cursor: pointer;
      @include mq($bpointScorecard){
        display: inline-block !important;
      }
    }
  }
  &__order {
    width: 13px;
    position: absolute;
    margin: -3px 3px;
    display: none;
    bottom: 2px;
    left: calc(50% - 10px);
    filter: sepia(1) contrast(0.5) saturate(33) hue-rotate(146deg);
    &.--false{
      display: inline;
      transform: rotate(180deg);
    }
    &.--true{
      display: inline;
    }

  }
  &__resp-ico {
    display: none;
    margin-bottom : 2px;
    @include mq($bpointScorecard){
      display: block;
    }
  }
  &__selector {
    display: block;
    border: 2px solid $rk_grey4;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    background: $rk_white;
    &:hover {
      img {
        opacity: .2;
      }
    }
    img{
      margin: -7px 0 0 2px;
      opacity: 0;
      transition: opacity .2s linear;
      &.--main {
        filter: hue-rotate(80deg);
      }
      &.--checked {
        opacity: 1;
      }
    }
  }
  &__sold {
    font-size: 1.5rem;
    color: $rk_step3;
  }
}

////////////////////////////////////////////////////////////// WITNESSESS


.witness-edition {
  &__header{
    position: relative;
    top: -20px;
  }
  &__address {
    margin: 10px 0;
    font-size: 1.1rem;
  }
  &__ads {
    display: flex;
    a {
      transition: color  .2s linear;
      margin: 0 10px 0 0;
      &:after {
        content: ' | '
      }
      &:hover {
        color: $rk_loader1;
      }
    }
  }
  &__title{
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    a {
      font-size: 1.5rem;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
    &-new {
      display: flex;
      align-items: center;
      color: $rk_grey6;
      font-size: 2rem;
      margin: 0;
    }
  }
  &__form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__txt-img{
    margin: 20px 0;
    line-height: 1.2;
    display: flex;
    width: 100%;
  }
  &__description{
    min-width: 47.5%;
    margin-right: 2.5%;
  }
  &__images {
    min-width: 45%;
    horus-custom-lightbox {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
    horus-uploader {
      border-radius: 0;
      background: transparent;
      border: 5px dashed $rk_grey2;
      display: block;
      min-width: 100%;
      margin-bottom: 60px;
      p {
        opacity: 1;
        border-radius: 0;
      }
    }
  }
  horus-url-input {
    min-width: 100%;
    margin: 20px 0;
  }
  .witness-edition__price {
    font-family: headers, Arial, sans-serif;
    font-size: 1.6rem;
    text-align: right;
  }
  .custom-lightbox__container {
    width: 18%;
    margin: 1%;
  }
  &__sold {
    filter: saturate(0);
    &:hover,
    &.mat-mdc-checkbox-checked{
      filter: none;
    }
    img {
      margin: 5px 2px -3px 20px;
    }
    .mdc-checkbox {
      position: absolute;
      opacity: 0;
    }
  }

}

