
.box-item {
  width: 15%;
  position: relative;
  text-decoration: none;
  margin:0 .8% 30px;
  display: flex;
  cursor: pointer;
  &:hover, &:focus{
    border-color: $rk_horus00;
    color: unset;
    h3{
      color: $rk_horus00;
    }
    figure{
      filter: none;
    }
  }
  @include mq('desktop-wide') {
    width: 18%;
  }
  @include mq('desktop') {
    width: 22%;
  }
  @include mq('tablet') {
    width: 31%;
  }
  @include mq('tablet-med') {
    width: 46%;
  }
  @include mq('phablet') {
    width: 100%;
    margin:0 0 30px;
  }
  &.--disabled{
    &:hover, &:focus{
      color: $rk_grey4;
      border-color: $rk_grey2 !important;
    }
    h3, img {
      filter: saturate(0);
    }
  }
}
.box-avatar {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: saturate(.6);
  overflow: hidden;
  background: $rk_white;
}
.box-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}
.box-name {
  word-break: break-word;
  text-transform: lowercase ;
  margin: 0 0 5px;
  line-height: 0.8;
  letter-spacing: -.03em;
  strong {
    line-height: 1;
    filter: saturate(0);
    font-family: 'special', Arial, sans-serif;
    margin-top: 6px;
    letter-spacing: 0;
  }
}


.--corner-round {
  // usuarios
  margin-bottom:10px;
  &--item{
    @extend .box-item;
    min-height: 50px;
    max-height: 100px;
    height: 30vh;
    min-width: 210px;
  }
  &--avatar{
    @extend .box-avatar;
    min-width: 75px;
    width: 75px;
    border-width: 0 0 1px 1px;
    border-radius: 0 0 0 50%;
    border-color: $rk_grey2;
    border-style: solid;
    &--img{
      max-height: 100%;
      object-fit: cover;
    }
    &--no {
      width: 70%;
      filter: hue-rotate(-55deg);
    }
  }
  &--data{
    @extend .box-data;
    border-bottom: 1px solid $rk_grey2;
    padding: 0 10px;
    &--name {
      @extend .box-name;
      font-size: 1.7rem;
      strong {
        font-size: 0.6em;
        display: block;
      }
    }
  }
}


.--squares {
  // contactos
  margin-bottom:10px;
  &--item{
    @extend .box-item;
    flex-wrap: wrap;
    border-style: solid;
    border-width: 0 0 1px 1px;
    border-color: $rk_grey2;
    &:hover {
      background: linear-gradient(45deg , $rk_grey2 0, $rk_transparent 25%);
    }
    &-a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  &--avatar {
    @extend .box-avatar;
    margin: 0 10px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid $rk_grey3;
    &.--big {
      margin-left:5px;
      width: 65px;
      min-width: 65px;
      height: 65px;
    }
    &--img{
      max-height: 100%;
    }
    &--no {
      width: 70%;
      filter: hue-rotate(-55deg);
    }
  }
  &--maindata {
    width: calc(100% - 80px);
    &--name {
      @extend .box-name;
      color: $rk_grey6;
      margin: 5px 0 5px 0;
      font-size: 1.5rem;
      strong {
        font-size: 0.8em;
      }
    }
  }
  &--data {
    @extend .box-data;
    padding:10px 10px 5px;
    &-extra {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
}

.--table {
  @include mq(tablet, min) {
    .--squares--item {
      max-width: 100%;
      min-height: 0;
      margin: 0;
      padding: 5px 15px;
      border-width: 0 0 1px 0 ;
      &-a {
        flex-direction: row;
        align-items: center;
        flex-grow: 2;
        > * {
          position: relative;
          width: auto;
          flex-basis: 20%;
        }
      }
      &-link {
        padding: 0;
        display: flex;
        align-items: center;
      }
    }
    .item__remove{
      top: 5px;
    }
  }
}
.--table-b {
  @include mq(tablet, min) {
    .--squares--item {
      max-width: 100%;
      min-height: 0;
      width: 100%;
      padding: 5px 30px 5px 10px;
      margin: 5px 0;
      border-width: 1px;
      border-top-color: $rk_transparent;
      &:hover{
        background: none;
      }
    }
    .--squares--maindata {
      width: auto;
      flex-grow: 2;
    }
    .--squares--data-extra {
      flex-direction: row;
      justify-content: flex-end;
      font-size: 1.3rem;
      color: $rk_grey6;
    }
    .item__remove{
      top: 15px;
    }
  }
}

.tables__views {
  display: none;
  @include mq(tablet, min) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -20px 0 20px;
    img {
      filter: saturate(0);
      width: 25px;
      margin: 0 5px;
      cursor: pointer;

      &:hover, &.--sel {
        filter: saturate(1);
      }
    }
  }
}

.cash {
  &__table{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  &__tr {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include mq(tablet){
      flex-direction: column;
      border-bottom: 1px solid $rk_grey4 !important;
    }
    &__summary {
      position: relative;
      display: none;
      font-size: 1.5rem;
      width: 100%;
      padding: 5px;
      align-items: center;
      justify-content: space-between;
      @include mq(tablet){
        display: flex;
      }
      & > div {
        padding: 0 2%;
      }
      &.accordion__opned > div {
        opacity: 0;
      }
      img {
        width: 20px;
      }
      &-info {
        flex-grow: 2;
      }
      &-done {
        width: 30px;
      }
      &-currency {
        font-family: headers, Arial, sans-serif;
        color: $rk_step4;
      }
    }
  }
  &__td {
    padding: 0 10px;
    overflow: hidden;
    @include mq(tablet){
      width: 100%;
    }
    .mat-mdc-form-field-infix {
      margin-top: 0;
    }
    &.--no-form {
      min-width: 125px;
      font-size: 1.7rem !important;
      @include mq(tablet){
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 10px 0;
        min-height: 30px;
        border-bottom: 1px solid $rk_grey2;
        margin: 2px auto 11px;
        max-width: calc(100% - 20px);
      }
      label {
        color: grey;
        font-size: 1.1rem;
        line-height: 1;
        display: block;
      }
    }
    &.--with-data {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 180px;
      @include mq(tablet){
        width: 100%;
        padding-bottom: 20px;
      }
      .mat-mdc-form-field {
        flex-grow: 2;
      }
      .mat-mdc-input-element {
        text-align: right;
        @include mq(tablet) {
          text-align: left;
        }
      }
      strong {
        font-size: 1.2rem;
        color: $rk_grey3;
        margin: 16px 5px 0 0;
      }
    }
    &.--long {
      min-width: 20%;
      @include mq(tablet){
        width: 100%;
        padding-bottom: 20px;
      }
    }
    &.--mini {
      width: 20px;
      min-width: 20px;
      padding: 0;
      @include mq(tablet){
        position: absolute;
        right: 0;
        top:20px;
      }
      &.--alone {
        @include mq(tablet) {
          top: 0;
        }
      }
      img {
        width: 20px;
      }
    }
    &.--fix {
      min-width: 30px;
      padding: 0;
      @include mq(tablet){
        width: 100%;
      }
      strong {
        display: none;
        @include mq(tablet){
          display: inline;
        }
      }
    }
  }
  .mat-mdc-checkbox{
    transform: scale(1.2);
    filter: hue-rotate(-101deg);
    opacity: .6;
  }
  &__date {
    min-width: 115px;
    .mat-datepicker-input {
      cursor: pointer;
      min-height: 23px;
      user-select: none !important;
    }
  }
  &__currencies {
    font-family: "headers", Arial, sans-serif !important;
    font-size: 1.7rem;
    color: $rk_step5;
  }
  &__currency-regular {
    font-family: "headers", Arial, sans-serif !important;
    color: $rk_grey4;
    font-size: 1.52rem;
  }
  &__currency .mat-mdc-input-element {
    text-align: right;
    font-family: "headers", Arial, sans-serif !important;
    font-size: 1.5rem !important;
    color: $rk_step5;
  }
  .mat-mdc-form-field-textarea-control {
    height: 22px !important;
    font-size: 1.1rem !important;
  }
}

.--cash-inactive{
  .mat-mdc-input-element,
  .mat-mdc-select{
    color: rgba($rk_progress, .8) !important;
  }
}

