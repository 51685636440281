* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: Arial, sans-serif;
}

figure{
  padding: 0;
  margin: 0;
}
