.custom-form {
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  &__title {
    min-width: 100%;
    margin: 5px 0;

  }
  &__field {
    margin: 10px 1%;
    flex-grow: 2;
    width: auto;
    max-width: 350px;
    width: 33%;
    align-items: center;
    @include mq(tablet-small) {
      min-width: 48%;
    }
    @include mq(phablet) {
      margin: 10px auto;
      min-width: 100%;
    }
    .--currency {
      font-size: 1.5rem !important;
      font-family: 'headers', Arial, sans-serif !important;
      text-align: right;
    }
  }
  &__fieldset {
    margin: 10px auto;
    min-width: 100%;
  }
  &__files {
    display: flex;
    align-items: center;
    min-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
    border-bottom: 1px solid $rk_grey1;
    padding-bottom: 10px;
  }
  &__mailing {
    min-width: 100%;
    margin-bottom: 15px;
    border: 1px solid $rk_grey1;
    padding: 10px;
    border-radius: 20px;;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;
      flex-wrap: wrap;
    }
    &__sent {
      font-family: basic, Arial, sans-serif;
      font-size: 1.2rem;
      color: $rk_grey5;
      margin-bottom: 20px;
      padding-left: 15px;
      &__title{
        font-style: italic;
        font-size: 1.4rem;
        color: $rk_grey5;
        border-bottom: 1px solid $rk_grey1;
        margin-top: 10px;
      }
      &__files{
        display: flex;
        flex-wrap: wrap;
      }
      &__link {
        width: 75px;
        height: auto;
        margin: 0 10px 0 0;
        font-size: .8em;
        word-break: break-all;
      }
      strong {
        display: block;
        margin: 0 0 10px 0;
        color: $rk_black;
      }
      img {
        width: 100%;
        filter: saturate(0);
        &:hover {
          filter: none;
        }
      }
    }
    .--attached {
      margin-bottom: 30px;
    }
    h3{
      flex-grow: 2;
    }
    .mat-mdc-form-field {
      max-width: 220px;
    }
    .createbutton {
      padding: 5px;
    }
  }
  &__requireds {
    font-weight: bold;
    color: $rk_horus08;
    font-size: 1.2rem;
  }
  .--error {
    font-family: 'headers', Arial, sans-serif !important;
    color: $rk_semaphoreOrange;
  }
}
