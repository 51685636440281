.right-modal {
  position: fixed;
  background: $rk_white;
  top: 0;
  width: calc(100% + 25px);
  height: 100vh;
  padding: 40px 4vw 40px 3vw;
  overflow-y: scroll;
  z-index: z(level4);
  max-width: 400px;
  border-left: 1px solid $rk_grey1;
  box-shadow: 0 0 15px rgba($rk_black, 0.5);
  right: -500px;
  transition: right 0.2s linear;
  @include mq("phablet") {
    padding: 40px 6vw 40px 5vw;
  }
  &.--med {
    max-width: 650px;
    right: -700px;
  }
  &.--long {
    max-width: 950px;
    right: -1000px;
  }
  &.--extralong {
    max-width: 1300px;
    right: -1400px;
  }

  &.--opened {
    right: -25px;
    @media screen and (max-device-width: 767px) {
      right: 0;
      max-width: 100vw;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 26px;
    width: 30px;
    transform: rotate(180deg);
    @include mq("tablet") {
      right: 30px;
      top: 10px;
    }
    @include mq("phablet") {
      right: 10px;
    }
  }
}

.page-modal {
  width: 100%;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  top:0;
  left:0;
  height: 100vh;
  z-index: z(level5);
  &__back {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: z(bottom);
    background: $rk_grey5;
    opacity: .95;
    top: 0;
    left: 0;
    &.--modal-right {
      z-index: z(level4);
      background: rgba($rk_black, 0.3);
    }
  }
  &__max {
    position: relative;
    transition: height .2s linear;
    width: 100%;
    min-height: 50vh;
    max-width: map-get($breakpoints, 'desktop-wide');
    padding: 10px 2vw 40px;
    background: $rk_white;
    margin:20px auto;
    border-radius: 20px;
    @include mq(desktop-wide) {
      margin: 0 auto;
      min-height: 100vh;
    }
    @include mq(tablet) {
      border-radius: 0;
    }

    &.--mini {
      max-width:  map-get($breakpoints, 'phablet');
      margin:20px auto;
      min-height: auto;
      @include mq(phablet) {
        margin: 0 auto;
        min-height: 100vh;
      }
    }
    &.--med {
      max-width:  map-get($breakpoints, 'tablet');
      margin:20px auto;
      min-height: auto;
      @include mq(tablet) {
        margin: 0 auto;
        min-height: 100vh;
      }
    }
    &.--large {
      max-width:  map-get($breakpoints, 'desktop');
    }
    &.--xlarge {
      max-width:  map-get($breakpoints, 'desktop-wide');
    }
  }
  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__nav{
    padding: 0 1vw;
    background: $rk_white;
    transition: left .2s linear;
    @include mq(tablet) {
      position: fixed;
      left: 100%;
      height: 100vh;
      width: 100%;
      padding: 60px 5vw;
      z-index: z(level3);
      border-left: 1px solid $rk_grey2;
      top: 0;
      &.--open {
        left: 15%;
        box-shadow: 0 0 10px rgba($rk_black, .6);
      }
    }
    &-trigger{
      background: $rk_grey1;
      width: 40px;
      height: 40px;
      border-radius: 20px 0 0 20px;
      position: absolute;
      left: -40px;
      align-items: center;
      justify-content: center;
      display: none;
      cursor: pointer;
      @include mq(tablet) {
        display: flex;
      }
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
      }
    }
    &-item {
      margin: 12px 0;
      display: block;
      cursor: pointer;
      transition: color .2s linear;
      color: $rk_grey3;
      &:hover, &.--sel {
        color: $rk_black;
      }
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 2vw;
    background-color: $rk_white;
  }
  &__main {
    position: relative;
    flex-grow: 2;
    flex-basis: 65%;
    max-width: 87vw;
    padding: 0 3vw 0 0;
    border: $rk_grey6 solid;
    border-width: 0 1px 0 0;
    @include mq(tablet) {
      max-width: 100%;
      flex-basis: 100%;
      border-width: 0 0 1px 0;
      padding: 0 0 3vw 0;
    }
  }
  &__menu{
    padding: 0 1vw;
  }
  &__section {
    padding:10px 0;
    position: relative;
  }
  &__title {
    font-size: 3rem;
    font-family: headers, Arial, sans-serif;
    color: $rk_grey2;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: z(level1);
    margin: 0 0 20px;
    small {
      font-size: 1.5rem;
      display: block;
    }
    img {
      width: 40px;
      @include mq("tablet") {
        width: 35px;
      }
      @include mq(tablet-small) {
        width: 25px;
      }
    }
  }
  &__head-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 100px;
    position: absolute;
    top: 10px;
    right: 20px;
    .--link {
      position: relative;
      margin-left: 10px;
      top: unset;
      right: unset;
    }
  }
  &__recoverlink {
    &.--link {
      img {
        filter: none;
      }
      &:hover img {
        filter: invert(32%) sepia(61%) saturate(402%) hue-rotate(99deg)
        brightness(115%) contrast(100%);
      }
    }
  }
  &__avatar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    &.--disabled img {
      filter: saturate(0);
    }
  }
  &__collapsed {
    height: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0 2%;
    transition: max-height 0.2s linear;
    * {
      transition: opacity 0.5s linear;
      opacity: 0;
    }
    &.--expanded {
      overflow: visible;
      height: auto;
      max-height: 1000px;
      * {
        opacity: 1;
      }
      .mdc-slider__input {
        opacity: 0;
      }
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    z-index: z(level1);
    @include mq("tablet") {
      top: 10px;
      right: 10px;
    }
    @include mq(tablet-small) {
      top: 5px;
      width: 20px;
      img {
        width: 25px;
      }
    }
  }
  &__deletelink,
  &__recoverlink {
    position: absolute;
    right: 55px;
    top: 15px;
    z-index: z(level1);
    @include mq("tablet") {
      top: 6px;
      right: 50px;
    }
    @include mq(tablet-small) {
      top: 4px;
      right: 39px;
    }
    img {
      width: 40px;
      @include mq("tablet") {
        width: 35px;
      }
      @include mq(tablet-small) {
        width: 25px;
      }
    }
  }

  &__delete, &__create {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($rk_horus08, 0.95);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: z(level6);
    padding: 5vh 15%;
    color: $rk_white;
    + .loader__container {
      z-index: z(level6);
    }
    &.--fullscreen {
      position: fixed;
      justify-content: center;
    }
    h2 {
      font-family: special, Arial, sans-serif;
      text-align: center;
      color: $rk_grey05;
    }
    span {
      color: $rk_white;
      display: block;
      font-family: headers, Arial, sans-serif;
      font-size: 3rem;
      padding: 10px 30px;
      @include mq("tablet") {
        font-size: 2rem;
      }
    }
  }
  &__create {
    background: rgba($rk_horus03, 0.95);
    text-align: center;
    span {
      font-size: 3rem;
      padding: 5px 30px;
      display: block;
      color: $rk_horus01;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    z-index: z(level1);
    @include mq("tablet") {
      top: 10px;
      right: 10px;
    }
    @include mq(tablet-small) {
      top: 5px;
      width: 20px;
      img {
        width: 25px;
      }
    }
  }
}

.--hidden-modal {
  opacity: 0;
  z-index: z(bottom);
}
.--show-modal {
  z-index: z(level4);
  animation: upriseModal .3s ease-out forwards;
}


.--modal-top {
  z-index: z(level6);
}
