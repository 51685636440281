// --------------COLORS
$rk_horus-1: #123333;
$rk_horus00: #133866;
$rk_horus01: #006390;
$rk_horus02: #68bacf;
$rk_horus03: #50e3c1;
$rk_horus04: #0ca66b;
$rk_horus05: #ffe44a;
$rk_horus06: #febc44;
$rk_horus07: #ff5886;
$rk_horus08: #ca4a48;
$rk_horus09: #753673;
$rk_horus10: #2b445d;

$rk_horus11 : #ffa98c;
$rk_horus12 : #ffcc8d;
$rk_horus13: #e4c6ff;

$rk_loader1: #002cff;
$rk_loader2: #00ffc4;
$rk_loader3: #001cb0;
$rk_loader4: #d4e59b;
$rk_loader5: #c785f3;

$rk_copper1: #aa4400;
$rk_copper2: #a86200;
$rk_copper3: #d8a90d;
$rk_copper4: #de8c0d;

$rk_gradient1: #332e7d;
$rk_gradient2: #50d3e3;
$rk_gradient3: #ff58ea;

$rk_action1: #ecb345;
$rk_action2: #ffa023; // #59d4ea;
$rk_action3: #d9ce00; // #838bff;
$rk_action4: #b9be0a; // #a579ec;

$rk_step1: #bfb000;
$rk_step2: #6d9d39;
$rk_step3: #4aa90b;
$rk_step4: #1f6d1b;
$rk_step5: #024624;
$rk_checked: #c8ffcb;


$rk_semaphoreGreen: #859836;
$rk_semaphoreYellow: #f9ba19;
$rk_semaphoreRed: #c12900;
$rk_semaphoreOrange: #ff6a00;

$rk_progress: #ff5e00;
$rk_maxDates: #366a98;
$rk_transparent: transparent;

$rk_appraiser-brown: #ffe8d2;
$rk_appraiser-brown2: #f1ded3;
$rk_appraiser-brown3: #fdd290;
$rk_appraiser-yellow: #ffd100;
$rk_appraiser-yellow2: #fff6ac;
$rk_appraiser-fluor: #00efd1;
$rk_appraiser-greygreen: #f1ffd1;
$rk_appraiser-green: #74f37e;
$rk_appraiser-greend: #64c76b;
$rk_appraiser-purple: #e8cfff;
$rk_appraiser-pink: #c301a2;
$rk_appraiser-red: #ff5555;


$rk_role_admin: #05c984;
$rk_role_administrador: #77c2ff;
$rk_role_equipo: #ffbe00;
$rk_role_legal: #fa8072;
$rk_role_coordinador: #ff65e4;
$rk_role_telemarketing: #be9aff;
$rk_role_agente: #afc900;


$rk_role_administradordark: #58799c;
$rk_role_equipodark: #dfaf2b;
$rk_role_legaldark: #8f5246;
$rk_role_coordinadordark: #914186;
$rk_role_telemarketingdark: #715e98;
$rk_role_agentedark: #6b791d;

/* Grises  */

$rk_black: #000;
$rk_grey9: #191919;
$rk_grey8: #333;
$rk_grey7: #4C4C4C;
$rk_grey6: #666;
$rk_grey5: #7F7F7F;
$rk_grey4: #999;
$rk_grey3: #B2B2B2;
$rk_grey2: #CCC;
$rk_grey1: #E5E5E5;
$rk_grey05: #F1F1F1;
$rk_grey02: #f9f9f9;
$rk_white: #FFF;
$rk_horusgrey: #778899;



$rk_toques : #fff094;
$rk_entrevistas : #ffc87f;
$rk_captaciones : #ff9fb1;
$rk_facturacion : #7fc8b1;
$rk_chartBase: #850559;
