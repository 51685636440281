.reporting {
  @include mq('tablet') {
    max-width: none;
    margin: auto;
    padding: 10px;
    h2, .mat-mdc-form-field
    {
      margin: 0 auto 20px;
      justify-content: center;
      text-align: center;
    }
  }
  .page-modal__content {
    min-height: 300px;
  }
  &__contacts {
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__contact{
    position: relative;
    margin: 10px 1%;
    padding: 0 1% 0 5px;
    width: 104px;
    font-size: 1rem;
    color: $rk_grey8;
    border-right: 1px solid $rk_grey2;
    &:last-child {
      border-color: $rk_transparent;
    }
    &.--disabled{
      opacity: .5;
      filter: saturate(0);
      color: $rk_grey5;
    }

    figure{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    img{
      border-radius: 50%;
      width:50px;
      height:50px;
      overflow: hidden;
      border:1px solid $rk_grey2;
      margin-bottom: 5px;
      object-fit: cover;
    }
    figcaption {
      text-align: center;
    }
  }

  &__comment {
    width: 100%;
    margin: 20px 0;
  }
  &__allowed {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $rk_white;
    border-radius: 50%;
    padding:2px;
    top:0;
    max-height: 24px;
    max-width: 24px;
    transition: transform .2s linear;
    cursor: pointer;
    &:hover {
      transform: scale(1.8);
    }
    &.--disabled{
      filter: saturate(0);
      cursor: default;
      &:hover {
        transform: none;
      }
    }
    img{
      border: none;
      border-radius: 0;
      width: 20px;
      height: 20px;
      margin: 0;
    };
    &.--email {
      left: 5px;
    }
    &.--mobile {
      right:  10px;
    }
  }
  .createbutton {
    border: 1px solid $rk_grey2;
    padding: 10px 20px;
    margin: auto;
  }
  &__title{
    margin: 0;
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }
  &__multiple {
    display: flex;
    justify-content: space-around;
    width: 100%;
    @include mq('tablet') {
      flex-direction: column;
    }
    .reporting__contacts {
      justify-content: flex-start;
    }
    section {
      margin-left:10px;
      margin-right:10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      @include mq('tablet') {
        padding: 0;
        margin-left:auto;
        margin-right:auto;
      }
    }
  }
  &__report {
    width: 100%;
    padding-bottom: 20px;
    margin:0 auto 25px;
    border-bottom: 1px solid $rk_grey2;
    @include mq('tablet') {
      width: 96%;
    }
    &__title{
      font-size: 1.3rem;
      color: $rk_grey4;
      margin: 10px auto 0;
      img{
        width: 20px;
        margin-right: 10px;
        filter: saturate(0);
      }
      h3{
        font-size: 1.5rem;
        color: $rk_grey4;
        margin: 0 10px 0 0;
      }
      strong {
        margin: 0 10px;
        font-size: 2rem;
        color: $rk_black;
      }
      span {
        display: block;
        padding-top: 3px;
      }
      small {
        font-family: basicregular, Arial, sans-serif;
        font-size: .6em;
      }
      &.disabled {
        h3 {
          text-decoration: line-through;
        }
      }
    }
    &__error {
      margin-left: 10px;
      color: $rk_semaphoreRed;
      font-size: 1.1rem;
      max-width: 250px;
    }
    .profile-form__container{
      max-width: 86%;
      padding: 20px 10px 20px 0;
      margin: auto;
    }
    &__remove{
      position: absolute;
      border-radius: 50px;
      display: flex;
      top:0;
      left: calc(50% + 10px);
      opacity: .4;
      padding: 5px;
      height: 30px;
      img {
        width: 15px;
        filter: saturate(0) brightness(0);
      }

    }
    &__ctas{
      display: flex;
      align-items: center;
      width: 90%;
      margin: auto;
      justify-content: space-around;
      .--link {
        margin: 0 10px;
        opacity: .5;
      }
    }
    .--selected-contact {
      position: relative;
      cursor: pointer;
      &:hover{
        figure {
          opacity: .6;
        }
        .reportingM__remove {
          opacity: 1;
        }
      }
    }
    small {
      color: $rk_grey2;
      font-weight: lighter;
    }
  }
  &__info{
    padding-bottom:10px;
    border-bottom: 1px solid $rk_grey1;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  &__well-done{
    color: $rk_white;
    font-size: 3.5vw;
    margin: auto;
    z-index: z(level3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    @include mq('desktop') {
      font-size: 4.5vw;
    }
    @include mq('tablet-small') {
      font-size: 50px;
    }
    @include mq('tablet-small') {
      font-size: 35px;
    }
    span  {
      display: block;
      font-size: .6em;
      color: $rk_black;
    }
    h2 {
      margin: 0 0 20px;
    }
    small {
      font-size: .4em;
    }
  }

  horus-wysiwyg{
    font-size: 1.1rem;
    margin:10px 0 0;
    .ck-editor__editable{
      border:1px solid $rk_grey1;
    }
    .mat-mdc-form-field {
      display: none;
    }
  }
  &__pending {
    font-style: italic;
    text-transform: uppercase;
    color: $rk_gradient3;
    max-width: 80%;
    text-align: center;
    margin: 0;
  }
  &-pending{
    &__toggle{
      position: absolute;
      top: 72px;
      right: 10px;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-radius: 50%;
      cursor: pointer;
      background-color: $rk_grey8;
      transition: background-color .2s linear;
      @include mq('phablet') {
        transform: scale(.6);
        top: 60px;
        right: 0;
        figcaption {
          display: none;
        }
      }
      &:hover {
        background: $rk_horus07;
        img{ filter: saturate(0) brightness(2); }
      }
    }
    &__icon{
      transition: filter .2s linear;
      width: 80%;
    }
    &__text{
      font-size: 1rem;
      color: $rk_horus06;
      position: absolute;
      background: $rk_grey8;
      text-align: center;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin: -32px 0 0 -65px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    &__title{
      font-size: 2.5rem;
      font-family: special, Arial, sans-serif;
      display: flex;
      align-items: center;
      &__icon{
        width: 45px;
        margin-right: 20px;
      }
    }
    &__item {
      display: flex;
      padding: 20px 5px;
      align-items: center;
      justify-content: space-between;
      font-size: 1.3rem;
      width: 100%;
      border-bottom: 1px solid $rk_grey3;
      cursor: pointer;
      &:hover{
        background-color: $rk_grey05;
        strong {
          color: $rk_black;
        }
      }
      figure{
        display: flex;
        align-items: center;
        margin: 0;
      }
      &__position {
        border: 1px solid $rk_grey6;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: headers, Arial, sans-serif;
        font-weight: bold;
        height: 20px;
        min-width: 20px;
        font-size: 1rem;
        color: $rk_grey6;
        margin-right: 10px;
      }
      &__title{
        flex-grow: 2;
        font-size: 1.5rem;
        padding-right: 10px;
        color: $rk_grey6;
      }
      &__desc{
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-left: 1px solid $rk_grey3;
        @include mq('phablet') {
          padding: 0;
          border: 0;
        }
      }
      &__type-icon {
        width: 25px;
        margin: 0 10px  0 0;
      }
      &__icon{
        width: 20px;
        filter: none !important;
        margin: 0 10px;
      }
    }
  }
}


