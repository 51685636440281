.acting-like{
  display: flex;
  align-items: center;
  color: $rk_grey3;
  font-size: 1.1rem;
  padding-right: 20px;
  border-right: 2px solid $rk_gradient2;
  @include mq('tablet') {
    padding-right: 10px;
  }
  @include mq('tablet-small') {
    padding-right: 5px;
  }
  &.--acting-link:hover, &.--acting-link:focus{
    cursor:pointer;
    color: $rk_white;
  }
  &__avatar{
    margin-right: 10px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    height: 36px;
    width: 36px;
    min-width: 36px;
    border-width: 3px;
    border-style: solid;
    background: $rk_black;
    .--link & img {
      object-fit: cover;
      filter: none;
    }
  }
  &__user {
    display: block;
    color: $rk_grey05;
    font-size: 1.15rem;
    min-width: 100px;
    font-family: 'special', Arial, sans serif;
    @include mq('tablet') {
      min-width: 0;
      display: none;
    }
  }
  &__role {
    @include mq('tablet-small') {
      display: none;
    }
  }
  &__modal{
    &-title{
      margin-top:0;
      color:$rk_horus01;
    }
    &-linklist{
      margin:0 0 10px 0;
      cursor: pointer;
      &:hover, &:focus{
        color: $rk_loader1;
      }
      &.--active{
        font-weight: bold;
        cursor: default ;
        user-select: none;
        &:before{
          content: "*";
          font-size: 2em;
          position: relative;
          left: 0;
          top: 0.35em;
          line-height: 0;
          color: $rk_loader1;
        }
        &:hover, &:focus {
          color: $rk_black;
        }
      }
    }
  }
}
