.eagle {
  padding: 30px 1vw;
  &__header {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    align-items: center;
    margin:10px 0 20px;
    padding: 0 10px;
    @include mq(tablet) {
      width: 100%;
      justify-content: space-between;
    }
    h2 {
      margin: 0 2% 0 0;
    }
    horus-user-autocomplete {
      min-width: 50%;
      max-width: 300px;
      margin-right: 2%;
      @include mq(tablet) {
        min-width: 70%;
      }
    }
  }
  .tables__views {
    display: none;
  }
  &__data {
    position: fixed;
    width: 100%;
    top: calc(100vh - 50px);
    left: 0;
    min-height: 40px;
    background-color: $rk_grey8;
    display: flex;
    z-index: z(level1);
    @include mq(tablet) {
      display: none;
    }
    .eagle__column {
      padding: 6px 20px;
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .eagle__data__info {
      width: 100%;
    }
    h2{
      margin: 0;
      font-size: 1.5rem;
      text-align: center;
      color: $rk_grey2;
    }
  }
  &__price {
    color: $rk_appraiser-green;
    font-family: "headers", Arial, sans-serif;
  }
  &__content{
    width: 100%;
    display: flex;
    @include mq(tablet) {
      flex-direction: column;
    }
  }
  &__column{
    min-height: calc(100vh - 90px);
    flex-basis: 100%;
    position: relative;
    @include mq(tablet) {
      min-height: 0;
      padding: 0;
    }
    &:last-child > div {
      border:0;
    }
    .accordion__trigger{
      text-align: center;
      margin: 0;
      min-height: 50px;
      align-items: center;
      display: none;
      padding: 1vh;
      @include mq(tablet) {
        display: flex;
      }
    }
    small {
      font-size: .8em;
      font-family: 'basic', Arial, sans-serif;
      font-weight: normal;
      margin: 0 10px;
    }
    &__list{
      margin: 5px 0 20px 0;
      padding: 0 .75vw;
      border-right: 2px solid $rk_grey7;
      min-height: 100%;
      @include mq(tablet) {
        margin: 0;
        border: 0;
      }
    }
  }
  &__alarm-counter{
    margin-left: 20px;
    background: $rk_semaphoreRed;
    color: $rk_white;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    display: none;
    @include mq(tablet) {
      display: flex;
    }

  }
  horus-lead-list {
    @include mq(tablet) {
      margin-top:15px;
      padding: 0 10px;
      display: block;
    }
  }
  .accordion__trigger:after {
    right: 15px;
  }
}
