.error-dialog {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10vh;
    overflow: hidden;
  }

  &__message{
    opacity: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15vh;
    z-index: z(level6);
    display: flex;
    font-size: 1.5rem;
    text-transform: lowercase;
    align-items: center;
    justify-content: center;
    background: rgba( $rk_black , .7);
    animation: appear .2s linear forwards;
    box-shadow: 0 -2px 10px $rk_semaphoreRed;
    img{
      max-height: 40px;
      margin-right: 10px;
      filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(313deg) brightness(104%) contrast(117%);
    }
    figcaption {
      color: $rk_white;
      font-family: headers, Arial, sans-serif;
    }
  }

  &__desc {
    padding-top: 5px;
    font-size: 1.3rem;
    font-family: basic, Arial, sans-serif;
    color: $rk_horus06;
  }

}

