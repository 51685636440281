.info {
  position: relative;
  font-family: basic, Arial, sans-serif;
  font-size:1.3rem;
  line-height: 1.6;
  max-width: 1800px;
  padding: 50px 5vw 200px;
  margin: auto;
  height: auto;
  display: flex;
  color: $rk_grey8;
  &__logo {
    margin:8vh auto 0;
    cursor: pointer;
    width: 90%;
    max-width: 400px;

  }
  &__title {
    font-size: 3rem;
    text-transform: lowercase;
    line-height: 1;
    font-family: headers, Arial, sans-serif;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &__intro {
    font-style: italic;
    font-size:1.2rem;
  }
  h2 {
    font-family: special, Arial, sans-serif;
    color: $rk_black;
  }
  li {
    margin-bottom: 5px;
  }
  h3 {
    font-family: special, Arial, sans-serif;
    color: $rk_grey8;
  }
  table {
    border: 1px solid $rk_grey2;
  }
  tr{
    border: solid $rk_grey2;
    border-width: 1px 0;
  }
  td{
    margin: 0;
    padding: 10px;
    border: solid $rk_grey2;
    border-width: 1px ;
  }
  main{
    min-width: 80%;
  }
  aside {
    margin: 0 0 0 5%;
    padding: 2%;
    min-width: 15%;
    border-left: 1px solid $rk_grey5;
  }
  &__nav{
    display: flex;
    flex-direction: column;
  }
  &__link {
    line-height: 1;
    margin: 10px 0;
    &.--sel {
      font-weight: bold;
      color: $rk_black;
    }
  }
  &__signature {
    padding: 5%;
    color: $rk_black;
    font-size: 1.3rem;
    img,
    .mdc-form-field {
      margin-top: 20px;
    }
    .mdc-label{
      font-size: 1.3rem;
    }
  }
}
