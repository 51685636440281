horus-dashboard {
  display: block;
  min-height: calc(90vh + 20px);
  @include mq("tablet") {
    height: auto;
  }
}
.dash {
  &__welcome {
    width: 30%;
    border-right: 1px solid $rk_grey3;
    @include mq("desktop-wide") {
      width: 25%;
    }
    @include mq("desktop") {
      width: 20%;
    }
    @include mq("tablet") {
      border: 0;
      width: 100%;
    }
  }
  &__header {
    position: relative;
    width: 100%;
    display: flex;
    h1 {
      font-size: 4rem;
      color: $rk_grey3;
      padding: 0 3vw;
      margin: 10px 0 20px;
      @include mq("desktop") {
        font-size: 2.5rem;
      }
      @include mq("tablet") {
        margin: 21px 0 20px;
        font-size: 2rem;
      }
    }
  }
  &__up {
    position: relative;
    width: 100%;
    min-height: 64vh;
    display: flex;
    padding: 20px 0 20px 0;
    @include mq("tablet") {
      flex-direction: column;
      padding: 0 0 20px 0;
      min-height: unset;
    }
  }
  &__quicklinks {
    padding: 1vh 3vw 0;
    display: flex;
    flex-direction: column;
    @include mq("tablet") {
      width: 100%;
      margin-bottom: 30px;
    }
    @include mq("tablet-small") {
      margin-bottom: 10px;
    }
    horus-search-engine {
      min-width: 100%;
    }
    nav {
      @include mq("tablet") {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
    a {
      display: flex;
      align-items: center;
      font-size: 2rem;
      padding: 10px 0;
      line-height: 0.8;
      border-bottom: 1px solid $rk_grey1;
      @include mq("desktop") {
        font-size: 1.5rem;
      }
      @include mq("tablet") {
        flex-direction: column;
        padding: 10px;
        border: 0;
        max-width: 100px;
      }
      @include mq("tablet-small") {
        font-size: 1.2rem;
        max-width: 75px;
        padding: 10px 5px;
      }
    }
    figure {
      background: $rk_grey8;
      border-radius: 50%;
      display: flex;
      width: 3vw;
      height: 3vw;
      max-height: 50px;
      max-width: 50px;
      min-height: 50px;
      min-width: 50px;
      margin-right: 20px;
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;
      border: 1px solid $rk_grey4;
      transition: background-color 0.2s linear;
      @include mq("tablet") {
        margin-right: 4%;
      }
      @include mq("tablet-small") {
        max-height: 45px;
        max-width: 45px;
        min-height: 45px;
        min-width: 45px;
      }
    }
    img {
      width: 60%;
    }
  }
  &__charts {
    width: 70%;
    position: relative;
    padding: 0 5% 0 1%;
    flex-wrap: wrap;
    min-height: 530px;
    max-width: 1160px;
    margin: auto;
    @include mq("desktop-wide") {
      padding: 0 2%;
      width: 75%;
    }
    @include mq("desktop") {
      padding: 0 2%;
      width: 80%;
    }
    @include mq("tablet") {
      width: 100%;
      min-height: 600px;
      padding: 0 2%;
    }
    @include mq("phone-wide") {
      padding: 0 5%;
      min-height: 550px;
    }
    &__chart {
      width: 100%;
      margin-top: 20px;
      height: unset;
    }
    .--box {
      border: 0;
    }
  }

  &__bottom {
    position: relative;
    width: 100%;
    @include mq("tablet") {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  &__alarm {
    width: 100%;
    padding: 10px 2vw;
    overflow-x: auto;
    @include mq("tablet") {
      margin-bottom: -23px;
      font-size: 2em;
    }
    .loader__container{
      margin-top: 10vh;
    }
    &__delete {
      position: absolute;
      top: 4px;
      right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      background: black;
      border-radius: 50%;
      height: 30px;
      cursor: pointer;
      &:hover {
        img {
          filter: brightness(2);
        }
      }
      img {
        height: 20px;
        width: auto;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $rk_grey05;
      @include mq("tablet-small") {
        padding-bottom: 10px;
      }
      img {
        width: 30px;
        margin-right: 10px;
      }
    }
    &__title {
      font-size: 2rem;
      color: $rk_grey5;
      margin: 5px 5px 3px;
      @include mq("tablet") {
        font-size: 2.5rem;
      }
    }
    &__list {
      display: flex;
      width: 100%;
      min-height: 100%;
      @include mq("tablet") {
        overflow-x: auto;
      }
    }
    &__no-alarm {
      font-size: 2rem;
      padding: 2vh 2vw;
      font-family: headers, Arial, sans-serif;
      color: $rk_grey2;
      @include mq("tablet") {
        font-size: 1.5rem;
      }
    }
    &__item {
      font-size: 1.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      color: $rk_black;
      flex-basis: 100%;
      box-shadow: 1px 2px 6px rgba($rk_gradient1, 0.6);
      transition: background-color 0.2s linear;
      position: relative;
      min-width: 200px;
      max-width: 20%;
      overflow: hidden;
      margin: 10px;
      border-radius: 20px;
      @include mq("tablet") {
        height: auto;
        max-height: unset;
      }
      &.--acción,
      &.--plantilla {
        border-radius: 0;
        border: 1px solid $rk_grey6;
      }
      &.--check {
        border-radius: 10px;
        border: 1px solid $rk_grey3;
      }
      &.--entre {
        border-radius: 5px;
        box-shadow: 1px 2px 6px rgba($rk_loader1, 0.6);
        border: 1px solid $rk_loader1;
      }
    }
    &__type {
      display: flex;
      align-items: center;
      bottom: 0;
      left: 0;
      padding: 20px 10px;
      font-size: 1.2rem;
      font-family: headers, Arial, sans-serif;
      width: 100%;
      color: $rk_black;
      transition: all 0.2s linear;
      min-height: 34px;
      &:hover {
        color: $rk_white;
      }
      @include mq("tablet") {
        font-size: 1rem;
      }
      &-icon {
        height: 30px;
        width: 30px;
        margin: 0 10px 0 0;
        &.--grey {
          filter: saturate(0);
        }
      }

      &.--calendario {
        background: $rk_grey2;
        &:hover {
          background: $rk_grey4;
        }
      }
      &.--acción {
        cursor: pointer;
        background: rgba($rk_action2, 0.3);
        &:hover {
          background-color: $rk_action2;
        }
      }
      &.--plantilla {
        cursor: pointer;
        background: rgba($rk_action4, 0.6);
        &:hover {
          background-color: $rk_action4;
        }
      }
    }
    &__data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      flex-grow: 2;
    }
    &__name {
      font-size: 1.2rem;
      margin: 0 0 10px;
      padding: 0 30px 5px 0;
      border-bottom: 1px solid $rk_grey1;
      color: $rk_grey6;
      small {
        font-family: headers, Arial, sans-serif;
        color: $rk_copper1;
        font-size: 1.4rem;
        display: block;
        padding: 5px 0;
        opacity: 0.8;
      }
      @include mq("tablet") {
        font-size: 1rem;
      }
    }
    &__desc {
      font-size: 1.2rem;
      color: $rk_grey6;
      margin-top: 5px;
      &.--big {
        font-size:1.3rem;
        color: $rk_grey8;
      }
      a {
        margin: 10px 0;
        display: block;
        text-decoration: underline;
      }
    }
    &__lead {
      font-size: 1.2rem;
      line-height: 1rem;
      display: block;
      a {
        margin: 10px 0;
        display: block;
      }
    }
  }
  // ADMIN --------------------
  &-admin {
    h2 {
      font-size: 2rem;
      color: $rk_grey3;
      padding-top: 0;
      margin-bottom: 15px;
      @include mq("tablet") {
        font-size: 1.5rem;
      }
    }
    &__welcome {
      min-width: 25%;
      h1 {
        font-size: 3rem;
      }
    }
    &__charts {
      padding: 0 2% 2vh;
      width: 75%;
      overflow: hidden;
      max-width: unset;
      @include mq("tablet") {
        width: 100%;
        max-height: unset;
        height: auto;
        padding: 0 4vw 3vw;
      }
    }
    &__bottom {
      padding: 20px 2vw 0;
    }
    &__title {
      font-family: "headers", Arial, sans-serif;
      color: $rk_grey5;
      font-size: 1.4rem;
      padding: 0 0 0 10px
    }
  }
}
