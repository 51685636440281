horus-banking-transactions {
  display: block;
  max-width: 100%;
}
.bank-tr {
  position: relative;
  width: 100%;
  min-height: 65px;


  &__done {
    width: 40px;
  }

  &__row{
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__summary{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $rk_grey3;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 2rem;
      padding:5px 20px;
      flex-basis: 33%;
      flex-wrap: wrap;
      border-right: 1px solid $rk_grey3;
      @include mq(tablet) {
        font-size: 1.5rem;
      }
      @include mq(tablet-med) {
        flex-basis: 100%;
        justify-content: space-between;
        border-right: 0;
        border-bottom: 1px solid $rk_grey3;
        margin-bottom: 20px;
        padding-left: 0;
      }
      &:nth-of-type(1) {
        padding-left: 0;
      }
      &:last-child {
        border: 0;
      }
      .--pending {
        color: $rk_grey6;
        font-weight: lighter;
        @include mq(tablet-med) {
          display: block;
        }
      }
      .--neg {
        color: $rk_progress;
      }
    }
    &-info {
      display: flex;
      align-items: center;
    }
    &__img {
      height: 58px;
      margin: 5px 15px 0  0;
      filter: saturate(0);
      opacity: .3;
      @include mq(tablet) {
        height: 40px;
        margin: 15px 10px 15px  0;
      }
      &.--ok {
        filter: none;
        opacity: 1;
      }
    }
    &__ok {
      position: absolute;
      top: 0;
      left: 0;
    }
    &-subtitle {
      font-size: 1.4rem;
      margin: 0;
      width: 100%;
      color: $rk_grey8;
      font-family: special, Arial, sans-serif;
    }
    &-data{
      font-size: 1.4rem;
      color: $rk_semaphoreGreen;
      div {
        margin: 5px 0;
      }
      strong {
        font-family: headers, Arial, sans-serif;
        font-size:1.7rem;
      }
    }
    &-paid{
      border-top: 1px solid $rk_grey3;
      padding-top: 10px;
      font-size: 1.3rem;
      font-style: italic;
      color: $rk_step5;
      opacity: .7;
      width: 100%;
      strong {
        font-family: headers, Arial, sans-serif;
        font-size:1.5rem;
      }
      @include mq(tablet-med) {
        width: 50%;
        border-top: 0;
        border-left: 1px solid $rk_grey3;
        display: flex;
        padding-left: 20px;
        flex-wrap: wrap;
      }
    }
  }

}

