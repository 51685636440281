
.checklist {
  &__block {
    &:after {
      content: "";
      height: 1px;
      width: 50%;
      display: block;
      margin:25px auto;
      background-color: $rk_gradient1;
      opacity: .25;
      @include mq(tablet-small) {
        background-color: $rk_transparent;
      }
    }
  }
  &__docs {
    padding: 0 0 0 14px;
    font-style: italic;
    border-radius: 10px;
    border: 1px solid $rk_grey2;
    .checklist__check{
      flex-wrap: wrap;
      @include mq(tablet-small) {
        border-bottom: none;
      }
      &__name-date {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      &__status {
        text-align: left;
      }
      &__name{
        font-size: 1.2rem;
      }
      &__section {
        min-height: 30px;
        flex-grow: 2;
      }
    }
  }
  &__header {
    position: relative;
    width: 100%;
    padding-right: 50px;
    margin-bottom: 20px;
    h2 {
      margin: 0;
      min-width: 160px;
    }
    &__progress {
      font-family: basicregular, Arial, sans-serif;
      color: $rk_grey4;
      font-weight: lighter;
      font-size: .8em;
      margin-left: 10px;
    }
  }
  &__percent {
    padding-top: 3px;
    display: flex;
    align-items: center;
    height: 5px;
    margin: 10px 0;
    width: 0;
    position: relative;
    animation: grow 1.5s ease-out forwards;
    background-color: $rk_progress;
  }
  &__help {
    width: 30px;
    right: 0;
    margin: -45px 0 0;
    opacity: .7;
  }
  &__check {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 2px 10px 2px 0;
    justify-content: space-between;
    color: $rk_grey6;
    cursor: pointer;
    border-right: 5px solid $rk_white;
    @include mq(tablet-small) {
      border-bottom: 1px solid $rk_grey2;
      flex-wrap: wrap;
    }
    &:hover{
      color: $rk_step2;
      border-color: $rk_step2;
      .--disabled {
        color: $rk_semaphoreRed;
      }
    }
    &.--disabled {
      cursor: not-allowed;
      opacity: .7;
      user-select: none;
      &:hover {
        color: $rk_grey6;
        border-color: $rk_white;
      }
    }
    &__mine {
      position: absolute;
      left: -21px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: $rk_appraiser-red;
    }
    &__section {
      display: flex;
      align-items: center;
      min-width: 70px;
      justify-content: flex-end;
      &:first-child {
        flex-grow: 2;
        min-width: 0;
      }
      &:last-child {
        @include mq(tablet-small){
          height: 40px;
        }
      }
    }
    &__ok{
      width: 20px;
      min-width: 20px;
      margin-right:10px;
      @include mq(tablet-small) {
        margin-right: 5px;
      }
      &.--ok {
        border-radius: 50%;
        background: $rk_checked;
        padding: 2px;
      }
    }
    &__number {
      font-size: 1.2rem;
      min-width: 25px;
      font-family: special, Arial, sans-serif;
      @include mq(tablet-small) {
        min-width: 20px;
      }
    }
    &__name-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 2;
      flex-wrap: wrap;
    }
    &__name {
      font-size: 1.5rem;
      font-family: basic, Arial, sans-serif;
      padding: 4px 5px 4px 0;
      flex-grow: 2;
      @include mq(tablet) {
        max-width: 58%;
      }
      @include mq(tablet-small) {
        max-width: 100%;
        font-size: 1.3rem;
      }
    }
    &__pending {
      font-size: .8em;
      border: 1px solid $rk_grey2;
      border-radius: 5px;
      margin: 0 5px 0 10px;
      padding:2px 5px 0;
      &.--disabled {
        display: block;
        margin: 5px 0;
        padding: 0;
        border:0;
        color: $rk_semaphoreRed;
      }
    }
    &__status {
      font-size: 1.1rem;
      text-align: right;
      color: $rk_grey4;
      &-date{
        display: block;
        &.--end {
          color: $rk_maxDates;
        }
      }
      @include mq(tablet-small) {
        text-align: left;
      }
      strong  {
        word-break: keep-all;
        font-style: normal;
        font-family: special, Arial, sans-serif;
        padding-left: 5px;
        font-size: 1rem;
        letter-spacing: 1px;
      }
    }
    &__time {
      width: 70px;
      text-align: right;
      strong {
        font-size: 1.1rem;
        display: block;
        font-family: special, Arial, sans-serif;
      }
    }
    &__info {
      position: relative;
      width: 20px;
      margin-left:13px;
      display: flex;
      align-items: center;
      @include mq(tablet-small) {
        margin-left:10px;
      }
      &.--notactive {
        cursor:  default;
      }
      &.--amount0 {
        img {
          filter: saturate(0);
        }
        figcaption {
          color: $rk_grey5;
        }
      }
      &-icon {
        position: absolute;
        //filter: hue-rotate(263deg);
      }
      &-num {
        font-family: basicregular, Arial, sans-serif;
        position: absolute;
        right: 0;
        padding-top: 3px;
        font-size: 1rem;
        &.--msg {
          padding: 0;
          top: -5px;
        }
        .--unread{
          display: block;
          border-radius: 50%;
          background: $rk_loader1;
          position: absolute;
          top: -5px;
          right: -11px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
// MODAL De EDICIÓN

  &__edit {
    display: flex;
    flex-wrap: wrap;
    &-title {
      font-size:2rem;
      margin-top: 0;
      width: 100%;
    }
    &-info {
      flex-basis: 45%;
      padding-right: 30px;
      @include mq(tablet) {
        flex-basis: 100%;
      }
    }
    &-forms {
      flex-grow: 2;
      flex-basis: 50%;
      @include mq(tablet) {
        margin: 30px 0;
      }
    }
   }

  &__info-msg {
    font-size: 1.2rem;
    line-height: 1.15;
    margin: 5px 0 30px;
    color: $rk_grey6;
    .--inm {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid $rk_grey3;
    }
    strong {
      color: $rk_horus04;
      font-family: headers, Arial, sans-serif;
    }
    a {
      color: $rk_loader1;
      opacity: .7;
      &:hover {
        opacity: 1;
      }
    }
  }
  horus-comments {
    display: block;
    width: 100%;
  }
  &__warning {
    margin-right: 10px;
  }
  &__ctas {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 20px 0 10px;
    padding: 20px 0 10px;
    border-top: 1px solid $rk_grey2;
    &__cta-cont {
      flex-basis: 50%;
    }
    &__cta {
      max-height: 50px;
      user-select: none;
    }
    &__revoke-msg {
      margin-top: 11px;
      width: 46%;
      text-align: center;
      font-size: 1.2rem;
      color: $rk_grey4;
    }
  }
}
