.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  position: relative;
  font-family: special, Arial, sans-serif;
  margin: 40px auto 80px;
  &__hide{
    visibility: hidden;
  }
  &__container{
    display: flex;
    align-items: center;
    figure  {
      max-width: 40px;
      max-height: 40px;
      display: flex;
      align-items: center;
      border: 0 !important;
    }
    img{
      width:20px;
      max-height: 20px;
      margin:0 10px;
    }
  }
  &__info{
    margin: 0 1vw;
    color: $rk_grey6;
    text-align: center;
  }
  &__cover{
    position: absolute;
    top:0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba($rk_white, .5);
  }
}
