.alarms__interview {
  padding-bottom:10px;
  &__title {
    margin-bottom: 5px;
  }
  &__description{
    margin-bottom: 20px;
  }
  &-list{
    position: relative;
    margin: 10px auto 40px;
  }
  &__item {
    display: flex;
    font-family: special, Arial, sans-serif;
    font-size: 1.2rem;
    border-bottom: 1px solid $rk_grey3;
    padding: 0 10px;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background: $rk_grey05;
    }
    &.--disabled{
      opacity: .6;
      filter: saturate(0);
    }
    &-info {
      display: flex;
      flex-grow: 2;
      cursor: pointer;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0;
      &:hover {
        font-weight: bold;
      }
      @include mq('tablet') {
        align-items: flex-start;
        flex-direction: column;
      }
    }
    &-when {
      display: flex;
      align-items: center;
      margin: 0 10px 5px 0;
    }
    &-icon {
      width: 20px;
    }
    &-icon-b {
      width: 30px;
      @include mq('tablet') {
        width: 40px;
      }
    }
    &-title{
      margin-right: 20px;
    }
    &-date {
      font-family: headers, Arial, sans-serif;
      color: $rk_grey8;
      border:solid $rk_grey3;
      border-width: 0 1px;
      margin-left: 10px;
      padding: 0 10px;
    }
    &-time {
      color: $rk_horus02;
    }
  }
  &__button{
    display: flex;
    border-radius: 5px;
    background: $rk_horus02;
    color: $rk_white;
    padding: 3px 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    transition: background-color .2s linear;
    @include mq('tablet') {
      flex-wrap: wrap;
      flex-direction: column;
    }
    h3{
      font-size: 2rem;
    }
    img {
      transition: filter .2s linear;
      margin:0 10px 0 20px;
      width: 30px;
    }
    .mat-datepicker-input {
      font-family: headers, Arial, sans-serif;
      font-size: 2rem;
      max-width: 140px;
      width: auto;
      text-decoration: none;
      color: $rk_white !important;
      margin: 0 5px;
      border: 0;
      background: $rk_transparent;
      cursor: pointer;
    }
    &.--date{
      .alarms__interview__button__dates-time{
        width: auto;
        opacity: 1;
      }
      .alarms__interview__button__save{
        display: unset;
      }
    }
    &__dates {
      display: flex;
      @include mq('tablet') {
        flex-wrap: wrap;
        justify-content: center;
      }
      > div {
        display: flex;
        align-items: center;
        @include mq('tablet') {
          justify-content: center;
        }
      }
      &-time{
        opacity: 0;
        width: 0;
        overflow: hidden;
        .h-timepicker__toggler {
          color: $rk_black;
        }
        strong{
          margin-right: 10px;
          font-size: 1.5rem;
          font-family: headers, Arial, sans-serif;
          color: $rk_grey1;
        }
      }
      .--minimum {
        height: 0;
        width: 0;
        overflow: hidden;
        padding: 0;
        border: 0;
      }
      &-opener{
        flex-grow: 2;
        cursor: pointer;
        @include mq('tablet') {
          min-width: 100%;
        }
        &:hover{
          h3 {
            color: $rk_black;
          }
          img{
            filter: hue-rotate(59deg) saturate(18);
          }
        }
      }
    }
    &__save {
      display: none;
      @include mq('tablet') {
        margin: 10px auto;
      }
      .button {
        padding: 10px 20px;
      }
    }
  }
  &-done {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    color: $rk_grey5;
    padding: 10px 0;
    border-bottom: 1px solid $rk_grey1 ;
    em {
      font-size: .9em;
      color: $rk_black;
    }
  }
}
