.tags{
  &__content{
    margin: 2vh auto 5vh;
  }
  &__item{
    padding:0 20px 0 10px;
    display: flex;
    align-items: center;
    border: 1px solid $rk_grey1;
    box-shadow: 0 1px 3px rgba($rk_black, .3);
    border-radius: 10px 0 10px 0;
    margin: 0 2vw 2vw 0;
    user-select: none;
    position: relative;
    &.--close {
      margin: 0 20px 30px 0;
    }
  }
  &__avatar{
    margin-left:-5px;
    img{
      max-width: 20px;
      margin-right:5px;
    }
  }
  &__data h4{
    margin: 10px auto;
    color: $rk_grey5;
  }
  &__remove{
    background: $rk_white;
    border-radius: 50%;
    border: 1px solid $rk_grey2;
    height: 20px;
    width: 20px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px; right: -5px;
     img {
       max-width: 10px;
     }
  }
}
