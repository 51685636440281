

a{
  text-decoration: none;
  color: $rk_grey6;
  &:hover, &:focus{
    color: $rk_copper1;
  }
}
.--link{
  cursor:pointer;
  &.--basicright {
    position: absolute;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 40px;
    }
  }
  img{
    transition: all .05s linear;
    filter: saturate(0);
    &.--to-expand{
      transform: rotate(90deg);
    }
    &.--alert{
      filter: invert(29%) sepia(147%) saturate(4807%) hue-rotate(24deg) brightness(120%) contrast(90%);
    }
    &.--invert{
      filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(313deg) brightness(104%) contrast(117%);
    }
    &.--wlink{
      //filter: invert(116%) sepia(147%) saturate(638%) hue-rotate(-25deg) brightness(152%) contrast(90%);
      filter:  invert(1) contrast(0) brightness(2);
    }
  }
  &:hover, &.active{
    img {
      transform: scale(1.1);
      &.--wlink {
        filter:  brightness(0);
      }
      &.--original {
        filter:  none;
      }
    }
  }
}

.--agen .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159) brightness(1.2) hue-rotate(391deg) saturate(3.5);
    }
  }
}
.--tele .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159) brightness(1.1) hue-rotate(208deg) saturate(3.5);
    }
  }
}
.--coor .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159) hue-rotate(617deg) saturate(3.5);
    }
  }
}
.--lega .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159) hue-rotate(676deg) saturate(3);
    }
  }
}
.--soci .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159)  hue-rotate(544deg) brightness(1.2) saturate(3.5);
    }
  }
}
.--equi .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159)  hue-rotate(360deg) brightness(1.2) saturate(3.5);
    }
  }
}
.--admi .--link {
  &:hover, &.active {
    img {
      filter: contrast(0) sepia(159)  hue-rotate(478deg) brightness(1.2) saturate(3.5);
    }
  }
}

.circle-link{
  cursor: pointer;
  border: 1px solid $rk_grey1;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background: $rk_white;
  z-index: z(level1);
  &.disabled{
    cursor: default;
    &:hover, &:focus{
      img {
        filter: saturate(0);
        transform: none;
        opacity: .5;
      }
    }
    img {
      filter: saturate(0);
      opacity: .5;
    }
  }
  &.--show-dialog{
    position: absolute;
    right: -15px;
    &:hover, &:focus {
      figcaption{
        opacity: 1;
      }
    }
    figcaption {
      position: absolute;
      transition: opacity .2s linear;
      color: $rk_grey4;
      top: 40px;
      font-size: 1rem;
      text-align: center;
      width: 90px;
      @include mq('phablet') {
        display: none;
      }
    }
  }
  img{
    width: 20px;
  }
  &.big-icon img{
    width: 30px;
  }
}

.circle-add-link ,
.circle-done-link,
.circle-pending-link{
  position: absolute;
  width: 100%;
  height: 100%;
  top:0; left: 0;
  display: flex;
  transition: opacity .2s linear;
  user-select: none;
}

.circle-pending-link{
  cursor: pointer;
}

.circle-add-link{
  align-items: center;
  justify-content: center;
  &:hover{
    figcaption{
      opacity: 1;
    }
    img{
      opacity: 1;
      transform: rotate(45deg);
      filter: invert(67%) sepia(98%) saturate(275%) hue-rotate(112deg) brightness(101%) contrast(82%);
    }
  }
  figcaption{
    width: 50%;
    height: 50%;
    opacity: 0;
    background: rgba($rk_white, .8);
    position: absolute;
    z-index: z(floor);
    border-radius: 50%;
  }
  img{
    opacity: 0;
    transform: rotate(45deg);
    width: 20%;
    z-index: z(level1);
  }
}

.circle-done-link {
  align-items: center;
  justify-content: center;
  background: rgba($rk_white, .6);
  img{
    width: 30px;
    top:-5px; right:5px;
    position: absolute;
  }
  figcaption {
    position: absolute;
    top: 15px;
    font-size: 1.2rem;
    font-family: basic, Arial, sans-serif;
    width: 35px;
    right: -20px;
  }
}

.item {
  &__remove {
    position: absolute;
    right: -10px;
    top: -10px;
    height: 30px;
    width: 30px;
    margin: 0;
    min-width: 0;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
    .eagle &{
      display: none;
    }
  }
  &__revert {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    transition: background 0.2s linear;
    cursor: pointer;
    background: rgba($rk_white, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $rk_semaphoreGreen;
    z-index: z(level1);
    border-radius: 10px 0 0 25px;
    &:hover {
      background: rgba($rk_white, .5);
    }
    figcaption {
      font-size: 1.3rem;
    }
    img {
      width: 40px;
    }
  }
}
