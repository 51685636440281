.teams{
  margin-top:30px;
  .--squares {
    padding: 10px;
    &--item{
      padding-bottom: 10px;
      border-width: 0 0 0 1px;
    }
    &--maindata {
      display: flex;
      align-items: center;
      &--name {
        font-size: 2rem;
        color: $rk_role_equipo;
        filter: brightness(.9);
      }
    }
  }
  &__name {
    margin-bottom: 20px;
  }
}
