.appraiser-widget {
  position: relative;
  padding:10px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: $rk_grey8;
  &.--void {
    border: 1px solid $rk_appraiser-fluor;
    background: $rk_grey02;
  }
  &__header{
    margin: 0 0 5px;
    color: $rk_grey8;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    display: flex;
    align-items: center;
    color: $rk_step2;
    margin: 0;
  }
  &__icon {
    width: 25px;
    margin-right: 5px;
  }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $rk_grey3;
  }
  &__report{
    width: 25px;
    margin: 0 10px -4px 0;
    filter: saturate(0);
    cursor:pointer;
    &:hover {
      filter: none;
    }
    &.--noPdf:hover {
      filter: saturate(0);
      cursor: not-allowed;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    color: $rk_grey3;
    cursor: pointer;
    flex-grow: 2;

  }
  &__link {
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    user-select: none;
    @include mq(desktop-wide) {
      width: 50%;
    }
    @include mq(tablet) {
      width: unset;
    }
    &:hover {
      color: $rk_white;
      .appraiser-widget__price {
        filter: brightness(1.5);
      }
      .appraiser-widget__horus {
        filter: none;
      }
    }
  }
  &__date {
    display: flex;
    align-items: center;
    &:hover {
      color: $rk_white;
      .appraiser__results__finalPrice {
        filter: brightness(2);
      }
    }
  }
  &__horus {
    margin: 0 10px;
    width: 25px;
    filter: saturate(0);
  }
  &__price {
    font-size: 1.8rem;
    margin: 0;
    text-align: right;
    font-family: "headers", Arial, sans-serif;
    width: unset;
    top: 0;
    padding: 0 5px 0 0;
    @include mq(desktop) {
      font-size: 1.5rem;
    }
    @include mq(tablet) {
      font-size: 2rem;
    }
    &:after {
      display: none;
    }
    &.--external {
      color: $rk_grey2;
    }
    &.--verde{
      color: $rk_appraiser-green;
    }
    &.--amarillo{
      color: $rk_appraiser-yellow;
    }
    &.--rojo{
      color: $rk_appraiser-red;
    }
  }
  &__delete {
    width: 25px;
    margin-top: -3px;
    filter: brightness(3);
    opacity: .5;
    cursor: pointer;
    &:hover {
      filter: hue-rotate(-62deg) saturate(6) brightness(3.5);
    }
    &-advice {
      opacity: 0;
      width: 0;
      color: $rk_white;
      position: absolute;
      transition: all .2s linear;
      height: 100%;
      font-family: headers, Arial, sans-serif;
      background: rgba($rk_white, 0.8);
      left: 0;
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      overflow: hidden;
      z-index: z(floor);
      &.--open {
        width: 100%;
        opacity: 1;
      }
    }
    &-cta {
      background: rgba($rk_appraiser-red, 0.9);
      padding:5px 10px;
      margin: auto;
      border-radius: 10px;
      border: 2px solid $rk_appraiser-red;
      &:hover {
        border-color: $rk_black;
      }
    }
  }
  &__nav {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &-item {
      max-width: 95px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 1rem;
      color: $rk_grey7;
      cursor: pointer;
      transition: color .1s linear;
      user-select: none;
      &:hover {
        color: $rk_black;
        img {
          filter: brightness(0);
        }
      }
    }
    img {
      transition: filter .1s linear;
      margin-bottom: 5px;
    }
  }
  &__external {
    border-top: 1px solid $rk_grey6;
    background: $rk_white;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    &__field {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
    }
    &__form {
      width: calc(100% - 80px);
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__pdf {
      min-width: 70px;
      max-width: 70px;
      min-height: 70px;
      max-height: 70px;
      border-radius: 50%;
      &.--with-pdf {
        background: $rk_transparent;
        border: 1px solid $rk_grey5;
      }
      &.--loaded {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        left: 0;
        margin: 0;
        img {
          max-width: 50%;
        }
      }
    }
    horus-uploader p {
      font-size: .9rem;
    }
    &__price{
      font-size: 1.4rem;
      .mat-mdc-input-element {
        font-family: 'headers', Arial, sans-serif !important;
        text-align: right;
      }
    }
    .createbutton {
      transform: scale(.8);
    }
  }
}
