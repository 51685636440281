horus-basic-chart{
  display: block;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  transition: max-height .2s linear;
}
.chart{
  flex-direction: column;
  display: flex;
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  canvas {
    max-width: 100%;
    max-height: 100%;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    font-size: 2.5rem;
    width: 100%;
    @include mq("tablet-small") {
      justify-content: center;
      font-size: 1.5rem;
    }
  }
  &__item{
    margin: 10px 20px 10px 0;
    filter: brightness(.6);
    strong {
      color: $rk_role_administrador;
      font-family: special, Arial, sans-serif;
    }
    small {
      font-size: .5em;
    }
  }
  &__msgs {
    width: 100%;
    margin-bottom: 100px;
  }
  &__edition {
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $rk_grey2;
    position: absolute;
    top: 10px;
    right: 10px
  }
}


.toques {background-color: $rk_toques}
.entrevistas {background-color: $rk_entrevistas}
.captaciones {background-color: $rk_captaciones}
.facturación {background-color: $rk_facturacion}
