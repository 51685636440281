.basic-filters{
  position: relative;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  margin: -20px 0 40px 0;
  padding:  0;
  border-style: solid;
  border-image-slice: 1;
  border-width: 0 0 1px;
  max-width: 100vw;
  max-height: 40px;
  border-image-source: linear-gradient(to right, rgba($rk_loader1, 0.6), rgba($rk_loader1, 0.4), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, $rk_transparent -5% ,  rgba($rk_grey4, .1) 20%, $rk_transparent 60%);
  @include  mq(tablet){
    max-height: unset;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 80px;
    padding-top: 20px;
  }

  > h3 {
    font-size: 1.1rem;
    margin:0 10px 0 0;
    color: rgba($rk_horus09,.8);
    display: flex;
    align-items: center;
    line-height: .8;
    @include mq('tablet') {
      display: none;
    }
  }
  &__quickfilters {
    position: relative;
    padding: 0 ;
    display: flex;
    align-items: center;
    max-width: 170px;
    @include  mq(tablet){
      min-width: 100%;
      min-height: 40px;
    }
    &:after{
      content : '|';
      padding:0 10px;
      color: $rk_grey5;
      font-weight: lighter;
      @include  mq(tablet){
        display: none;
      }
    }
    .mat-mdc-form-field-subscript-wrapper{
      height: 2px;
    }
    .mat-mdc-form-field{
      max-width: calc(100% - 30px);
    }
    &.--link {
      justify-content: flex-end;
    }
  }
  &__byname {
    @include mq('tablet') {
      border-bottom: 1px solid $rk_grey2;
      min-width: 90%;
    }
  }
  &__direction,
  &__quickreset,
  &__reset{
    transition: transform 2s linear;
    display: flex;
    img {
      width: 20px;
      &.--desc,
      &.--desc:hover {
        transform: rotate(180deg);
      }
    }
  }

  &__direction {
    @include mq('tablet') {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: flex-end;
      bottom: 7px;
    }
  }

  &__reset {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__quickreset {
    max-height: 100%;
    margin-left: 10px;
    img {
      width: 15px;
    }
  }
  &__toggle-advanced,
  &__resetall{
    position: absolute;
    left: 90%;
    top:-40px;
    height: 40px;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mq('tablet') {
      top:-40px;
      width: 25px;
    }
    &:hover, &:focus {
      figcaption {
        @include mq('desktop-wide', min) {
          opacity: 1;
        }
      }
    }
    img{
      height: 100%;
    }
    figcaption {
      position: absolute;
      text-align: center;
      opacity: 0;
      transition: opacity .2s linear;
      font-size:1rem;
      color: $rk_grey5;
      margin-left: -28px;
      margin-top: 60px;
      width: 90px;
    }
  }

  &__toggle-advanced {
    @include mq('tablet') {
      right: 40px;
      left: unset;
    }
  }
  .--all{
    left: calc(90% + 50px);
    @include mq('tablet') {
      right: 0;
      left:unset;
    }
  }


  &__section{
    position: relative;
    padding-bottom: 10px;
    opacity: 1;
    &.--disabled{
      opacity: .5;
    }
  }
  &__data{
    &__item{
      cursor:pointer;
      &:hover{
        color: $rk_loader1;
      }
    }
  }
  &__subtitles{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:20px;
    color: $rk_horus09;
    border-style: solid;
    border-image-slice: 1;
    border-width: 0 0 1px;
    border-image-source: linear-gradient(to right, $rk_transparent 0, rgba($rk_loader1, 0.9), $rk_transparent 95%);
  }
  &__valoration{
    display: flex;
    justify-content: space-around;
    max-width: 300px;
    img {
      width: 75%;
      max-width: 60px;
      filter: saturate(0);
      opacity: .5;
      cursor: pointer;
      &:hover, &:focus {
        filter: saturate(.8) hue-rotate(-36deg);
        opacity: 1;
      }
      &.selected {
        filter: saturate(1);
      }
    }
    .selected{
      opacity: 1;
    }
  }
  &__legends {
    position: absolute;
    top:50px;
    left: 0;
    @include  mq(tablet){
      position: relative;
      top:30px;
    }
  }
  &__legend {
    font-size:1.1rem;
    padding: 5px 0;
    color: rgba($rk_loader1, .7);
    @include  mq(tablet) {
      top: unset;
      bottom: -50px;
    }
    strong {
      font-style: normal;
    }
    &:nth-of-type(n+2 ) {
      &:before{
        content: '|';
        font-style: normal;
        margin:0 10px;
        color: $rk_grey4;
      }
    }
  }

  &__slider {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    position: relative;
    font-size: 1.2rem;
    color: $rk_grey5;
    .basic-filters__legend {
      padding-top:0;
      margin-top: -5px;
    }
  }

  .lastActivityDays {
    margin-top:-20px;
  }
}

.--no-results {
  font-size: 2rem;
  color: $rk_horus09;
}
