.leads{
  h1{
    color: $rk_horus09;
    img{
      margin:0 0 0 20px;
      width: 30px;
      @include mq('tablet') {
        margin:0 0 0 10px;
      }
      @include mq('phablet') {
        margin:0 0 0 5px;
      }
    }
  }
  .--squares--item {
    border-radius: 18px 18px 0 18px;
    border-width: 0 0 2px 2px;
  }
  &__mine {
    padding: 5px 0 0;
  }
  &__prospect {
    .--squares--avatar {
      &--no {
        width: 75%;
        filter: sepia(1) saturate(3) hue-rotate(306deg);
      }
    }
    .leads__score {
      display: none;
    }
  }
  &__not-mine {
    filter: saturate(0);
  }
  &__owner-name {
    padding: 3px 0 0;
    font-style: italic;
    &__name {
      filter: brightness(.6);
    }
  }


  &__emoji{
    position: absolute;
    top: -5px;
    left: -10px;
    width: 30px;
    z-index: z(level2);
    img{
      width: 100%;
    }
    &.--manager{
      top: 0;
      left:0;
      width: 35px;
      @include mq('tablet') {
        width: 20px;
      }
    }
  }
  &__header.--header{
    @include mq('tablet') {
     padding-top: 70px;
    }
  }

  &__plan {
    position: absolute;
    top: 75px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $rk_grey6;
    cursor: pointer;
    transition: background-color .2s linear;
    &:hover {
      background-color: $rk_black;
    }
    img{
      max-width: 80%;
    }
    figcaption {
      position: absolute;
      display: flex;
      align-items: center;
      line-height: .8;
      text-align: right;
      right: 45px;
      @include mq(desktop-wide){
        display: none;
      }
    }
  }

  &__eagle {
    min-height: 0;
    flex-direction: column;
    .--squares--item{
      margin-bottom: 20px;
      user-select: none;
      width: 100%;
      padding: 5px 10px;
      border-width: 0 0 1px 5px;
      border-bottom-color: $rk_grey3;
      @include mq(tablet){
        flex-wrap: nowrap;
      }
      h3 {
        text-align: right;
      }
    }
    .--squares--avatar{
      height: 45px;
      margin: -10px 10px 5px -6px;
      min-height: 45px;
      width: 45px;
      min-width: 45px;
    }
    .leads__emoji {
      left: -10px;
    }
    .--squares--maindata{
      flex-grow: 2;
      padding: 0 10px;
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      @include mq(desktop) {
        width: 100%;
      }
    }
    .--squares--data {
      position: absolute;
      right: -15px;
      top: -20px;
      &-extra {
        display: none;
      }
    }

    .leads__score{
      transform: scale(.8);
    }
  }

  &__disabled {
    background: $rk_grey05;
    &.--squares--item:hover{
      border-color: $rk_grey2;
      background: $rk_grey05;
    }
    h3, .--squares--data-extra{
      opacity: .6;
      filter: saturate(0);
    }
    .--squares--avatar--img,
    .--squares--avatar--no{
      filter: brightness(1) saturate(0);
    }
    .leads__score {
      img {
        filter: brightness(.95);
      }
      figcaption {
        filter: saturate(0) brightness(.8);
      }
    }
  }

  &__score{
    position: relative;
    width: 90%;
    max-width: 120px;
    background-color: $rk_grey05;
    height: 21px;
    img{
      top:-2px;
      height: 25px;
      max-width: 120px;
      position: absolute;
      z-index: z(level1);
    }
    figcaption{
      transition: width .5s linear;
      background-image:  linear-gradient( to top, $rk_horus06 30%, $rk_horus05 50%);
      display: block;
      position: absolute;
      top: -1px; left:1px;
      opacity: 0;
      width: 118px;
      height: 21px;
      border-right: 1px solid rgba($rk_horus08, .4);
      max-width: calc(100% - 2px);
    }
    .--loaded + figcaption {
      opacity: 1;
    }
  }

  &__owner{
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 50%;
    border: 1px solid $rk_grey1;
    width: 35px;
    height: 35px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      max-width: 44px;
    }
  }

  &__alert{
    width: 25px;
    height: 25px;
    right: 0;
    top: 1px;
    background: $rk_semaphoreRed;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.--calendario {
      cursor: default;
      background: $rk_copper4;
    }
    &__icon{
      height: 15px;
      filter: brightness(2);
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.1rem;
    font-weight: bold;
    margin:0 0 10px 0;
    color: $rk_gradient1;
    cursor: pointer;
    padding: 3px 5px;
    border: 1px solid $rk_white;
    transition: border .2s linear;
    border-radius: 10px;
    &:hover{
      border-color:$rk_gradient1;
      .leads__summary__close{
        opacity: 1;
      }
    }
    &__avatar{
      display: flex;
      align-items: center;
      padding-right: 20px;
      flex-grow: 2;
      img{
        max-width: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }

    }
    &__close {
      max-width: 20px;
      height: 20px;
      opacity: 0;
      filter: saturate(0);
      transition: opacity .2s linear;
    }
  }
}

.lead-filters{
  &__icon{
    width: 20px;
    margin-right: 10px;
  }
}

.lead-manager{
  .--mini {
    .page-modal__title {
      font-size: 2rem;
    }
    .page-modal__main {
      max-width: unset;
      padding: 0;
      border:0;
      flex-basis: 100%;
    }
  }
  &__name {
    flex-grow: 2;
    margin-left: 10px;
  }

  &__tagstitle {
    display: flex;
    align-items: center;
  }
  &__tags{
    padding: 20px 1vw 10px 2vw;
    .tags__content{
      @include mq('tablet') {
         margin-bottom: 0;
      }
    }
    .tags__item{
      @include mq('tablet') {
        margin-bottom: 10px;
      }
    }
  }
  horus-tag-autocomplete{
    @include mq('tablet') {
      order: 2;
    }
  }
  &__newtag  {
    width: 30px;
    padding-left:10px !important;
    margin-left: 10px !important;
    border-left: 1px solid $rk_grey05 !important;
    cursor: pointer;
    @include mq('tablet-small') {
      order: 1;
    }
  }

  &__logs{
    margin-left: 10px;
    &__title{
      font-size: 1.2rem;
      color: $rk_grey4;
    }
    h3.lead-manager__edittitle{
      margin: 0;
      font-size: 1.4rem;
      max-width: 85%;
    }
  }
  &__notas{
    min-height: 200px;
  }

}

