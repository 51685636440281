@charset "UTF-8";
/* Grises  */
@font-face {
  font-family: "basic";
  src: url("/assets/fonts/Roboto-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "special";
  src: url("/assets/fonts/Montserrat-Light.woff2") format("woff2");
}
@font-face {
  font-family: "headers";
  src: url("/assets/fonts/Montserrat-Bold.woff2") format("woff2");
}
* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: Arial, sans-serif;
}

figure {
  padding: 0;
  margin: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes show {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  5% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    display: none;
    opacity: 0;
  }
  5% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spinner5 {
  25% {
    transform: scaleY(2);
  }
  50% {
    transform: scaleY(1);
  }
}
@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes upriseModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showRouter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #536dfe;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #536dfe;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #536dfe;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #536dfe;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #536dfe;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #536dfe;
  --mdc-filled-text-field-focus-active-indicator-color: #536dfe;
  --mdc-filled-text-field-focus-label-text-color: rgba(83, 109, 254, 0.87);
  --mdc-outlined-text-field-caret-color: #536dfe;
  --mdc-outlined-text-field-focus-outline-color: #536dfe;
  --mdc-outlined-text-field-focus-label-text-color: rgba(83, 109, 254, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(83, 109, 254, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #536dfe;
  --mdc-checkbox-selected-hover-icon-color: #536dfe;
  --mdc-checkbox-selected-icon-color: #536dfe;
  --mdc-checkbox-selected-pressed-icon-color: #536dfe;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #536dfe;
  --mdc-checkbox-selected-hover-state-layer-color: #536dfe;
  --mdc-checkbox-selected-pressed-state-layer-color: #536dfe;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #536dfe;
  --mdc-radio-selected-hover-icon-color: #536dfe;
  --mdc-radio-selected-icon-color: #536dfe;
  --mdc-radio-selected-pressed-icon-color: #536dfe;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #536dfe;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(83, 109, 254, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #3f51b5;
  --mat-slider-hover-state-layer-color: rgba(63, 81, 181, 0.05);
  --mat-slider-focus-state-layer-color: rgba(63, 81, 181, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #536dfe;
  --mat-slider-hover-state-layer-color: rgba(83, 109, 254, 0.05);
  --mat-slider-focus-state-layer-color: rgba(83, 109, 254, 0.2);
  --mdc-slider-handle-color: #536dfe;
  --mdc-slider-focus-handle-color: #536dfe;
  --mdc-slider-hover-handle-color: #536dfe;
  --mdc-slider-active-track-color: #536dfe;
  --mdc-slider-inactive-track-color: #536dfe;
  --mdc-slider-with-tick-marks-inactive-container-color: #536dfe;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #536dfe;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(83, 109, 254, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(83, 109, 254, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(83, 109, 254, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(83, 109, 254, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #536dfe;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

:root {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mat-select-trigger-text-size: 1.3rem;
  --mat-form-field-container-text-size: 1.3rem;
  --mdc-filled-text-field-label-text-size: 1.3rem;
  --mat-select-enabled-trigger-text-color: $rk_grey7;
  --mdc-filled-text-field-container-color: $rk_white;
  --mat-select-disabled-trigger-text-color: $rk_grey6;
  --mat-optgroup-label-text-color: $rk_grey3;
  --mat-optgroup-label-text-font: headers, Arial, sans-serif;
  --mdc-filled-text-field-label-text-font: basic, Arial, sans-serif;
  --mdc-filled-text-field-input-text-color: basic, Arial, sans-serif;
  --mdc-filled-text-field-input-text-placeholder-color: $rk_grey4;
  --mat-form-field-state-layer-color: $rk_white;
  --mat-form-field-container-vertical-padding: 0;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0;
  --mat-select-trigger-text-tracking: -.02rem;
  --mdc-checkbox-state-layer-size: 30px;
  --mdc-filled-text-field-label-text-size: 1.1rem;
}

form {
  font-size: 1.2rem !important;
  font-family: special, Arial, sans-serif !important;
  padding: 0 2vw;
  border-left: 5px solid;
}
form .Berror {
  position: absolute;
  bottom: -17px;
  left: 0;
  padding: 0;
  font-size: 0.75em;
}
form.ng-valid {
  border-color: #50e3c1;
}
form.ng-invalid {
  border-color: #ca4a48;
}

.form__warning {
  color: #ca4a48;
  display: inline-block;
  padding: 25px 20px;
  font-weight: bold;
  margin: auto;
}
.form__showpass {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: -3px;
  width: 30px;
  transition: transform 0.2s linear;
}
.form__showpass:hover, .form__showpass:focus {
  transform: scale(1.15);
}

.readonly, [readonly] {
  color: #B2B2B2 !important;
  text-decoration: line-through;
}

.textarea {
  color: #666;
  border: 1px solid #E5E5E5 !important;
  border-radius: 5px !important;
  font-size: 1.1rem !important;
  padding: 5px !important;
  font-family: basic, Arial, sans-serif;
  width: 100%;
  min-height: 50px;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 10px 0;
}
fieldset.center {
  text-align: center;
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field-infix {
  min-height: 0 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-line-ripple {
  opacity: 0.5;
}
.mdc-line-ripple:before, .mdc-line-ripple:after {
  border-color: #B2B2B2 !important;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}
.--inactive .mdc-list-item__primary-text {
  filter: saturate(0);
  opacity: 0.5;
}

.mat-mdc-optgroup-label {
  border-bottom: 1px solid #006390;
  min-height: 25px !important;
  padding-left: 0 !important;
  opacity: 0.8;
  font-size: 1.6rem;
  color: #666;
  letter-spacing: -0.02em;
  margin: 20px 10px 0;
}

.mat-mdc-floating-label {
  top: 23px !important;
}

.mdc-floating-label--float-above {
  top: 19px !important;
}

.mdc-floating-label {
  color: #666;
  font-size: 1.2rem;
  overflow: visible !important;
}

.mat-mdc-radio-group {
  display: flex;
  width: auto;
}

.mat-mdc-radio-button {
  margin: 10px;
}

.mat-label {
  font-family: basic, Arial, sans-serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #7F7F7F;
}

.mdc-label {
  cursor: pointer;
}

.mdc-tooltip {
  white-space: pre-line;
}
.mdc-tooltip__surface {
  font-size: 1.1rem !important;
  padding: 10px !important;
}

.--tooltip {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  transition: transform 0.2s linear;
  position: absolute;
  margin: -5px 10px;
}
@media only screen and (max-width: 1022px) {
  .--tooltip {
    transform: scale(0.8);
  }
}
.--tooltip:hover, .--tooltip:focus {
  transform: scale(1.3);
}

.cdk-text-field-autofilled {
  box-shadow: inset 5em 7em #FFF !important;
}

.mat-mdc-input-element[disabled] {
  color: #666 !important;
}

.mat-form-field-disabled .mdc-line-ripple:before {
  border-bottom: 2px dotted #999 !important;
}
.mat-form-field-disabled .mdc-line-ripple:after {
  display: none;
}

.mdc-text-field--disabled {
  background: transparent !important;
}

input::placeholder {
  font-weight: normal !important;
  letter-spacing: 0 !important;
  font-size: 1.2rem !important;
  color: #666 !important;
}

.mat-mdc-checkbox label {
  line-height: 1;
  font-size: 1.1rem;
  color: #666;
}

.mat-custom-avatar, .mat-mdc-autocomplete-panel .mat-mdc-option img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

horus-lead-autocomplete,
horus-tag-autocomplete,
horus-search-engine {
  width: 100%;
}

.--autocomplete {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  width: 100%;
  max-height: 51px;
  flex-grow: 2;
}
.--autocomplete .mat-mdc-input-element {
  background: url(/assets/img/icons/buscar.svg) no-repeat right center;
  background-size: 20px;
}
.--autocomplete .mdc-line-ripple:before {
  border-color: rgba(127, 127, 127, 0.5) !important;
}
.--autocomplete .mdc-line-ripple:after {
  border-color: #50d3e3 !important;
}
.--autocomplete .mat-mdc-form-field-infix {
  width: 100px;
}
.--autocomplete__id {
  margin-right: 10px;
}
.--autocomplete__all {
  margin-left: 10px;
  min-width: 40px;
  text-align: right;
  font-family: basic, Arial, sans-serif;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 0.8;
}
.--autocomplete__all:hover {
  color: #000;
}
.--autocomplete__match {
  margin-left: 15px;
}
.--autocomplete__match strong {
  color: #002cff;
}

.cdk-overlay-pane {
  box-shadow: 1px 2px 10px #CCC !important;
  border-radius: 5px;
  width: auto !important;
  max-width: 100% !important;
  display: block;
  min-height: 0;
}

.mat-mdc-autocomplete-panel {
  width: calc(105% + 30px) !important;
  display: block;
  overflow-y: scroll !important;
  max-width: 200% !important;
}
.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-hidden {
  display: none;
}
.mat-mdc-autocomplete-panel .mat-mdc-option {
  background: #FFF;
  color: #000;
  min-height: 0;
}
@media only screen and (max-width: 1022px) {
  .mat-mdc-autocomplete-panel .mat-mdc-option {
    min-height: 50px;
  }
}
@media only screen and (max-width: 1022px) {
  .mat-mdc-autocomplete-panel .mat-mdc-option {
    font-size: 1.2rem !important;
  }
}

.button, .createbutton, .invbutton {
  padding: 15px 40px;
  font-size: 1.5rem;
  font-family: basicbold, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  border-radius: 10px 0 10px 0;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  background: #3f51b5;
  color: #FFF;
  transition: background-color 0.2s linear;
}
.button[disabled], [disabled].createbutton, [disabled].invbutton {
  background: #E5E5E5;
  color: #999;
  cursor: not-allowed;
  border-color: transparent;
}
.button[disabled] img, [disabled].createbutton img, [disabled].invbutton img {
  filter: saturate(0);
}
.button[disabled]:focus, [disabled].createbutton:focus, [disabled].invbutton:focus, .button[disabled]:active, [disabled].createbutton:active, [disabled].invbutton:active, .button[disabled]:hover, [disabled].createbutton:hover, [disabled].invbutton:hover {
  background: #E5E5E5 !important;
}
.button:focus, .createbutton:focus, .invbutton:focus, .button:active, .createbutton:active, .invbutton:active, .button:hover, .createbutton:hover, .invbutton:hover {
  text-decoration: none;
  background: #3365ff;
}

.invbutton {
  background: #FFF;
  border: 1px solid rgba(202, 74, 72, 0.4);
  color: #000;
}
.invbutton:focus, .invbutton:active, .invbutton:hover {
  background: #ca4a48;
  color: #FFF;
}

.createbutton {
  background: #FFF;
  border: 1px solid rgba(12, 166, 107, 0.4);
  color: #000;
}
.createbutton:focus, .createbutton:active, .createbutton:hover {
  background: rgba(12, 166, 107, 0.6);
  color: #FFF;
}

/*
// calendar
.--input-calendar { // en  mat-form-field o fieldset
  padding-right: 30px;
  background: url(/assets/img/icons/dia.svg) no-repeat center right;
  background-size: 25px;
  .mat-mdc-datepicker-input {
    color: $rk_grey5;
    text-align: right;
    cursor: pointer;
  }
}
*/
.profile-form {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 2vw;
}
@media only screen and (max-width: 1022px) {
  .profile-form {
    padding: 0 5vw;
  }
}
.profile-form__errors {
  text-align: center;
  margin: 10px auto;
  color: #7F7F7F;
  cursor: pointer;
}
.profile-form .form__warning {
  position: absolute;
  bottom: -40px;
  text-align: center;
  width: 100%;
  left: 0;
}
.profile-form--left {
  position: relative;
  flex-basis: 40%;
  flex-grow: 2;
  padding-right: 5%;
  border-right: 1px solid;
}
@media only screen and (max-width: 1022px) {
  .profile-form--left {
    border: 0;
    flex-basis: 100%;
    max-width: 87vw;
    padding-right: 0;
  }
}
@media only screen and (max-width: 559px) {
  .profile-form--left {
    max-width: 80vw;
  }
}
.profile-form--left.--complete {
  min-width: 100% !important;
}
.ng-valid .profile-form--left {
  border-color: #0ca66b;
}
.ng-invalid .profile-form--left {
  border-color: #ca4a48;
}
.profile-form--left.--fifty {
  flex-basis: 50%;
  min-width: 50%;
}
@media only screen and (max-width: 1022px) {
  .profile-form--left.--fifty {
    flex-basis: 100%;
    min-width: 100%;
  }
}
.profile-form--right {
  flex-basis: 60%;
}
@media only screen and (max-width: 1022px) {
  .profile-form--right {
    flex-basis: 100%;
    max-width: 87vw;
    padding-left: 0;
  }
}
.profile-form--right.--fifty {
  flex-basis: 50%;
  min-width: 50%;
}
@media only screen and (max-width: 1022px) {
  .profile-form--right.--fifty {
    flex-basis: 100%;
    min-width: 100%;
  }
}
.profile-form--unique {
  flex-basis: 100%;
  max-width: 700px;
}
.profile-form--base {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3vh 0 1vh;
}
.profile-form--base button.button, .profile-form--base button.invbutton, .profile-form--base button.createbutton {
  margin: 10px auto;
}
.profile-form__container {
  padding: 2vh 0 10px 5%;
  position: relative;
}
@media only screen and (max-width: 1022px) {
  .profile-form__container {
    padding-left: 0;
  }
}
.profile-form__container.--nopadd {
  padding-bottom: 0;
}
.profile-form__action-link {
  padding: 20px 10px 10px;
  text-align: center;
  cursor: pointer;
}
.profile-form__action-link:hover, .profile-form__action-link:focus {
  color: #aa4400;
  text-decoration: underline;
}
.profile-form h3 {
  margin: 10px 0;
  color: #4C4C4C;
}
.profile-form__mat-label {
  font-family: headers, Arial, sans-serif;
  color: #666;
  font-size: 1.2rem;
  margin: 10px 0;
  display: block;
}
.profile-form .button, .profile-form .invbutton, .profile-form .createbutton {
  margin-top: 30px;
}

.edit h3 {
  color: rgba(0, 44, 255, 0.6);
  border-bottom: 1px solid #B2B2B2;
}
.edit .header2 .mat-mdc-input-element {
  font-family: headers, Arial, sans-serif;
}
.edit .header2 .mat-mdc-input-element.textarea {
  border: 0 !important;
  resize: none;
  min-height: 130px;
  line-height: 1;
  margin-top: -45px;
}
.edit .header2thin {
  margin-bottom: 30px;
}
.edit .header2thin .mat-mdc-input-element {
  font-family: special, Arial, sans-serif;
}
.edit .header3thin {
  margin-bottom: 30px;
}
.edit .header3thin .mat-mdc-input-element {
  font-family: special, Arial, sans-serif;
  font-size: 1.2rem;
}
.edit .header2, .edit .header2thin {
  line-height: 1;
}
.edit .header2 .mdc-line-ripple:before, .edit .header2 .mdc-line-ripple:after, .edit .header2thin .mdc-line-ripple:before, .edit .header2thin .mdc-line-ripple:after {
  border-color: transparent !important;
}
.edit .header2 .mat-mdc-form-field, .edit .header2thin .mat-mdc-form-field {
  max-height: unset;
  margin: 0 0 10px;
}
.edit .header2 .mat-mdc-input-element, .edit .header2thin .mat-mdc-input-element {
  letter-spacing: -0.02em;
  padding: 0;
  font-size: 2.6rem !important;
  height: auto;
}
.edit .header2 .mat-mdc-text-field-wrapper, .edit .header2thin .mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
}
.edit .manager__slider {
  font-size: 1.1rem;
  padding: 10px 1vw 10px 2vw;
}

.manager__expand {
  top: 5px;
  right: -10px;
}
.manager__slider {
  display: flex;
  flex-direction: column;
  padding: 10px 1vw 10px 2vw;
  position: relative;
}

.mat-mdc-input-element.--precio {
  font-family: "headers", Arial, sans-serif !important;
}

.box-item, .--squares--item, .--corner-round--item {
  width: 15%;
  position: relative;
  text-decoration: none;
  margin: 0 0.8% 30px;
  display: flex;
  cursor: pointer;
}
.box-item:hover, .--squares--item:hover, .--corner-round--item:hover, .box-item:focus, .--squares--item:focus, .--corner-round--item:focus {
  border-color: #133866;
  color: unset;
}
.box-item:hover h3, .--squares--item:hover h3, .--corner-round--item:hover h3, .box-item:focus h3, .--squares--item:focus h3, .--corner-round--item:focus h3 {
  color: #133866;
}
.box-item:hover figure, .--squares--item:hover figure, .--corner-round--item:hover figure, .box-item:focus figure, .--squares--item:focus figure, .--corner-round--item:focus figure {
  filter: none;
}
@media only screen and (max-width: 1439px) {
  .box-item, .--squares--item, .--corner-round--item {
    width: 18%;
  }
}
@media only screen and (max-width: 1247px) {
  .box-item, .--squares--item, .--corner-round--item {
    width: 22%;
  }
}
@media only screen and (max-width: 1022px) {
  .box-item, .--squares--item, .--corner-round--item {
    width: 31%;
  }
}
@media only screen and (max-width: 768px) {
  .box-item, .--squares--item, .--corner-round--item {
    width: 46%;
  }
}
@media only screen and (max-width: 559px) {
  .box-item, .--squares--item, .--corner-round--item {
    width: 100%;
    margin: 0 0 30px;
  }
}
.box-item.--disabled:hover, .--disabled.--squares--item:hover, .--disabled.--corner-round--item:hover, .box-item.--disabled:focus, .--disabled.--squares--item:focus, .--disabled.--corner-round--item:focus {
  color: #999;
  border-color: #CCC !important;
}
.box-item.--disabled h3, .--disabled.--squares--item h3, .--disabled.--corner-round--item h3, .box-item.--disabled img, .--disabled.--squares--item img, .--disabled.--corner-round--item img {
  filter: saturate(0);
}

.box-avatar, .--squares--avatar, .--corner-round--avatar {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: saturate(0.6);
  overflow: hidden;
  background: #FFF;
}

.box-data, .--squares--data, .--corner-round--data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

.box-name, .--squares--maindata--name, .--corner-round--data--name {
  word-break: break-word;
  text-transform: lowercase;
  margin: 0 0 5px;
  line-height: 0.8;
  letter-spacing: -0.03em;
}
.box-name strong, .--squares--maindata--name strong, .--corner-round--data--name strong {
  line-height: 1;
  filter: saturate(0);
  font-family: "special", Arial, sans-serif;
  margin-top: 6px;
  letter-spacing: 0;
}

.--corner-round {
  margin-bottom: 10px;
}
.--corner-round--item {
  min-height: 50px;
  max-height: 100px;
  height: 30vh;
  min-width: 210px;
}
.--corner-round--avatar {
  min-width: 75px;
  width: 75px;
  border-width: 0 0 1px 1px;
  border-radius: 0 0 0 50%;
  border-color: #CCC;
  border-style: solid;
}
.--corner-round--avatar--img {
  max-height: 100%;
  object-fit: cover;
}
.--corner-round--avatar--no {
  width: 70%;
  filter: hue-rotate(-55deg);
}
.--corner-round--data {
  border-bottom: 1px solid #CCC;
  padding: 0 10px;
}
.--corner-round--data--name {
  font-size: 1.7rem;
}
.--corner-round--data--name strong {
  font-size: 0.6em;
  display: block;
}

.--squares {
  margin-bottom: 10px;
}
.--squares--item {
  flex-wrap: wrap;
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-color: #CCC;
}
.--squares--item:hover {
  background: linear-gradient(45deg, #CCC 0, transparent 25%);
}
.--squares--item-a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.--squares--avatar {
  margin: 0 10px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #B2B2B2;
}
.--squares--avatar.--big {
  margin-left: 5px;
  width: 65px;
  min-width: 65px;
  height: 65px;
}
.--squares--avatar--img {
  max-height: 100%;
}
.--squares--avatar--no {
  width: 70%;
  filter: hue-rotate(-55deg);
}
.--squares--maindata {
  width: calc(100% - 80px);
}
.--squares--maindata--name {
  color: #666;
  margin: 5px 0 5px 0;
  font-size: 1.5rem;
}
.--squares--maindata--name strong {
  font-size: 0.8em;
}
.--squares--data {
  padding: 10px 10px 5px;
}
.--squares--data-extra {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1023px) {
  .--table .--squares--item {
    max-width: 100%;
    min-height: 0;
    margin: 0;
    padding: 5px 15px;
    border-width: 0 0 1px 0;
  }
  .--table .--squares--item-a {
    flex-direction: row;
    align-items: center;
    flex-grow: 2;
  }
  .--table .--squares--item-a > * {
    position: relative;
    width: auto;
    flex-basis: 20%;
  }
  .--table .--squares--item-link {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .--table .item__remove {
    top: 5px;
  }
}

@media only screen and (min-width: 1023px) {
  .--table-b .--squares--item {
    max-width: 100%;
    min-height: 0;
    width: 100%;
    padding: 5px 30px 5px 10px;
    margin: 5px 0;
    border-width: 1px;
    border-top-color: transparent;
  }
  .--table-b .--squares--item:hover {
    background: none;
  }
  .--table-b .--squares--maindata {
    width: auto;
    flex-grow: 2;
  }
  .--table-b .--squares--data-extra {
    flex-direction: row;
    justify-content: flex-end;
    font-size: 1.3rem;
    color: #666;
  }
  .--table-b .item__remove {
    top: 15px;
  }
}

.tables__views {
  display: none;
}
@media only screen and (min-width: 1023px) {
  .tables__views {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -20px 0 20px;
  }
  .tables__views img {
    filter: saturate(0);
    width: 25px;
    margin: 0 5px;
    cursor: pointer;
  }
  .tables__views img:hover, .tables__views img.--sel {
    filter: saturate(1);
  }
}

.cash__table {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.cash__tr {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1022px) {
  .cash__tr {
    flex-direction: column;
    border-bottom: 1px solid #999 !important;
  }
}
.cash__tr__summary {
  position: relative;
  display: none;
  font-size: 1.5rem;
  width: 100%;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1022px) {
  .cash__tr__summary {
    display: flex;
  }
}
.cash__tr__summary > div {
  padding: 0 2%;
}
.cash__tr__summary.accordion__opned > div {
  opacity: 0;
}
.cash__tr__summary img {
  width: 20px;
}
.cash__tr__summary-info {
  flex-grow: 2;
}
.cash__tr__summary-done {
  width: 30px;
}
.cash__tr__summary-currency {
  font-family: headers, Arial, sans-serif;
  color: #1f6d1b;
}
.cash__td {
  padding: 0 10px;
  overflow: hidden;
}
@media only screen and (max-width: 1022px) {
  .cash__td {
    width: 100%;
  }
}
.cash__td .mat-mdc-form-field-infix {
  margin-top: 0;
}
.cash__td.--no-form {
  min-width: 125px;
  font-size: 1.7rem !important;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--no-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 0;
    min-height: 30px;
    border-bottom: 1px solid #CCC;
    margin: 2px auto 11px;
    max-width: calc(100% - 20px);
  }
}
.cash__td.--no-form label {
  color: grey;
  font-size: 1.1rem;
  line-height: 1;
  display: block;
}
.cash__td.--with-data {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 180px;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--with-data {
    width: 100%;
    padding-bottom: 20px;
  }
}
.cash__td.--with-data .mat-mdc-form-field {
  flex-grow: 2;
}
.cash__td.--with-data .mat-mdc-input-element {
  text-align: right;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--with-data .mat-mdc-input-element {
    text-align: left;
  }
}
.cash__td.--with-data strong {
  font-size: 1.2rem;
  color: #B2B2B2;
  margin: 16px 5px 0 0;
}
.cash__td.--long {
  min-width: 20%;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--long {
    width: 100%;
    padding-bottom: 20px;
  }
}
.cash__td.--mini {
  width: 20px;
  min-width: 20px;
  padding: 0;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--mini {
    position: absolute;
    right: 0;
    top: 20px;
  }
}
@media only screen and (max-width: 1022px) {
  .cash__td.--mini.--alone {
    top: 0;
  }
}
.cash__td.--mini img {
  width: 20px;
}
.cash__td.--fix {
  min-width: 30px;
  padding: 0;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--fix {
    width: 100%;
  }
}
.cash__td.--fix strong {
  display: none;
}
@media only screen and (max-width: 1022px) {
  .cash__td.--fix strong {
    display: inline;
  }
}
.cash .mat-mdc-checkbox {
  transform: scale(1.2);
  filter: hue-rotate(-101deg);
  opacity: 0.6;
}
.cash__date {
  min-width: 115px;
}
.cash__date .mat-datepicker-input {
  cursor: pointer;
  min-height: 23px;
  user-select: none !important;
}
.cash__currencies {
  font-family: "headers", Arial, sans-serif !important;
  font-size: 1.7rem;
  color: #024624;
}
.cash__currency-regular {
  font-family: "headers", Arial, sans-serif !important;
  color: #999;
  font-size: 1.52rem;
}
.cash__currency .mat-mdc-input-element {
  text-align: right;
  font-family: "headers", Arial, sans-serif !important;
  font-size: 1.5rem !important;
  color: #024624;
}
.cash .mat-mdc-form-field-textarea-control {
  height: 22px !important;
  font-size: 1.1rem !important;
}

.--cash-inactive .mat-mdc-input-element,
.--cash-inactive .mat-mdc-select {
  color: rgba(255, 94, 0, 0.8) !important;
}

.--toolnav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  animation: appear 0.2s linear;
  margin-top: -4px;
}
.--toolnav:hover .active figcaption, .--toolnav:focus .active figcaption {
  opacity: 0;
}
.--toolnav figure {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}
.--toolnav .--link {
  margin: 0 5px;
  border: 1px solid rgba(0, 44, 255, 0.5);
  border-radius: 50%;
}
@media only screen and (max-width: 559px) {
  .--toolnav .--link {
    margin: 3px;
  }
}
.--toolnav .--link:hover, .--toolnav .--link:focus, .--toolnav .--link.active {
  border: 1px solid #333;
  background: #333;
}
.--toolnav .--link:hover figcaption {
  opacity: 1;
}
.--toolnav .--link img {
  width: 60%;
  transition: filter 0.05s linear;
}
.--toolnav .active figcaption {
  opacity: 1;
  font-family: headers, Arial, sans-serif;
}
.--toolnav figcaption {
  position: absolute;
  top: -14px;
  width: 90px;
  text-align: center;
  color: #666;
}
@media only screen and (max-width: 1247px) {
  .--toolnav figcaption {
    visibility: hidden;
    margin-top: -3000px;
  }
}

.--big-toolnav {
  display: flex;
  align-items: center;
  width: 90%;
  margin-top: 10vh;
  justify-content: center;
  flex-wrap: wrap;
}
.--big-toolnav figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 6vw;
  min-width: 90px;
  margin: 0 2vw;
}
.--big-toolnav figcaption {
  display: block;
  opacity: 1;
  margin-top: 10px;
  text-align: center;
}
@media only screen and (max-width: 1022px) {
  .--big-toolnav figcaption {
    margin-left: 0;
  }
}

.right-modal {
  position: fixed;
  background: #FFF;
  top: 0;
  width: calc(100% + 25px);
  height: 100vh;
  padding: 40px 4vw 40px 3vw;
  overflow-y: scroll;
  z-index: 4;
  max-width: 400px;
  border-left: 1px solid #E5E5E5;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  right: -500px;
  transition: right 0.2s linear;
}
@media only screen and (max-width: 559px) {
  .right-modal {
    padding: 40px 6vw 40px 5vw;
  }
}
.right-modal.--med {
  max-width: 650px;
  right: -700px;
}
.right-modal.--long {
  max-width: 950px;
  right: -1000px;
}
.right-modal.--extralong {
  max-width: 1300px;
  right: -1400px;
}
.right-modal.--opened {
  right: -25px;
}
@media screen and (max-device-width: 767px) {
  .right-modal.--opened {
    right: 0;
    max-width: 100vw;
  }
}
.right-modal__close {
  position: absolute;
  top: 20px;
  right: 26px;
  width: 30px;
  transform: rotate(180deg);
}
@media only screen and (max-width: 1022px) {
  .right-modal__close {
    right: 30px;
    top: 10px;
  }
}
@media only screen and (max-width: 559px) {
  .right-modal__close {
    right: 10px;
  }
}

.page-modal {
  width: 100%;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 5;
}
.page-modal__back {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  background: #7F7F7F;
  opacity: 0.95;
  top: 0;
  left: 0;
}
.page-modal__back.--modal-right {
  z-index: 4;
  background: rgba(0, 0, 0, 0.3);
}
.page-modal__max {
  position: relative;
  transition: height 0.2s linear;
  width: 100%;
  min-height: 50vh;
  max-width: 1440px;
  padding: 10px 2vw 40px;
  background: #FFF;
  margin: 20px auto;
  border-radius: 20px;
}
@media only screen and (max-width: 1439px) {
  .page-modal__max {
    margin: 0 auto;
    min-height: 100vh;
  }
}
@media only screen and (max-width: 1022px) {
  .page-modal__max {
    border-radius: 0;
  }
}
.page-modal__max.--mini {
  max-width: 560px;
  margin: 20px auto;
  min-height: auto;
}
@media only screen and (max-width: 559px) {
  .page-modal__max.--mini {
    margin: 0 auto;
    min-height: 100vh;
  }
}
.page-modal__max.--med {
  max-width: 1023px;
  margin: 20px auto;
  min-height: auto;
}
@media only screen and (max-width: 1022px) {
  .page-modal__max.--med {
    margin: 0 auto;
    min-height: 100vh;
  }
}
.page-modal__max.--large {
  max-width: 1248px;
}
.page-modal__max.--xlarge {
  max-width: 1440px;
}
.page-modal__head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.page-modal__nav {
  padding: 0 1vw;
  background: #FFF;
  transition: left 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .page-modal__nav {
    position: fixed;
    left: 100%;
    height: 100vh;
    width: 100%;
    padding: 60px 5vw;
    z-index: 3;
    border-left: 1px solid #CCC;
    top: 0;
  }
  .page-modal__nav.--open {
    left: 15%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }
}
.page-modal__nav-trigger {
  background: #E5E5E5;
  width: 40px;
  height: 40px;
  border-radius: 20px 0 0 20px;
  position: absolute;
  left: -40px;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;
}
@media only screen and (max-width: 1022px) {
  .page-modal__nav-trigger {
    display: flex;
  }
}
.page-modal__nav-trigger input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.page-modal__nav-item {
  margin: 12px 0;
  display: block;
  cursor: pointer;
  transition: color 0.2s linear;
  color: #B2B2B2;
}
.page-modal__nav-item:hover, .page-modal__nav-item.--sel {
  color: #000;
}
.page-modal__content {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 2vw;
  background-color: #FFF;
}
.page-modal__main {
  position: relative;
  flex-grow: 2;
  flex-basis: 65%;
  max-width: 87vw;
  padding: 0 3vw 0 0;
  border: #666 solid;
  border-width: 0 1px 0 0;
}
@media only screen and (max-width: 1022px) {
  .page-modal__main {
    max-width: 100%;
    flex-basis: 100%;
    border-width: 0 0 1px 0;
    padding: 0 0 3vw 0;
  }
}
.page-modal__menu {
  padding: 0 1vw;
}
.page-modal__section {
  padding: 10px 0;
  position: relative;
}
.page-modal__title {
  font-size: 3rem;
  font-family: headers, Arial, sans-serif;
  color: #CCC;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  margin: 0 0 20px;
}
.page-modal__title small {
  font-size: 1.5rem;
  display: block;
}
.page-modal__title img {
  width: 40px;
}
@media only screen and (max-width: 1022px) {
  .page-modal__title img {
    width: 35px;
  }
}
@media only screen and (max-width: 639px) {
  .page-modal__title img {
    width: 25px;
  }
}
.page-modal__head-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.page-modal__head-nav .--link {
  position: relative;
  margin-left: 10px;
  top: unset;
  right: unset;
}
.page-modal__recoverlink.--link img {
  filter: none;
}
.page-modal__recoverlink.--link:hover img {
  filter: invert(32%) sepia(61%) saturate(402%) hue-rotate(99deg) brightness(115%) contrast(100%);
}
.page-modal__avatar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}
.page-modal__avatar.--disabled img {
  filter: saturate(0);
}
.page-modal__collapsed {
  height: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0 2%;
  transition: max-height 0.2s linear;
}
.page-modal__collapsed * {
  transition: opacity 0.5s linear;
  opacity: 0;
}
.page-modal__collapsed.--expanded {
  overflow: visible;
  height: auto;
  max-height: 1000px;
}
.page-modal__collapsed.--expanded * {
  opacity: 1;
}
.page-modal__collapsed.--expanded .mdc-slider__input {
  opacity: 0;
}
.page-modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  .page-modal__close {
    top: 10px;
    right: 10px;
  }
}
@media only screen and (max-width: 639px) {
  .page-modal__close {
    top: 5px;
    width: 20px;
  }
  .page-modal__close img {
    width: 25px;
  }
}
.page-modal__deletelink, .page-modal__recoverlink {
  position: absolute;
  right: 55px;
  top: 15px;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  .page-modal__deletelink, .page-modal__recoverlink {
    top: 6px;
    right: 50px;
  }
}
@media only screen and (max-width: 639px) {
  .page-modal__deletelink, .page-modal__recoverlink {
    top: 4px;
    right: 39px;
  }
}
.page-modal__deletelink img, .page-modal__recoverlink img {
  width: 40px;
}
@media only screen and (max-width: 1022px) {
  .page-modal__deletelink img, .page-modal__recoverlink img {
    width: 35px;
  }
}
@media only screen and (max-width: 639px) {
  .page-modal__deletelink img, .page-modal__recoverlink img {
    width: 25px;
  }
}
.page-modal__delete, .page-modal__create {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(202, 74, 72, 0.95);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 6;
  padding: 5vh 15%;
  color: #FFF;
}
.page-modal__delete + .loader__container, .page-modal__create + .loader__container {
  z-index: 6;
}
.page-modal__delete.--fullscreen, .page-modal__create.--fullscreen {
  position: fixed;
  justify-content: center;
}
.page-modal__delete h2, .page-modal__create h2 {
  font-family: special, Arial, sans-serif;
  text-align: center;
  color: #F1F1F1;
}
.page-modal__delete span, .page-modal__create span {
  color: #FFF;
  display: block;
  font-family: headers, Arial, sans-serif;
  font-size: 3rem;
  padding: 10px 30px;
}
@media only screen and (max-width: 1022px) {
  .page-modal__delete span, .page-modal__create span {
    font-size: 2rem;
  }
}
.page-modal__create {
  background: rgba(80, 227, 193, 0.95);
  text-align: center;
}
.page-modal__create span {
  font-size: 3rem;
  padding: 5px 30px;
  display: block;
  color: #006390;
}
.page-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  .page-modal__close {
    top: 10px;
    right: 10px;
  }
}
@media only screen and (max-width: 639px) {
  .page-modal__close {
    top: 5px;
    width: 20px;
  }
  .page-modal__close img {
    width: 25px;
  }
}

.--hidden-modal {
  opacity: 0;
  z-index: -1;
}

.--show-modal {
  z-index: 4;
  animation: upriseModal 0.3s ease-out forwards;
}

.--modal-top {
  z-index: 6;
}

.basic-filters {
  position: relative;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  margin: -20px 0 40px 0;
  padding: 0;
  border-style: solid;
  border-image-slice: 1;
  border-width: 0 0 1px;
  max-width: 100vw;
  max-height: 40px;
  border-image-source: linear-gradient(to right, rgba(0, 44, 255, 0.6), rgba(0, 44, 255, 0.4), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, transparent -5%, rgba(153, 153, 153, 0.1) 20%, transparent 60%);
}
@media only screen and (max-width: 1022px) {
  .basic-filters {
    max-height: unset;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 80px;
    padding-top: 20px;
  }
}
.basic-filters > h3 {
  font-size: 1.1rem;
  margin: 0 10px 0 0;
  color: rgba(117, 54, 115, 0.8);
  display: flex;
  align-items: center;
  line-height: 0.8;
}
@media only screen and (max-width: 1022px) {
  .basic-filters > h3 {
    display: none;
  }
}
.basic-filters__quickfilters {
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  max-width: 170px;
}
@media only screen and (max-width: 1022px) {
  .basic-filters__quickfilters {
    min-width: 100%;
    min-height: 40px;
  }
}
.basic-filters__quickfilters:after {
  content: "|";
  padding: 0 10px;
  color: #7F7F7F;
  font-weight: lighter;
}
@media only screen and (max-width: 1022px) {
  .basic-filters__quickfilters:after {
    display: none;
  }
}
.basic-filters__quickfilters .mat-mdc-form-field-subscript-wrapper {
  height: 2px;
}
.basic-filters__quickfilters .mat-mdc-form-field {
  max-width: calc(100% - 30px);
}
.basic-filters__quickfilters.--link {
  justify-content: flex-end;
}
@media only screen and (max-width: 1022px) {
  .basic-filters__byname {
    border-bottom: 1px solid #CCC;
    min-width: 90%;
  }
}
.basic-filters__direction, .basic-filters__quickreset, .basic-filters__reset {
  transition: transform 2s linear;
  display: flex;
}
.basic-filters__direction img, .basic-filters__quickreset img, .basic-filters__reset img {
  width: 20px;
}
.basic-filters__direction img.--desc, .basic-filters__direction img.--desc:hover, .basic-filters__quickreset img.--desc, .basic-filters__quickreset img.--desc:hover, .basic-filters__reset img.--desc, .basic-filters__reset img.--desc:hover {
  transform: rotate(180deg);
}
@media only screen and (max-width: 1022px) {
  .basic-filters__direction {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    bottom: 7px;
  }
}
.basic-filters__reset {
  position: absolute;
  right: 0;
  top: 0;
}
.basic-filters__quickreset {
  max-height: 100%;
  margin-left: 10px;
}
.basic-filters__quickreset img {
  width: 15px;
}
.basic-filters__toggle-advanced, .basic-filters__resetall {
  position: absolute;
  left: 90%;
  top: -40px;
  height: 40px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1022px) {
  .basic-filters__toggle-advanced, .basic-filters__resetall {
    top: -40px;
    width: 25px;
  }
}
@media only screen and (min-width: 1440px) {
  .basic-filters__toggle-advanced:hover figcaption, .basic-filters__toggle-advanced:focus figcaption, .basic-filters__resetall:hover figcaption, .basic-filters__resetall:focus figcaption {
    opacity: 1;
  }
}
.basic-filters__toggle-advanced img, .basic-filters__resetall img {
  height: 100%;
}
.basic-filters__toggle-advanced figcaption, .basic-filters__resetall figcaption {
  position: absolute;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s linear;
  font-size: 1rem;
  color: #7F7F7F;
  margin-left: -28px;
  margin-top: 60px;
  width: 90px;
}
@media only screen and (max-width: 1022px) {
  .basic-filters__toggle-advanced {
    right: 40px;
    left: unset;
  }
}
.basic-filters .--all {
  left: calc(90% + 50px);
}
@media only screen and (max-width: 1022px) {
  .basic-filters .--all {
    right: 0;
    left: unset;
  }
}
.basic-filters__section {
  position: relative;
  padding-bottom: 10px;
  opacity: 1;
}
.basic-filters__section.--disabled {
  opacity: 0.5;
}
.basic-filters__data__item {
  cursor: pointer;
}
.basic-filters__data__item:hover {
  color: #002cff;
}
.basic-filters__subtitles {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #753673;
  border-style: solid;
  border-image-slice: 1;
  border-width: 0 0 1px;
  border-image-source: linear-gradient(to right, transparent 0, rgba(0, 44, 255, 0.9), transparent 95%);
}
.basic-filters__valoration {
  display: flex;
  justify-content: space-around;
  max-width: 300px;
}
.basic-filters__valoration img {
  width: 75%;
  max-width: 60px;
  filter: saturate(0);
  opacity: 0.5;
  cursor: pointer;
}
.basic-filters__valoration img:hover, .basic-filters__valoration img:focus {
  filter: saturate(0.8) hue-rotate(-36deg);
  opacity: 1;
}
.basic-filters__valoration img.selected {
  filter: saturate(1);
}
.basic-filters__valoration .selected {
  opacity: 1;
}
.basic-filters__legends {
  position: absolute;
  top: 50px;
  left: 0;
}
@media only screen and (max-width: 1022px) {
  .basic-filters__legends {
    position: relative;
    top: 30px;
  }
}
.basic-filters__legend {
  font-size: 1.1rem;
  padding: 5px 0;
  color: rgba(0, 44, 255, 0.7);
}
@media only screen and (max-width: 1022px) {
  .basic-filters__legend {
    top: unset;
    bottom: -50px;
  }
}
.basic-filters__legend strong {
  font-style: normal;
}
.basic-filters__legend:nth-of-type(n+2):before {
  content: "|";
  font-style: normal;
  margin: 0 10px;
  color: #999;
}
.basic-filters__slider {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  position: relative;
  font-size: 1.2rem;
  color: #7F7F7F;
}
.basic-filters__slider .basic-filters__legend {
  padding-top: 0;
  margin-top: -5px;
}
.basic-filters .lastActivityDays {
  margin-top: -20px;
}

.--no-results {
  font-size: 2rem;
  color: #753673;
}

.social__edit__links {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 70px;
  flex-wrap: wrap;
}
.social__edit__links figure {
  margin: 5px 5px 5px 0;
}
.social__edit__fields {
  display: block;
  height: 0;
  max-height: 0;
  overflow: hidden;
  margin: 20px 0 0;
  transition: max-height 0.2s linear;
}
.social__edit__fields img {
  display: none;
}
.social__edit__fields.--editing {
  height: auto;
  max-height: 300px;
}

a {
  text-decoration: none;
  color: #666;
}
a:hover, a:focus {
  color: #aa4400;
}

.--link {
  cursor: pointer;
}
.--link.--basicright {
  position: absolute;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.--link.--basicright img {
  width: 40px;
}
.--link img {
  transition: all 0.05s linear;
  filter: saturate(0);
}
.--link img.--to-expand {
  transform: rotate(90deg);
}
.--link img.--alert {
  filter: invert(29%) sepia(147%) saturate(4807%) hue-rotate(24deg) brightness(120%) contrast(90%);
}
.--link img.--invert {
  filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(313deg) brightness(104%) contrast(117%);
}
.--link img.--wlink {
  filter: invert(1) contrast(0) brightness(2);
}
.--link:hover img, .--link.active img {
  transform: scale(1.1);
}
.--link:hover img.--wlink, .--link.active img.--wlink {
  filter: brightness(0);
}
.--link:hover img.--original, .--link.active img.--original {
  filter: none;
}

.--agen .--link:hover img, .--agen .--link.active img {
  filter: contrast(0) sepia(159) brightness(1.2) hue-rotate(391deg) saturate(3.5);
}

.--tele .--link:hover img, .--tele .--link.active img {
  filter: contrast(0) sepia(159) brightness(1.1) hue-rotate(208deg) saturate(3.5);
}

.--coor .--link:hover img, .--coor .--link.active img {
  filter: contrast(0) sepia(159) hue-rotate(617deg) saturate(3.5);
}

.--lega .--link:hover img, .--lega .--link.active img {
  filter: contrast(0) sepia(159) hue-rotate(676deg) saturate(3);
}

.--soci .--link:hover img, .--soci .--link.active img {
  filter: contrast(0) sepia(159) hue-rotate(544deg) brightness(1.2) saturate(3.5);
}

.--equi .--link:hover img, .--equi .--link.active img {
  filter: contrast(0) sepia(159) hue-rotate(360deg) brightness(1.2) saturate(3.5);
}

.--admi .--link:hover img, .--admi .--link.active img {
  filter: contrast(0) sepia(159) hue-rotate(478deg) brightness(1.2) saturate(3.5);
}

.circle-link {
  cursor: pointer;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background: #FFF;
  z-index: 1;
}
.circle-link.disabled {
  cursor: default;
}
.circle-link.disabled:hover img, .circle-link.disabled:focus img {
  filter: saturate(0);
  transform: none;
  opacity: 0.5;
}
.circle-link.disabled img {
  filter: saturate(0);
  opacity: 0.5;
}
.circle-link.--show-dialog {
  position: absolute;
  right: -15px;
}
.circle-link.--show-dialog:hover figcaption, .circle-link.--show-dialog:focus figcaption {
  opacity: 1;
}
.circle-link.--show-dialog figcaption {
  position: absolute;
  transition: opacity 0.2s linear;
  color: #999;
  top: 40px;
  font-size: 1rem;
  text-align: center;
  width: 90px;
}
@media only screen and (max-width: 559px) {
  .circle-link.--show-dialog figcaption {
    display: none;
  }
}
.circle-link img {
  width: 20px;
}
.circle-link.big-icon img {
  width: 30px;
}

.circle-add-link,
.circle-done-link,
.circle-pending-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  transition: opacity 0.2s linear;
  user-select: none;
}

.circle-pending-link {
  cursor: pointer;
}

.circle-add-link {
  align-items: center;
  justify-content: center;
}
.circle-add-link:hover figcaption {
  opacity: 1;
}
.circle-add-link:hover img {
  opacity: 1;
  transform: rotate(45deg);
  filter: invert(67%) sepia(98%) saturate(275%) hue-rotate(112deg) brightness(101%) contrast(82%);
}
.circle-add-link figcaption {
  width: 50%;
  height: 50%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 0;
  border-radius: 50%;
}
.circle-add-link img {
  opacity: 0;
  transform: rotate(45deg);
  width: 20%;
  z-index: 1;
}

.circle-done-link {
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
}
.circle-done-link img {
  width: 30px;
  top: -5px;
  right: 5px;
  position: absolute;
}
.circle-done-link figcaption {
  position: absolute;
  top: 15px;
  font-size: 1.2rem;
  font-family: basic, Arial, sans-serif;
  width: 35px;
  right: -20px;
}

.item__remove {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  margin: 0;
  min-width: 0;
  align-items: center;
}
.item__remove img {
  width: 20px;
  height: 20px;
}
.eagle .item__remove {
  display: none;
}
.item__revert {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  transition: background 0.2s linear;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #859836;
  z-index: 1;
  border-radius: 10px 0 0 25px;
}
.item__revert:hover {
  background: rgba(255, 255, 255, 0.5);
}
.item__revert figcaption {
  font-size: 1.3rem;
}
.item__revert img {
  width: 40px;
}

horus-re-file-score {
  display: block;
  position: relative;
}
horus-re-file-score h2 {
  margin-top: 0;
  padding-right: 45px;
}

.stars__score {
  position: relative;
  width: 90%;
  max-width: 230px;
  margin: 0 0 20px;
  line-height: 0;
  overflow: hidden;
}
.stars__score img {
  position: relative;
  max-width: 100%;
  min-height: 100%;
  z-index: 1;
}
.stars__score__bk {
  display: block;
  position: absolute;
  background-color: #F1F1F1;
  z-index: 0;
  top: 0;
  height: 99%;
  width: 100%;
  left: 0;
}
.stars__score__value {
  transition: width 0.5s linear;
  background-image: linear-gradient(to top, #febc44 30%, #ffe44a 50%);
  border-right: 1px solid rgba(202, 74, 72, 0.4);
  top: 0;
  height: calc(100% - 1px);
  width: 100%;
  left: 1px;
  position: absolute;
  opacity: 0;
  animation: upriseModal 0.5s linear 0.5s forwards;
}
.stars__score__edition {
  border-radius: 50%;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid #B2B2B2;
  position: absolute;
  right: 0;
  top: 0;
}

.upload-box-content {
  display: flex;
  height: 100%;
  align-items: stretch;
}

.horus-uploader-img {
  position: relative;
  display: block;
  border-radius: 50%;
  min-width: 130px;
  width: 130px;
  height: 130px;
  border: 1px solid #E5E5E5;
  background: #FFF;
  filter: hue-rotate(-55deg);
  overflow: hidden;
}
.horus-uploader-img.--disabled {
  opacity: 0.5;
}
.horus-uploader-img.--disabled .image-uploader__actions {
  display: none;
}

horus-uploader {
  display: block;
  border-radius: 50%;
  position: relative;
  background: #333 url("/assets/img/icons/camera.svg") no-repeat center;
  background-size: 60%;
  min-width: 130px;
  width: 130px;
  height: 130px;
}
horus-uploader .drop-container {
  width: 100%;
}
horus-uploader.--type-pdf {
  background-image: url("/assets/img/icons/pdf.svg");
}
horus-uploader.--big {
  background: transparent;
  border: 3px dashed #B2B2B2;
  border-radius: 0;
  margin: 10px 10px 10px 0;
  max-width: 40%;
  min-width: 200px;
  min-height: 100px;
  height: fit-content;
}
@media only screen and (max-width: 1022px) {
  horus-uploader.--big {
    max-width: 100%;
    width: 100%;
  }
}
horus-uploader.--big p {
  position: relative;
  opacity: 0.6;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
horus-uploader.--attached {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-width: 1px;
  color: #666;
  background: url("/assets/img/icons/clip.svg") no-repeat 10px center;
  background-size: 40px;
  padding-left: 50px;
  filter: saturate(0);
}
horus-uploader.--mini {
  max-width: 100px;
  max-height: 100px;
}
horus-uploader.--uploader-contact {
  background-image: url("/assets/img/icons/contacto.svg");
}
horus-uploader.--uploader-signature {
  min-width: 100%;
  width: 100%;
  border-radius: 0;
  min-height: 100%;
  background: #E5E5E5;
}
horus-uploader.--uploader-signature p {
  border-radius: 0;
}
horus-uploader.--uploader-signature strong {
  display: block;
}
horus-uploader.--uploader-signature.--uploader-loaded:before {
  display: none;
}
horus-uploader.--uploader-signature:before {
  position: absolute;
  content: "firma para el mail";
  font-family: special, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 30px;
}
horus-uploader.--uploader-file {
  background-image: url("/assets/img/icons/clip.svg");
  background-color: #FFF;
}
horus-uploader.--uploader-file p {
  padding: 0;
}
horus-uploader.--uploader-loaded {
  position: absolute;
  top: 0;
  background: transparent;
}
horus-uploader.--disabled {
  opacity: 0.5;
}
horus-uploader.--disabled .drop-container {
  display: none;
  position: fixed;
  top: -1000px;
  left: -8000px;
}
horus-uploader:hover p, horus-uploader:focus p {
  opacity: 1;
}
horus-uploader p {
  transition: opacity 0.2s linear;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
  font-family: basic, Arial, sans-serif;
  padding: 10px;
  opacity: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  word-break: break-all;
}
horus-uploader input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-item {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.7);
  flex-direction: column;
  word-break: break-all;
  padding: 10px;
}
.upload-item .is-done {
  color: #859836;
}

.uploaded-image {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-uploader__figure-no-image {
  display: flex;
  height: 100%;
}

.no-image {
  width: 80%;
  margin: auto;
}

.upload-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

horus-cropper {
  padding: 0;
  margin: 5px;
  max-width: 50%;
}
horus-cropper .page-modal__back {
  background: rgba(255, 255, 255, 0.8);
}
horus-cropper figure {
  min-width: 130px;
  width: 130px;
  height: 130px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1247px) {
  horus-cropper figure {
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
  }
}
@media only screen and (max-width: 639px) {
  horus-cropper figure {
    min-width: 60px;
    max-width: 60px;
    max-height: 60px;
  }
}
horus-cropper figure.--rounded {
  border-radius: 50%;
  border: 1px solid #CCC;
}

image-cropper {
  width: auto !important;
  min-height: 60px;
  max-height: 80vh !important;
}

.image-uploader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.image-uploader__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
}
.image-uploader__action {
  font-size: 1.1rem;
  padding: 0 35px 0 0;
  display: flex;
  align-items: center;
  width: 50px;
  margin-left: 10px;
  border-left: 1px solid #E5E5E5;
  height: 40px;
  cursor: pointer;
  transition: filter 0.2s linear;
  filter: saturate(0);
}
@media only screen and (max-width: 559px) {
  .image-uploader__action {
    font-size: 0;
    width: 20px;
    color: transparent;
  }
}
.image-uploader__action:hover, .image-uploader__action:focus {
  color: #0ca66b;
  filter: none;
}
.image-uploader__action.--modify {
  background: url(/assets/img/icons/hecho.svg) no-repeat 5px center;
  background-size: 40px;
}
.image-uploader__action.--delete {
  background: url(/assets/img/icons/close.svg) no-repeat 8px center;
  background-size: 20px;
}
.image-uploader__action.--center {
  background: url(/assets/img/icons/focus.svg) no-repeat 8px center;
  background-size: 20px;
}

.--admi {
  border-color: #05c984;
}
.--admi .roleized {
  filter: saturate(0);
}
.--admi .mdc-line-ripple:after {
  border-color: #05c984 !important;
}

.--equi {
  border-color: #ffbe00;
}
.--equi .roleized {
  filter: contrast(0) sepia(159) hue-rotate(360deg) brightness(1.2) saturate(3.5);
}
.--equi .mdc-line-ripple:after {
  border-color: #ffbe00 !important;
}

.--soci {
  border-color: #77c2ff;
}
.--soci .roleized {
  filter: contrast(0) sepia(159) hue-rotate(544deg) brightness(1.2) saturate(3.5);
}
.--soci .mdc-line-ripple:after {
  border-color: #77c2ff !important;
}

.--lega {
  border-color: #fa8072;
}
.--lega .roleized {
  filter: contrast(0) sepia(159) hue-rotate(689deg) saturate(3.5);
}
.--lega .mdc-line-ripple:after {
  border-color: #fa8072 !important;
}

.--coor {
  border-color: #ff65e4;
}
.--coor .roleized {
  filter: contrast(0) sepia(159) hue-rotate(617deg) saturate(3.5);
}
.--coor .mdc-line-ripple:after {
  border-color: #ff65e4 !important;
}

.--tele {
  border-color: #be9aff;
}
.--tele .roleized {
  filter: contrast(0) sepia(159) hue-rotate(214deg) saturate(3.5);
}
.--tele .mdc-line-ripple:after {
  border-color: #be9aff !important;
}

.--agen {
  border-color: #afc900;
}
.--agen .roleized {
  filter: contrast(0) sepia(159) brightness(1.2) hue-rotate(391deg) saturate(3.5);
}
.--agen .mdc-line-ripple:after {
  border-color: #afc900 !important;
}

.--admi-color {
  color: #05c984;
}

.--socio-color,
.--soci-color {
  color: #77c2ff;
}

.--equipo-color,
.--equi-color {
  color: #ffbe00;
  filter: brightness(0.9);
}

.--lega-color {
  color: #fa8072;
}

.--coor-color {
  color: #ff65e4;
}

.--tele-color {
  color: #be9aff;
}

.--agen-color {
  color: #afc900;
}

.--admi-bk {
  background-color: #05c984;
}

.--socio-bk,
.--soci-bk {
  background-color: #77c2ff;
}

.--equipo-bk,
.--equi-bk {
  background-color: #ffbe00;
  filter: brightness(0.9);
}

.--lega-bk {
  background-color: #fa8072;
}

.--coor-bk {
  background-color: #ff65e4;
}

.--tele-bk {
  background-color: #be9aff;
}

.--agen-bk {
  background-color: #afc900;
}

.--socio-dark,
.--soci-dark {
  color: #58799c;
}

.--equipo-dark,
.--equi-dark {
  color: #dfaf2b;
  filter: brightness(0.9);
}

.--lega-dark {
  color: #8f5246;
}

.--coor-dark {
  color: #914186;
}

.--tele-dark {
  color: #715e98;
}

.--agen-dark {
  color: #6b791d;
}

.--role-code {
  font-size: 0.8em;
}

horus-basic-chart {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  transition: max-height 0.2s linear;
}

.chart {
  flex-direction: column;
  display: flex;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
}
.chart canvas {
  max-width: 100%;
  max-height: 100%;
}
.chart__info {
  display: flex;
  flex-wrap: wrap;
  font-size: 2.5rem;
  width: 100%;
}
@media only screen and (max-width: 639px) {
  .chart__info {
    justify-content: center;
    font-size: 1.5rem;
  }
}
.chart__item {
  margin: 10px 20px 10px 0;
  filter: brightness(0.6);
}
.chart__item strong {
  color: #77c2ff;
  font-family: special, Arial, sans-serif;
}
.chart__item small {
  font-size: 0.5em;
}
.chart__msgs {
  width: 100%;
  margin-bottom: 100px;
}
.chart__edition {
  min-width: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #CCC;
  position: absolute;
  top: 10px;
  right: 10px;
}

.toques {
  background-color: #fff094;
}

.entrevistas {
  background-color: #ffc87f;
}

.captaciones {
  background-color: #ff9fb1;
}

.facturación {
  background-color: #7fc8b1;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 75%;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: basic, Arial, sans-serif;
  max-width: 100%;
  height: 100%;
}

#main-router + * {
  min-height: 100vh;
  padding-bottom: 50px;
  display: block;
  animation: showRouter 0.7s linear;
}

#popup + *,
#popup ~ horus-footer {
  display: none;
  opacity: 0;
  max-height: 99vh;
  overflow: hidden;
}

.environment {
  display: none;
  position: fixed;
  width: 100vw;
  z-index: -1;
  opacity: 0.3;
  font-size: 4vw;
  letter-spacing: -0.05em;
}
.environment:before {
  position: absolute;
  top: 61px;
  left: 10px;
  content: attr(title);
  font-family: headers, Arial, sans-serif;
}
.environment.--dev {
  display: block;
  color: #753673;
}
.environment.--test {
  display: block;
  color: #0ca66b;
}

article {
  min-height: calc(100vh - 50px);
  padding: 0 5vw;
  height: 100%;
}
article.normal {
  min-height: 0;
}

.limited {
  max-width: 1705px;
  margin: auto;
}
.limited article {
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: headers, Arial, sans-serif;
  letter-spacing: -0.03em;
}

.--header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 20px;
}
@media only screen and (max-width: 1022px) {
  .--header {
    padding: 20px 0;
  }
}
.--header--title {
  font-size: 2.5rem;
  flex-grow: 2;
  display: flex;
  align-items: center;
  margin: 0;
  border-style: solid;
  border-image-slice: 1;
  border-width: 0 0 1px;
  border-image-source: linear-gradient(to right, #133866, rgba(80, 227, 193, 0.4), rgba(0, 0, 0, 0));
  height: 42px;
}
@media only screen and (max-width: 1022px) {
  .--header--title {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 559px) {
  .--header--title {
    font-size: 1.5rem;
  }
}
.--header--title span {
  padding: 0 10px;
  /* &:nth-of-type(2) {
     filter: brightness(2.2);
   }*/
}
.--header--mini {
  display: inline-block;
  text-align: right;
  font-size: 0.7em;
  line-height: 1em;
  font-family: basic, Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  .--header--mini {
    font-size: 0.9em;
  }
}
.--header--split {
  font-size: 0.8em;
  color: #50e3c1;
  font-weight: lighter;
  font-family: special, Arial, sans-serif;
}

.horus {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.--content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.--icon {
  width: 25px;
  margin: 0 10px 0 0;
}

.--noavatar {
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.--noavatar img {
  height: 60%;
}

@media only screen and (max-width: 1022px) {
  .accordion__trigger {
    margin: 0 0 20px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .accordion__trigger:after {
    content: "";
    position: absolute;
    right: 1%;
    background: url(/assets/img/icons/expandir.svg) center right no-repeat;
    width: 25px;
    transition: all 0.05s linear;
    filter: saturate(0);
    height: 25px;
  }
  .accordion__content {
    height: 0;
    max-height: 0;
    margin-bottom: 20px;
    overflow: hidden;
    transition: max-height 0.6s linear;
    border-bottom: 1px solid #F1F1F1;
  }
  .accordion__opened:after {
    transform: rotate(90deg);
  }
  .accordion__opened + .accordion__content {
    height: auto;
    max-height: 9000px;
  }
}

.--disabled-style {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.--disabled-style .--link {
  cursor: not-allowed !important;
}

.--disabled-container {
  position: relative;
}
.--disabled-container__layer {
  position: absolute;
  width: 104%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  display: none;
}
.--disabled-container__layer:before {
  position: absolute;
  left: 50%;
  top: 10px;
  margin-left: -100px;
  padding: 10px;
  content: "Vista sólo consulta";
  font-size: 1.2rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  font-family: headers, Arial, sans-serif;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.--disabled-container__layer:hover:before {
  opacity: 1;
}
.--disabled-container .--disabled-container__layer {
  display: block;
  cursor: not-allowed;
}

.--g-desktop {
  display: unset;
}
@media only screen and (max-width: 768px) {
  .--g-desktop {
    display: none;
  }
}

.--g-mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .--g-mobile {
    display: unset;
  }
}

@media print {
  horus-header,
  horus-footer {
    display: none;
  }
  .horus {
    overflow: visible;
  }
}
google-map {
  position: absolute;
}

google-map,
.map-container {
  width: 100% !important;
  height: 100% !important;
  display: block;
  top: 0;
  left: 0;
}

.gm-bundled-control-on-bottom {
  bottom: 60% !important;
}

.gm-ui-hover-effect {
  transform: scale(1.5);
  margin: 5px !important;
}

.--fcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.--fdc {
  flex-direction: column;
}

.--facenter {
  display: flex;
  align-items: center;
}

.--fjcenter {
  display: flex;
  justify-content: center;
}

.--fjend {
  display: flex;
  justify-content: center;
}

.--faend {
  display: flex;
  align-items: flex-end;
}

.--fjb {
  display: flex;
  justify-content: space-between;
}

.--fill {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.--box {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #CCC;
  flex-grow: 2;
}

.--base, .--popup, .loader__saving, .--footer, .loader__deleting, .loader__invalid {
  opacity: 0;
  position: fixed;
  z-index: 5;
  display: flex;
  text-transform: lowercase;
  align-items: center;
  justify-content: center;
  animation: appear 0.2s linear forwards;
  background: rgba(0, 0, 0, 0.7);
  user-select: none;
}
.--base img, .--popup img, .loader__saving img, .--footer img, .loader__deleting img, .loader__invalid img {
  max-height: 40px;
  margin-right: 10px;
}
.--base figcaption, .--popup figcaption, .loader__saving figcaption, .--footer figcaption, .loader__deleting figcaption, .loader__invalid figcaption {
  color: #FFF;
  font-family: headers, Arial, sans-serif;
}

.--footer, .loader__deleting, .loader__invalid {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 15vh;
  font-size: 2rem;
}

.--popup, .loader__saving {
  width: 250px;
  height: 60px;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  border-radius: 10px;
}
.--popup img, .loader__saving img {
  max-height: 30px;
}

.loader__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader__container.--cover, .loader__container.--submit {
  z-index: 5;
  background-color: transparent;
}
.loader__container.--cover {
  margin-top: 0;
}
.loader__container.--fixed {
  position: fixed;
  margin: 0;
}
.loader__container.--white {
  background-color: #FFF;
}
.loader__spinner {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.loader__spinner-item {
  height: 40%;
  width: 9.2307692308px;
  animation: spinner5 1s ease-in-out infinite;
  opacity: 0.6;
}
.loader__spinner-item:nth-child(1) {
  background-color: #6d9d39;
}
.loader__spinner-item:nth-child(2) {
  background-color: #4aa90b;
  animation-delay: 0.1s;
}
.loader__spinner-item:nth-child(3) {
  background-color: #1f6d1b;
  animation-delay: 0.2s;
}
.loader__spinner-item:nth-child(4) {
  background-color: #024624;
  animation-delay: 0.3s;
}
.loader__spinner-item:nth-child(5) {
  background-color: #bfb000;
  animation-delay: 0.4s;
}
.loader__saving {
  box-shadow: 0 -2px 10px #002cff;
}
.loader__saving img {
  filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(313deg) brightness(104%) contrast(117%);
}
.loader__saving figcaption {
  font-family: headers, Arial, sans-serif;
}
.loader__invalid {
  box-shadow: 0 -2px 10px #d8a90d;
}
.loader__invalid img {
  filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(357deg) brightness(104%) contrast(117%);
}
.loader__invalid figcaption {
  color: #d8a90d;
}
.loader__deleting {
  box-shadow: 0 -2px 10px #000;
  background: rgba(202, 74, 72, 0.95);
}
.loader__deleting img {
  filter: invert(61%) sepia(92%) brightness(0);
}
.loader__description {
  padding-top: 5px;
  font-size: 0.8em;
  font-family: basic, Arial, sans-serif;
  color: #F1F1F1;
}

.error-dialog__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10vh;
  overflow: hidden;
}
.error-dialog__message {
  opacity: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 15vh;
  z-index: 6;
  display: flex;
  font-size: 1.5rem;
  text-transform: lowercase;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  animation: appear 0.2s linear forwards;
  box-shadow: 0 -2px 10px #c12900;
}
.error-dialog__message img {
  max-height: 40px;
  margin-right: 10px;
  filter: invert(61%) sepia(92%) saturate(1239%) hue-rotate(313deg) brightness(104%) contrast(117%);
}
.error-dialog__message figcaption {
  color: #FFF;
  font-family: headers, Arial, sans-serif;
}
.error-dialog__desc {
  padding-top: 5px;
  font-size: 1.3rem;
  font-family: basic, Arial, sans-serif;
  color: #febc44;
}

.main-header {
  position: sticky;
  top: 0;
  display: flex;
  height: 50px;
  background-color: #333;
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
  font-size: 1.3rem;
  border-width: 0 0 3px;
  border-style: solid;
}
.main-header:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 26px;
  background-image: linear-gradient(to bottom, #FFF 75%, rgba(255, 255, 255, 0) 100%);
  top: 50px;
  left: 0;
  z-index: -1;
}
.main-header.--noshadow:after {
  display: none;
}
.main-header__info {
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 2;
  overflow: hidden;
}
@media only screen and (max-width: 1022px) {
  .main-header__info {
    width: 100%;
    justify-content: flex-start;
  }
}
.main-header__logo {
  max-height: 90%;
  margin: 20px 10px 20px 20px;
  padding-right: 10px;
  border-width: 0 2px 0 0;
  border-style: solid;
}
@media only screen and (max-width: 1247px) {
  .main-header__logo {
    max-width: 55px;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 1022px) {
  .main-header__logo {
    padding-right: 5px;
    min-width: 55px;
    margin: 20px 10px 20px 5px;
  }
}
.main-header__logo.--agen img {
  filter: hue-rotate(402deg) brightness(1.2);
}
.main-header__logo.--tele img {
  filter: hue-rotate(228deg) brightness(1.1);
}
.main-header__logo.--coor img {
  filter: hue-rotate(296deg);
}
.main-header__logo.--lega img {
  filter: hue-rotate(304deg) saturate(4);
}
.main-header__logo.--soci img {
  filter: hue-rotate(498deg);
}
.main-header__logo.--equi img {
  filter: hue-rotate(-21deg) saturate(2);
}
.main-header__logo.--admi img {
  filter: hue-rotate(440deg);
}
.main-header__hamburguer {
  flex-direction: column;
  min-width: 70px;
  max-width: 70px;
  cursor: pointer;
  transform: scale(0.6);
  display: none;
}
@media only screen and (max-width: 1022px) {
  .main-header__hamburguer {
    display: flex;
  }
}
.main-header__hamburguer span {
  background: #FFF;
  border-radius: 10px;
  height: 7px;
  margin: 7px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.main-header__hamburguer span:nth-of-type(1) {
  width: 50%;
}
.main-header__hamburguer span:nth-of-type(2) {
  width: 100%;
}
.main-header__hamburguer span:nth-of-type(3) {
  width: 75%;
}
.main-header__hamburguer.--checked span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotate(45deg) translate(8px, 0px);
}
.main-header__hamburguer.--checked span:nth-of-type(2) {
  transform-origin: top;
  transform: rotate(-45deg);
}
.main-header__hamburguer.--checked span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(30px, -11px) rotate(45deg);
}
.main-header figure {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .main-header figure {
    margin-right: 5px;
  }
}
.main-header img {
  height: 30px;
}
.main-header horus-search-engine {
  flex-grow: 2;
  padding: 0 20px;
  max-width: 500px;
  max-height: 51px;
}
@media only screen and (max-width: 639px) {
  .main-header horus-search-engine {
    padding: 0 5px;
  }
}
.main-header horus-search-engine label, .main-header horus-search-engine .mat-mdc-input-element {
  color: #B2B2B2 !important;
}
@media only screen and (max-width: 1022px) {
  .main-header horus-search-engine label {
    max-width: 65px;
    overflow-x: hidden;
  }
}
.main-header__nav {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main-header__nav small {
  display: inline-block;
  padding: 0 5px;
  color: #68bacf;
}
@media only screen and (max-width: 1022px) {
  .main-header__nav {
    width: 100vw;
    min-height: 100vh;
    background: #333;
    flex-direction: column;
    left: 0;
    position: fixed;
    margin: 0;
    top: -200vh;
    text-align: center;
    justify-content: flex-start;
    padding-top: 15vh;
    transition: top 0.3s linear;
    z-index: -1;
  }
  .main-header__nav small {
    display: none;
  }
}
.main-header__nav:hover .main-header__nav__text {
  opacity: 0;
}
.main-header__nav__link {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  margin: 0 10px;
  font-size: 1.2rem;
  width: 35px;
  padding-top: 27px;
  overflow: visible;
  color: #000;
  font-family: basic, Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  .main-header__nav__link {
    flex-direction: row;
    padding-top: 2vh;
    width: 100vw;
    padding-left: 20vw;
    color: #CCC;
    font-size: 1.6rem;
    margin-bottom: 2vh;
  }
}
.main-header__nav__link.active span {
  font-family: headers, Arial, sans-serif;
  opacity: 1;
}
@media only screen and (max-width: 1022px) {
  .main-header__nav__link.active span {
    color: #FFF;
  }
}
.main-header__nav__link:hover span.main-header__nav__text {
  opacity: 1;
  color: #4C4C4C;
}
@media only screen and (max-width: 1022px) {
  .main-header__nav__link:hover span.main-header__nav__text {
    color: #FFF;
  }
}
.main-header__nav__img {
  width: 30px;
  margin-bottom: 15px;
  transition: filter 0.1s linear;
}
@media only screen and (max-width: 1022px) {
  .main-header__nav__img {
    width: 40px;
    min-height: 40px;
    margin-bottom: 0;
  }
}
.main-header__nav__text {
  transition: opacity 0.2s linear;
  opacity: 0;
}
@media only screen and (max-width: 1022px) {
  .main-header__nav__text {
    opacity: 1 !important;
    margin-left: 30px;
  }
}
.main-header__nav.--open-mobile {
  top: 0;
}
.main-header__alert {
  position: absolute;
  top: 20px;
  right: -10px;
  height: 10px;
  width: 10px;
  filter: none !important;
}
@media only screen and (max-width: 1022px) {
  .main-header__alert {
    right: unset;
    left: 20px;
    height: 20px;
    width: 20px;
  }
}

horus-dashboard {
  display: block;
  min-height: calc(90vh + 20px);
}
@media only screen and (max-width: 1022px) {
  horus-dashboard {
    height: auto;
  }
}

.dash__welcome {
  width: 30%;
  border-right: 1px solid #B2B2B2;
}
@media only screen and (max-width: 1439px) {
  .dash__welcome {
    width: 25%;
  }
}
@media only screen and (max-width: 1247px) {
  .dash__welcome {
    width: 20%;
  }
}
@media only screen and (max-width: 1022px) {
  .dash__welcome {
    border: 0;
    width: 100%;
  }
}
.dash__header {
  position: relative;
  width: 100%;
  display: flex;
}
.dash__header h1 {
  font-size: 4rem;
  color: #B2B2B2;
  padding: 0 3vw;
  margin: 10px 0 20px;
}
@media only screen and (max-width: 1247px) {
  .dash__header h1 {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 1022px) {
  .dash__header h1 {
    margin: 21px 0 20px;
    font-size: 2rem;
  }
}
.dash__up {
  position: relative;
  width: 100%;
  min-height: 64vh;
  display: flex;
  padding: 20px 0 20px 0;
}
@media only screen and (max-width: 1022px) {
  .dash__up {
    flex-direction: column;
    padding: 0 0 20px 0;
    min-height: unset;
  }
}
.dash__quicklinks {
  padding: 1vh 3vw 0;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1022px) {
  .dash__quicklinks {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 639px) {
  .dash__quicklinks {
    margin-bottom: 10px;
  }
}
.dash__quicklinks horus-search-engine {
  min-width: 100%;
}
@media only screen and (max-width: 1022px) {
  .dash__quicklinks nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.dash__quicklinks a {
  display: flex;
  align-items: center;
  font-size: 2rem;
  padding: 10px 0;
  line-height: 0.8;
  border-bottom: 1px solid #E5E5E5;
}
@media only screen and (max-width: 1247px) {
  .dash__quicklinks a {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1022px) {
  .dash__quicklinks a {
    flex-direction: column;
    padding: 10px;
    border: 0;
    max-width: 100px;
  }
}
@media only screen and (max-width: 639px) {
  .dash__quicklinks a {
    font-size: 1.2rem;
    max-width: 75px;
    padding: 10px 5px;
  }
}
.dash__quicklinks figure {
  background: #333;
  border-radius: 50%;
  display: flex;
  width: 3vw;
  height: 3vw;
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  margin-right: 20px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
  transition: background-color 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .dash__quicklinks figure {
    margin-right: 4%;
  }
}
@media only screen and (max-width: 639px) {
  .dash__quicklinks figure {
    max-height: 45px;
    max-width: 45px;
    min-height: 45px;
    min-width: 45px;
  }
}
.dash__quicklinks img {
  width: 60%;
}
.dash__charts {
  width: 70%;
  position: relative;
  padding: 0 5% 0 1%;
  flex-wrap: wrap;
  min-height: 530px;
  max-width: 1160px;
  margin: auto;
}
@media only screen and (max-width: 1439px) {
  .dash__charts {
    padding: 0 2%;
    width: 75%;
  }
}
@media only screen and (max-width: 1247px) {
  .dash__charts {
    padding: 0 2%;
    width: 80%;
  }
}
@media only screen and (max-width: 1022px) {
  .dash__charts {
    width: 100%;
    min-height: 600px;
    padding: 0 2%;
  }
}
@media only screen and (max-width: 479px) {
  .dash__charts {
    padding: 0 5%;
    min-height: 550px;
  }
}
.dash__charts__chart {
  width: 100%;
  margin-top: 20px;
  height: unset;
}
.dash__charts .--box {
  border: 0;
}
.dash__bottom {
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 1022px) {
  .dash__bottom {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.dash__alarm {
  width: 100%;
  padding: 10px 2vw;
  overflow-x: auto;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm {
    margin-bottom: -23px;
    font-size: 2em;
  }
}
.dash__alarm .loader__container {
  margin-top: 10vh;
}
.dash__alarm__delete {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  background: black;
  border-radius: 50%;
  height: 30px;
  cursor: pointer;
}
.dash__alarm__delete:hover img {
  filter: brightness(2);
}
.dash__alarm__delete img {
  height: 20px;
  width: auto;
}
.dash__alarm__header {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #F1F1F1;
}
@media only screen and (max-width: 639px) {
  .dash__alarm__header {
    padding-bottom: 10px;
  }
}
.dash__alarm__header img {
  width: 30px;
  margin-right: 10px;
}
.dash__alarm__title {
  font-size: 2rem;
  color: #7F7F7F;
  margin: 5px 5px 3px;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm__title {
    font-size: 2.5rem;
  }
}
.dash__alarm__list {
  display: flex;
  width: 100%;
  min-height: 100%;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm__list {
    overflow-x: auto;
  }
}
.dash__alarm__no-alarm {
  font-size: 2rem;
  padding: 2vh 2vw;
  font-family: headers, Arial, sans-serif;
  color: #CCC;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm__no-alarm {
    font-size: 1.5rem;
  }
}
.dash__alarm__item {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #000;
  flex-basis: 100%;
  box-shadow: 1px 2px 6px rgba(51, 46, 125, 0.6);
  transition: background-color 0.2s linear;
  position: relative;
  min-width: 200px;
  max-width: 20%;
  overflow: hidden;
  margin: 10px;
  border-radius: 20px;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm__item {
    height: auto;
    max-height: unset;
  }
}
.dash__alarm__item.--acción, .dash__alarm__item.--plantilla {
  border-radius: 0;
  border: 1px solid #666;
}
.dash__alarm__item.--check {
  border-radius: 10px;
  border: 1px solid #B2B2B2;
}
.dash__alarm__item.--entre {
  border-radius: 5px;
  box-shadow: 1px 2px 6px rgba(0, 44, 255, 0.6);
  border: 1px solid #002cff;
}
.dash__alarm__type {
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  padding: 20px 10px;
  font-size: 1.2rem;
  font-family: headers, Arial, sans-serif;
  width: 100%;
  color: #000;
  transition: all 0.2s linear;
  min-height: 34px;
}
.dash__alarm__type:hover {
  color: #FFF;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm__type {
    font-size: 1rem;
  }
}
.dash__alarm__type-icon {
  height: 30px;
  width: 30px;
  margin: 0 10px 0 0;
}
.dash__alarm__type-icon.--grey {
  filter: saturate(0);
}
.dash__alarm__type.--calendario {
  background: #CCC;
}
.dash__alarm__type.--calendario:hover {
  background: #999;
}
.dash__alarm__type.--acción {
  cursor: pointer;
  background: rgba(255, 160, 35, 0.3);
}
.dash__alarm__type.--acción:hover {
  background-color: #ffa023;
}
.dash__alarm__type.--plantilla {
  cursor: pointer;
  background: rgba(185, 190, 10, 0.6);
}
.dash__alarm__type.--plantilla:hover {
  background-color: #b9be0a;
}
.dash__alarm__data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex-grow: 2;
}
.dash__alarm__name {
  font-size: 1.2rem;
  margin: 0 0 10px;
  padding: 0 30px 5px 0;
  border-bottom: 1px solid #E5E5E5;
  color: #666;
}
.dash__alarm__name small {
  font-family: headers, Arial, sans-serif;
  color: #aa4400;
  font-size: 1.4rem;
  display: block;
  padding: 5px 0;
  opacity: 0.8;
}
@media only screen and (max-width: 1022px) {
  .dash__alarm__name {
    font-size: 1rem;
  }
}
.dash__alarm__desc {
  font-size: 1.2rem;
  color: #666;
  margin-top: 5px;
}
.dash__alarm__desc.--big {
  font-size: 1.3rem;
  color: #333;
}
.dash__alarm__desc a {
  margin: 10px 0;
  display: block;
  text-decoration: underline;
}
.dash__alarm__lead {
  font-size: 1.2rem;
  line-height: 1rem;
  display: block;
}
.dash__alarm__lead a {
  margin: 10px 0;
  display: block;
}
.dash-admin h2 {
  font-size: 2rem;
  color: #B2B2B2;
  padding-top: 0;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1022px) {
  .dash-admin h2 {
    font-size: 1.5rem;
  }
}
.dash-admin__welcome {
  min-width: 25%;
}
.dash-admin__welcome h1 {
  font-size: 3rem;
}
.dash-admin__charts {
  padding: 0 2% 2vh;
  width: 75%;
  overflow: hidden;
  max-width: unset;
}
@media only screen and (max-width: 1022px) {
  .dash-admin__charts {
    width: 100%;
    max-height: unset;
    height: auto;
    padding: 0 4vw 3vw;
  }
}
.dash-admin__bottom {
  padding: 20px 2vw 0;
}
.dash-admin__title {
  font-family: "headers", Arial, sans-serif;
  color: #7F7F7F;
  font-size: 1.4rem;
  padding: 0 0 0 10px;
}

horus-logs {
  position: relative;
  display: block;
}

.logs {
  flex-basis: 22.5%;
  margin-left: 1.5%;
  padding-left: 1.5%;
  border-left: 1px solid #CCC;
}
.logs.--loading {
  opacity: 0;
}
.logs__list-box {
  position: relative;
}
.logs__list {
  margin: 20px 0;
  font-size: 1.1rem;
}
@media only screen and (max-width: 1022px) {
  .logs__list {
    padding: 0 10px;
  }
}
.logs__item {
  position: relative;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F1F1F1;
}
@media only screen and (max-width: 639px) {
  .logs__item {
    flex-wrap: wrap;
    padding: 10px 0;
  }
}
.logs__item > span {
  color: #7F7F7F;
  padding: 0 5px;
  flex-basis: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1247px) {
  .logs__item > span {
    display: none;
  }
}
@media only screen and (max-width: 639px) {
  .logs__item > span {
    flex-basis: calc(100% - 125px);
    margin: 0 0 0 20px;
    padding-left: 0;
  }
}
.logs__item .--log {
  margin-left: 20px;
  flex-grow: 2;
  max-width: 200px;
}
@media only screen and (max-width: 639px) {
  .logs__item .--log {
    padding: 0 60px 5px 0;
    max-width: 100%;
  }
}
.logs__header {
  flex-wrap: wrap;
}
.logs__header > span {
  font-size: 1.2rem;
  color: #000;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 639px) {
  .logs__header > span {
    display: none;
  }
}
.logs__header__score {
  margin-right: 30px !important;
  justify-content: flex-end;
}
.logs__filters {
  width: 100%;
}
.logs__resp-sort {
  display: none !important;
  width: 100%;
}
@media only screen and (max-width: 639px) {
  .logs__resp-sort {
    display: flex !important;
  }
}
.logs__resp-sort .--resp {
  position: absolute;
  right: 0;
  left: unset;
  width: 25px;
  bottom: 26px;
}
.logs__resp-sort .mat-mdc-form-field {
  width: calc(100% - 40px);
}
.logs__order {
  width: 13px;
  position: relative;
  left: 5px;
  cursor: pointer;
  display: none;
}
.logs__order.--dir1 {
  display: inline;
  transform: rotate(180deg);
}
.logs__order.--dir-1 {
  display: inline;
}
.logs__name {
  display: flex;
  align-items: center;
  line-height: 0.9;
  color: #333 !important;
  transition: color 0.2s linear;
}
.logs__sentby {
  position: absolute;
  width: 17px;
  top: calc(50% - 11px);
  left: -3px;
  transition: margin 0.2s linear;
  filter: brightness(0) invert(1);
}
@media only screen and (max-width: 639px) {
  .logs__sentby {
    left: -5px;
  }
}
.logs__link {
  transition: color 0.2s linear;
  user-select: none;
  cursor: pointer;
  flex-grow: 2;
}
.logs__link:hover {
  color: #002cff;
}
.logs__score {
  justify-content: flex-end;
  font-size: 2rem;
  font-family: headers, Arial, sans-serif;
  flex-basis: calc(20% - 50px);
}
.logs__score small {
  font-size: 0.6em;
  font-family: basicregular, Arial, sans-serif;
}
@media only screen and (max-width: 639px) {
  .logs__score {
    position: absolute;
    right: 25px;
    font-size: 1.7rem;
  }
}
.logs__info {
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  margin: 2px 0 0 0;
}
@media only screen and (max-width: 639px) {
  .logs__info {
    position: absolute;
    right: 0;
  }
}
.logs__desc {
  position: absolute;
  top: 30px;
  width: 100%;
  background: #FFF;
  opacity: 0;
  transition: opacity 0.2s linear 0.5s;
  box-shadow: 2px 2px 10px #7F7F7F;
  border-radius: 5px;
  padding: 15px;
  font-size: 1.3rem;
  display: none;
  right: 0;
  max-width: 600px;
}
@media only screen and (max-width: 1022px) {
  .logs__desc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
  }
}
.logs__desc h5, .logs__desc h6 {
  margin: 0;
}
.logs__desc__title {
  color: #666;
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  max-width: calc(100% - 40px);
}
.logs__desc__title span {
  font-family: basic, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: lighter;
}
.logs__desc__receivers {
  padding: 10px 0 0;
  margin: 10px 0;
  border: solid #999;
  border-width: 1px 0;
}
.logs__desc__receivers li {
  font-size: 1.3rem;
}
.--detail .logs__desc {
  display: block;
  opacity: 1;
  z-index: 3;
}
.logs__desc__description {
  color: #666;
  font-size: 1.1rem;
  width: 100%;
  padding-top: 10px;
  word-break: break-word;
}
.logs__desc__description:nth-of-type(3) {
  padding-top: 10px;
  border-top: 1px solid #CCC;
}
.logs__desc__description img {
  max-width: 100%;
}
.logs__desc__close {
  width: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.sentby__item {
  position: absolute;
}
.sentby__item:before {
  position: absolute;
  top: calc(50% - 12px);
  left: -5px;
  content: "";
  min-width: 20px;
  min-height: 20px;
  display: inline-block;
  border-radius: 50%;
  z-index: 0;
  background: #b9be0a;
}
@media only screen and (max-width: 639px) {
  .sentby__item:before {
    left: -8px;
  }
}

.--log-action:before {
  background: #ffa023;
}

.--log-special:before {
  background: #1f6d1b;
}

horus-alarms {
  user-select: none;
}

.alarms__type {
  opacity: 0.7;
  transition: opacity 0.2s linear;
}
.alarms__type:hover {
  opacity: 1;
}
.alarms__type strong {
  display: block;
  margin-right: 10px;
  color: #FFF;
}
.alarms__type address {
  color: #F1F1F1;
  margin: 0 10px 0 0;
}
.alarms__type .cal-event-title {
  user-select: none;
  color: #F1F1F1;
}
.alarms__type .cal-event-title:hover {
  cursor: default;
  text-decoration: none;
}
.alarms__type .cal-event-title a {
  color: #F1F1F1;
  margin: 0 10px;
  font-size: 1.5rem;
}
.alarms__type .cal-event-title a:hover {
  color: #50d3e3;
}
.alarms__type__calendario {
  border-bottom: 1px solid #B2B2B2;
}
.alarms__type__calendario .cal-event {
  background: url("/assets/img/icons/calendar.svg") no-repeat center center;
  background-size: 90% auto;
}
.alarms__type__contacto {
  border-bottom: 1px solid #333;
}
.alarms__type__contacto .cal-event {
  background: url("/assets/img/icons/contacto.svg") no-repeat center center;
  background-size: 90% auto;
}
.alarms__type__acción {
  border-bottom: 1px solid #ffa023;
}
.alarms__type__acción .cal-event {
  filter: sepia(1) brightness(1.5) saturate(23.5) hue-rotate(-20deg);
  background: url("/assets/img/icons/accion_alarma.svg") no-repeat center center;
  background-size: 80% auto;
}
.alarms__type__plantilla {
  border-bottom: 1px solid #b9be0a;
}
.alarms__type__plantilla .cal-event {
  filter: sepia(1) saturate(2.5) hue-rotate(28deg) contrast(1.5);
  background: url("/assets/img/icons/doc.svg") no-repeat center center;
  background-size: 80% auto;
}
.alarms__type__refile {
  border-bottom: 1px solid #333;
}
.alarms__type__refile .cal-event {
  background: url("/assets/img/icons/expediente.svg") no-repeat center center;
  background-size: 90% auto;
}
.alarms-manager .mat-mdc-radio-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  font-family: special, Arial, sans-serif;
}
.alarms-manager .mat-mdc-radio-button {
  margin: auto 10px auto;
  cursor: pointer;
}
.alarms-manager .mdc-form-field {
  height: 20px;
}
.alarms-manager__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.alarms-manager__title img {
  width: 30px;
  margin: -5px 8px 0 -30px;
  filter: saturate(0);
}
@media only screen and (max-width: 1247px) {
  .alarms-manager__title img {
    margin: 0 8px 0 0;
  }
}
@media only screen and (max-width: 1022px) {
  .alarms-manager__title img {
    display: none;
  }
}
.alarms-manager__subtitle {
  padding: 10px 0 0;
  margin: 10px 0 0;
  width: 100%;
}
.alarms-manager__subtitle-date {
  font-size: 2.3rem;
  font-family: headers, Arial, sans-serif;
  margin: 10px auto 10px;
  color: #332e7d;
  min-width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1022px) {
  .alarms-manager__subtitle-date {
    font-size: 2rem;
  }
}
.alarms-manager__subtitle-date span {
  padding-right: 10px;
  border-right: 1px solid #999;
  display: inline-block;
  transition: color 0.2s linear;
}
@media only screen and (max-width: 559px) {
  .alarms-manager__subtitle-date span {
    width: 100%;
  }
}
.alarms-manager__subtitle-date span:hover {
  color: #ff58ea;
}
.alarms-manager__subtitle-date figure {
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  font-family: special, Arial, sans-serif;
  letter-spacing: 0;
  color: #666;
}
.alarms-manager__subtitle-date img {
  margin: 0 10px 0 20px;
  height: 30px;
}
@media only screen and (max-width: 1022px) {
  .alarms-manager__subtitle-date img {
    margin: 10px 10px 10px;
  }
}
.edit .alarms-manager__subtitle-date {
  margin-top: 10px;
}
.alarms-manager .page-modal__content {
  min-height: 550px;
}
.alarms-manager__asoc {
  margin-top: 2vh;
  width: 100%;
}
.edit .alarms-manager__asoc h3 {
  border-bottom: 0;
  margin: 20px 0 5px;
  color: #000;
}
.alarms-manager__typeselector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alarms-manager__typeselector .mat-mdc-checkbox {
  margin: 10px auto;
  min-width: 200px;
}
.alarms-manager__typeselector .mdc-checkbox {
  display: none;
}
.alarms-manager__typeselector figure {
  text-align: center;
  font-size: 1.1rem;
  font-family: basic, Arial, sans-serif;
  transition: transform 0.2s linear;
  color: #666;
  opacity: 0.7;
  display: flex;
  line-height: 1;
  align-items: center;
}
.alarms-manager__typeselector figure:hover, .alarms-manager__typeselector figure.active {
  opacity: 1;
  color: #000;
}
.alarms-manager__openplan {
  width: 100%;
  max-width: 30px;
  margin: 5px;
}
.alarms-manager__names {
  width: 100%;
}
.edit .alarms-manager__names {
  font-size: 2.5rem;
}
.edit .alarms-manager__names .mat-mdc-input-element {
  font-size: 2.2rem;
}
@media only screen and (max-width: 1022px) {
  .edit .alarms-manager__names .mat-mdc-input-element {
    font-size: 2rem;
  }
}
.alarms-manager__names .header2thin {
  font-size: 1.3rem;
}
.alarms-manager__names .header2thin .mat-mdc-input-element {
  font-size: 1.3rem;
  font-family: basic, Arial, sans-serif;
  min-height: 60px;
  padding: 10px 0;
}
.alarms-manager__description {
  margin: 15px 0 0;
}
.alarms-manager__description .textarea {
  min-height: 80px;
}
.alarms-manager__dates {
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  margin: 10px 0 0;
}
.alarms-manager__dates .header2 {
  margin-top: 10px;
  color: #332e7d;
}
.alarms-manager__date {
  height: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}
.alarms-manager__repeat {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.alarms-manager__repeat .mat-mdc-checkbox {
  margin-right: 10px;
}
.alarms-manager__repeat .mat-mdc-form-field {
  max-height: 20px;
  margin: 0 10px;
}
.alarms-manager__repeat .mat-mdc-text-field-wrapper {
  max-width: 30px;
  text-align: center;
  border-top: 0;
  padding: 0 !important;
}
.alarms-manager__repeat .mat-mdc-input-element {
  font-size: 1.1rem !important;
}
.alarms-manager .actions__item {
  margin-bottom: 0;
}
.alarms-manager .actions__content {
  margin: 10px 0;
}
.alarms-manager .actions__item__circle {
  margin: 20px 10px 0;
}
.alarms-manager .--squares {
  min-height: 0;
}
.alarms-manager .--squares .templates__item {
  margin-bottom: 0;
}

.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder;
}

.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  color: #666;
  font-family: special, Arial, sans-serif;
  font-size: 1.2rem;
}

.cal-month-view .cal-days {
  border: 1px solid;
  border-bottom: 0;
}

.cal-month-view .cal-cell-top {
  min-height: 78px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.cal-month-view .cal-cell-top > span {
  display: flex;
  justify-content: space-between;
}

.cal-month-view .cal-cell-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cal-month-view .cal-cell {
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.cal-month-view .cal-cell .cal-event {
  pointer-events: all !important;
}

.cal-month-view .cal-day-cell {
  position: relative;
  min-height: 80px;
  min-width: 14.2857142857%;
}

@media all and (-ms-high-contrast: none) {
  .cal-month-view .cal-day-cell {
    display: block;
  }
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid;
}

.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
}

.cal-month-view .cal-day-number {
  font-size: 1.9em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 10px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
  flex-grow: 2;
  text-align: right;
}
@media only screen and (max-width: 559px) {
  .cal-month-view .cal-day-number {
    font-size: 1.5em !important;
    margin-right: 5px;
    margin-top: 5px;
  }
}

.cal-month-view .cal-events {
  flex: 1;
  align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: flex;
  flex-wrap: wrap;
}

.cal-month-view .cal-event {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: 5px 10px;
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  font-size: 1.9em;
  opacity: 0.1;
  cursor: default;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em;
  font-weight: bold;
  color: #000;
}

.cal-month-view .cal-open-day-events {
  max-height: 50vh;
  overflow-y: auto !important;
}
.cal-month-view .cal-open-day-events > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1400px;
  width: 96%;
  margin: auto;
  padding: 5px 0;
}

/*.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px; }*/
.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3;
}

.cal-month-view .cal-draggable {
  cursor: move;
}

.cal-month-view .cal-drag-active * {
  pointer-events: none;
}

.cal-month-view mwl-calendar-event-title {
  flex-grow: 2;
  padding: 0 20px 0 0;
}

.cal-month-view .cal-event-title {
  line-height: 1.1;
  font-size: 1.4rem;
  min-width: 200px;
  display: flex;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 1022px) {
  .cal-month-view .cal-event-title {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.2rem;
  }
}

.cal-month-view {
  background-color: #fff;
}

.cal-month-view .cal-cell-row:hover {
  background-color: #fafafa;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #ededed;
}

.cal-month-view .cal-days {
  border-color: #e1e1e1;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #e1e1e1;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #e1e1e1;
}

.cal-month-view .cal-day-badge {
  background: url(/assets/img/icons/alarmas.svg) no-repeat;
  background-size: 30px;
  margin: 5px;
  width: 33px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.cal-month-view .cal-event {
  border-color: #d1e8ff;
  color: #fff;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #8b0000;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: #e8fde7;
}

.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #e0e0e0 !important;
}

.cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #555;
  -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
@media only screen and (max-width: 1022px) {
  .cal-month-view .cal-open-day-events {
    padding: 10px;
  }
}

.cal-event-actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 0 10px;
  border-left: 1px solid #7F7F7F;
}
.cal-event-actions img {
  margin: 0 5px;
  width: 25px;
}

.calendar__close {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
}
.calendar__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  .calendar__header {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 1022px) {
  .calendar__header h2 {
    font-size: 1.5rem;
    color: #666;
  }
}
.calendar__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1022px) {
  .calendar__nav:nth-of-type(2) {
    display: none;
  }
}
.calendar__menu {
  padding: 0 5px;
  cursor: pointer;
  filter: saturate(0);
  opacity: 0.5;
}
.calendar__menu.active {
  filter: none;
  opacity: 1;
}
.calendar__menu img {
  width: 40px;
}
.calendar__today {
  font-size: 1.2rem;
}
@media only screen and (max-width: 1022px) {
  .calendar__today {
    display: none;
  }
}
.calendar__today:hover {
  font-weight: bold;
}
.calendar__body {
  position: relative;
  z-index: 1;
  margin-bottom: 150px;
}
.calendar__eventNav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.calendar__eventNav .cal-day-badge {
  flex-basis: 60%;
  height: 100%;
  background-position: center;
  background-size: 30%;
  transition: transform 0.2s linear;
}
.calendar__eventNav .cal-day-badge:hover {
  transform: scale(1.2);
}
@media only screen and (max-width: 1022px) {
  .calendar__eventNav .cal-day-badge {
    background: #c12900;
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 0;
    color: #FFF;
    left: 0;
    top: 17px;
  }
}
.calendar__addEvent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  flex-grow: 2;
  height: 100%;
  padding-top: 14%;
  /*    .cal-disabled.cal-today & {
        display: flex;
      }*/
}
.cal-disabled .calendar__addEvent {
  display: none;
}
@media only screen and (max-width: 1022px) {
  .calendar__addEvent.--withAlarms {
    height: 30px;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}
.calendar__addEvent.--withAlarms:before {
  content: "";
  height: 50%;
  width: 1px;
  top: 25%;
  position: absolute;
  background: #B2B2B2;
  left: -1px;
}
@media only screen and (max-width: 1022px) {
  .calendar__addEvent.--withAlarms:before {
    display: none;
  }
}
.calendar__addEvent img {
  height: 50%;
  transition: background-color 0.1s linear;
}
@media only screen and (max-width: 1022px) {
  .calendar__addEvent img {
    width: 20px;
    top: unset;
    bottom: 5px;
    position: absolute;
    right: 5px;
    height: unset;
  }
}

.acting-like {
  display: flex;
  align-items: center;
  color: #B2B2B2;
  font-size: 1.1rem;
  padding-right: 20px;
  border-right: 2px solid #50d3e3;
}
@media only screen and (max-width: 1022px) {
  .acting-like {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 639px) {
  .acting-like {
    padding-right: 5px;
  }
}
.acting-like.--acting-link:hover, .acting-like.--acting-link:focus {
  cursor: pointer;
  color: #FFF;
}
.acting-like__avatar {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-width: 3px;
  border-style: solid;
  background: #000;
}
.--link .acting-like__avatar img {
  object-fit: cover;
  filter: none;
}
.acting-like__user {
  display: block;
  color: #F1F1F1;
  font-size: 1.15rem;
  min-width: 100px;
  font-family: "special", Arial, sans serif;
}
@media only screen and (max-width: 1022px) {
  .acting-like__user {
    min-width: 0;
    display: none;
  }
}
@media only screen and (max-width: 639px) {
  .acting-like__role {
    display: none;
  }
}
.acting-like__modal-title {
  margin-top: 0;
  color: #006390;
}
.acting-like__modal-linklist {
  margin: 0 0 10px 0;
  cursor: pointer;
}
.acting-like__modal-linklist:hover, .acting-like__modal-linklist:focus {
  color: #002cff;
}
.acting-like__modal-linklist.--active {
  font-weight: bold;
  cursor: default;
  user-select: none;
}
.acting-like__modal-linklist.--active:before {
  content: "*";
  font-size: 2em;
  position: relative;
  left: 0;
  top: 0.35em;
  line-height: 0;
  color: #002cff;
}
.acting-like__modal-linklist.--active:hover, .acting-like__modal-linklist.--active:focus {
  color: #000;
}

horus-wysiwyg {
  font-size: 0.9em;
  font-family: "basic", Arial, sans-serif;
  font-weight: lighter;
  line-height: 1.2;
  color: #778899;
  margin: 20px auto;
  position: relative;
}
horus-wysiwyg a {
  color: #001cb0;
  text-decoration: underline;
}
horus-wysiwyg em {
  display: block;
  color: #332e7d;
  font-size: 0.8em;
}
horus-wysiwyg .mat-mdc-form-field {
  position: absolute;
  top: -44px;
  width: 200px;
  max-width: 50%;
  right: 0;
}
horus-wysiwyg .mat-mdc-form-field .mat-mdc-form-field-label-wrapper {
  display: none;
}
horus-wysiwyg .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
horus-wysiwyg .marker-yellow {
  user-modify: read-only;
  user-select: none;
  -moz-user-modify: read-only;
  -webkit-user-modify: read-only;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  position: relative;
  font-family: special, Arial, sans-serif;
  margin: 40px auto 80px;
}
.pagination__hide {
  visibility: hidden;
}
.pagination__container {
  display: flex;
  align-items: center;
}
.pagination__container figure {
  max-width: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  border: 0 !important;
}
.pagination__container img {
  width: 20px;
  max-height: 20px;
  margin: 0 10px;
}
.pagination__info {
  margin: 0 1vw;
  color: #666;
  text-align: center;
}
.pagination__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.eagle {
  padding: 30px 1vw;
}
.eagle__header {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 20px;
  padding: 0 10px;
}
@media only screen and (max-width: 1022px) {
  .eagle__header {
    width: 100%;
    justify-content: space-between;
  }
}
.eagle__header h2 {
  margin: 0 2% 0 0;
}
.eagle__header horus-user-autocomplete {
  min-width: 50%;
  max-width: 300px;
  margin-right: 2%;
}
@media only screen and (max-width: 1022px) {
  .eagle__header horus-user-autocomplete {
    min-width: 70%;
  }
}
.eagle .tables__views {
  display: none;
}
.eagle__data {
  position: fixed;
  width: 100%;
  top: calc(100vh - 50px);
  left: 0;
  min-height: 40px;
  background-color: #333;
  display: flex;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  .eagle__data {
    display: none;
  }
}
.eagle__data .eagle__column {
  padding: 6px 20px;
}
.eagle__data__info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.eagle__data .eagle__data__info {
  width: 100%;
}
.eagle__data h2 {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  color: #CCC;
}
.eagle__price {
  color: #74f37e;
  font-family: "headers", Arial, sans-serif;
}
.eagle__content {
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .eagle__content {
    flex-direction: column;
  }
}
.eagle__column {
  min-height: calc(100vh - 90px);
  flex-basis: 100%;
  position: relative;
}
@media only screen and (max-width: 1022px) {
  .eagle__column {
    min-height: 0;
    padding: 0;
  }
}
.eagle__column:last-child > div {
  border: 0;
}
.eagle__column .accordion__trigger {
  text-align: center;
  margin: 0;
  min-height: 50px;
  align-items: center;
  display: none;
  padding: 1vh;
}
@media only screen and (max-width: 1022px) {
  .eagle__column .accordion__trigger {
    display: flex;
  }
}
.eagle__column small {
  font-size: 0.8em;
  font-family: "basic", Arial, sans-serif;
  font-weight: normal;
  margin: 0 10px;
}
.eagle__column__list {
  margin: 5px 0 20px 0;
  padding: 0 0.75vw;
  border-right: 2px solid #4C4C4C;
  min-height: 100%;
}
@media only screen and (max-width: 1022px) {
  .eagle__column__list {
    margin: 0;
    border: 0;
  }
}
.eagle__alarm-counter {
  margin-left: 20px;
  background: #c12900;
  color: #FFF;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  display: none;
}
@media only screen and (max-width: 1022px) {
  .eagle__alarm-counter {
    display: flex;
  }
}
@media only screen and (max-width: 1022px) {
  .eagle horus-lead-list {
    margin-top: 15px;
    padding: 0 10px;
    display: block;
  }
}
.eagle .accordion__trigger:after {
  right: 15px;
}

.config h1 {
  color: #133866;
  font-size: 2rem;
  transition: font-size 0.05s linear;
}
@media only screen and (max-width: 1022px) {
  .config h1 span {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 559px) {
  .config h1 span {
    font-size: 1.3rem;
  }
}
.config h1 span:nth-of-type(1) {
  padding-left: 0;
}
.config h1 img {
  margin: 0 0 0 20px;
}
@media only screen and (max-width: 559px) {
  .config h1 img {
    margin-left: 10px;
  }
}
.config-header {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .config-header.--header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.config-header__nav {
  display: flex;
  align-items: center;
  min-width: 100px;
}
.config-header nav {
  width: 42vw;
  max-width: 90%;
}
@media only screen and (max-width: 1439px) {
  .config-header nav {
    width: 52vw;
  }
}
@media only screen and (max-width: 1022px) {
  .config-header nav {
    position: relative;
    order: -1;
    margin: 10px auto 20px;
    width: 600px;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.config-area {
  flex-direction: column;
}
.config-area h1 {
  width: 100%;
  font-size: 3rem;
  order: -20;
}
.config-area a {
  text-decoration: none;
  color: #4C4C4C;
  font-size: 1.2rem;
  margin-top: 2.5vh;
}
.config-area figure {
  position: relative;
}
.config__alert {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  filter: none !important;
}

.leads h1 {
  color: #753673;
}
.leads h1 img {
  margin: 0 0 0 20px;
  width: 30px;
}
@media only screen and (max-width: 1022px) {
  .leads h1 img {
    margin: 0 0 0 10px;
  }
}
@media only screen and (max-width: 559px) {
  .leads h1 img {
    margin: 0 0 0 5px;
  }
}
.leads .--squares--item {
  border-radius: 18px 18px 0 18px;
  border-width: 0 0 2px 2px;
}
.leads__mine {
  padding: 5px 0 0;
}
.leads__prospect .--squares--avatar--no {
  width: 75%;
  filter: sepia(1) saturate(3) hue-rotate(306deg);
}
.leads__prospect .leads__score {
  display: none;
}
.leads__not-mine {
  filter: saturate(0);
}
.leads__owner-name {
  padding: 3px 0 0;
  font-style: italic;
}
.leads__owner-name__name {
  filter: brightness(0.6);
}
.leads__emoji {
  position: absolute;
  top: -5px;
  left: -10px;
  width: 30px;
  z-index: 2;
}
.leads__emoji img {
  width: 100%;
}
.leads__emoji.--manager {
  top: 0;
  left: 0;
  width: 35px;
}
@media only screen and (max-width: 1022px) {
  .leads__emoji.--manager {
    width: 20px;
  }
}
@media only screen and (max-width: 1022px) {
  .leads__header.--header {
    padding-top: 70px;
  }
}
.leads__plan {
  position: absolute;
  top: 75px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #666;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
.leads__plan:hover {
  background-color: #000;
}
.leads__plan img {
  max-width: 80%;
}
.leads__plan figcaption {
  position: absolute;
  display: flex;
  align-items: center;
  line-height: 0.8;
  text-align: right;
  right: 45px;
}
@media only screen and (max-width: 1439px) {
  .leads__plan figcaption {
    display: none;
  }
}
.leads__eagle {
  min-height: 0;
  flex-direction: column;
}
.leads__eagle .--squares--item {
  margin-bottom: 20px;
  user-select: none;
  width: 100%;
  padding: 5px 10px;
  border-width: 0 0 1px 5px;
  border-bottom-color: #B2B2B2;
}
@media only screen and (max-width: 1022px) {
  .leads__eagle .--squares--item {
    flex-wrap: nowrap;
  }
}
.leads__eagle .--squares--item h3 {
  text-align: right;
}
.leads__eagle .--squares--avatar {
  height: 45px;
  margin: -10px 10px 5px -6px;
  min-height: 45px;
  width: 45px;
  min-width: 45px;
}
.leads__eagle .leads__emoji {
  left: -10px;
}
.leads__eagle .--squares--maindata {
  flex-grow: 2;
  padding: 0 10px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1247px) {
  .leads__eagle .--squares--maindata {
    width: 100%;
  }
}
.leads__eagle .--squares--data {
  position: absolute;
  right: -15px;
  top: -20px;
}
.leads__eagle .--squares--data-extra {
  display: none;
}
.leads__eagle .leads__score {
  transform: scale(0.8);
}
.leads__disabled {
  background: #F1F1F1;
}
.leads__disabled.--squares--item:hover {
  border-color: #CCC;
  background: #F1F1F1;
}
.leads__disabled h3, .leads__disabled .--squares--data-extra {
  opacity: 0.6;
  filter: saturate(0);
}
.leads__disabled .--squares--avatar--img,
.leads__disabled .--squares--avatar--no {
  filter: brightness(1) saturate(0);
}
.leads__disabled .leads__score img {
  filter: brightness(0.95);
}
.leads__disabled .leads__score figcaption {
  filter: saturate(0) brightness(0.8);
}
.leads__score {
  position: relative;
  width: 90%;
  max-width: 120px;
  background-color: #F1F1F1;
  height: 21px;
}
.leads__score img {
  top: -2px;
  height: 25px;
  max-width: 120px;
  position: absolute;
  z-index: 1;
}
.leads__score figcaption {
  transition: width 0.5s linear;
  background-image: linear-gradient(to top, #febc44 30%, #ffe44a 50%);
  display: block;
  position: absolute;
  top: -1px;
  left: 1px;
  opacity: 0;
  width: 118px;
  height: 21px;
  border-right: 1px solid rgba(202, 74, 72, 0.4);
  max-width: calc(100% - 2px);
}
.leads__score .--loaded + figcaption {
  opacity: 1;
}
.leads__owner {
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 50%;
  border: 1px solid #E5E5E5;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.leads__owner img {
  max-width: 44px;
}
.leads__alert {
  width: 25px;
  height: 25px;
  right: 0;
  top: 1px;
  background: #c12900;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leads__alert.--calendario {
  cursor: default;
  background: #de8c0d;
}
.leads__alert__icon {
  height: 15px;
  filter: brightness(2);
}
.leads__summary {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #332e7d;
  cursor: pointer;
  padding: 3px 5px;
  border: 1px solid #FFF;
  transition: border 0.2s linear;
  border-radius: 10px;
}
.leads__summary:hover {
  border-color: #332e7d;
}
.leads__summary:hover .leads__summary__close {
  opacity: 1;
}
.leads__summary__avatar {
  display: flex;
  align-items: center;
  padding-right: 20px;
  flex-grow: 2;
}
.leads__summary__avatar img {
  max-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
.leads__summary__close {
  max-width: 20px;
  height: 20px;
  opacity: 0;
  filter: saturate(0);
  transition: opacity 0.2s linear;
}

.lead-filters__icon {
  width: 20px;
  margin-right: 10px;
}

.lead-manager .--mini .page-modal__title {
  font-size: 2rem;
}
.lead-manager .--mini .page-modal__main {
  max-width: unset;
  padding: 0;
  border: 0;
  flex-basis: 100%;
}
.lead-manager__name {
  flex-grow: 2;
  margin-left: 10px;
}
.lead-manager__tagstitle {
  display: flex;
  align-items: center;
}
.lead-manager__tags {
  padding: 20px 1vw 10px 2vw;
}
@media only screen and (max-width: 1022px) {
  .lead-manager__tags .tags__content {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1022px) {
  .lead-manager__tags .tags__item {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1022px) {
  .lead-manager horus-tag-autocomplete {
    order: 2;
  }
}
.lead-manager__newtag {
  width: 30px;
  padding-left: 10px !important;
  margin-left: 10px !important;
  border-left: 1px solid #F1F1F1 !important;
  cursor: pointer;
}
@media only screen and (max-width: 639px) {
  .lead-manager__newtag {
    order: 1;
  }
}
.lead-manager__logs {
  margin-left: 10px;
}
.lead-manager__logs__title {
  font-size: 1.2rem;
  color: #999;
}
.lead-manager__logs h3.lead-manager__edittitle {
  margin: 0;
  font-size: 1.4rem;
  max-width: 85%;
}
.lead-manager__notas {
  min-height: 200px;
}

horus-duplicated-lead {
  display: block;
  width: 100%;
}

.duplicated-lead {
  background-color: #c12900;
  width: 100%;
  padding: 10px;
  font-family: special, Arial, sans-serif;
  border-radius: 10px;
  margin: 10px 0;
  font-weight: bold;
}
.duplicated-lead__selection {
  width: 23px;
  cursor: pointer;
  filter: brightness(3);
  opacity: 0.6;
}
.duplicated-lead__selection:hover {
  opacity: 1;
}
.duplicated-lead.--warning {
  background-color: #fff6ac;
  color: #024624;
}
.duplicated-lead.--mine {
  background-color: #024624;
  color: #FFF;
}
.duplicated-lead ul {
  margin: 10px 0 0 0;
}
.duplicated-lead li {
  display: flex;
  align-items: center;
  margin: 5px;
}
.duplicated-lead li:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFF;
}
.duplicated-lead em {
  font-size: 0.9em;
}
.duplicated-lead a {
  font-family: headers, Arial, sans-serif;
  padding: 0 5px;
  color: #FFF;
}
.duplicated-lead a:hover {
  color: #ff6a00;
}

.partners {
  margin-top: 30px;
}
.partners .--squares--item {
  border-width: 0 1px 0 0;
  border-radius: 0;
  align-items: center;
  transition: filter 0.2s linear;
  padding: 0 10px 0 0;
}
.partners .--squares--item:hover {
  background: transparent;
}
.partners .--squares--item:hover h3 {
  filter: brightness(0.8);
}
.partners .--squares h3 {
  font-size: 2rem;
  color: #77c2ff;
  filter: brightness(0.5);
}
.partners-manager__ranges {
  display: flex;
  flex-wrap: wrap;
}
.partners-manager__ranges h4 {
  min-width: 100%;
  margin: 10px 0 5px;
}
.partners-manager__ranges .mat-mdc-form-field {
  width: calc(100% - 40px);
}
.partners-manager__ranges__section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 44, 255, 0.6);
}
.partners-manager__ranges .leads__emoji {
  position: relative;
  top: 0;
  left: 0;
  margin: 5px 5px 5px 0;
  width: 20px;
}
.partners-manager__licenses {
  flex-wrap: wrap;
  margin-bottom: 30px;
  border: solid #E5E5E5;
  border-width: 1px 0;
  padding: 20px 0;
}
.partners-manager__licenses h2 {
  margin: 0;
  width: 100%;
  color: #666;
}
.partners-manager__licenses .mat-mdc-form-field {
  width: 15%;
  margin: 10px 2% 0 0;
}
.partners-manager__licenses-info {
  text-align: center;
  padding: 10px;
  margin: 0 10px 0 0;
  border-left: 1px solid #B2B2B2;
}
.partners-manager__licenses-info:nth-child(2) {
  border: none;
  padding-left: 0;
}
.partners-manager__licenses .--limit {
  color: #c12900;
}
.partners-manager__licenses strong {
  display: block;
  font-family: headers, Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  color: #666;
  margin-bottom: 0;
}
.partners-manager__licenses small {
  font-weight: normal;
  font-family: special, Arial, Helvetica, sans-serif;
  font-size: 0.7em;
  color: #999;
}
.partners-manager__licenses em {
  font-size: 1.1rem;
}
.partners-manager__banner {
  max-width: 100%;
  height: auto;
}
.partners-manager__sign {
  min-height: 300px;
  font-size: 1rem !important;
  line-height: 1 !important;
  color: #666 !important;
}

.teams {
  margin-top: 30px;
}
.teams .--squares {
  padding: 10px;
}
.teams .--squares--item {
  padding-bottom: 10px;
  border-width: 0 0 0 1px;
}
.teams .--squares--maindata {
  display: flex;
  align-items: center;
}
.teams .--squares--maindata--name {
  font-size: 2rem;
  color: #ffbe00;
  filter: brightness(0.9);
}
.teams__name {
  margin-bottom: 20px;
}

.users__role {
  border-width: 3px 0 0 0;
  border-style: solid;
  padding: 6px 0 0;
  border-radius: 2px;
  font-size: 10px;
}
.reports .users__role {
  color: transparent;
  font-size: 0;
}

.user-manager__name {
  border-left: 1px solid #CCC;
  margin-left: 10px;
  padding-left: 10px;
}
@media only screen and (max-width: 559px) {
  .user-manager__name {
    width: 100%;
  }
}
.user-manager__name .mat-mdc-form-field {
  margin: 0;
}
.user-manager__calendar {
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
}
.user-manager__calendar__img {
  width: 30px;
  margin-right: 10px;
}
.user-manager__calendar__revert {
  display: block;
  font-weight: bold;
  cursor: pointer;
}
.user-manager__calendar__revert:hover {
  color: #ff58ea;
}
.user-manager__msgs {
  margin: 10px 0;
  display: block;
  font-family: "special", Arial, sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  color: #1f6d1b;
}
.user-manager__msgs strong {
  font-family: "headers", Arial, sans-serif;
}
.user-manager__nosharing {
  color: #ff5e00;
}
.user-manager .--clickable {
  cursor: pointer;
  color: #002cff;
  transition: color 0.2s linear;
}
.user-manager .--clickable:hover {
  color: #133866;
}

.tags__content {
  margin: 2vh auto 5vh;
}
.tags__item {
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px 0 10px 0;
  margin: 0 2vw 2vw 0;
  user-select: none;
  position: relative;
}
.tags__item.--close {
  margin: 0 20px 30px 0;
}
.tags__avatar {
  margin-left: -5px;
}
.tags__avatar img {
  max-width: 20px;
  margin-right: 5px;
}
.tags__data h4 {
  margin: 10px auto;
  color: #7F7F7F;
}
.tags__remove {
  background: #FFF;
  border-radius: 50%;
  border: 1px solid #CCC;
  height: 20px;
  width: 20px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
}
.tags__remove img {
  max-width: 10px;
}

.templates .basic-filters {
  margin: 0;
}
.templates__content {
  margin: 0 auto 2vh;
}
.templates__content:after {
  content: "";
  flex-grow: 2;
}
@media only screen and (max-width: 1022px) {
  .templates__content {
    justify-content: space-around;
  }
}
.templates__content-title {
  margin: 30px 0 30px;
  font-size: 1.7rem;
  color: #753673;
  width: 100%;
  opacity: 0.8;
  border-bottom: 1px #753673 solid;
}
.templates-manager .--disabled fieldset, .templates-manager .--disabled .profile-form--right {
  filter: saturate(0.25);
}
@media only screen and (max-width: 1022px) {
  .templates-manager .profile-form__container {
    padding-left: 0;
  }
}
.templates-manager__no-report {
  color: #c12900;
  min-width: 80%;
  margin: 10px 0 20px;
  font-family: headers, Arial, sans-serif;
}
.templates-manager .form__warning {
  position: relative;
  font-family: headers, Arial, sans-serif;
  bottom: unset;
  padding: 20px 0;
}
.templates-manager__left {
  width: 100%;
}
.templates-manager__left .profile-form__container {
  padding-left: 0;
}
.templates-manager__clone {
  display: flex;
  font-size: 1.2rem;
  transition: color 0.1s linear;
  align-items: center;
  width: max-content;
}
@media only screen and (max-width: 1022px) {
  .templates-manager__clone {
    margin-bottom: 30px;
  }
}
.templates-manager__clone:hover, .templates-manager__clone:focus {
  color: #a86200;
}
.templates-manager__clone img {
  width: 30px;
  margin-right: 10px;
}
.templates-manager .form__warning {
  color: #FFF;
  padding: 0 0 20px;
}
.templates-manager__title {
  width: 100%;
  justify-content: flex-start;
}
@media only screen and (max-width: 1022px) {
  .templates-manager__title {
    flex-direction: row;
  }
}
@media only screen and (max-width: 559px) {
  .templates-manager__title {
    position: relative;
    top: -50px;
    left: 0;
    margin-bottom: -30px;
    flex-direction: column;
    align-items: flex-start;
  }
}
h2 .templates-manager__owner {
  position: relative;
  bottom: unset;
  right: unset;
  left: -20px;
  display: inline-block;
}
@media only screen and (max-width: 559px) {
  h2 .templates-manager__owner {
    left: 0;
    padding-left: 0;
  }
}
.templates-manager__owner-name {
  margin-left: 10px;
  font-size: 1.5rem;
}
@media only screen and (max-width: 559px) {
  .templates-manager__owner-name {
    margin-left: 0;
    padding-left: 0 !important;
  }
}
.templates__item-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.templates__item {
  position: relative;
  transition: all 0.1s linear;
}
.templates__item__media {
  min-width: 40px;
}
.templates__item__media-item {
  border: 0;
  margin: 2vh auto;
  max-width: 90%;
  min-width: 400px;
  position: relative !important;
}
.templates__item__media-none {
  filter: saturate(0);
}
.templates__item__media-open {
  cursor: pointer;
  opacity: 0.5;
  filter: saturate(0.5);
}
.templates__item__media-open:hover {
  opacity: 1;
  filter: none;
}
.templates__item__toggle-hide {
  color: #859836;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}
.templates__item__toggle-hide.--hide {
  filter: saturate(0);
  opacity: 0.5;
}
.templates__item__hideicon {
  max-width: 30px;
  cursor: pointer;
}
.templates__item.editable:hover .circle-link, .templates__item.editable:focus .circle-link {
  transform: scale(0.8);
}
.--squares .templates__item {
  box-shadow: 2px 2px 4px rgba(51, 51, 51, 0.3);
  width: 8vw;
  font-size: 0.9rem;
  min-width: 165px;
  min-height: 180px;
  border: 1px solid #CCC;
  margin: 0 2vw 2vh 0;
  padding: 10px 20px;
  filter: saturate(0);
}
@media only screen and (max-width: 559px) {
  .--squares .templates__item {
    min-width: 135px;
  }
}
.--squares .templates__item__container {
  padding: 10px;
  display: block;
}
.--squares .templates__item h3 {
  color: #666;
}
.--squares .templates__item.editable {
  filter: none;
}
.--squares .templates__item.editable h3 {
  color: inherit;
}
.--squares .templates__item.editable:hover a, .--squares .templates__item.editable:focus a {
  color: #000;
}
.--squares .templates__item .templates__owner {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.--squares .templates__item-row {
  width: auto;
}
.--squares .templates__item__media {
  display: none;
}
.--tables .templates__item {
  border-bottom: 1px solid #CCC;
  font-size: 1.2rem;
  margin: 0 auto;
  width: 100%;
}
.--tables .templates__item:hover a, .--tables .templates__item:focus a {
  color: #000;
}
.--tables .templates__item-row .--hidden {
  display: none;
}
.--tables .templates__item a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.--tables .templates__item h3 {
  flex-grow: 2;
  color: unset;
  border: 0;
}
.--tables .templates__item .templates__fake-text {
  display: none;
}
.--tables .templates__item .templates__owner {
  position: relative;
}
.templates__item.--done {
  filter: none;
}
.templates__item.--done:hover {
  transform: none;
  filter: none;
}
.templates__item .circle-link {
  position: absolute;
  top: -25px;
  right: -35px;
  transform: scale(0.6);
  background: #B2B2B2;
}
.templates__item .circle-link:hover {
  background: #333;
}
.templates__item .circle-link:hover img {
  filter: none;
}
.templates__item .circle-link img {
  filter: brightness(0);
}
.templates__item .circle-done-link {
  cursor: pointer;
}
.templates__item .circle-done-link img {
  right: 0;
  top: 0;
  width: 20px;
  filter: none;
}
.templates h3 {
  min-height: 36px;
}
.templates__last {
  color: #000;
  font-size: 0.9em;
  font-family: basic, Arial, sans-serif;
}
.templates__icons-list {
  display: flex;
}
.templates__icons-list img {
  width: 25px;
  margin: 0 10px 0 0;
}
.templates__icons {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #B2B2B2;
  font-family: basic, Arial, sans-serif;
  font-size: 2.2rem;
  color: #7F7F7F;
  padding-bottom: 10px;
}
.templates__icons img {
  width: 30px;
  margin: 0 10px 0 0;
}
.templates__fake-text {
  display: block;
  margin: 10px 0;
  background-color: #F1F1F1;
  height: 10px;
  width: 100%;
  transition: background-color 0.2s linear;
}
.templates__owner img {
  max-width: 44px;
}
.templates horus-wysiwyg {
  display: block;
  margin: 0;
}
.templates .--uploader-file {
  position: absolute;
  right: -9%;
  top: 10%;
  height: 90%;
  width: 60px;
  background-size: 35px;
  background-position: center center;
  display: block;
}
.templates__attachments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 20px auto;
  border: 1px solid #E5E5E5;
}
.templates__attachments h4 {
  color: #B2B2B2;
}
.templates__attachments div {
  display: flex;
}
.templates__attachments a {
  width: 40px;
  margin: 0 30px;
  text-align: center;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.templates__attachments a:hover span, .templates__attachments a:focus span {
  color: #000;
}
.templates__attachments span {
  display: block;
  padding-top: 5px;
}

horus-templates-list .--no-results {
  margin: 30px 0 20px;
}

.actions__content {
  margin: 2vh auto 40px;
}
.actions__content:after {
  content: "";
  flex-grow: 2;
}
.actions-manager__owner {
  position: relative;
  bottom: unset;
  right: unset;
  left: -20px;
  display: inline-block;
}
.actions-manager__owner img {
  max-width: 44px;
}
.actions-manager__owner-name {
  width: 100%;
  font-size: 1.5rem;
  margin: -19px 0 0 -10px;
}
.actions-manager__container {
  flex-direction: column;
}
.actions-manager__container > div {
  width: 100%;
}
.actions-manager__container .textarea {
  font-size: 1.7rem;
}
.actions-manager__container .--tooltip {
  right: 0;
}
.actions-manager__score.header2 {
  max-height: unset;
  text-align: center;
}
.actions-manager__score.header2 .mat-mdc-input-element {
  color: #a86200;
  margin: 0 auto 5px;
  text-align: center;
  width: 130px;
  height: 6rem !important;
  font-size: 6rem !important;
}
.actions-manager__score.header2 .mat-mdc-form-field-infix {
  display: flex;
}
.actions-manager__score-legend {
  color: #B2B2B2;
  font-size: 1.3rem;
  line-height: 1;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin: -10px auto 20px;
}
.actions-manager__score-legend img {
  width: 20px;
}
.actions__item__circle {
  position: relative;
  min-height: 130px;
  margin: 30px 27px 30px 0;
  font-family: headers, Arial, sans-serif;
  padding: 10px;
  width: 130px;
  min-width: 130px;
  font-size: 1.4rem;
  line-height: 0.8;
  border-radius: 50%;
  transition: all 0.1s linear;
  user-select: none;
  cursor: auto;
}
@media only screen and (max-width: 1022px) {
  .actions__item__circle {
    max-width: 46%;
    margin: 0 2.35% 5vh;
  }
}
.actions__item__circle__bk {
  content: "";
  position: absolute;
  width: 138%;
  height: 124%;
  display: block;
  left: -15px;
  top: -30px;
  background-image: url(/assets/img/icons/accion_bola.svg);
  background-repeat: no-repeat;
}
.--horus .actions__item__circle__bk {
  background-image: url(/assets/img/icons/accion-horus.svg);
}
.actions__item__circle__container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #7F7F7F;
  font-size: 1.2rem;
  word-break: break-word;
  z-index: 0;
  position: relative;
}
.actions__item__circle.editable:hover {
  transform: scale(1.05);
  filter: saturate(0.8);
  cursor: pointer;
}
.actions__item__circle:hover a {
  color: #000;
}
.actions__item__circle:hover a .actions__item__score {
  color: #000;
}
.actions__item__circle:hover img {
  filter: saturate(2);
}
.actions__item__circle__score {
  align-items: center;
  padding: 5px;
  margin: 0 auto 3px;
  line-height: 1;
  font-size: 2rem;
  color: #999;
  min-height: 28px;
}
.actions__item__circle__score small {
  font-size: 1rem;
  font-family: basic, Arial, sans-serif;
}
.actions__item__circle__owner {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.actions__item__circle__owner img {
  max-width: 35px;
}
.actions__item__circle .circle-link {
  position: absolute;
  top: -10px;
  right: -25px;
  transform: scale(0.6);
  background: #B2B2B2;
}
.actions__item__circle .circle-link:hover {
  background: #333;
}
.actions__item__circle .circle-link:hover img {
  filter: none;
}
.actions__item__circle .circle-link img {
  filter: brightness(0);
}
.actions__item__group {
  position: relative;
  margin: 0 10px 10px;
  font-family: headers, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 0.8;
  transition: all 0.1s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  cursor: auto;
  padding: 10px;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #CCC;
}
@media only screen and (max-width: 639px) {
  .actions__item__group {
    margin: 0 2vw 10px;
  }
}
.actions__item__group:hover {
  border: 1px solid #666;
}
.actions__item__group:hover .actions__item__group__title {
  opacity: 1;
}
.actions__item__group__title {
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.2rem;
  opacity: 0.4;
  transition: opacity 0.2s linear;
  text-align: center;
  max-width: 100px;
  margin: 0 0 20px;
  min-height: 25px;
  display: flex;
  align-items: center;
}
.actions__item__group__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: 0 10px;
  color: #000;
  cursor: pointer;
  min-width: 85px;
  transition: transform 0.2s linear;
}
.actions__item__group__container:hover {
  transform: scale(1.1);
}
.actions__item__group__container:hover .actions__item__group__owner {
  opacity: 1;
}
.actions__item__group__container.--done:hover .actions__item__group__channel {
  transform: none;
}
.actions__item__group__options {
  display: flex;
}
.actions__item__group__channel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 50%;
}
.--done .actions__item__group__channel {
  background: #F1F1F1;
}
.actions__item__group__channel__icon {
  height: 50%;
}
.--done .actions__item__group__channel__icon {
  filter: saturate(0);
}
.actions__item__group__bk {
  position: absolute;
  width: 40px;
  background-image: url(/assets/img/icons/accion_bola.svg);
  background-repeat: no-repeat;
  background-size: cover;
  top: -18px;
  left: -13px;
  height: 65px;
}
.actions__item__group__score {
  font-family: "headers", Arial, sans-serif;
  font-size: 2rem;
  color: #999;
}
.actions__item__group__score small {
  font-family: basicregular, Arial, sans-serif;
  font-size: 0.6em;
}
.actions__item__group__done {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -15%;
  left: 50%;
  font-size: 0.9rem;
  margin-left: 12px;
}
.actions__item__group__done figcaption {
  margin-top: 23px;
}
.actions__item__group__done:hover img {
  filter: none;
}
.actions__item__group__done img {
  width: 15px;
  filter: none;
}
.actions__item__group__owner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: basic, Arial, sans-serif;
  text-align: left;
  margin: 11px 5px;
  opacity: 0.6;
  transition: opacity 0.2s linear;
}
.actions__item__group__owner img {
  max-width: 25px;
  margin-right: 5px;
}
.actions__item__group__owner .--done {
  opacity: 0.4;
}
.actions__item__group__owner .--done:hover .actions__item__group__owner .--done {
  opacity: 0.4;
}

horus-actions-list .--no-results {
  margin: -30px 0 20px;
}

@media only screen and (max-width: 1022px) {
  .reporting {
    max-width: none;
    margin: auto;
    padding: 10px;
  }
  .reporting h2, .reporting .mat-mdc-form-field {
    margin: 0 auto 20px;
    justify-content: center;
    text-align: center;
  }
}
.reporting .page-modal__content {
  min-height: 300px;
}
.reporting__contacts {
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: center;
}
.reporting__contact {
  position: relative;
  margin: 10px 1%;
  padding: 0 1% 0 5px;
  width: 104px;
  font-size: 1rem;
  color: #333;
  border-right: 1px solid #CCC;
}
.reporting__contact:last-child {
  border-color: transparent;
}
.reporting__contact.--disabled {
  opacity: 0.5;
  filter: saturate(0);
  color: #7F7F7F;
}
.reporting__contact figure {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.reporting__contact img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border: 1px solid #CCC;
  margin-bottom: 5px;
  object-fit: cover;
}
.reporting__contact figcaption {
  text-align: center;
}
.reporting__comment {
  width: 100%;
  margin: 20px 0;
}
.reporting__allowed {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  border-radius: 50%;
  padding: 2px;
  top: 0;
  max-height: 24px;
  max-width: 24px;
  transition: transform 0.2s linear;
  cursor: pointer;
}
.reporting__allowed:hover {
  transform: scale(1.8);
}
.reporting__allowed.--disabled {
  filter: saturate(0);
  cursor: default;
}
.reporting__allowed.--disabled:hover {
  transform: none;
}
.reporting__allowed img {
  border: none;
  border-radius: 0;
  width: 20px;
  height: 20px;
  margin: 0;
}
.reporting__allowed.--email {
  left: 5px;
}
.reporting__allowed.--mobile {
  right: 10px;
}
.reporting .createbutton {
  border: 1px solid #CCC;
  padding: 10px 20px;
  margin: auto;
}
.reporting__title {
  margin: 0;
  font-size: 2rem;
  width: 100%;
  text-align: center;
}
.reporting__multiple {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
@media only screen and (max-width: 1022px) {
  .reporting__multiple {
    flex-direction: column;
  }
}
.reporting__multiple .reporting__contacts {
  justify-content: flex-start;
}
.reporting__multiple section {
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1022px) {
  .reporting__multiple section {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.reporting__report {
  width: 100%;
  padding-bottom: 20px;
  margin: 0 auto 25px;
  border-bottom: 1px solid #CCC;
}
@media only screen and (max-width: 1022px) {
  .reporting__report {
    width: 96%;
  }
}
.reporting__report__title {
  font-size: 1.3rem;
  color: #999;
  margin: 10px auto 0;
}
.reporting__report__title img {
  width: 20px;
  margin-right: 10px;
  filter: saturate(0);
}
.reporting__report__title h3 {
  font-size: 1.5rem;
  color: #999;
  margin: 0 10px 0 0;
}
.reporting__report__title strong {
  margin: 0 10px;
  font-size: 2rem;
  color: #000;
}
.reporting__report__title span {
  display: block;
  padding-top: 3px;
}
.reporting__report__title small {
  font-family: basicregular, Arial, sans-serif;
  font-size: 0.6em;
}
.reporting__report__title.disabled h3 {
  text-decoration: line-through;
}
.reporting__report__error {
  margin-left: 10px;
  color: #c12900;
  font-size: 1.1rem;
  max-width: 250px;
}
.reporting__report .profile-form__container {
  max-width: 86%;
  padding: 20px 10px 20px 0;
  margin: auto;
}
.reporting__report__remove {
  position: absolute;
  border-radius: 50px;
  display: flex;
  top: 0;
  left: calc(50% + 10px);
  opacity: 0.4;
  padding: 5px;
  height: 30px;
}
.reporting__report__remove img {
  width: 15px;
  filter: saturate(0) brightness(0);
}
.reporting__report__ctas {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}
.reporting__report__ctas .--link {
  margin: 0 10px;
  opacity: 0.5;
}
.reporting__report .--selected-contact {
  position: relative;
  cursor: pointer;
}
.reporting__report .--selected-contact:hover figure {
  opacity: 0.6;
}
.reporting__report .--selected-contact:hover .reportingM__remove {
  opacity: 1;
}
.reporting__report small {
  color: #CCC;
  font-weight: lighter;
}
.reporting__info {
  padding-bottom: 10px;
  border-bottom: 1px solid #E5E5E5;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.reporting__well-done {
  color: #FFF;
  font-size: 3.5vw;
  margin: auto;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
@media only screen and (max-width: 1247px) {
  .reporting__well-done {
    font-size: 4.5vw;
  }
}
@media only screen and (max-width: 639px) {
  .reporting__well-done {
    font-size: 50px;
  }
}
@media only screen and (max-width: 639px) {
  .reporting__well-done {
    font-size: 35px;
  }
}
.reporting__well-done span {
  display: block;
  font-size: 0.6em;
  color: #000;
}
.reporting__well-done h2 {
  margin: 0 0 20px;
}
.reporting__well-done small {
  font-size: 0.4em;
}
.reporting horus-wysiwyg {
  font-size: 1.1rem;
  margin: 10px 0 0;
}
.reporting horus-wysiwyg .ck-editor__editable {
  border: 1px solid #E5E5E5;
}
.reporting horus-wysiwyg .mat-mdc-form-field {
  display: none;
}
.reporting__pending {
  font-style: italic;
  text-transform: uppercase;
  color: #ff58ea;
  max-width: 80%;
  text-align: center;
  margin: 0;
}
.reporting-pending__toggle {
  position: absolute;
  top: 72px;
  right: 10px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #333;
  transition: background-color 0.2s linear;
}
@media only screen and (max-width: 559px) {
  .reporting-pending__toggle {
    transform: scale(0.6);
    top: 60px;
    right: 0;
  }
  .reporting-pending__toggle figcaption {
    display: none;
  }
}
.reporting-pending__toggle:hover {
  background: #ff5886;
}
.reporting-pending__toggle:hover img {
  filter: saturate(0) brightness(2);
}
.reporting-pending__icon {
  transition: filter 0.2s linear;
  width: 80%;
}
.reporting-pending__text {
  font-size: 1rem;
  color: #febc44;
  position: absolute;
  background: #333;
  text-align: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: -32px 0 0 -65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.reporting-pending__title {
  font-size: 2.5rem;
  font-family: special, Arial, sans-serif;
  display: flex;
  align-items: center;
}
.reporting-pending__title__icon {
  width: 45px;
  margin-right: 20px;
}
.reporting-pending__item {
  display: flex;
  padding: 20px 5px;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  width: 100%;
  border-bottom: 1px solid #B2B2B2;
  cursor: pointer;
}
.reporting-pending__item:hover {
  background-color: #F1F1F1;
}
.reporting-pending__item:hover strong {
  color: #000;
}
.reporting-pending__item figure {
  display: flex;
  align-items: center;
  margin: 0;
}
.reporting-pending__item__position {
  border: 1px solid #666;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: headers, Arial, sans-serif;
  font-weight: bold;
  height: 20px;
  min-width: 20px;
  font-size: 1rem;
  color: #666;
  margin-right: 10px;
}
.reporting-pending__item__title {
  flex-grow: 2;
  font-size: 1.5rem;
  padding-right: 10px;
  color: #666;
}
.reporting-pending__item__desc {
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid #B2B2B2;
}
@media only screen and (max-width: 559px) {
  .reporting-pending__item__desc {
    padding: 0;
    border: 0;
  }
}
.reporting-pending__item__type-icon {
  width: 25px;
  margin: 0 10px 0 0;
}
.reporting-pending__item__icon {
  width: 20px;
  filter: none !important;
  margin: 0 10px;
}

.communications__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.communications__content.--with-plan {
  border-style: solid;
  border-image-slice: 1;
  border-width: 0 0 1px;
  border-image-source: linear-gradient(to right, rgba(117, 54, 115, 0.3), rgba(0, 0, 0, 0));
  margin-bottom: 30px;
}
.communications__content.--with-plan img {
  display: none;
}
.communications .active {
  margin: 10px;
  border: solid #E5E5E5;
  border-width: 0 1px;
}
.communications .active img {
  max-width: 30px;
  display: block;
}
.communications .active h3 {
  margin: 10px;
}
.communications__item {
  width: 90%;
  max-width: 100px;
  margin: 20px;
  text-align: center;
  user-select: none;
}
.communications__icons-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.communications__plans__title {
  font-size: 2rem;
  color: #B2B2B2;
  display: flex;
  align-items: center;
}
.communications__plans__title .--link {
  width: 40px;
  display: flex;
  align-items: center;
  border-left: 1px solid #B2B2B2;
  margin-left: 10px;
  padding-left: 10px;
}
.communications__plans__title .--link img {
  max-width: 100%;
}

.basic-plan {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.basic-plan__left {
  width: 70%;
  padding: 0 3% 0 0;
}
@media only screen and (max-width: 1022px) {
  .basic-plan__left {
    width: 100%;
    padding: 0 2%;
  }
}
.basic-plan__right {
  width: 30%;
  padding: 0 0 0 3%;
  border-left: 1px solid #E5E5E5;
}
@media only screen and (max-width: 1022px) {
  .basic-plan__right {
    width: 100%;
    border: 0;
    padding: 0;
  }
}
.basic-plan__title {
  font-size: 1.5rem;
  color: #999;
  margin: 0 50px 30px 0;
  border-bottom: 1px solid #F1F1F1;
  width: 100%;
}
@media only screen and (max-width: 639px) {
  .basic-plan__title {
    width: 65%;
    min-height: 120px;
  }
}
.basic-plan__title span {
  font-size: 2.5rem;
  color: #000;
  display: block;
}
@media only screen and (max-width: 639px) {
  .basic-plan__title span {
    font-size: 2rem;
  }
}
.basic-plan__subtitle {
  font-family: headers, Arial, sans-serif;
  font-size: 2.2rem;
  color: #B2B2B2;
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .basic-plan__subtitle {
    font-size: 1.5rem;
  }
}
.basic-plan__edition {
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  min-height: 30px;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  display: inline-block;
  margin: 0 10px;
}
.basic-plan__edition .--active {
  filter: hue-rotate(250deg);
}
.basic-plan__total-score {
  position: absolute;
  top: -40px;
  right: 40px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  font-size: 2.5rem;
  font-family: headers, Arial, sans-serif;
  background: rgba(255, 228, 74, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.basic-plan__total-score small {
  font-size: 0.5em;
  font-family: basic, Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  .basic-plan__total-score {
    top: 0;
    right: 10px;
  }
}
.basic-plan .loader__container {
  position: relative;
  min-height: 250px;
}

.property-manager {
  margin-bottom: 100px;
}
.property-manager__form {
  margin-top: 25px;
  flex-direction: column;
}
.property-manager figure {
  display: flex;
  align-items: center;
  height: 130px;
}
.property-manager figcaption {
  white-space: pre-line;
  font-size: 1.2rem;
  margin-right: 30px;
}
.property-manager .mat-form-field-wrapper {
  width: 100%;
}
.property-manager .mdc-floating-label {
  width: 100%;
  min-height: 25px;
}
.property-manager .mat-mdc-radio-group {
  flex-wrap: wrap;
  border-bottom: 1px solid #CCC;
}
.property-manager .mat-mdc-radio-button {
  position: relative;
  text-align: left;
  padding: 5px 10px;
  display: flex;
  margin: 0;
  background: rgba(255, 255, 255, 0.8);
  height: 55px;
}
@media only screen and (max-width: 639px) {
  .property-manager .mat-mdc-radio-button {
    padding: 10px 0;
    width: 100%;
  }
}
.property-manager__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  width: 100%;
}
.property-manager__section__item {
  display: flex;
  align-items: center;
  width: 31%;
}
@media only screen and (max-width: 1022px) {
  .property-manager__section__item {
    width: 48%;
  }
}
@media only screen and (max-width: 639px) {
  .property-manager__section__item {
    width: 100%;
  }
}
.property-manager__avatar {
  position: relative;
  margin: 30px 0 0;
  padding: 0;
  height: 150px;
}
.property-manager__avatar horus-cropper {
  margin: 0 40px 0 0;
}
@media only screen and (max-width: 1022px) {
  .property-manager__avatar {
    height: 75px;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 639px) {
  .property-manager__avatar {
    margin: 0;
    height: 145px;
  }
}
.property-manager__extrainfo {
  margin-bottom: -20px;
}
.property-manager__address {
  max-width: 100%;
}
.property-manager__sameaddress {
  margin: 10px 0;
}
.property-manager #autocompleteMap {
  height: 250px;
}
.property-manager__icon {
  width: 20px;
  margin-right: 10px;
  filter: none;
}
@media only screen and (max-width: 1022px) {
  .property-manager__icon {
    margin-left: 20px;
  }
}

.reFiles h1 {
  color: #ffa98c;
}
.reFiles h1 img {
  width: 30px;
}
.reFiles h1 .--link {
  padding: 0 15px 0 0;
}
.reFiles__emoji {
  width: 30px;
  margin: 0 10px 0 0;
}
.reFiles-id, .reFiles-id .mat-mdc-input-element {
  border: solid #002cff;
  border-width: 0 0 0 1px;
  padding: 0 0 0 5px;
  margin: 0 10px 0 5px;
  letter-spacing: 1px;
  line-height: 1;
  font-size: 0.7em;
  max-width: 182px;
  color: #ffa98c !important;
  font-family: "headers", Arial, sans-serif !important;
}
.reFiles__header__nav a span {
  padding: 0;
}
.reFiles__list {
  display: flex;
  flex-wrap: wrap;
}
.reFiles__list__owner {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 1.1rem;
  font-weight: lighter;
  line-height: 1;
}
.reFiles__list__owner__avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.reFiles__item {
  width: 100%;
  max-width: 170px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 1.3rem;
  color: #000;
  overflow: visible;
  opacity: 0.9;
  border-width: 1px;
  background-color: #FFF;
  margin: 1%;
}
.reFiles__item:hover {
  transform: scale(1);
  border-color: #000;
  box-shadow: 0 0 4px rgba(0, 44, 255, 0.7);
  background: linear-gradient(224deg, #B2B2B2 4%, transparent 13%);
  opacity: 1;
}
@media only screen and (max-width: 1022px) {
  .reFiles__item {
    max-width: 29%;
    min-height: 180px;
    font-size: 1.5rem;
    margin: 2%;
  }
}
@media only screen and (max-width: 639px) {
  .reFiles__item {
    font-size: 1.5rem;
    min-height: 130px;
    max-width: 100%;
    margin: 2% 0;
  }
}
.reFiles__item.--no-link {
  cursor: auto;
}
.reFiles__item.--no-link:hover {
  transform: none;
}
.reFiles__item__link {
  width: 100%;
  height: 100%;
  padding: 10px 18px 25px 10px;
}
.reFiles__item__name {
  color: #133866;
  display: flex;
  align-items: center;
  margin-left: -20px;
  text-transform: capitalize;
}
.--squares .reFiles__item__name {
  align-items: flex-start;
}
.reFiles__item address {
  font-weight: normal;
  color: #7F7F7F;
  font-size: 0.8em;
  padding-top: 10px;
}
@media only screen and (max-width: 639px) {
  .reFiles__item address {
    font-size: 1.2rem;
  }
}
.reFiles__item .stars__score {
  max-width: 115px;
  min-height: 24px;
  margin-bottom: 0;
}
.reFiles__item__step {
  position: absolute;
  width: 100%;
  height: 30px;
  left: 0;
  bottom: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  color: #4C4C4C;
  font-size: 1.2rem;
}
.reFiles__item__step-color {
  border-radius: 50%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 5px;
  position: relative;
}
.reFiles__item__step-percent {
  padding: 0 10px;
  font-family: headers, Arial, sans-serif;
  font-size: 1.1em;
}
.reFiles__alert {
  width: 25px;
  height: 25px;
  background: #c12900;
  border-radius: 50%;
  margin: 5px;
}
.reFiles__alert-block {
  position: absolute;
  right: 0;
  width: 17px;
}
.--squares .reFiles__alert-block {
  top: 25px;
}
.eagle .reFiles__alert-block {
  top: -3px;
}
@media only screen and (max-width: 1022px) {
  .eagle .reFiles__alert-block {
    top: 0;
    width: 25px;
  }
}
.reFiles__alert.--calendario {
  background: #de8c0d;
}
.reFiles__alert.--legal {
  top: 3px;
  right: -7px;
  background: none;
}
.reFiles__alert.--regular {
  background: #e4c6ff;
}
.reFiles__alert__icon {
  filter: brightness(2);
}
.reFiles__eagle {
  min-height: unset;
}
.reFiles__eagle .reFiles__item {
  padding: 0;
  max-width: unset;
  height: auto;
  min-height: unset;
  margin: 0 auto 20px;
  border-width: 0 0 1px 5px;
  border-bottom-color: #B2B2B2;
  border-radius: 0;
  overflow: visible;
}
.reFiles__eagle .reFiles__item:hover {
  background: none;
}
.reFiles__eagle .reFiles__item__link {
  position: relative;
  width: 100%;
  padding-bottom: 0;
}
.reFiles__eagle .reFiles__item__name {
  margin-left: -20px;
  padding-right: 26px;
}
.reFiles__eagle .reFiles__item__step {
  width: 55px;
  left: unset;
  right: 0;
}
@media only screen and (max-width: 1022px) {
  .reFiles__eagle .reFiles__item__step {
    right: 20px;
  }
}
.reFiles__eagle .reFiles__item__step div {
  display: none;
}
.reFiles__eagle .reFiles__item__step-percent {
  padding: 0;
  background-color: transparent;
  color: #999;
  font-size: 0.8rem;
}
.reFiles__eagle .reFiles__item__step-percent strong {
  font-size: 1.4rem;
}
.reFiles__eagle .reFiles__item__step-color {
  display: none;
}
.reFiles__eagle .reFiles__alert {
  margin: -1px 2px 5px 2px;
}
.reFiles__eagle .reFiles__alert.--legal {
  margin: -1x 0 5px 3px;
}
.reFiles__eagle .reFiles__msgs {
  border-radius: 50%;
  background: #002cff;
  color: #FFF;
  font-size: 1.1rem;
  position: absolute;
  top: -1px;
  right: 18px;
  width: 25px;
  height: 25px;
}
.reFiles__eagle .reFiles__list__owner {
  max-width: calc(100% - 40px);
}
.reFiles__eagle address {
  padding-top: 5px;
  font-size: 1rem;
}
.reFiles__eagle .stars__score {
  margin-bottom: 5px;
}
@media only screen and (max-width: 1247px) {
  .reFiles__eagle .stars__score {
    width: 68px;
    margin: 5px 0;
    min-height: 0;
  }
  .reFiles__eagle .stars__score img {
    height: 14px;
  }
}
@media only screen and (min-width: 1023px) {
  .--table .reFiles__item:hover {
    background: none;
  }
  .--table .reFiles__list__owner {
    margin: 0;
  }
  .--table .reFiles__alert-block {
    top: unset;
    position: relative;
    right: unset;
    min-width: 90px;
    display: flex;
  }
  .--table .reFiles__remove {
    border: 0;
    top: 5px;
    right: 0;
  }
}

horus-new-re-file horus-property-manager {
  position: relative;
  z-index: 5;
}

.new-reFile__main-title {
  width: 100%;
}
.new-reFile__section {
  margin-bottom: 10px;
  padding: 10px 0;
}
.new-reFile__section.--right {
  margin-left: 2vw;
}
@media only screen and (max-width: 1022px) {
  .new-reFile__section.--right {
    margin-left: 0;
  }
}
.new-reFile__section small {
  font-family: special, Arial, sans-serif;
  font-size: 1.1rem;
  display: block;
  line-height: 1.1;
  letter-spacing: 0.025em;
  padding-top: 5px;
  width: 100%;
}
.new-reFile .profile-form--left {
  flex-basis: 60%;
  max-width: 60%;
}
.new-reFile .profile-form--right {
  flex-basis: 40%;
}
.new-reFile .profile-form {
  padding-bottom: 0;
}
@media only screen and (max-width: 1022px) {
  .new-reFile .profile-form--left, .new-reFile .profile-form--right {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.new-reFile .profile-form--base {
  width: 265px;
  margin-left: auto;
  margin-right: auto;
}
.new-reFile__title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 10px;
  color: #ffa98c;
  font-size: 1.7rem;
  font-family: headers, Arial, sans-serif;
}
.new-reFile__title small {
  color: #666;
}
.new-reFile__subtitle {
  width: 100%;
}
.new-reFile__name fieldset {
  flex-grow: 2;
  margin: -16px 0 0 5%;
}
@media only screen and (max-width: 1022px) {
  .new-reFile__name fieldset {
    width: 100%;
    margin: 0;
  }
}
.new-reFile__type .mat-mdc-radio-button {
  margin-right: 20px;
  font-size: 1.3rem;
}
.new-reFile__contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.new-reFile__contact-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.new-reFile__contact fieldset {
  flex-basis: 27%;
  min-width: 130px;
}
@media only screen and (max-width: 639px) {
  .new-reFile__contact fieldset {
    flex-basis: 100%;
  }
}
.new-reFile__contact horus-int-prefix {
  width: 40%;
}
@media only screen and (max-width: 639px) {
  .new-reFile__contact horus-int-prefix {
    width: 100%;
  }
}
.new-reFile__owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  filter: saturate(0);
  border: 1px solid #CCC;
  border-radius: 12px;
  cursor: pointer;
  margin: 0 10px 10px 0;
}
.new-reFile__owner strong {
  flex-grow: 2;
}
.new-reFile__owner .mat-mdc-select {
  width: 100px;
}
.new-reFile__owner:hover {
  filter: saturate(1);
}
.new-reFile__owner__remove {
  width: 15px;
  margin: 0 5px;
}
.new-reFile__properties {
  margin-top: 10px;
  width: 100%;
}
.new-reFile__properties__new {
  max-width: 25px;
}
.new-reFile__properties__new img {
  max-width: 100%;
  margin-left: 10px;
}
.new-reFile__properties__list {
  margin: 20px 0 40px 0;
}
.new-reFile__property {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F1F1F1;
  padding: 10px 0;
}
.new-reFile__property em {
  color: #006390;
}
.new-reFile__property:hover {
  border-color: #ffa98c;
}
.new-reFile__property__close {
  width: 15px;
}
.new-reFile__notes {
  padding-top: 0;
  margin-top: 16px;
}
.new-reFile__notes .textarea {
  height: 74px;
}

.reFile__error {
  font-size: 1.2rem;
  color: #c12900;
  font-family: "basic", Arial, sans-serif;
  margin: 10px 0;
}
.reFile__error .--b {
  display: block;
}
.reFile__user-role {
  margin: 3px 1px;
  color: #FFF;
  padding: 2px 3px;
  font-size: 1.1rem;
  background: #333;
}
.reFile__user-role.--generado {
  background: #715e98;
}
.reFile__user-role.--captación {
  background: #6b791d;
}
.reFile__user-role.--administración {
  background: #914186;
}
.reFile__summary {
  margin-top: 30px;
  border-bottom: 1px solid #CCC;
  box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 639px) {
  .reFile__summary {
    margin-top: 22px;
  }
}
.reFile-manager__owner {
  display: flex;
  font-size: 2rem;
  align-items: center;
}
.reFile-manager__owner__name {
  color: #666;
  margin-right: 6px;
  font-family: basic, Arial, sans-serif;
  text-transform: none;
  font-weight: bold;
  font-size: 0.8em;
}
.reFile-manager__header {
  position: relative;
  padding: 0 5vw;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.reFile-manager__header-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.reFile-manager__name {
  font-family: "headers", Arial, sans-serif;
  font-size: 2rem;
  font-weight: normal;
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}
@media only screen and (max-width: 639px) {
  .reFile-manager__name {
    width: 100%;
    font-size: 1.5rem;
  }
}
.reFile-manager__icon {
  margin: 3px 5px 3px 0;
  width: 30px !important;
  height: 30px !important;
  background: #333;
  padding: 3px;
  border-radius: 50%;
}
@media only screen and (max-width: 1022px) {
  .reFile-manager__icon {
    margin: 3px 5px 3px 0;
    width: 27px;
    min-height: 27px;
  }
}
.reFile-manager__summary-data {
  border-left: 1px solid #B2B2B2;
  display: flex;
  align-items: center;
  max-width: 40%;
  padding: 5px 10px;
}
.reFile-manager__summary-data.--blocked {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  filter: saturate(0);
  transition: filter 0.2s linear;
}
.reFile-manager__summary-data.--blocked:hover, .reFile-manager__summary-data.--blocked.--selected {
  filter: none;
}
.reFile-manager__summary-data__txt-blocked {
  color: #c12900;
  text-align: right;
  padding-right: 10px;
  width: 70px;
}
@media only screen and (max-width: 1022px) {
  .reFile-manager__summary-data.--property {
    max-width: 60%;
    flex-grow: 2;
  }
}
@media only screen and (max-width: 639px) {
  .reFile-manager__summary-data.--property {
    padding-left: 0;
    border: 0;
  }
}
.reFile-manager__summary-data-info {
  font-size: 1.3rem;
  color: #333;
  display: flex;
  align-items: center;
}
.reFile-manager__summary-data address {
  display: inline;
  font-size: 1rem;
  padding-right: 5px;
}
.reFile-manager__ad {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reFile-manager__ad img {
  max-width: 25px;
}
.reFile-manager__edition {
  min-width: 30px;
  min-height: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #666;
  margin: 5px 0;
}
.reFile-manager__modal {
  position: relative;
  font-size: 1.2rem;
}
.reFile-manager__modal .--precio {
  font-size: 2rem !important;
}
.reFile-manager__head {
  margin-bottom: 0;
  width: 100%;
}
.reFile-manager__header-edit {
  margin-top: 0;
  width: 100%;
  margin-bottom: 20px;
}
.reFile-manager__subtitle {
  font-family: "headers", Arial, sans serif;
  letter-spacing: -0.08rem;
  width: 100%;
  color: #ffa98c;
  font-size: 2rem;
  justify-content: space-between;
  margin: 30px 0 0;
}
.reFile-manager__subtitle:first-child {
  margin-top: 0;
}
.reFile-manager__subtitle-help {
  font-size: 1.2rem;
  font-style: italic;
  color: #666;
}
.reFile-manager__subtitle-help.--mb {
  margin-bottom: 15px;
}
.reFile-manager__content {
  padding: 0;
}
.reFile-manager__main {
  display: flex;
  max-width: 100%;
  padding: 0;
  border: 0;
}
@media only screen and (max-width: 1022px) {
  .reFile-manager__main > div {
    width: 100%;
    border: 0;
  }
}
.reFile-manager__left {
  width: 40%;
  padding: 0 30px 40px 0;
  border-right: 1px solid #B2B2B2;
}
.reFile-manager__right {
  width: 60%;
  padding: 0 30px 40px;
}
.reFile-manager .--section-line {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.reFile-manager .--section-line__label {
  margin-right: 10px;
}
.reFile-manager .--field-item {
  width: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
}
.reFile-manager .--field-item:hover {
  border-color: #000;
}
.reFile-manager__notes {
  width: 100%;
  margin-top: 20px;
}
@media only screen and (max-width: 1022px) {
  .reFile-manager__notes {
    border-bottom: 1px solid #E5E5E5;
    margin: 10px 0 5px;
  }
}
.reFile-manager__notes .textarea {
  min-width: 100%;
  min-height: 150px;
}
.reFile-manager__property {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.reFile-manager__property-type {
  font-size: 0.9em;
  color: #666;
  display: block;
  margin-bottom: 3px;
}
.reFile-manager__property.--user {
  cursor: default;
}
.reFile-manager__property.--user figcaption {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.reFile-manager__property.--user div {
  flex-grow: 2;
}
.reFile-manager__property.--user .mat-mdc-form-field {
  max-width: 35%;
}
.reFile-manager__remove {
  height: 25px;
  width: 25px;
  margin-left: 20px;
}
.reFile-manager__add {
  display: flex;
  max-width: 115px;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1;
  color: #999;
  font-weight: normal;
  font-family: "basic", Arial, sans-serif;
  text-align: right;
  letter-spacing: 0;
  margin: 0;
}
.reFile-manager__add:hover {
  color: #000;
}
.reFile-manager__add img {
  margin-left: 10px;
}
.reFile-manager__add figcaption {
  padding-top: 4px;
}
.reFile-manager__contact-add {
  position: relative;
  margin: 15px 0 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .reFile-manager__contact-add {
    flex-wrap: wrap;
  }
}
.reFile-manager__contact-add > .mat-mdc-form-field {
  margin: 0 10px;
  color: #333;
  width: 100px;
}
@media only screen and (max-width: 639px) {
  .reFile-manager__contact-add > .mat-mdc-form-field {
    width: 100%;
    margin: 0;
  }
}
.reFile-manager__contact-add .createbutton {
  margin-left: 10px;
  padding: 5px;
  z-index: 0;
  font-size: 1.3rem;
}
@media only screen and (max-width: 639px) {
  .reFile-manager__contact-add .createbutton {
    margin: 10px auto 20px;
    width: 100%;
  }
}
.reFile-manager__pcontact__manager .createbutton {
  margin: 10px auto;
}
.reFile-manager__pcontact__manager fieldset {
  width: 100%;
  margin: 15px 0;
}
.reFile-manager__contact-link {
  flex-grow: 2;
}
.reFile-manager__contact-type {
  margin-left: 20px;
  max-width: 150px;
}
.reFile-manager__lead-owner {
  font-size: 1rem;
  display: block;
  color: #000;
}
.reFile-manager__lead-owner-name {
  font-weight: bold;
}
.reFile-manager__autocomplete {
  margin-right: 20px;
  color: #333;
  flex-grow: 2;
}
@media only screen and (max-width: 639px) {
  .reFile-manager__autocomplete {
    width: 100%;
    margin-right: 0;
  }
}
.reFile-manager__contact-tools {
  display: flex;
  flex-grow: 2;
  align-items: center;
}
.reFile-manager__nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px 0 10px;
  margin-right: 10px;
  border-right: 1px solid #CCC;
  min-width: 110px;
}
@media only screen and (max-width: 1022px) {
  .reFile-manager__nav {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 125px;
  }
}
@media only screen and (max-width: 639px) {
  .reFile-manager__nav {
    min-width: 60px;
  }
}
.reFile-manager__nav img {
  margin: 3px 5px 0;
  border-radius: 0;
  background: none;
  width: 25px;
}
@media only screen and (max-width: 639px) {
  .reFile-manager__nav img {
    margin: 0 5px 0 5px;
    width: 20px;
  }
}
.reFile__nav {
  position: relative;
  min-height: 100px;
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1022px) {
  .reFile__nav {
    width: 100%;
    max-width: 100%;
    min-height: 65px;
    align-items: flex-start;
    margin: 20px 0 50px;
  }
}
.reFile__nav:before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 95%;
  background: #B2B2B2;
  left: 3%;
  z-index: -1;
  top: 41px;
}
@media only screen and (max-width: 1022px) {
  .reFile__nav:before {
    top: 23px;
  }
}
.reFile__nav__step {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: space-around;
  align-items: center;
  color: #999;
  cursor: pointer;
}
.reFile__nav__step:hover {
  color: #000;
}
@media only screen and (max-width: 1022px) {
  .reFile__nav__step {
    width: 16.5%;
    height: 45px;
  }
}
.reFile__nav__step.--blocked {
  padding-top: 56px;
  background: url(/assets/img/icons/blocked.svg) no-repeat center 5px;
  background-size: 45px;
  text-decoration: line-through;
}
.reFile__nav__step.--blocked .--steps_back {
  display: none;
}
.reFile__nav__step-percent {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  height: 45px;
  width: 45px;
  font-family: basic, Arial, sans-serif;
  color: #FFF;
  filter: saturate(0);
  user-select: none;
  border: 3px solid transparent;
}
.reFile__nav__step-percent strong {
  font-family: headers, Arial, sans-serif;
  font-size: 1.3rem;
}
.reFile__nav__step-name {
  font-size: 1.3rem;
}
@media only screen and (max-width: 1022px) {
  .reFile__nav__step-name {
    position: absolute;
    font-size: 1.1rem;
    bottom: -20px;
    min-width: 66px;
    text-align: center;
  }
}
.reFile__nav__step .--tooltip {
  top: -3px;
  left: calc(50% + 10px);
  display: none;
}
.reFile__nav__step.--active {
  color: #aa4400;
}
.reFile__nav__step.--active .reFile__nav__step-percent {
  filter: saturate(1);
  border-color: rgba(0, 0, 0, 0.4);
}
.reFile__nav__step.--active .--tooltip {
  display: block;
}
.reFile__nav__step.--disabled {
  color: #CCC;
  cursor: default;
}
.reFile__nav__step.--disabled:hover {
  color: #CCC;
}
.reFile__nav__step.--disabled .reFile__nav__step-percent {
  color: transparent;
  background: #CCC;
}
.reFile__nav-sub {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -25px;
}
.reFile__nav-sub__item {
  position: relative;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin: 0 5px;
  user-select: none;
}
.reFile__nav-sub__item:hover, .reFile__nav-sub__item.--selected {
  background: #666;
}
.reFile__nav-sub__img {
  max-width: 60%;
  margin: auto;
}
.reFile__nav-legal {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.1rem;
}
.reFile__nav-legal:before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 95%;
  background: #B2B2B2;
  left: 3%;
  z-index: -1;
  top: 22px;
}
.reFile__nav-legal__item {
  position: relative;
  border-radius: 50%;
  background-color: #fa8072;
  height: 45px;
  width: 45px;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  filter: saturate(0);
  user-select: none;
}
.reFile__nav-legal__item:hover, .reFile__nav-legal__item.--selected {
  filter: none;
  font-weight: bold;
}
.reFile__nav-legal__item.--disabled {
  cursor: default;
  color: #7F7F7F;
}
.reFile__nav-legal__item.--disabled:hover {
  font-weight: normal;
  filter: saturate(0);
}
.reFile__nav-legal__img {
  max-width: 60%;
  margin: auto;
}
.reFile__nav-legal__name {
  margin-top: 50px;
}
.reFile__nav-legal__step {
  position: absolute;
  right: 0;
  border-radius: 10px;
  color: #FFF;
  padding: 5px 10px;
}
@media only screen and (max-width: 1022px) {
  .reFile .basic-plan__total-score {
    top: -56px;
    font-size: 2rem;
    width: 70px;
    height: 70px;
  }
}

.step {
  display: flex;
  flex-wrap: wrap;
  padding: 3vh 0 5vh;
}
@media only screen and (max-width: 1022px) {
  .step {
    min-height: 0;
  }
}
@media only screen and (max-width: 639px) {
  .step {
    padding-top: 0;
  }
}
.step-row {
  width: 100%;
  padding: 20px 30px;
  border: 1px solid #CCC;
  box-shadow: 0 0 15px #B2B2B2;
  border-radius: 10px;
  margin: 20px 0;
}
.step-left {
  width: 70%;
  padding: 30px;
  border: 1px solid #CCC;
  box-shadow: 0 0 15px #B2B2B2;
  border-radius: 20px;
}
@media only screen and (max-width: 1022px) {
  .step-left {
    margin-bottom: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  .step-left {
    padding: 30px 20px;
  }
}
.step-left.--float {
  border: 0;
  box-shadow: none;
  padding: 0;
}
.step-right {
  width: 30%;
  padding: 0 0 0 1.5vw;
  font-size: 1.2rem;
}
@media only screen and (max-width: 1247px) {
  .step-right {
    padding-left: 2.5vw;
  }
}
@media only screen and (max-width: 1022px) {
  .step-right {
    width: 100%;
    padding: 0;
    border: 0;
  }
}
.step-right .manager__slider {
  padding: 10px 0;
}
.step-title {
  font-size: 2.2rem;
  color: #B2B2B2;
}
.step-description {
  font-size: 1.5rem;
  color: #332e7d;
}
.step__alarms {
  margin-bottom: 40px;
}
.step__legal {
  margin-top: 0;
}
.step__docs {
  margin-bottom: 20px;
}
.step__last-fields {
  position: relative;
  border: 1px solid #B2B2B2;
  padding: 20px;
  margin: 1vw auto 150px;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.step__last-fields__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #F1F1F1;
}
.step__last-fields__subtitle {
  font-style: oblique;
  color: #333;
}
.step__last-fields__help {
  position: absolute;
  right: 10px;
  top: 10px;
}
.step__last-fields__data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.5rem;
  font-weight: bold;
  overflow: hidden;
  width: 30%;
  padding-left: 25px;
  border-left: 1px solid #CCC;
}
.step__last-fields__data:first-of-type {
  border: 0;
  padding-left: 0;
}
@media only screen and (max-width: 1022px) {
  .step__last-fields__data {
    width: 100%;
    font-size: 1.2rem;
    padding-left: 0;
    border: 0;
    margin: 10px 0;
  }
}
.step__last-fields__data > span {
  min-width: 110px;
}
@media only screen and (max-width: 1022px) {
  .step__last-fields__data > span {
    min-width: 100%;
  }
}
.step__last-fields__data .mat-mdc-form-field {
  margin: 0;
  font-weight: bold;
  max-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
}
.step__last-fields__price {
  font-size: 1.7rem;
  color: #7F7F7F;
}
.step__last-fields__100 {
  width: 40px;
  position: absolute;
  top: -20px;
  right: -10px;
}
.step__legal {
  width: 100%;
}

.--step {
  color: #B2B2B2;
  transition: color 0.2s linear;
}
.--step img {
  filter: saturate(0);
}

.--contacto:hover, .--contacto:focus, .--contacto.active {
  color: #753673;
}
.--contacto:hover img, .--contacto:focus img, .--contacto.active img {
  filter: invert(21%) sepia(96%) saturate(806%) hue-rotate(264deg) brightness(60%) contrast(83%);
}

.--prospecto:hover, .--prospecto:focus, .--prospecto.active {
  color: #bfb000;
}
.--prospecto:hover img, .--prospecto:focus img, .--prospecto.active img {
  filter: invert(48%) sepia(32%) saturate(1888%) hue-rotate(-32deg) brightness(92%) contrast(84%);
}

.--en-proceso:hover, .--en-proceso:focus, .--en-proceso.active {
  color: #6d9d39;
}
.--en-proceso:hover img, .--en-proceso:focus img, .--en-proceso.active img {
  filter: invert(46%) sepia(56%) saturate(472%) hue-rotate(145deg) brightness(93%) contrast(93%);
}

.--activo:hover, .--activo:focus, .--activo.active {
  color: #4aa90b;
}
.--activo:hover img, .--activo:focus img, .--activo.active img {
  filter: invert(58%) sepia(80%) saturate(4758%) hue-rotate(134deg) brightness(95%) contrast(91%);
}

.--en-cierre:hover, .--en-cierre:focus, .--en-cierre.active {
  color: #1f6d1b;
}
.--en-cierre:hover img, .--en-cierre:focus img, .--en-cierre.active img {
  filter: invert(50%) sepia(32%) saturate(832%) hue-rotate(68deg) brightness(92%) contrast(94%);
}

.--cerrado:hover, .--cerrado:focus, .--cerrado.active {
  color: #024624;
}
.--cerrado:hover img, .--cerrado:focus img, .--cerrado.active img {
  filter: invert(18%) sepia(82%) saturate(4237%) hue-rotate(74deg) brightness(100%) contrast(40%);
}

.--horus:hover, .--horus:focus, .--horus.active {
  color: #de8c0d;
}
.--horus:hover img, .--horus:focus img, .--horus.active img {
  filter: none;
}

.--prospecto_color {
  color: #bfb000;
}

.--en-proceso_color {
  color: #6d9d39;
}

.--activo_color {
  color: #4aa90b;
}

.--en-cierre_color {
  color: #1f6d1b;
}

.--cerrado_color {
  color: #024624;
}

.--steps_back.--prospecto_bk {
  background-color: #bfb000;
}
.--steps_back.--en-proceso_bk {
  background-color: #6d9d39;
}
.--steps_back.--activo_bk {
  background-color: #4aa90b;
}
.--steps_back.--en-cierre_bk {
  background-color: #1f6d1b;
}
.--steps_back.--cerrado_bk {
  background-color: #024624;
}

.--rojo {
  border-color: #c12900;
}
.--rojo:hover, .--rojo:focus {
  border-color: #c12900;
}

.--amarillo {
  border-color: #f9ba19;
}
.--amarillo:hover, .--amarillo:focus {
  border-color: #f9ba19;
}

.--verde {
  border-color: #859836;
}
.--verde:hover, .--verde:focus {
  border-color: #859836;
}

horus-step1-prospecto {
  display: block;
  padding-bottom: 100px;
}
@media only screen and (max-width: 1022px) {
  horus-step1-prospecto .step {
    padding: 20px 0;
  }
}

horus-step3-activo {
  display: block;
  padding-bottom: 100px;
}
horus-step3-activo textarea {
  width: 100%;
  min-height: 100px;
  font-size: 1.2rem;
  color: #4C4C4C;
}
@media only screen and (max-width: 1022px) {
  horus-step3-activo horus-uploader {
    min-width: 100% !important;
  }
}

.step-activo__title {
  margin: 0;
  font-size: 2.5rem;
  color: #4aa90b;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.step-activo__title img {
  width: 50px;
  margin-left: 10px;
}
.step-activo__form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 40px 0;
}
.step-activo__field {
  width: 30%;
  margin: 1% 3% 1% 0;
}
@media only screen and (max-width: 1022px) {
  .step-activo__field {
    width: 48%;
    margin: 1%;
  }
}
@media only screen and (max-width: 639px) {
  .step-activo__field {
    width: 100%;
  }
}
.step-activo .basic-plan h2 {
  margin-bottom: 20px;
}
.step-activo__urls-title {
  width: 100%;
  margin-bottom: 20px;
}
.step-activo__checks {
  margin: 30px 0;
}
.step-activo__offers {
  margin: 10px 0 30px;
}
.step-activo__offers__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #4aa90b;
  filter: saturate(0.7);
}
.step-activo__offers__disabled {
  font-size: 1.2rem;
  font-family: "special", Arial, sans-serif;
  color: #c12900;
  letter-spacing: 0;
  margin: 0 10px;
  padding: 0 10px;
  border-left: 1px solid #999;
}
.step-activo__offers__new {
  width: 30px;
  transition: color 0.2s linear;
  font-family: "special", Arial, sans serif;
  font-size: 1.2rem;
  display: flex;
  color: #4aa90b;
  margin: 0 10px;
  padding: 0 10px;
  border-left: 1px solid #999;
  cursor: pointer;
  filter: saturate(0) brightness(1.2);
}
.step-activo__offers__new:hover {
  filter: saturate(1);
}
.step-activo__offers__new figcaption {
  padding: 0 5px;
}
.step-activo__offers-list__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 1.2rem;
  font-family: special, Arial, sans-serif;
  cursor: pointer;
  color: #666;
  border-bottom: 1px solid #FFF;
}
.step-activo__offers-list__item:hover {
  background: #f9f9f9;
}
.step-activo__offers-list__item.--approved {
  color: #000;
  border-radius: 3px;
  background-color: rgba(175, 201, 0, 0.2);
  border: 1px solid rgba(74, 169, 11, 0.5);
}
.step-activo__offers-list__item.--approved strong {
  color: #1f6d1b;
}
.step-activo__offers-list__item.--cancelled strong {
  color: #B2B2B2;
}
.step-activo__offers-list__item.--pending strong {
  color: #ffa023;
}
.step-activo__offers-list__title {
  padding: 0 10px 0 0;
}
@media only screen and (max-width: 1247px) {
  .step-activo__offers-list__title {
    width: 100%;
  }
}
.step-activo__offers-list__title span {
  font-family: headers, Arial, sans-serif;
}
.step-activo__offers-list__buyers {
  font-size: 0.8em;
  margin: 0 10px 0 0;
}
.step-activo__offers-list__status {
  font-family: headers, Arial, sans-serif;
  flex-grow: 2;
  text-align: right;
}
.step-activo__offers-list__status img {
  width: 20px;
  margin-right: -10px;
}
.step-activo__extra {
  margin-bottom: 50px;
}
.step-activo__extra input {
  font-family: "headers", Arial, sans-serif;
  font-size: 1.5rem;
  color: #4C4C4C;
}
.step-activo__extra__field {
  display: flex;
  font-size: 1.5rem;
}
.step-activo__extra__field h4 {
  margin: 0;
  flex-grow: 2;
  display: flex;
  align-items: center;
  color: #666;
}
.step-activo__extra__field .mat-mdc-form-field {
  max-width: 50%;
  text-align: right;
}

.step-en-cierre__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #B2B2B2;
  padding-bottom: 10px;
  margin: 0 0 10px;
  min-height: 50px;
  user-select: none;
}
.step-en-cierre__title {
  margin: 0;
  font-size: 2.5rem;
  color: #1f6d1b;
  display: flex;
  align-items: center;
}
.step-en-cierre__nav {
  display: flex;
}
.step-en-cierre__new {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #999;
}
.step-en-cierre__new:hover {
  color: #000;
}
.step-en-cierre__new img {
  margin-bottom: 5px;
  filter: none;
  height: 30px;
}
.step-en-cierre__offer {
  position: relative;
  padding: 10px;
  border: 1px solid #1f6d1b;
  border-radius: 10px;
  background-color: rgba(31, 109, 27, 0.1);
}
.step-en-cierre__offer-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: #1f6d1b;
  filter: saturate(0.7);
  padding-right: 32px;
  line-height: 0.7;
}
.step-en-cierre__offer-title img {
  margin: -4px 0 0 0;
  max-width: 30px;
}
.step-en-cierre__offer-subtitle {
  color: #666;
  margin: 15px 0 5px;
}
.step-en-cierre__offer-total {
  font-size: 2.5rem;
  font-family: headers, Arial, sans-serif;
  text-align: right;
  margin: 10px 0 0;
  color: #1f6d1b;
}
.step-en-cierre__offer-edit {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1f6d1b;
  border-radius: 50%;
  background-color: #FFF;
}
.step-en-cierre__offer-edit img {
  height: 61%;
}

.offers-manager {
  padding-bottom: 100px;
}
.offers-manager-cancelled {
  border-left: 3px solid #c12900;
}
.offers-manager-cancelled .step-activo__offers-manager__section {
  opacity: 0.6;
}
.offers-manager-pending {
  border-left: 3px solid #ffa023;
}
.offers-manager-approved {
  border-left: 3px solid #4aa90b;
}
.offers-manager input.mat-mdc-input-element {
  font-family: headers, Arial, sans-serif !important;
  font-size: 1.5rem !important;
  color: #4C4C4C;
  text-align: right;
}
.offers-manager__title {
  position: relative;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin: 0;
}
.offers-manager__title img {
  width: 36px;
  min-width: 36px;
  height: 36px;
}
.offers-manager__title .--link {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-family: special, Arial, sans-serif;
  color: #7F7F7F;
}
.offers-manager__owner {
  font-size: 1.2rem;
  color: #4C4C4C;
  margin: 0 20px;
}
.offers-manager__status {
  padding: 0 10px;
  font-size: 0.9em;
}
.offers-manager__status .--cancelled {
  color: #c12900;
}
.offers-manager__status .--pending {
  color: #ffa023;
}
.offers-manager__status .--approved {
  color: #4aa90b;
}
.offers-manager__icon {
  margin-right: 10px;
}
.offers-manager__icon.--pending {
  filter: hue-rotate(217deg) brightness(1.5) saturate(2.5);
}
.offers-manager__icon.--cancelled {
  filter: hue-rotate(193deg) brightness(0.9);
}
.offers-manager__revoke {
  margin-left: 10px;
}
.offers-manager__main-origin {
  top: 6px;
  left: -7px;
  margin-right: 20px;
}
.offers-manager__main-origin.--unchecked {
  opacity: 0.5;
}
.offers-manager__main-origin label {
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: -0.03rem;
  line-height: 1;
  padding-top: 3px;
}
.offers-manager__origins {
  flex-grow: 2;
  width: auto;
}
.offers-manager__subtitle {
  font-size: 1.5rem;
  color: #7F7F7F;
  flex-basis: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offers-manager__section {
  padding: 5px 0;
  margin: 5px 0;
  border-top: 1px solid #CCC;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.offers-manager__section:nth-of-type(1) {
  border: 0;
  top: 11px;
  position: relative;
  min-height: 65px;
}
.offers-manager__section:last-child {
  border: 0;
}
.offers-manager__section.--med {
  width: 50%;
}
.offers-manager__section.--files {
  justify-content: flex-start;
}
.offers-manager__section.--files horus-uploader {
  min-width: 0;
  margin-right: 10px;
}
.offers-manager__section .createbutton {
  margin: auto;
}
.offers-manager__subsection {
  width: 100%;
  margin: 20px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.offers-manager__msg {
  font-size: 1.35rem;
  color: #4aa90b;
  padding: 5px 0 10px;
  width: 100%;
}
.offers-manager__msg.--big {
  font-family: headers, Arial, sans-serif;
  font-size: 2rem;
}
.offers-manager__msg.--center {
  text-align: center;
}
.offers-manager__msg.--error {
  color: #ff6a00;
}
.offers-manager__people {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.offers-manager__people-sel {
  margin: 0 20px;
  max-width: calc(100% - 200px);
}
.offers-manager__buyer {
  padding: 0 2px;
  display: flex;
  align-items: center;
  margin: 5px 0 6px;
}
.offers-manager__buyer-lead {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  transition: opacity 0.2s linear;
  font-size: 1.5rem;
  border-right: 1px solid #CCC;
  color: #666;
}
.offers-manager__buyer-lead:hover, .offers-manager__buyer-lead.--editing {
  font-weight: bold;
  color: #000;
}
.offers-manager__buyer-status {
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 5px 0 0;
  border-radius: 50%;
  background-color: #ff6a00;
}
.offers-manager__buyer-status.--ok {
  background-color: #4aa90b;
}
.offers-manager__buyer-edition {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid #CCC;
  border-radius: 10px;
}
.offers-manager__buyer-edition horus-uploader:before {
  content: "DNI";
  font-family: "headers", Arial, sans-serif;
  font-size: 2.5rem;
  margin: 10px 10px 0;
  z-index: 1;
  color: #999;
}
@media only screen and (max-width: 1022px) {
  .offers-manager__buyer-edition horus-uploader:before {
    right: 0;
  }
}
.offers-manager__bed-name {
  margin: 0;
}
.offers-manager__bed-name em {
  font-size: 0.8em;
  padding: 0 0 0 10px;
  letter-spacing: 0.04em;
  font-family: special, Arial, sans-serif;
  font-weight: normal;
}
.offers-manager__bed-files {
  min-width: 60%;
  display: flex;
}
.offers-manager h4 {
  font-size: 1.3rem;
  font-family: basicregular, Arial, sans-serif;
  font-weight: normal;
  margin: 0 10px 0 0;
  color: #666;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 559px) {
  .offers-manager h4 {
    width: 100%;
  }
}
.offers-manager__field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 40%;
  flex-grow: 2;
}
.offers-manager__field:nth-child(even) {
  border-right: 1px solid #E5E5E5;
  margin-right: 20px;
  padding-right: 20px;
}
@media only screen and (max-width: 639px) {
  .offers-manager__field {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  .offers-manager__field:nth-child(even) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}
.offers-manager__field.--final-fee {
  min-width: 48%;
}
@media only screen and (max-width: 1022px) {
  .offers-manager__field.--final-fee {
    padding-right: 0;
    margin-right: 0;
    min-width: 100%;
    border: 0;
  }
}
@media only screen and (max-width: 639px) {
  .--dates .offers-manager__field:nth-child(2) {
    order: 2;
  }
  .--dates .offers-manager__field:nth-child(3) {
    order: 4;
  }
  .--dates .offers-manager__field:nth-child(4) {
    order: 3;
  }
  .--dates .offers-manager__field:nth-child(5) {
    order: 5;
  }
}
.offers-manager__field .--input-field {
  width: 150px;
}
@media only screen and (max-width: 639px) {
  .offers-manager__field .--input-field {
    width: 100%;
  }
}
.offers-manager__field .--name {
  max-width: 92px;
  font-size: 1.2rem;
  padding-right: 5px;
}
@media only screen and (max-width: 1022px) {
  .offers-manager__field .--name {
    max-width: unset;
    width: 200px;
  }
}
.offers-manager__field .--fee-input {
  max-width: 120px;
  padding-right: 10px;
}
@media only screen and (max-width: 1022px) {
  .offers-manager__field .--fee-input {
    max-width: calc(100% - 80px);
    flex-grow: 2;
    margin-right: 10px;
  }
}
.offers-manager__field .--fee {
  font-weight: bold;
  border: 1px solid grey;
  padding: 2px 3px;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.2s linear;
  cursor: pointer;
  width: 35px;
  text-align: center;
  font-size: 1.5rem;
}
.offers-manager__field .--fee:hover, .offers-manager__field .--fee.--sel {
  background-color: #4aa90b;
  color: #FFF;
}
.offers-manager__field .--note {
  font-family: "special", Arial, sans-serif;
  font-size: 1.3rem;
  color: #333;
  max-width: 70px;
}
.offers-manager__field-unique {
  min-width: 100%;
}
.offers-manager__field-unique .textarea {
  height: 80px;
}
.offers-manager__field-large {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: unset;
}
.offers-manager__field-large:nth-child(6) {
  order: 6;
}
.offers-manager__field-large h4 {
  max-width: 50%;
}
.offers-manager__field-large .mat-mdc-checkbox {
  height: 60px;
  display: flex;
  align-items: center;
}
.offers-manager__field-large.--total h4 {
  display: block;
  font-weight: bold;
  color: #4aa90b;
  font-size: 1.5rem;
  min-width: calc(100% - 250px);
}
@media only screen and (max-width: 639px) {
  .offers-manager__field-large.--total h4 {
    min-width: 100%;
  }
}
.offers-manager__field-large.--total small {
  display: block;
  padding-top: 5px;
  color: #999;
}
.offers-manager__field-large.--total span {
  color: #000;
}
.offers-manager__field-large.--total .mat-mdc-input-element {
  text-align: right;
  font-size: 2rem !important;
  color: #4aa90b;
}

.checklist__block:after {
  content: "";
  height: 1px;
  width: 50%;
  display: block;
  margin: 25px auto;
  background-color: #332e7d;
  opacity: 0.25;
}
@media only screen and (max-width: 639px) {
  .checklist__block:after {
    background-color: transparent;
  }
}
.checklist__docs {
  padding: 0 0 0 14px;
  font-style: italic;
  border-radius: 10px;
  border: 1px solid #CCC;
}
.checklist__docs .checklist__check {
  flex-wrap: wrap;
}
@media only screen and (max-width: 639px) {
  .checklist__docs .checklist__check {
    border-bottom: none;
  }
}
.checklist__docs .checklist__check__name-date {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.checklist__docs .checklist__check__status {
  text-align: left;
}
.checklist__docs .checklist__check__name {
  font-size: 1.2rem;
}
.checklist__docs .checklist__check__section {
  min-height: 30px;
  flex-grow: 2;
}
.checklist__header {
  position: relative;
  width: 100%;
  padding-right: 50px;
  margin-bottom: 20px;
}
.checklist__header h2 {
  margin: 0;
  min-width: 160px;
}
.checklist__header__progress {
  font-family: basicregular, Arial, sans-serif;
  color: #999;
  font-weight: lighter;
  font-size: 0.8em;
  margin-left: 10px;
}
.checklist__percent {
  padding-top: 3px;
  display: flex;
  align-items: center;
  height: 5px;
  margin: 10px 0;
  width: 0;
  position: relative;
  animation: grow 1.5s ease-out forwards;
  background-color: #ff5e00;
}
.checklist__help {
  width: 30px;
  right: 0;
  margin: -45px 0 0;
  opacity: 0.7;
}
.checklist__check {
  position: relative;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 2px 10px 2px 0;
  justify-content: space-between;
  color: #666;
  cursor: pointer;
  border-right: 5px solid #FFF;
}
@media only screen and (max-width: 639px) {
  .checklist__check {
    border-bottom: 1px solid #CCC;
    flex-wrap: wrap;
  }
}
.checklist__check:hover {
  color: #6d9d39;
  border-color: #6d9d39;
}
.checklist__check:hover .--disabled {
  color: #c12900;
}
.checklist__check.--disabled {
  cursor: not-allowed;
  opacity: 0.7;
  user-select: none;
}
.checklist__check.--disabled:hover {
  color: #666;
  border-color: #FFF;
}
.checklist__check__mine {
  position: absolute;
  left: -21px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #ff5555;
}
.checklist__check__section {
  display: flex;
  align-items: center;
  min-width: 70px;
  justify-content: flex-end;
}
.checklist__check__section:first-child {
  flex-grow: 2;
  min-width: 0;
}
@media only screen and (max-width: 639px) {
  .checklist__check__section:last-child {
    height: 40px;
  }
}
.checklist__check__ok {
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
@media only screen and (max-width: 639px) {
  .checklist__check__ok {
    margin-right: 5px;
  }
}
.checklist__check__ok.--ok {
  border-radius: 50%;
  background: #c8ffcb;
  padding: 2px;
}
.checklist__check__number {
  font-size: 1.2rem;
  min-width: 25px;
  font-family: special, Arial, sans-serif;
}
@media only screen and (max-width: 639px) {
  .checklist__check__number {
    min-width: 20px;
  }
}
.checklist__check__name-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 2;
  flex-wrap: wrap;
}
.checklist__check__name {
  font-size: 1.5rem;
  font-family: basic, Arial, sans-serif;
  padding: 4px 5px 4px 0;
  flex-grow: 2;
}
@media only screen and (max-width: 1022px) {
  .checklist__check__name {
    max-width: 58%;
  }
}
@media only screen and (max-width: 639px) {
  .checklist__check__name {
    max-width: 100%;
    font-size: 1.3rem;
  }
}
.checklist__check__pending {
  font-size: 0.8em;
  border: 1px solid #CCC;
  border-radius: 5px;
  margin: 0 5px 0 10px;
  padding: 2px 5px 0;
}
.checklist__check__pending.--disabled {
  display: block;
  margin: 5px 0;
  padding: 0;
  border: 0;
  color: #c12900;
}
.checklist__check__status {
  font-size: 1.1rem;
  text-align: right;
  color: #999;
}
.checklist__check__status-date {
  display: block;
}
.checklist__check__status-date.--end {
  color: #366a98;
}
@media only screen and (max-width: 639px) {
  .checklist__check__status {
    text-align: left;
  }
}
.checklist__check__status strong {
  word-break: keep-all;
  font-style: normal;
  font-family: special, Arial, sans-serif;
  padding-left: 5px;
  font-size: 1rem;
  letter-spacing: 1px;
}
.checklist__check__time {
  width: 70px;
  text-align: right;
}
.checklist__check__time strong {
  font-size: 1.1rem;
  display: block;
  font-family: special, Arial, sans-serif;
}
.checklist__check__info {
  position: relative;
  width: 20px;
  margin-left: 13px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .checklist__check__info {
    margin-left: 10px;
  }
}
.checklist__check__info.--notactive {
  cursor: default;
}
.checklist__check__info.--amount0 img {
  filter: saturate(0);
}
.checklist__check__info.--amount0 figcaption {
  color: #7F7F7F;
}
.checklist__check__info-icon {
  position: absolute;
}
.checklist__check__info-num {
  font-family: basicregular, Arial, sans-serif;
  position: absolute;
  right: 0;
  padding-top: 3px;
  font-size: 1rem;
}
.checklist__check__info-num.--msg {
  padding: 0;
  top: -5px;
}
.checklist__check__info-num .--unread {
  display: block;
  border-radius: 50%;
  background: #002cff;
  position: absolute;
  top: -5px;
  right: -11px;
  width: 10px;
  height: 10px;
}
.checklist__edit {
  display: flex;
  flex-wrap: wrap;
}
.checklist__edit-title {
  font-size: 2rem;
  margin-top: 0;
  width: 100%;
}
.checklist__edit-info {
  flex-basis: 45%;
  padding-right: 30px;
}
@media only screen and (max-width: 1022px) {
  .checklist__edit-info {
    flex-basis: 100%;
  }
}
.checklist__edit-forms {
  flex-grow: 2;
  flex-basis: 50%;
}
@media only screen and (max-width: 1022px) {
  .checklist__edit-forms {
    margin: 30px 0;
  }
}
.checklist__info-msg {
  font-size: 1.2rem;
  line-height: 1.15;
  margin: 5px 0 30px;
  color: #666;
}
.checklist__info-msg .--inm {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #B2B2B2;
}
.checklist__info-msg strong {
  color: #0ca66b;
  font-family: headers, Arial, sans-serif;
}
.checklist__info-msg a {
  color: #002cff;
  opacity: 0.7;
}
.checklist__info-msg a:hover {
  opacity: 1;
}
.checklist horus-comments {
  display: block;
  width: 100%;
}
.checklist__warning {
  margin-right: 10px;
}
.checklist__ctas {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px 0 10px;
  padding: 20px 0 10px;
  border-top: 1px solid #CCC;
}
.checklist__ctas__cta-cont {
  flex-basis: 50%;
}
.checklist__ctas__cta {
  max-height: 50px;
  user-select: none;
}
.checklist__ctas__revoke-msg {
  margin-top: 11px;
  width: 46%;
  text-align: center;
  font-size: 1.2rem;
  color: #999;
}

horus-banking-transactions {
  display: block;
  max-width: 100%;
}

.bank-tr {
  position: relative;
  width: 100%;
  min-height: 65px;
}
.bank-tr__done {
  width: 40px;
}
.bank-tr__row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.bank-tr__summary {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #B2B2B2;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.bank-tr__summary-item {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 2rem;
  padding: 5px 20px;
  flex-basis: 33%;
  flex-wrap: wrap;
  border-right: 1px solid #B2B2B2;
}
@media only screen and (max-width: 1022px) {
  .bank-tr__summary-item {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .bank-tr__summary-item {
    flex-basis: 100%;
    justify-content: space-between;
    border-right: 0;
    border-bottom: 1px solid #B2B2B2;
    margin-bottom: 20px;
    padding-left: 0;
  }
}
.bank-tr__summary-item:nth-of-type(1) {
  padding-left: 0;
}
.bank-tr__summary-item:last-child {
  border: 0;
}
.bank-tr__summary-item .--pending {
  color: #666;
  font-weight: lighter;
}
@media only screen and (max-width: 768px) {
  .bank-tr__summary-item .--pending {
    display: block;
  }
}
.bank-tr__summary-item .--neg {
  color: #ff5e00;
}
.bank-tr__summary-info {
  display: flex;
  align-items: center;
}
.bank-tr__summary__img {
  height: 58px;
  margin: 5px 15px 0 0;
  filter: saturate(0);
  opacity: 0.3;
}
@media only screen and (max-width: 1022px) {
  .bank-tr__summary__img {
    height: 40px;
    margin: 15px 10px 15px 0;
  }
}
.bank-tr__summary__img.--ok {
  filter: none;
  opacity: 1;
}
.bank-tr__summary__ok {
  position: absolute;
  top: 0;
  left: 0;
}
.bank-tr__summary-subtitle {
  font-size: 1.4rem;
  margin: 0;
  width: 100%;
  color: #333;
  font-family: special, Arial, sans-serif;
}
.bank-tr__summary-data {
  font-size: 1.4rem;
  color: #859836;
}
.bank-tr__summary-data div {
  margin: 5px 0;
}
.bank-tr__summary-data strong {
  font-family: headers, Arial, sans-serif;
  font-size: 1.7rem;
}
.bank-tr__summary-paid {
  border-top: 1px solid #B2B2B2;
  padding-top: 10px;
  font-size: 1.3rem;
  font-style: italic;
  color: #024624;
  opacity: 0.7;
  width: 100%;
}
.bank-tr__summary-paid strong {
  font-family: headers, Arial, sans-serif;
  font-size: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .bank-tr__summary-paid {
    width: 50%;
    border-top: 0;
    border-left: 1px solid #B2B2B2;
    display: flex;
    padding-left: 20px;
    flex-wrap: wrap;
  }
}

horus-retributions {
  display: block;
  width: 100%;
}

.reports {
  min-height: 100vh;
  padding-bottom: 300px;
  /*&__icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }*/
}
.reports h1 {
  color: #1f6d1b;
  font-size: 2rem;
}
.reports h1 img {
  margin: 0 0 0 20px;
  width: 30px;
}
.reports__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3vh;
  min-height: 100%;
  height: auto;
}
.reports__container h2 {
  min-width: 100%;
}
.reports .-no-reports {
  font-size: 1.5rem;
}
.reports__graph {
  width: 100%;
  margin: 0 auto 5vh;
  height: 500px;
  max-height: 60vh;
}
.reports__graph__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
  padding: 0 10px;
}
.reports__graph__header h2 {
  color: #333;
  font-size: 2.3rem;
  margin: 0 0 20px;
}
.reports__graph__nav {
  display: flex;
  align-items: center;
  flex-grow: 2;
  justify-content: flex-end;
}
.reports__graph__icon {
  display: flex;
  align-items: center;
  justify-content: right;
  color: #de8c0d;
  font-size: 1.2rem;
  font-weight: bold;
  border-left: 1px solid #E5E5E5;
  padding: 5px 10px;
}
.reports__graph__icon:hover figcaption {
  color: #d8a90d;
}
.reports__graph__icon img {
  width: 20px;
  margin-right: 10px;
}
.reports__graph__icon figcaption {
  max-width: 120px;
  color: #B2B2B2;
}
.reports__graph__info {
  font-size: 1.2rem;
  margin-bottom: 30px;
}
.reports__graph__info li {
  margin-bottom: 5px;
}
.reports__graph__info strong {
  margin-right: 10px;
}
.reports__params {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}
.reports__params__title {
  display: flex;
  margin-bottom: 30px;
}
.reports__params__title img {
  margin: -20px 10px 0 -20px;
  width: 55px;
  filter: saturate(0);
}
.reports__params .mat-mdc-checkbox {
  min-width: 50%;
}
.reports__params__navs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 50%;
}
@media only screen and (max-width: 639px) {
  .reports__params__navs {
    flex-direction: column;
  }
}
.reports__params__remove {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
  visibility: hidden;
  /*&__text{
    font-size: 1rem;
    color: $rk_grey4;
    text-align: right;
    max-width: 180px;
    padding-right: 10px;
    @include mq(tablet-small) {
     max-width: unset;
    }
  }*/
}
.reports__params__remove.--show {
  visibility: visible;
}
@media only screen and (max-width: 639px) {
  .reports__params__remove {
    margin: 10px auto;
  }
}
.reports__params__remove img {
  width: 20px;
}
.reports__params__apply {
  margin-top: 30px;
}
.reports__list.--noReports {
  color: #de8c0d;
  font-size: 2rem;
}
.reports__list__item {
  width: 18%;
  margin: 1% 1% 3%;
  min-height: 90px;
  display: block;
  min-width: 225px;
}
.reports__list__avatar {
  display: flex;
  align-items: center;
}
.reports__list__avatar__img {
  max-width: 90px;
  width: 30%;
}
.reports__list__data {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #CCC;
  height: 100%;
}
.reports__list__data h3 {
  margin: 0 0 5px;
}
.reports__list__data h4 {
  color: #7F7F7F;
  margin: 0;
  font-family: basic, Arial, sans-serif;
  font-weight: lighter;
  font-size: 1.1rem;
}
.reports__list__data span {
  display: block;
}
.reports__dialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.reports__dialog .mat-mdc-form-field {
  font-size: 1.5rem;
}

.report-user__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.report-user__title {
  display: flex;
  align-items: center;
  margin-top: 0;
  font-size: 3rem;
  font-family: special, Arial, sans-serif;
  font-weight: lighter;
  margin-bottom: 20px;
}
@media only screen and (max-width: 639px) {
  .report-user__title {
    font-size: 2rem;
  }
}
.report-user__title__icon {
  display: flex;
  min-width: 70px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.report-user__title__icon img {
  width: 100%;
  object-fit: cover;
}
.report-user__title__text strong {
  margin-right: 5px;
  font-family: headers, Arial, sans-serif;
}
.report-user__nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px 0 10px;
  flex-wrap: wrap;
  z-index: 1;
}
@media only screen and (max-width: 1022px) {
  .report-user__nav {
    margin: 0 0 20px;
  }
}
.report-user__nav__item {
  padding: 0 10px;
  border-right: 1px solid #999;
  font-size: 1.4rem;
  cursor: pointer;
  color: #7F7F7F;
}
.report-user__nav__item:hover, .report-user__nav__item.--selected {
  color: #002cff;
}
.report-user__nav__range {
  width: 175px;
  max-width: 175px;
  margin: -10px 0 0 10px;
  cursor: pointer;
}
@media only screen and (max-width: 639px) {
  .report-user__nav__range {
    margin: 20px 0 10px 10px;
  }
}
.report-user__nav__range__date {
  position: absolute;
}
.report-user__nav__range__input {
  opacity: 0;
  cursor: pointer;
}
.report-user__nav__range__input[disabled] {
  cursor: not-allowed;
}
.mat-date-range-input .report-user__nav__range__input {
  opacity: 1;
  font-size: 1rem;
}
.report-user__charts {
  height: auto;
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  align-items: flex-end;
}
@media only screen and (max-width: 639px) {
  .report-user__charts {
    height: auto;
    margin-top: 0;
  }
}
.report-user__charts.--3m {
  margin-top: 0;
  height: auto;
  align-items: flex-start;
}
.report-user__charts.--leads, .report-user__charts.--act {
  height: 350px;
}
.report-user__charts.--refile {
  min-height: 0;
  height: auto;
}
.report-user__logs {
  margin-bottom: 200px;
}
.report-user__logs .basic-filters {
  margin-bottom: 30px;
}
.report-user__logs .logs__title {
  font-size: 2rem;
  margin-top: 10px;
  color: #B2B2B2;
}

.report-ranking {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.report-ranking h2 {
  width: 100%;
}
.report-ranking__item {
  padding: 16px 10px;
  min-width: 280px;
  width: 32%;
}
@media only screen and (max-width: 1247px) {
  .report-ranking__item {
    min-width: 0;
    width: 50%;
  }
}
@media only screen and (max-width: 559px) {
  .report-ranking__item {
    width: 100%;
  }
}
.report-ranking__user {
  display: flex;
  position: relative;
}
.report-ranking__user:hover img {
  filter: none;
}
.report-ranking__img {
  height: 100px;
  min-width: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #000;
  transition: filter 0.2s linear;
  filter: saturate(0.3);
  object-fit: cover;
}
@media only screen and (max-width: 639px) {
  .report-ranking__img {
    height: 80px;
    min-width: 80px;
    width: 80px;
  }
}
.report-ranking__info {
  font-family: special, Arial, sans-serif;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}
@media only screen and (max-width: 639px) {
  .report-ranking__info {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 559px) {
  .report-ranking__info {
    width: 100%;
    font-size: 1.3rem;
  }
}
.report-ranking__info.--yo {
  border-radius: 0 0 20px 0;
  border: solid #CCC;
  border-width: 0 1px 1px 0;
}
.report-ranking__info__name {
  font-size: 1.5rem;
  margin: 0 0 5px;
  color: #666;
}
.report-ranking__info__pt {
  font-size: 2.5rem;
  color: #332e7d;
}
.report-ranking__position {
  font-family: "headers", Arial, sans-serif;
  font-size: 2rem;
  background: #FFF;
  border: 1px solid #000;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1rem;
  left: -5px;
}
@media only screen and (max-width: 639px) {
  .report-ranking__position {
    height: 2rem;
    font-size: 1.5rem;
    width: 2rem;
  }
}
.--yo .report-ranking__position {
  color: #000;
  background-color: #febc44;
}

.alarmsList {
  position: relative;
}
.alarmsList .--link {
  border: 0;
  width: 40px;
  height: auto;
  margin: 5px 0;
  justify-content: flex-end;
}
.alarmsList .--link img {
  width: 25px;
}
.alarmsList__title {
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.alarmsList__noresults {
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
}
.alarmsList__alarm__list {
  margin-bottom: 40px;
}
.alarmsList__alarm__item {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #CCC;
}
.alarmsList__alarm__item.--acción {
  border-bottom: 2px solid #50d3e3;
}
.alarmsList__alarm__item.--plantilla {
  border-bottom: 2px solid #b9be0a;
}
.alarmsList__alarm__icon {
  width: 20px;
  margin-right: 10px;
}
.alarmsList__alarm__data {
  color: #7F7F7F;
  font-size: 1.2rem;
  flex-grow: 2;
}
.alarmsList__alarm__data strong {
  display: block;
}
.alarmsList__alarm__actions {
  display: flex;
}
.alarmsList__alarm__actions figure {
  display: flex;
}

.alarms__interview {
  padding-bottom: 10px;
}
.alarms__interview__title {
  margin-bottom: 5px;
}
.alarms__interview__description {
  margin-bottom: 20px;
}
.alarms__interview-list {
  position: relative;
  margin: 10px auto 40px;
}
.alarms__interview__item {
  display: flex;
  font-family: special, Arial, sans-serif;
  font-size: 1.2rem;
  border-bottom: 1px solid #B2B2B2;
  padding: 0 10px;
  justify-content: flex-start;
  align-items: center;
}
.alarms__interview__item:hover {
  background: #F1F1F1;
}
.alarms__interview__item.--disabled {
  opacity: 0.6;
  filter: saturate(0);
}
.alarms__interview__item-info {
  display: flex;
  flex-grow: 2;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0;
}
.alarms__interview__item-info:hover {
  font-weight: bold;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__item-info {
    align-items: flex-start;
    flex-direction: column;
  }
}
.alarms__interview__item-when {
  display: flex;
  align-items: center;
  margin: 0 10px 5px 0;
}
.alarms__interview__item-icon {
  width: 20px;
}
.alarms__interview__item-icon-b {
  width: 30px;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__item-icon-b {
    width: 40px;
  }
}
.alarms__interview__item-title {
  margin-right: 20px;
}
.alarms__interview__item-date {
  font-family: headers, Arial, sans-serif;
  color: #333;
  border: solid #B2B2B2;
  border-width: 0 1px;
  margin-left: 10px;
  padding: 0 10px;
}
.alarms__interview__item-time {
  color: #68bacf;
}
.alarms__interview__button {
  display: flex;
  border-radius: 5px;
  background: #68bacf;
  color: #FFF;
  padding: 3px 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  transition: background-color 0.2s linear;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__button {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.alarms__interview__button h3 {
  font-size: 2rem;
}
.alarms__interview__button img {
  transition: filter 0.2s linear;
  margin: 0 10px 0 20px;
  width: 30px;
}
.alarms__interview__button .mat-datepicker-input {
  font-family: headers, Arial, sans-serif;
  font-size: 2rem;
  max-width: 140px;
  width: auto;
  text-decoration: none;
  color: #FFF !important;
  margin: 0 5px;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.alarms__interview__button.--date .alarms__interview__button__dates-time {
  width: auto;
  opacity: 1;
}
.alarms__interview__button.--date .alarms__interview__button__save {
  display: unset;
}
.alarms__interview__button__dates {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__button__dates {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.alarms__interview__button__dates > div {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__button__dates > div {
    justify-content: center;
  }
}
.alarms__interview__button__dates-time {
  opacity: 0;
  width: 0;
  overflow: hidden;
}
.alarms__interview__button__dates-time .h-timepicker__toggler {
  color: #000;
}
.alarms__interview__button__dates-time strong {
  margin-right: 10px;
  font-size: 1.5rem;
  font-family: headers, Arial, sans-serif;
  color: #E5E5E5;
}
.alarms__interview__button__dates .--minimum {
  height: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
  border: 0;
}
.alarms__interview__button__dates-opener {
  flex-grow: 2;
  cursor: pointer;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__button__dates-opener {
    min-width: 100%;
  }
}
.alarms__interview__button__dates-opener:hover h3 {
  color: #000;
}
.alarms__interview__button__dates-opener:hover img {
  filter: hue-rotate(59deg) saturate(18);
}
.alarms__interview__button__save {
  display: none;
}
@media only screen and (max-width: 1022px) {
  .alarms__interview__button__save {
    margin: 10px auto;
  }
}
.alarms__interview__button__save .button, .alarms__interview__button__save .invbutton, .alarms__interview__button__save .createbutton {
  padding: 10px 20px;
}
.alarms__interview-done {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  color: #7F7F7F;
  padding: 10px 0;
  border-bottom: 1px solid #E5E5E5;
}
.alarms__interview-done em {
  font-size: 0.9em;
  color: #000;
}

@media only screen and (max-width: 639px) {
  .main-search-engine .cdk-overlay-pane {
    transform: none !important;
    left: 0 !important;
    max-width: 100% !important;
    width: 99% !important;
  }
}
.main-search-engine__option {
  font-size: 1.3rem !important;
  padding-left: 10px !important;
}
.main-search-engine__option.mat-mdc-option {
  color: #753673 !important;
}
.main-search-engine__option.mat-mdc-option img {
  border-radius: 0;
  margin: 0 5px 0 0;
}
.main-search-engine__option.mat-mdc-option em {
  color: #666;
  margin-left: 0;
}
.main-search-engine__option.mat-mdc-option strong {
  color: #002cff;
}

.goals__content {
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
}
.goals__item {
  width: 18%;
  margin: 1% 1% 3%;
  min-height: 90px;
  display: block;
  min-width: 225px;
}
.goals__avatar {
  display: flex;
  align-items: center;
}
.goals__avatar__img {
  max-width: 90px;
  width: 30%;
}
.goals__data {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #CCC;
  height: 100%;
}
.goals__data h3 {
  margin: 10px 0 5px;
}
.goals__data h4 {
  color: #7F7F7F;
  margin: 0;
}
.goals__list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.goal-manager__title {
  margin: 20px 0 5px;
}
.goal-manager__subtitle {
  margin: 20px 0 10px;
  color: #7F7F7F !important;
}
.goal-manager__one-to-one {
  margin: 5px 0 10px 0;
  font-style: italic;
}
.goal-manager__one-to-one span {
  font-size: 1.2rem;
}
.goal-manager__status {
  display: flex;
}
.goal-manager__score {
  display: flex;
}
@media only screen and (max-width: 639px) {
  .goal-manager__score {
    flex-direction: column;
  }
}
.goal-manager__score h4 {
  margin-top: 0;
  color: #B2B2B2;
}
.goal-manager__score__min {
  padding: 0 20px 0 0;
  border-right: 1px solid #E5E5E5;
}
.goal-manager__score__max {
  padding: 0 0 0 20px;
}
.goal-manager__score__min, .goal-manager__score__max {
  width: 50%;
}
@media only screen and (max-width: 639px) {
  .goal-manager__score__min, .goal-manager__score__max {
    border: 0;
    width: 100%;
    padding: 0;
  }
}
.goal-manager__score .basic-filters__valoration {
  justify-content: center;
}
.goal-manager__icon {
  width: 30px;
  height: 30px;
}
.goal-manager__icon.--main {
  margin-right: 20px;
}
.goal-manager__total {
  display: flex;
  align-items: center;
  justify-content: center;
}
.goal-manager__total .mat-mdc-form-field {
  max-width: 60px;
}
.goal-manager__total input {
  text-align: right;
  font: 2rem/2rem headers, Arial, sans-serif;
}

.goals-list {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
}
.goals-list__goal {
  max-width: 45%;
  margin: 0 auto;
  min-width: 220px;
}
.goals-list__goal__title {
  text-align: center;
  margin-bottom: 0;
}
.goals-list__goal__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goals-list__goal__trophy {
  position: absolute;
  height: 40%;
  top: 30%;
  left: 28%;
}
.goals-list__goal horus-custom-charts {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  filter: saturate(0) brightness(3.5);
}
.goals-list__goal horus-custom-charts .label {
  display: none;
}
.goals-list__goal .goals-list__goal__target {
  position: absolute;
  opacity: 0.8;
  filter: saturate(1);
}
.goals-list__goal .goals-list__goal__target .pie-grid-arcs g:nth-of-type(2) {
  display: none;
}
.goals-list__goal .goals-list__goal__target .label {
  display: unset;
}
.goals-list__goal .pie-grid .percent-label {
  font-size: 23px;
  font-weight: bold;
  font-family: "special", Arial, sans-serif;
}

horus-appraiser {
  min-height: calc(100vh - 90px) !important;
  padding-bottom: 0 !important;
}

.appraiser {
  user-select: none;
}
.appraiser .loader__container {
  z-index: 3;
  margin-top: 0;
}
.appraiser__color__icon {
  filter: hue-rotate(-70deg);
}
.appraiser__welcome {
  display: flex;
  width: 100%;
  margin: 60px auto 0;
  padding-bottom: 100px;
  justify-content: center;
  min-height: 100vh;
}
@media only screen and (max-width: 1022px) {
  .appraiser__welcome {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px auto 0;
    min-height: calc(100vh - 70px);
  }
}
.appraiser__welcome__logo {
  width: 50%;
  max-width: 450px;
  padding: 0 2%;
  text-align: center;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1439px) {
  .appraiser__welcome__logo {
    font-size: 1.3rem;
    width: 30%;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser__welcome__logo {
    font-size: 1.2rem;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__welcome__logo {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 559px) {
  .appraiser__welcome__logo {
    font-size: 1.1rem;
    width: auto;
  }
}
.appraiser__welcome__logo__img {
  margin: 0 auto 30px;
  max-height: 250px;
  height: 25vh;
}
@media only screen and (max-width: 1022px) {
  .appraiser__welcome__logo__img {
    max-width: 100px;
    margin: 0 2% 30px;
    height: auto;
  }
}
@media only screen and (max-width: 399px) {
  .appraiser__welcome__logo__img {
    max-width: 70px;
  }
}
.appraiser__welcome__main {
  position: relative;
  padding: 0 2%;
  width: 50%;
  border: solid #aa4400;
  border-width: 0 0 0 1px;
}
@media only screen and (max-width: 1439px) {
  .appraiser__welcome__main {
    width: 70%;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser__welcome__main {
    padding: 5vh 0;
    border-width: 1px 0 0;
    width: 90%;
    max-width: 100%;
  }
}
.appraiser__welcome__title {
  font-size: 2.3rem;
  color: #999;
  margin: 0 0 5px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.appraiser__welcome__title span {
  font-size: 1.7rem;
}
.appraiser__welcome__title strong {
  display: block;
  color: #000;
}
.appraiser__welcome__title__type {
  display: flex;
  font-size: 1.2rem;
  font-family: "special", Arial, sans-serif;
  align-items: center;
  border-left: 1px solid #000;
  margin: 10px;
  cursor: pointer;
  max-width: 125px;
  filter: saturate(0);
  transition: filter 0.2s linear;
}
.appraiser__welcome__title__type:hover {
  color: #000;
  filter: none;
}
.appraiser__welcome__title__type img {
  width: 30px;
  margin: 0 10px;
}
.appraiser__welcome horus-re-file-autocomplete {
  display: block;
  margin: 35px 0;
}
.appraiser__welcome__assessments-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #CCC;
}
.appraiser__welcome__assessments-header .pagination {
  margin: 0 auto;
}
@media only screen and (max-width: 1022px) {
  .appraiser__welcome__assessments-header .pagination {
    margin: 20px auto;
  }
}
.appraiser__properties {
  position: relative;
  max-height: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.appraiser__properties.--open {
  margin: 35px 0;
  min-height: 120px;
  max-height: unset;
  transition: all 0.2s linear;
}
.appraiser__properties__edition {
  position: absolute;
  width: 20px;
  top: calc(50% - 10px);
  right: 0;
  cursor: pointer;
  filter: saturate(0);
  transition: filter 0.2s linear;
}
.appraiser__properties__edition:hover {
  filter: hue-rotate(-80deg);
}
.appraiser__properties__data {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background: rgba(178, 178, 178, 0.1);
}
.appraiser__properties__subtitle {
  padding: 0 5px;
  text-align: left;
  font-size: 1.2rem;
  margin: 4px 0;
  color: #999;
  font-family: headers, Arial, sans-serif;
  border-bottom: 1px solid #E5E5E5;
  width: 50%;
}
@media only screen and (max-width: 639px) {
  .appraiser__properties__subtitle {
    width: 100%;
  }
}
.appraiser__properties__subtitle span {
  min-width: 100px;
}
.appraiser__properties__subtitle small {
  margin-left: 10px;
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.3rem;
  color: #000;
}
.appraiser__properties .mat-mdc-radio-group {
  width: 100%;
}
.appraiser__properties .mat-mdc-radio-button {
  position: relative;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #CCC;
  padding: 5px 10px;
  margin: 0;
  display: block;
  background: rgba(255, 255, 255, 0.8);
}
@media only screen and (max-width: 639px) {
  .appraiser__properties .mat-mdc-radio-button {
    padding: 10px 0;
    width: 100%;
  }
}
.appraiser__properties .mat-mdc-radio-button.--link {
  color: #666;
  opacity: 0.7;
}
.appraiser__properties .mat-mdc-radio-label {
  min-height: 25px;
}
.appraiser__properties__property {
  display: flex;
  align-items: center;
}
.appraiser__properties__address {
  white-space: pre-line;
  font-size: 1.2rem;
  margin-right: 30px;
  line-height: 1;
}
.appraiser__properties .appraiser__icon,
.appraiser__properties .appraiser__color__icon {
  width: 25px;
  margin-right: 10px;
}
.appraiser__valoration-type {
  display: flex;
  width: 100%;
  margin: 10px 20px;
  min-width: 200px;
}
.appraiser__valoration-type.--radius {
  border-image-source: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 239, 209, 0.7), rgba(0, 0, 0, 0));
  border-style: solid;
  border-image-slice: 1;
  border-width: 0 0 1px;
}
.appraiser__valoration-type__title {
  font-size: 1.6rem;
  text-align: left;
  color: #999;
  width: 100%;
}
.appraiser__valoration-type__title.--error {
  padding: 30px 0 0;
  color: #ff6a00;
}
.appraiser__valoration-type__info {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  font-size: 1.3rem;
  width: 100%;
}
.appraiser__valoration-type__info strong {
  display: block;
  font-size: 1.6rem;
}
.appraiser__valoration-type img {
  width: 70px;
  margin-right: 20px;
}
.appraiser__valoration-type .createbutton {
  margin: 0 0 0 10px;
  padding: 5px;
  font-size: 1.4rem;
  max-width: 140px;
  line-height: 1;
}
@media only screen and (max-width: 639px) {
  .appraiser__valoration-type .createbutton {
    font-size: 1.3rem;
    min-width: 100%;
    margin: 0 0 10px 0;
  }
}
.appraiser__radio-selector {
  font-size: 1.2em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 10px;
}
.appraiser__radio-selector__info {
  margin-right: 20px;
  min-width: 175px;
}
.appraiser__radio-selector__slider {
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.appraiser__radio-selector__warning {
  width: 80%;
  color: #ff6a00;
  opacity: 0;
  font-size: 1.1rem;
  transition: opacity 0.2s linear;
}
@media only screen and (max-width: 639px) {
  .appraiser__radio-selector__warning {
    width: 100%;
  }
}
.appraiser__radio-selector__warning.--warning {
  opacity: 1;
}
.appraiser__polygon-selector__info {
  max-width: calc(100% - 150px);
  flex-grow: 2;
  padding-bottom: 20px;
}
@media only screen and (max-width: 639px) {
  .appraiser__polygon-selector__info {
    max-width: unset;
  }
}
.appraiser__dashboard {
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
}
.appraiser__dashboard__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #333;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard__header h1 {
    font-size: 1.2rem;
  }
}
.appraiser__dashboard__icon {
  display: flex;
  align-items: center;
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.3rem;
  color: #CCC;
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard__icon.--logo {
    overflow: hidden;
    width: 60px;
    min-width: 60px;
  }
}
.appraiser__dashboard__icon.--property {
  flex-grow: 2;
  cursor: pointer;
  filter: saturate(0);
}
.appraiser__dashboard__icon.--property:hover {
  filter: brightness(1.5);
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard__icon.--property {
    font-size: 1rem;
  }
}
.appraiser__dashboard__icon__img {
  height: 40px;
  margin: 0 10px;
  cursor: default;
}
.appraiser__dashboard__icon__info {
  max-width: 210px;
  font-size: 1rem;
}
.appraiser__dashboard .appraiser__color__icon {
  height: 35px;
  cursor: pointer;
  margin: 0 20px;
  transition: filter 0.2s linear;
}
.appraiser__dashboard .appraiser__color__icon.--nolink {
  filter: saturate(0);
  cursor: default;
}
.appraiser__dashboard .appraiser__color__icon.--nolink:hover {
  filter: saturate(0);
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard .appraiser__color__icon--no-mobile {
    display: none;
  }
}
.appraiser__dashboard .appraiser__color__icon:hover {
  filter: brightness(1.2) hue-rotate(-70deg);
}
.appraiser__dashboard__subheader {
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 3;
}
.appraiser__dashboard__new-witness {
  width: 35px;
  margin: 0 0 0 10px;
  cursor: pointer;
  filter: saturate(0.2);
}
.appraiser__dashboard__new-witness:hover {
  filter: none;
}
.appraiser__dashboard__info {
  background: #FFF;
  width: auto;
  display: flex;
  padding: 0 10px;
  min-height: 45px;
  position: absolute;
  margin: 10px 10px;
  border-radius: 10px;
  align-items: center;
  font-size: 1.2rem;
  font-family: "basicregular", Arial, sans-serif;
}
@media only screen and (max-width: 1022px) {
  .appraiser__dashboard__info {
    width: 100%;
    position: relative;
    margin: 0;
    border-radius: 0;
    padding: 5px;
  }
}
.appraiser__dashboard__info__title {
  margin: 0 0 0 20px;
  color: #a86200;
  font-size: 2rem;
  line-height: 1;
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard__info__title {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard__info__title strong {
    display: none;
  }
}
.appraiser__dashboard__info span {
  border-left: 2px solid #7F7F7F;
  margin: 0 0 0 10px;
  padding: 0 0 0 10px;
  min-width: 100px;
  cursor: pointer;
  text-align: center;
  color: #7F7F7F;
}
.appraiser__dashboard__info span.--active {
  color: #002cff;
  font-weight: bold;
}
.appraiser__dashboard__menu {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .appraiser__dashboard__menu {
    top: 44px;
    position: absolute;
    display: flex;
    width: 100%;
    max-height: 20px;
    justify-content: center;
    background: #666;
    right: 0;
  }
}
.appraiser__dashboard__menu__item {
  margin: 5px;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  background: #333;
  display: flex;
  cursor: pointer;
  transition: filter 0.2s linear;
  align-items: center;
  justify-content: center;
  filter: saturate(0);
}
@media only screen and (max-width: 639px) {
  .appraiser__dashboard__menu__item {
    margin: 2px 5px;
  }
}
.appraiser__dashboard__menu__item:hover {
  filter: none;
}
.appraiser__dashboard__menu__item.--selected {
  filter: none;
}
.appraiser__dashboard__menu__item.--selected:hover {
  filter: brightness(1.5);
}
.appraiser__dashboard__menu__item.--filters {
  border: 1px solid #00efd1;
  background: #FFF;
}
.appraiser__dashboard__menu__icon {
  height: 30px;
}
.appraiser__section {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 90px);
  height: auto;
  top: 0;
  left: 0;
  background-color: #FFF;
  z-index: 3;
  display: block;
}
.appraiser__section.--map {
  height: calc(100vh - 90px);
}
.appraiser__section__container {
  padding: 20px 30px 40px;
}
@media only screen and (max-width: 1247px) {
  .appraiser__section__container {
    padding: 20px 10px 40px;
  }
}
.appraiser__results {
  position: fixed;
  width: 100%;
  padding: 1vh 5%;
  bottom: 0;
  background-color: #333;
  border-top: 5px solid #7F7F7F;
  transition: border-color 0.2s linear;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1439px) {
  .appraiser__results {
    padding: 1vh 3%;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__results {
    align-items: flex-end;
  }
}
.appraiser__results.--v-verde {
  border-top: 5px solid #74f37e;
}
.appraiser__results.--v-amarillo {
  border-color: #ffd100;
}
.appraiser__results.--v-rojo {
  border-color: #ff5555;
}
.appraiser__results__property {
  font-size: 1.3rem;
  font-family: "special", Arial, sans-serif;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 1022px) {
  .appraiser__results__property {
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__results__property {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser__results__property > div {
    width: 45%;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__results__property > div {
    width: 100%;
  }
}
.appraiser__results__property__property {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}
@media only screen and (max-width: 1247px) {
  .appraiser__results__property__property {
    display: none;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser__results__property__property {
    display: flex;
    text-align: right;
    padding: 15px;
  }
}
.appraiser__results__property__property h3 {
  padding: 0 10px 0 0;
  width: 40%;
  margin: 0;
  font-size: 1.4rem;
  font-family: special, Arial, sans-serif;
  color: #74f37e;
  letter-spacing: 0;
  min-width: 100px;
  transition: color 0.2s linear;
}
.--v-amarillo .appraiser__results__property__property h3 {
  color: #ffd100;
}
.--v-rojo .appraiser__results__property__property h3 {
  color: #ff5555;
}
@media only screen and (max-width: 1439px) {
  .appraiser__results__property__property h3 {
    font-size: 1.2rem;
    width: 100%;
  }
}
.appraiser__results__property__property span {
  opacity: 0.7;
  font-size: 1.3rem;
  font-family: basicbold, Arial, sans-serif;
  margin-right: 10px;
}
.appraiser__results__property__price, .appraiser__results__property__info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 1vh 0;
}
.appraiser__results__property__price sup, .appraiser__results__property__info sup {
  font-size: 0.6em;
}
.appraiser__results__property__info {
  padding: 5px 10px;
  border: solid #c301a2;
  border-width: 0 2px;
  margin-right: 20px;
  min-width: 250px;
  color: #e8cfff;
}
@media only screen and (max-width: 639px) {
  .appraiser__results__property__info {
    border-width: 2px 0;
    margin: 0;
    display: flex;
  }
}
.appraiser__results__property__info span {
  padding: 4px 0;
  text-align: right;
}
.appraiser__results__property__price {
  background: #FFF;
  max-width: 100%;
  min-width: 300px;
  padding: 1vh;
  border-radius: 10px;
  margin: 2vh 0;
}
@media only screen and (max-width: 639px) {
  .appraiser__results__property__price {
    margin: 0;
    border-radius: 0;
  }
}
.appraiser__results__property__price strong {
  font-family: "headers", Arial, sans-serif;
  font-size: 2.4rem;
}
@media only screen and (max-width: 639px) {
  .appraiser__results__property__price strong {
    font-size: 1.5rem;
  }
}
.appraiser__results__finalPrice {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 30px;
  margin: 0 20px;
  width: 100%;
  max-width: 300px;
  top: -10px;
  font-variant: tabular-nums;
}
@media only screen and (max-width: 1022px) {
  .appraiser__results__finalPrice {
    top: -30px;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__results__finalPrice {
    padding-right: 15px;
    margin: 0 35px 0;
    min-height: 56px;
    top: -10px;
  }
}
.appraiser__results__finalPrice .--c {
  font-family: headers, Arial, sans-serif !important;
  transition: color 0.2s linear !important;
}
.--v-verde .appraiser__results__finalPrice * {
  color: #74f37e !important;
}
.--v-amarillo .appraiser__results__finalPrice * {
  color: #ffd100 !important;
}
.--v-rojo .appraiser__results__finalPrice * {
  color: #ff5555 !important;
}
.appraiser__results__finalPrice strong {
  font-size: 2rem;
  opacity: 0.7;
}
@media only screen and (max-width: 639px) {
  .appraiser__results__finalPrice strong {
    font-size: 1.5rem;
  }
}
.appraiser__results__finalPrice .mat-mdc-input-element {
  text-align: right;
  font-size: 3rem !important;
}
@media only screen and (max-width: 639px) {
  .appraiser__results__finalPrice .mat-mdc-input-element {
    font-size: 2rem !important;
  }
}
.appraiser__results__finalPrice .mat-mdc-form-field-infix {
  padding-top: 0;
}
@media only screen and (max-width: 639px) {
  .appraiser__results__finalPrice .mat-mdc-form-field-infix {
    border: none;
  }
}
.appraiser__results__finalPrice .mat-mdc-form-field-wrapper {
  padding-bottom: 0.9em;
}
.appraiser__results__finalPrice span {
  font-size: 1.2rem;
  position: absolute;
  bottom: -20px;
  right: 30px;
}
@media only screen and (max-width: 1022px) {
  .appraiser__results__finalPrice span {
    font-size: 1rem;
    right: 35px;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser__results.accordion__content {
    margin-bottom: 0;
    padding: 0;
    min-height: 120px;
    max-height: unset;
    align-items: flex-end;
  }
}
@media only screen and (max-width: 639px) {
  .appraiser__results.accordion__content {
    min-height: 124px;
  }
}
.appraiser .accordion__trigger {
  position: fixed;
  bottom: 30px;
  left: -14px;
  z-index: 4;
  transform: scale(1.5);
  /*    @include mq('tablet-small') {
        display: block;
      }*/
}

.assessment-list {
  margin-top: 20px;
}
.assessment-list__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.assessment-list__assessment {
  position: relative;
  width: 100%;
  margin: 0 30px 0 0;
  border-bottom: 1px solid #B2B2B2;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
.assessment-list__assessment:nth-of-type(odd) {
  background: rgba(178, 178, 178, 0.1);
}
.assessment-list__assessment:hover {
  background: rgba(217, 206, 0, 0.1);
}
.assessment-list__assessment__data {
  display: flex;
  padding: 8px 0;
}
.assessment-list__assessment__icon {
  display: flex;
  align-items: center;
  flex-grow: 2;
}
.assessment-list__assessment__icon img {
  min-width: 25px;
  max-width: 25px;
  margin: 0 10px;
}
@media only screen and (max-width: 639px) {
  .assessment-list__assessment__icon img {
    margin: 0 3px;
  }
}
.assessment-list__assessment__icon.--rotate {
  filter: hue-rotate(-70deg);
  flex-grow: unset;
}
.assessment-list__assessment__info {
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 639px) {
  .assessment-list__assessment__info {
    flex-wrap: wrap;
  }
}
.assessment-list__assessment__info span {
  border-left: 1px solid #332e7d;
  margin: 2px 0;
  display: flex;
  padding: 0 10px;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .assessment-list__assessment__info span {
    border: none;
    display: inline;
    text-align: right;
  }
}
@media only screen and (max-width: 639px) {
  .assessment-list__assessment__info span:nth-of-type(2) {
    flex-grow: 2;
  }
}
@media only screen and (max-width: 639px) {
  .assessment-list__price {
    text-align: right;
  }
}
.assessment-list__address {
  flex-grow: 2;
  padding: 5px 10px;
  font-size: 1.1rem;
  line-height: 1;
}
.assessment-list__date {
  font-family: special, Arial, sans-serif;
}
.assessment-list__price {
  font-family: headers, Arial, sans-serif;
  min-width: 150px;
  text-align: right;
}
.assessment-list .loader__container {
  z-index: 2;
}

.witnessess__infoWindow {
  display: flex;
}
@media only screen and (max-width: 1022px) {
  .witnessess__infoWindow {
    flex-direction: column;
  }
}
.witnessess__infoWindow__picture {
  overflow: hidden;
  max-width: 200px;
  min-width: 200px;
}
@media only screen and (max-width: 1022px) {
  .witnessess__infoWindow__picture {
    max-width: 95%;
    text-align: center;
    margin: 0 auto;
  }
}
.witnessess__infoWindow__picture img {
  max-height: 150px;
}
.witnessess__infoWindow__ads {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.witnessess__infoWindow__ad {
  margin: 2px 5px;
}
.witnessess__infoWindow__ad:after {
  content: " | ";
}
.witnessess__infoWindow__ad:hover {
  color: #002cff;
}
.witnessess__infoWindow__info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  font-size: 1.1rem;
  margin-left: 10px;
  padding: 0 10px;
  font-family: special, Arial, sans-serif;
  border-left: 1px solid #E5E5E5;
}
.witnessess__infoWindow__info span {
  margin: 0;
  width: 50%;
  min-width: 50%;
}
.witnessess__infoWindow__info strong {
  font-size: 1.2em;
  margin-left: 5px;
}
.witnessess__infoWindow__info a {
  color: #332e7d;
  margin: 5px 0 !important;
}
.witnessess__infoWindow__info a:hover {
  text-decoration: underline;
}
.witnessess__infoWindow__desc {
  margin-bottom: 10px;
  min-width: 100%;
}
.witnessess__infoWindow__toolbar {
  border-top: 1px solid #E5E5E5;
  margin: 5px 0 0;
  padding-top: 5px;
  min-width: 100%;
  display: flex;
  align-items: center;
}
.witnessess__infoWindow__toolbar span {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 0;
  padding: 5px 10px 5px 0;
}
.witnessess__infoWindow__toolbar span:hover {
  color: #000;
}
.witnessess__infoWindow__toolbar img {
  width: 20px;
  filter: saturate(0);
}
.witnessess__infoWindow__selected:hover img,
.witnessess__infoWindow__selected .--ok {
  filter: none;
}
.witnessess__infoWindow__delete.--hide {
  display: none;
}
.witnessess__infoWindow__delete:hover img {
  filter: hue-rotate(118deg) brightness(0.25) saturate(12.5);
}

.witnessess__filters {
  position: relative;
  width: 90%;
  margin: 0 7% 0 3%;
  animation: appear 0.2s linear forwards;
}
.witnessess__filters__title {
  color: #999;
  font-size: 2.5rem;
  margin: -20px 0 30px -5px;
  line-height: 0.8;
  display: flex;
}
@media only screen and (max-width: 639px) {
  .witnessess__filters__title {
    font-size: 2rem;
  }
}
.witnessess__filters__notenough {
  color: #ff5555;
}
.witnessess__filters__disabled {
  display: flex;
  align-items: center;
  min-height: 45px;
  padding: 16px 0 0 0;
  font-size: 1.1rem;
  color: #999;
}
.witnessess__filters__item {
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 65px;
}
.witnessess__filters__subtitle {
  margin: 10px 32px -4px 0;
  font-family: headers, Arial, sans-serif;
  font-size: 1.2rem;
  text-align: left;
  width: 46px;
  line-height: 1;
}
.witnessess__filters__subtitle.--form {
  margin-top: 5px;
}
.witnessess__filters__slider {
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: basic, Arial, sans-serif;
}
.witnessess__filters__slider mat-slider {
  min-width: 100%;
}
.witnessess__filters__form-field {
  max-width: calc(100% - 80px);
  font-size: 1.4rem;
}
.witnessess__filters__legend {
  color: #191919;
  font-size: 1rem;
  opacity: 0.6;
}
.witnessess__filters__current {
  font-size: 1.2rem;
  font-weight: bold;
  color: #859836;
}
.witnessess__filters__current small {
  font-weight: lighter;
}
.witnessess__filters__reset {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  font-size: 1.3rem;
  font-family: basic, Arial, sans-serif;
  letter-spacing: 0;
  font-weight: lighter;
  width: 40px;
  line-height: 1;
  border-left: 1px solid #7F7F7F;
  filter: saturate(0);
  transition: all 0.2s linear;
}
.witnessess__filters__reset:hover {
  color: #000;
  filter: none;
}
.witnessess__filters__reset:hover img {
  transform: scale(1.1);
}
.witnessess__filters__reset__icon {
  width: 23px;
  margin: 0 7px;
}
.witnessess__filters .--unused {
  filter: saturate(0) brightness(2);
}

horus-scorecard {
  display: block;
  min-height: 100%;
}

.scorecard {
  padding: 50px 25px 150px;
}
@media only screen and (max-width: 1439px) {
  .scorecard {
    padding: 50px 5px 150px;
  }
}
.scorecard__table {
  width: 100%;
  margin: 30px auto;
  max-width: 1750px;
  border-spacing: 0;
  border-collapse: collapse;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table {
    margin: 70px auto;
  }
}
.scorecard__table label {
  font-size: 1rem;
  left: -5px;
  position: relative;
}
.scorecard__table__body td:nth-child(n+4):nth-child(-n+6) {
  background: #F1F1F1;
}
.scorecard__table__body td:nth-of-type(5) {
  font-size: 1rem;
}
.scorecard__table__body.--k1 td:nth-of-type(4) {
  background: #f1ffd1;
}
.scorecard__table__body.--k2 td:nth-of-type(5) {
  background: #f1ffd1;
}
.scorecard__table__body.--k3 td:nth-of-type(6) {
  background: #f1ffd1;
}
.scorecard__table__witness {
  transition: background 0.2s linear;
  cursor: pointer;
}
.scorecard__table__witness td:first-child {
  padding-left: 0;
}
.scorecard__table__witness:hover td {
  color: #000;
}
.scorecard__table tr {
  height: 42px;
}
.scorecard__table tr.--checked {
  background: rgba(255, 246, 172, 0.3);
}
@media only screen and (max-width: 1101px) {
  .scorecard__table tr {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin: 5px;
    padding: 5px 20px;
    border: 1px solid #B2B2B2;
    border-radius: 5px;
    justify-content: space-around;
  }
  .scorecard__table tr:nth-of-type(1) {
    border: 1px solid #E5E5E5;
    flex-wrap: wrap;
  }
}
.scorecard__table th, .scorecard__table td {
  position: relative;
  text-align: center;
  padding: 10px 5px;
}
.scorecard__table th:nth-child(3), .scorecard__table td:nth-child(3) {
  color: #4C4C4C;
  cursor: default;
  width: 30%;
}
.scorecard__table td {
  padding: 5px;
  border-bottom: 1px solid #E5E5E5;
  font-weight: bold;
  font-family: "special", Arial, sans serif;
}
.scorecard__table td.--no-data {
  color: #002cff;
  font-style: italic;
  font-size: 1em;
  text-align: center;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td:first-child {
    margin-left: -15px;
    width: 0;
    top: -5px;
  }
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td:nth-child(2) {
    position: absolute;
    top: 30px;
    left: -8px;
    z-index: 0;
    width: 0;
  }
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td:nth-child(3) {
    width: 100%;
    padding: 10px;
  }
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td:nth-child(n+4):nth-child(-n+10) {
    width: 12%;
    min-width: 60px;
    word-break: break-all;
    flex-grow: 2;
  }
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td:last-child {
    position: absolute;
    right: 7px;
    width: 0;
  }
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td {
    background: transparent !important;
    border-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 5px;
    text-align: left;
    width: 20%;
  }
  .scorecard__table td:before {
    margin-right: 10px;
    content: attr(data-column);
    font-size: 0.9rem;
    color: #333;
  }
}
.scorecard__table td[data-column] {
  flex-direction: row;
  border-top: 1px solid #E5E5E5;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table td[data-column] {
    width: 33.33333333%;
    padding: 5px 0 5px 6%;
  }
}
@media only screen and (max-width: 559px) {
  .scorecard__table td[data-column] {
    width: 50%;
    padding: 5px 0 5px 10px;
  }
}
.scorecard__table th {
  border-bottom: 2px solid #CCC;
  border-left: 1px solid #E5E5E5;
  font-size: 0.9rem;
  color: #00efd1;
  cursor: pointer;
  border-radius: 9px 9px 0 0;
}
.scorecard__table th.scorecard__header__selector {
  padding: 0;
}
.scorecard__table th:first-child, .scorecard__table th:nth-child(n+12) {
  border-left: 0;
}
.scorecard__table th.--link {
  color: #4C4C4C;
}
.scorecard__table th.--highlighted {
  color: #000;
  border-radius: 9px 11px 0 0;
  font-family: "headers", Arial, sans serif;
}
.scorecard__table th.--final {
  border-radius: 11px 9px 0 0;
}
.scorecard__table .--amarillo .scorecard__table__color {
  background-color: #ffd100;
  color: #000;
}
.scorecard__table .--amarillo:hover td {
  border-bottom: 1px solid #ffd100;
}
.scorecard__table .--verde .scorecard__table__color {
  background-color: #64c76b;
}
.scorecard__table .--verde:hover td {
  border-bottom: 1px solid #64c76b;
}
.scorecard__table .--rojo .scorecard__table__color {
  background-color: #ff5555;
}
.scorecard__table .--rojo:hover td {
  border-bottom: 1px solid #ff5555;
}
.scorecard__table__score {
  position: absolute;
  top: 0;
  left: -5px;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table__score {
    left: 10px;
  }
}
.scorecard__table__color {
  font-family: headers, Arial, sans-serif;
  color: #FFF;
  font-size: 1.2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-radius: 50%;
  letter-spacing: -0.1rem;
}
.scorecard__table .--highlighted {
  background: #ffe8d2;
  min-width: 130px;
  font-size: 1.2rem;
  border-bottom: 1px solid #ffe8d2;
}
@media only screen and (max-width: 1439px) {
  .scorecard__table .--highlighted {
    min-width: 100px;
  }
}
@media only screen and (max-width: 1247px) {
  .scorecard__table .--highlighted {
    min-width: 80px;
  }
}
.scorecard__table .--final {
  background: #f1ded3;
  border-left: 2px solid #FFF !important;
  border-bottom: 1px solid #f1ded3;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table .--final {
    border-left: 0 !important;
  }
}
.scorecard__table .--price-visita {
  background: #fdd290;
  border-bottom: 1px solid #fdd290;
}
.scorecard__table .--price-venta {
  background: #fff6ac;
  border-left: 2px solid #FFF !important;
  border-bottom: 1px solid #fff6ac;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table .--price-venta {
    border-left: 0 !important;
  }
}
.scorecard__table .--special {
  font-family: headers, Arial, sans-serif;
  font-size: 1.2rem;
  color: #7F7F7F;
}
.scorecard__table a {
  transition: color 0.1s linear;
  margin: auto;
  font-size: 0.9rem;
  line-height: 1.2;
  display: block;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table a {
    display: inline;
  }
  .scorecard__table a:after {
    content: " | ";
  }
}
.scorecard__table a:hover {
  color: #002cff;
}
.scorecard__table a.--horus {
  border: none;
}
.scorecard__table a.--horus img {
  width: 30px;
}
.scorecard__table__delete {
  min-width: 40px;
}
@media only screen and (max-width: 1101px) {
  .scorecard__table__delete {
    min-width: 20px;
    padding-right: 0;
  }
}
.scorecard__header {
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1;
  font-weight: normal;
  font-family: "headers", Arial, sans-serif;
}
@media only screen and (max-width: 1101px) {
  .scorecard__header {
    display: none;
  }
}
.scorecard__header__ico {
  display: block;
  margin: 0 auto 5px;
}
.scorecard__header__selector {
  line-height: 1.6;
}
.scorecard__header__selector div {
  color: #666;
  cursor: pointer;
  padding: 0 5px;
}
.scorecard__header__selector div:hover, .scorecard__header__selector div.--active {
  color: #00efd1;
}
.scorecard__k {
  font-size: 1rem;
  min-width: 95px;
  text-align: left !important;
}
@media only screen and (max-width: 1247px) {
  .scorecard__k {
    min-width: 60px;
  }
}
.scorecard__resp {
  width: 100% !important;
}
.scorecard__resp__tool {
  display: none !important;
  font-size: 1.1rem;
  cursor: pointer;
}
@media only screen and (max-width: 1101px) {
  .scorecard__resp__tool {
    display: inline-block !important;
  }
}
.scorecard__order {
  width: 13px;
  position: absolute;
  margin: -3px 3px;
  display: none;
  bottom: 2px;
  left: calc(50% - 10px);
  filter: sepia(1) contrast(0.5) saturate(33) hue-rotate(146deg);
}
.scorecard__order.--false {
  display: inline;
  transform: rotate(180deg);
}
.scorecard__order.--true {
  display: inline;
}
.scorecard__resp-ico {
  display: none;
  margin-bottom: 2px;
}
@media only screen and (max-width: 1101px) {
  .scorecard__resp-ico {
    display: block;
  }
}
.scorecard__selector {
  display: block;
  border: 2px solid #999;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background: #FFF;
}
.scorecard__selector:hover img {
  opacity: 0.2;
}
.scorecard__selector img {
  margin: -7px 0 0 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.scorecard__selector img.--main {
  filter: hue-rotate(80deg);
}
.scorecard__selector img.--checked {
  opacity: 1;
}
.scorecard__sold {
  font-size: 1.5rem;
  color: #4aa90b;
}

.witness-edition__header {
  position: relative;
  top: -20px;
}
.witness-edition__address {
  margin: 10px 0;
  font-size: 1.1rem;
}
.witness-edition__ads {
  display: flex;
}
.witness-edition__ads a {
  transition: color 0.2s linear;
  margin: 0 10px 0 0;
}
.witness-edition__ads a:after {
  content: " | ";
}
.witness-edition__ads a:hover {
  color: #002cff;
}
.witness-edition__title {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
}
.witness-edition__title a {
  font-size: 1.5rem;
}
.witness-edition__title img {
  width: 30px;
  margin-right: 10px;
}
.witness-edition__title-new {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 2rem;
  margin: 0;
}
.witness-edition__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.witness-edition__txt-img {
  margin: 20px 0;
  line-height: 1.2;
  display: flex;
  width: 100%;
}
.witness-edition__description {
  min-width: 47.5%;
  margin-right: 2.5%;
}
.witness-edition__images {
  min-width: 45%;
}
.witness-edition__images horus-custom-lightbox {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.witness-edition__images horus-uploader {
  border-radius: 0;
  background: transparent;
  border: 5px dashed #CCC;
  display: block;
  min-width: 100%;
  margin-bottom: 60px;
}
.witness-edition__images horus-uploader p {
  opacity: 1;
  border-radius: 0;
}
.witness-edition horus-url-input {
  min-width: 100%;
  margin: 20px 0;
}
.witness-edition .witness-edition__price {
  font-family: headers, Arial, sans-serif;
  font-size: 1.6rem;
  text-align: right;
}
.witness-edition .custom-lightbox__container {
  width: 18%;
  margin: 1%;
}
.witness-edition__sold {
  filter: saturate(0);
}
.witness-edition__sold:hover, .witness-edition__sold.mat-mdc-checkbox-checked {
  filter: none;
}
.witness-edition__sold img {
  margin: 5px 2px -3px 20px;
}
.witness-edition__sold .mdc-checkbox {
  position: absolute;
  opacity: 0;
}

.appraiser-widget {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  background: #333;
}
.appraiser-widget.--void {
  border: 1px solid #00efd1;
  background: #f9f9f9;
}
.appraiser-widget__header {
  margin: 0 0 5px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.appraiser-widget__title {
  display: flex;
  align-items: center;
  color: #6d9d39;
  margin: 0;
}
.appraiser-widget__icon {
  width: 25px;
  margin-right: 5px;
}
.appraiser-widget__container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #B2B2B2;
}
.appraiser-widget__report {
  width: 25px;
  margin: 0 10px -4px 0;
  filter: saturate(0);
  cursor: pointer;
}
.appraiser-widget__report:hover {
  filter: none;
}
.appraiser-widget__report.--noPdf:hover {
  filter: saturate(0);
  cursor: not-allowed;
}
.appraiser-widget__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #B2B2B2;
  cursor: pointer;
  flex-grow: 2;
}
.appraiser-widget__link {
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  user-select: none;
}
@media only screen and (max-width: 1439px) {
  .appraiser-widget__link {
    width: 50%;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser-widget__link {
    width: unset;
  }
}
.appraiser-widget__link:hover {
  color: #FFF;
}
.appraiser-widget__link:hover .appraiser-widget__price {
  filter: brightness(1.5);
}
.appraiser-widget__link:hover .appraiser-widget__horus {
  filter: none;
}
.appraiser-widget__date {
  display: flex;
  align-items: center;
}
.appraiser-widget__date:hover {
  color: #FFF;
}
.appraiser-widget__date:hover .appraiser__results__finalPrice {
  filter: brightness(2);
}
.appraiser-widget__horus {
  margin: 0 10px;
  width: 25px;
  filter: saturate(0);
}
.appraiser-widget__price {
  font-size: 1.8rem;
  margin: 0;
  text-align: right;
  font-family: "headers", Arial, sans-serif;
  width: unset;
  top: 0;
  padding: 0 5px 0 0;
}
@media only screen and (max-width: 1247px) {
  .appraiser-widget__price {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1022px) {
  .appraiser-widget__price {
    font-size: 2rem;
  }
}
.appraiser-widget__price:after {
  display: none;
}
.appraiser-widget__price.--external {
  color: #CCC;
}
.appraiser-widget__price.--verde {
  color: #74f37e;
}
.appraiser-widget__price.--amarillo {
  color: #ffd100;
}
.appraiser-widget__price.--rojo {
  color: #ff5555;
}
.appraiser-widget__delete {
  width: 25px;
  margin-top: -3px;
  filter: brightness(3);
  opacity: 0.5;
  cursor: pointer;
}
.appraiser-widget__delete:hover {
  filter: hue-rotate(-62deg) saturate(6) brightness(3.5);
}
.appraiser-widget__delete-advice {
  opacity: 0;
  width: 0;
  color: #FFF;
  position: absolute;
  transition: all 0.2s linear;
  height: 100%;
  font-family: headers, Arial, sans-serif;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  overflow: hidden;
  z-index: 0;
}
.appraiser-widget__delete-advice.--open {
  width: 100%;
  opacity: 1;
}
.appraiser-widget__delete-cta {
  background: rgba(255, 85, 85, 0.9);
  padding: 5px 10px;
  margin: auto;
  border-radius: 10px;
  border: 2px solid #ff5555;
}
.appraiser-widget__delete-cta:hover {
  border-color: #000;
}
.appraiser-widget__nav {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.appraiser-widget__nav-item {
  max-width: 95px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  color: #4C4C4C;
  cursor: pointer;
  transition: color 0.1s linear;
  user-select: none;
}
.appraiser-widget__nav-item:hover {
  color: #000;
}
.appraiser-widget__nav-item:hover img {
  filter: brightness(0);
}
.appraiser-widget__nav img {
  transition: filter 0.1s linear;
  margin-bottom: 5px;
}
.appraiser-widget__external {
  border-top: 1px solid #666;
  background: #FFF;
  padding: 10px;
  border-radius: 0 0 10px 10px;
}
.appraiser-widget__external__field {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}
.appraiser-widget__external__form {
  width: calc(100% - 80px);
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appraiser-widget__external__pdf {
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  border-radius: 50%;
}
.appraiser-widget__external__pdf.--with-pdf {
  background: transparent;
  border: 1px solid #7F7F7F;
}
.appraiser-widget__external__pdf.--loaded {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  left: 0;
  margin: 0;
}
.appraiser-widget__external__pdf.--loaded img {
  max-width: 50%;
}
.appraiser-widget__external horus-uploader p {
  font-size: 0.9rem;
}
.appraiser-widget__external__price {
  font-size: 1.4rem;
}
.appraiser-widget__external__price .mat-mdc-input-element {
  font-family: "headers", Arial, sans-serif !important;
  text-align: right;
}
.appraiser-widget__external .createbutton {
  transform: scale(0.8);
}

horus-custom-lightbox {
  width: 100%;
}

.custom-lightbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.custom-lightbox__img {
  width: 20%;
  margin: 1%;
  min-width: 150px;
}

.custom-form {
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
}
.custom-form__title {
  min-width: 100%;
  margin: 5px 0;
}
.custom-form__field {
  margin: 10px 1%;
  flex-grow: 2;
  width: auto;
  max-width: 350px;
  width: 33%;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .custom-form__field {
    min-width: 48%;
  }
}
@media only screen and (max-width: 559px) {
  .custom-form__field {
    margin: 10px auto;
    min-width: 100%;
  }
}
.custom-form__field .--currency {
  font-size: 1.5rem !important;
  font-family: "headers", Arial, sans-serif !important;
  text-align: right;
}
.custom-form__fieldset {
  margin: 10px auto;
  min-width: 100%;
}
.custom-form__files {
  display: flex;
  align-items: center;
  min-width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 10px;
}
.custom-form__mailing {
  min-width: 100%;
  margin-bottom: 15px;
  border: 1px solid #E5E5E5;
  padding: 10px;
  border-radius: 20px;
}
.custom-form__mailing__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  flex-wrap: wrap;
}
.custom-form__mailing__sent {
  font-family: basic, Arial, sans-serif;
  font-size: 1.2rem;
  color: #7F7F7F;
  margin-bottom: 20px;
  padding-left: 15px;
}
.custom-form__mailing__sent__title {
  font-style: italic;
  font-size: 1.4rem;
  color: #7F7F7F;
  border-bottom: 1px solid #E5E5E5;
  margin-top: 10px;
}
.custom-form__mailing__sent__files {
  display: flex;
  flex-wrap: wrap;
}
.custom-form__mailing__sent__link {
  width: 75px;
  height: auto;
  margin: 0 10px 0 0;
  font-size: 0.8em;
  word-break: break-all;
}
.custom-form__mailing__sent strong {
  display: block;
  margin: 0 0 10px 0;
  color: #000;
}
.custom-form__mailing__sent img {
  width: 100%;
  filter: saturate(0);
}
.custom-form__mailing__sent img:hover {
  filter: none;
}
.custom-form__mailing .--attached {
  margin-bottom: 30px;
}
.custom-form__mailing h3 {
  flex-grow: 2;
}
.custom-form__mailing .mat-mdc-form-field {
  max-width: 220px;
}
.custom-form__mailing .createbutton {
  padding: 5px;
}
.custom-form__requireds {
  font-weight: bold;
  color: #ca4a48;
  font-size: 1.2rem;
}
.custom-form .--error {
  font-family: "headers", Arial, sans-serif !important;
  color: #ff6a00;
}

horus-url-input {
  display: block;
  width: 100%;
}

.url-input__field {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 45px;
  font-size: 1.2rem;
}
.url-input__field .mat-mdc-form-field {
  width: 0;
  flex-grow: 2;
}
.url-input__link {
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 639px) {
  .url-input__link {
    margin: 0 0 0 5px;
  }
}
.url-input__link img {
  max-width: 25px;
}

.info {
  position: relative;
  font-family: basic, Arial, sans-serif;
  font-size: 1.3rem;
  line-height: 1.6;
  max-width: 1800px;
  padding: 50px 5vw 200px;
  margin: auto;
  height: auto;
  display: flex;
  color: #333;
}
.info__logo {
  margin: 8vh auto 0;
  cursor: pointer;
  width: 90%;
  max-width: 400px;
}
.info__title {
  font-size: 3rem;
  text-transform: lowercase;
  line-height: 1;
  font-family: headers, Arial, sans-serif;
}
.info__title:first-letter {
  text-transform: uppercase;
}
.info__intro {
  font-style: italic;
  font-size: 1.2rem;
}
.info h2 {
  font-family: special, Arial, sans-serif;
  color: #000;
}
.info li {
  margin-bottom: 5px;
}
.info h3 {
  font-family: special, Arial, sans-serif;
  color: #333;
}
.info table {
  border: 1px solid #CCC;
}
.info tr {
  border: solid #CCC;
  border-width: 1px 0;
}
.info td {
  margin: 0;
  padding: 10px;
  border: solid #CCC;
  border-width: 1px;
}
.info main {
  min-width: 80%;
}
.info aside {
  margin: 0 0 0 5%;
  padding: 2%;
  min-width: 15%;
  border-left: 1px solid #7F7F7F;
}
.info__nav {
  display: flex;
  flex-direction: column;
}
.info__link {
  line-height: 1;
  margin: 10px 0;
}
.info__link.--sel {
  font-weight: bold;
  color: #000;
}
.info__signature {
  padding: 5%;
  color: #000;
  font-size: 1.3rem;
}
.info__signature img,
.info__signature .mdc-form-field {
  margin-top: 20px;
}
.info__signature .mdc-label {
  font-size: 1.3rem;
}

.v-report {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
}
.v-report .appraiser__dashboard__header {
  padding: 10px 20px;
  color: #FFF;
}
@media only screen and (max-width: 639px) {
  .v-report .appraiser__dashboard__header {
    padding: 10px 0 10px 10px;
  }
}
.v-report .appraiser__dashboard__icon {
  background-color: #FFF;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  justify-content: center;
  display: flex;
  transform: scale(0.6);
}
.v-report__generate {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
}
.v-report__generate img {
  width: 120px;
  transition: transform 0.2s linear;
  margin: 20px auto;
}
.v-report__generate figcaption {
  font-family: headers, Arial, sans-serif;
  font-size: 3rem;
  color: #00efd1;
}
.v-report__link {
  font-size: 1.5rem;
  margin: 20px 0;
}
.v-report__link a {
  cursor: pointer;
  color: #332e7d;
}
.v-report__link a:hover {
  color: #ff58ea;
}
.v-report small {
  font-family: special, Arial, sans-serif;
}
.v-report__pies {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.v-report__pies__pie {
  height: 300px;
  min-width: 46%;
  margin: 4% 2%;
}
.v-report .--amarillo {
  background-color: #ffd100;
  color: #000;
}
.v-report .--verde {
  background-color: #859836;
  color: #FFF;
}
.v-report .--rojo {
  background-color: #ff5555;
  color: #FFF;
}

#termometro {
  position: absolute;
  height: 600px;
  left: 55%;
  margin-left: -25px;
}

.priceRanges__precios-quizas {
  top: 482px;
}

.priceRanges__precios-visitas {
  top: 682px;
}

.--priceRanges {
  width: 60%;
  position: absolute;
  margin: 0 15%;
  background: #CCC;
  height: 1px;
}

#page-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 100px 0;
  border: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(158, 158, 158, 0.95);
}

.page {
  width: 21cm;
  height: 29.7cm;
  margin: 13px auto;
  box-shadow: 1px 1px 3px 1px #333;
  border-collapse: separate;
  position: relative;
  background-color: #FFF;
  overflow: hidden;
  border: 0;
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s linear;
}
.page:nth-of-type(even) .page__header__title {
  margin: 0 20% 0 0;
  padding-left: 20%;
}
.page.--hiddenPage:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 2;
}
.page.--withbk {
  background: linear-gradient(180deg, #FFF 70%, #B2B2B2);
}
.page.fullpage {
  justify-content: center;
  align-items: center;
}
.page__hide {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  margin: 0;
  height: 60px;
  width: 60px;
  cursor: pointer;
  filter: saturate(0);
  opacity: 0.5;
  transition: all 0.2s linear;
}
.page__hide:hover {
  filter: saturate(1);
  opacity: 1 !important;
}
.page__fullpage {
  max-width: 101%;
  min-height: 100%;
}
.page__header {
  width: 100%;
  position: relative;
  color: #FFF;
  text-shadow: 0 0 10px #000;
  max-height: 300px;
  overflow: hidden;
}
.page__header.--mini {
  max-height: 250px;
}
.page__header__back {
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.page__header__back__img {
  height: auto;
  width: 100%;
}
.page__header__title {
  padding: 0 0 20px;
  margin: 0 0 0 20%;
  width: 80%;
  position: absolute;
  line-height: 1.1;
  border-bottom: 6px solid #FFF;
  left: 0;
}
.page__header__title h2 {
  margin: 0;
  font-size: 4.05rem;
  letter-spacing: 0;
}
.page__header__title__intro {
  display: block;
  font-size: 0.4em;
  margin: 0 0 10px;
}
.page__intro {
  position: absolute;
  padding: 13% 50% 10% 10%;
  height: 100%;
  color: #FFF;
  font-size: 1.2rem;
  line-height: 1.5;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
}
.page__backmap {
  position: absolute;
  width: 100%;
  bottom: 60px;
}
.page__content {
  margin: 4% 20%;
  font-size: 1.2rem;
  line-height: 1.3;
  flex-grow: 2;
  text-shadow: none;
  display: flex;
  flex-direction: column;
}
.page__content.--portada {
  z-index: 1;
  margin: -80px auto 4%;
}
.page__content.--fullwidth {
  margin: 4% 5%;
}
.page__content h2, .page__content h3 {
  font-family: "special", Arial, sans-serif;
  text-transform: uppercase;
}
.page__content__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #666;
}
.page__content__user__avatar {
  max-height: 200px;
  margin-bottom: 20px;
  background-color: #FFF;
}
.page__content__user__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
}
.page__content__user__info__name {
  font-size: 2.5rem;
}
.page__content__user__info strong {
  font-family: "headers", Arial, sans-serif;
}
.page__content__user__info a {
  font-size: 2rem;
}
.page__content__user__info a:hover {
  color: #ff5555;
}
.page__content__title {
  color: #ff5555;
  font-size: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  font-family: "headers", Arial, sans-serif;
  line-height: 1;
}
.page__content__title small {
  color: #333;
  font-size: 0.4em;
  font-family: "special", Arial, sans-serif;
}
.page__content__subtitle {
  margin: 0;
}
.page__content__address {
  font-style: italic;
  font-family: "headers", Arial, sans-serif;
  font-size: 1.8rem;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  line-height: 1;
}
.page__content__prop-info {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background: rgba(229, 229, 229, 0.3);
  border-radius: 15px;
  margin: 20px auto;
  max-width: 80%;
  border: 1px solid #B2B2B2;
}
.page__content__prop-item {
  font-size: 1.2rem;
  padding: 0 5px;
  width: 50%;
}
.page__content__prop-item strong {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #7F7F7F;
}
.page__content__main {
  flex-grow: 2;
}
.page__content__link {
  text-align: center;
  margin: auto;
  color: #000;
  padding-bottom: 6%;
}
.page__content__link strong {
  display: block;
}
.page__content__qr {
  max-width: 120px;
}
.page__map {
  height: 300px;
}
.page__poi {
  display: flex;
}
.page__poi h2 {
  margin: 0 0 10px;
}
.page__poi horus-map img {
  min-width: 100%;
}
.page__poi__list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
}
.page__poi__list h3 {
  margin: 0;
}
.page__poi__item {
  padding: 0 1%;
  width: 48%;
}
.page__poi__item:nth-of-type(2), .page__poi__item:nth-of-type(3) {
  width: 26%;
}
.page__poi__item ol {
  padding-left: 18px;
}
.page__source {
  font-style: italic;
  margin: 5px 10%;
  color: #7F7F7F;
}
.page__charts {
  min-width: 100%;
  position: relative;
}
.page__charts .total-value,
.page__charts .legend-title-text,
.page__charts .item-value {
  display: none !important;
}
.page__charts .item-label {
  margin-top: 0 !important;
}
.page__charts__legend {
  border-top: 1px solid #B2B2B2;
  padding: 10px 20px 10px 10px;
  margin: 10px 0 0 0;
  line-height: 1;
  width: 100%;
  position: relative;
  top: 629px;
  color: #666;
}
.page__charts__legend ul {
  display: flex;
  max-width: 90%;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.page__charts__legend li {
  min-width: 40%;
  margin-left: 10%;
}
.page__charts__recommended-price {
  font-size: 2rem;
  position: absolute;
  left: 490px;
  top: 490px;
  color: #74f37e;
  font-family: headers, Arial, sans-serif;
  padding: 10px 0 0 10px;
}
.page__charts__recommended-price .page__charts__your-price__legend {
  top: 40px;
}
.page__charts__your-price {
  font-size: 2.5rem;
  font-family: headers, Arial, sans-serif;
  position: absolute;
  left: 490px;
  padding: 5px 10px;
  border-radius: 10px;
}
.page__charts__your-price__img {
  height: 26px;
  position: absolute;
  top: 14px;
  left: -68px;
  opacity: 0.5;
  filter: hue-rotate(-106deg);
}
.--rojo .page__charts__your-price__img {
  filter: hue-rotate(-175deg);
}
.--amarillo .page__charts__your-price__img {
  filter: hue-rotate(-147deg);
}
.page__charts__your-price__legend {
  position: absolute;
  top: 55px;
  font-family: special, Arial, sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 1.1rem;
  width: 200px;
  line-height: 1;
  left: 0;
}
.page__witnessess {
  display: flex;
  align-items: center;
  max-height: 150px;
  overflow: hidden;
  border-bottom: 1px solid #7F7F7F;
  padding: 20px 0;
}
.page__witnessess:last-child {
  border: 0;
}
.page__witnessess__number {
  display: flex;
  min-width: 0.7cm;
  min-height: 0.7cm;
  max-height: 0.7cm;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: "headers", Arial, sans-serif;
  position: absolute;
  left: 30px;
}
.page__witnessess__img-cont {
  overflow: hidden;
  max-width: 130px;
  min-width: 130px;
  margin: 0 10px;
  border: 1px solid #CCC;
  height: 100%;
}
.page__witnessess__img {
  min-height: 100%;
  width: auto;
  max-height: 100%;
}
.page__witnessess__data {
  flex-grow: 2;
}
.page__witnessess__data h3 {
  margin: 0;
  color: #666;
  display: inline;
}
.page__witnessess__data__items {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  text-align: center;
  font-family: "special", Arial, sans-serif;
  font-weight: bold;
  font-size: 1rem;
}
.page__witnessess__data__items figure {
  padding: 0 5px;
  border-right: 1px solid #B2B2B2;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70px;
  line-height: 1;
}
.page__witnessess__data__items figure:last-child {
  border: 0;
}
.page__witnessess__data__items img {
  margin: 0 0 10px;
}
.page__witnessess__data__items img.--nope {
  filter: saturate(0);
}
.page__witnessess__data__price {
  font-family: "headers", Arial, sans-serif;
  font-size: 1.6rem;
  min-width: 141px;
  text-align: right;
  padding-left: 10px;
  line-height: 1;
}
.page__witnessess__data__price small {
  font-size: 0.7em;
}
.page__advice {
  position: absolute;
  left: 5%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  min-height: 140px;
  filter: saturate(0) brightness(0);
  opacity: 0.2;
}
.page__advice._active {
  filter: none;
  opacity: 1;
}
.page__advice h2 {
  font-family: "headers", Arial, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0 0 20px;
}
.page__advice._verde {
  top: 430px;
}
.page__advice._verde h2 {
  color: #859836;
}
.page__advice._rojo {
  top: 24px;
}
.page__advice._rojo h2 {
  color: #c12900;
}
.page__advice._amarillo {
  top: 207px;
  height: 185px;
}
.page__advice._amarillo h2 {
  color: #f9ba19;
}
.page__advice__recommended {
  position: relative;
  top: 637px;
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1;
  font-family: headers, Arial, sans-serif;
  padding-bottom: 5px;
  color: #4C4C4C;
}
.page__advice__recommended__price {
  font-size: 2.5rem;
}
.page__advice__recommended__price:nth-of-type(2) {
  color: #859836;
}
.page__advice__recommended span {
  flex-grow: 2;
}
.page__advice__recommended span:nth-of-type(3) {
  border-left: 1px solid #7F7F7F;
  padding-left: 20px;
}
.page__footer {
  position: relative;
  width: 21.5cm;
}
.page .--forcewhite {
  color: #FFF !important;
}
.page .--white {
  color: #FFF;
  border-color: #FFF;
  text-shadow: none;
}
.page .--black {
  color: #000;
  border-color: #000;
  text-shadow: none;
}

#sidebar {
  position: fixed;
  top: 50px;
  right: 28px;
  width: 40px;
}
@media only screen and (max-width: 639px) {
  #sidebar {
    right: 8px;
  }
}
#sidebar span {
  border-radius: 50%;
  background-color: #333;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.2s linear;
}
@media only screen and (max-width: 639px) {
  #sidebar span:nth-of-type(-n+2) {
    display: none;
  }
}
#sidebar span:nth-of-type(3) {
  display: none;
}
#sidebar span.--disabled {
  filter: saturate(0);
  cursor: default;
  background-color: #CCC;
}
#sidebar span.--disabled:hover {
  background-color: #CCC;
}
#sidebar span.--disabled:hover img {
  max-height: 60%;
}
#sidebar span:hover {
  background-color: #000;
}
#sidebar span:hover img {
  max-height: 70%;
}
#sidebar img {
  transition: max-height 0.2s linear;
  max-height: 60%;
  filter: invert(1);
}

@media screen {
  .--zoom {
    display: flex;
    flex-wrap: wrap;
    padding-right: 100px !important;
  }
}
@media only screen and (max-width: 639px) {
  .--zoom {
    padding-right: 50px !important;
  }
  .--zoom .page {
    margin: -325px -225px !important;
    transform: scale(0.4) !important;
  }
}
@media screen {
  .--zoomtransition .page {
    opacity: 0;
  }
  .--zoom9 .page {
    margin: -25px -10px;
    transform: scale(0.9);
  }
  .--zoom8 .page {
    margin: -90px -60px;
    transform: scale(0.8);
  }
  .--zoom7 .page {
    margin: -150px -105px;
    transform: scale(0.7);
  }
  .--zoom6 .page {
    margin: -200px -135px;
    transform: scale(0.6);
  }
  .--zoom5 .page {
    margin: -265px -180px;
    transform: scale(0.5);
  }
  .--zoom4 .page {
    margin: -325px -225px;
    transform: scale(0.4);
  }
  .--zoom3 .page {
    margin: -385px -270px;
    transform: scale(0.3);
  }
  .--zoom2 .page {
    margin: -442px -310px;
    transform: scale(0.2);
  }
  .--zoom1 .page {
    margin: -500px -350px;
    transform: scale(0.1);
  }
}
@media print {
  @page {
    height: 30cm;
    min-height: 30cm;
    max-height: 30cm;
    width: 21.5cm;
    min-width: 21.5cm;
    max-width: 21.5cm;
    margin: 0 !important;
  }
  html {
    margin: 0;
  }
  body {
    margin: 0;
    -webkit-print-color-adjust: exact;
  }
  .v-report {
    position: relative;
    height: auto;
  }
  #sidebar, .v-report__generate, .page__hide {
    display: none;
  }
  #page-container {
    width: auto;
    height: auto;
    overflow: visible;
    background-color: transparent;
    background-image: none;
    padding: 0;
  }
  .page {
    page: blank;
    height: 30cm;
    min-height: 30cm;
    max-height: 30cm;
    width: 21.5cm;
    min-width: 21.5cm;
    max-width: 21.5cm;
    margin: 0 !important;
    box-shadow: none;
    page-break-after: always;
    page-break-inside: avoid;
    position: relative;
    background-color: #FFF;
    overflow: hidden;
    border: 0;
    text-shadow: none;
    transform: unset !important;
  }
  .page.--hiddenPage {
    display: none;
  }
  @-moz-document url-prefix() {
    .page {
      overflow: visible;
      border: 1px solid #FFF;
    }
  }
  .appraiser__dashboard__header,
  .appraiser__dashboard__footer,
  .appraiser__dashboard__menu,
  .appraiser__dashboard__info,
  .appraiser__results {
    display: none;
  }
}
horus-footer {
  position: relative;
  top: 0;
  background: #000;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #859836;
  font-weight: bold;
  font-family: special, Arial, sans-serif;
}
horus-footer.blocked {
  top: unset;
  position: fixed;
  bottom: 0;
}

.footer__docs {
  color: #7F7F7F;
  margin-left: 10px;
  font-size: 1.3rem;
  border-left: 1px solid #FFF;
  padding-left: 10px;
}
.footer__docs:hover {
  color: #FFF;
}