.main-search-engine{
  .cdk-overlay-pane {
    @include mq('tablet-small'){
      transform: none !important;
      left: 0 !important;
      max-width: 100% !important;
      width: 99% !important;
    }
  }
  &__option {
    font-size: 1.3rem !important;
    padding-left: 10px !important;
    &.mat-mdc-option {
      color: $rk_horus09 !important;
      img{
        border-radius: 0;
        margin:0 5px 0 0;
      }
      em{
        color: $rk_grey6;
        margin-left: 0;
      }
      strong {
        color: $rk_loader1;
      }
    }
  }
}

