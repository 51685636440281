.step-en-cierre {

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $rk_grey3;
    padding-bottom: 10px;
    margin: 0 0 10px;
    min-height: 50px;
    user-select: none;
  }
  &__title {
    margin: 0;
    font-size: 2.5rem;
    color: $rk_step4;
    display: flex;
    align-items: center;
  }
  &__nav{
    display: flex;
  }
  &__new {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $rk_grey4;
    &:hover {
      color: $rk_black;
    }
    img{
      margin-bottom: 5px;
      filter: none;
      height: 30px;
    }
  }
  &__offer {
    position: relative;
    padding: 10px;
    border: 1px solid $rk_step4;
    border-radius: 10px;
    background-color: rgba($rk_step4, .1) ;
    &-title{
      display: flex;
      align-items: center;
      margin: 0;
      color: $rk_step4;
      filter: saturate(.7);
      padding-right: 32px;
      line-height: .7;
      img{
        margin: -4px 0 0 0;
        max-width: 30px;
      }
    }
    &-subtitle{
      color: $rk_grey6;
      margin: 15px 0 5px;
    }
    &-total {
      font-size: 2.5rem;
      font-family: headers, Arial, sans-serif;
      text-align: right;
      margin: 10px 0 0;
      color: $rk_step4;
    }
    &-edit {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $rk_step4;
      border-radius: 50%;
      background-color: $rk_white;
      img{
        height: 61%;
      }
    }
  }

}
