
.report-ranking {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  h2 {
    width: 100%;
  }

  &__item {
    padding: 16px 10px;
    min-width: 280px;
    width: 32%;
    @include mq(desktop) {
      min-width: 0;
      width: 50%;
    }
    @include mq(phablet) {
      width: 100%;
    }
  }
  &__user {
    display: flex;
    position: relative;
    &:hover {
      img {filter: none}
    }
  }
  &__img {
    height: 100px;
    min-width: 100px;
    width: 100px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid $rk_black;
    transition: filter .2s linear;
    filter: saturate(.3);
    object-fit: cover;
    @include mq(tablet-small) {
      height: 80px;
      min-width: 80px;
      width: 80px;
    }
  }
  &__info {
    font-family: special, Arial, sans-serif;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    @include mq(tablet-small) {
      font-size: 1.1rem;
    }
    @include mq(phablet) {
      width: 100%;
      font-size: 1.3rem;
    }
    &.--yo {
      border-radius: 0 0 20px 0;
      border: solid $rk_grey2;
      border-width: 0 1px 1px 0;
    }
    &__name {
      font-size: 1.5rem;
      margin: 0 0 5px;
      color: $rk_grey6;
    }
    &__pt {
      font-size: 2.5rem;
      color: $rk_gradient1;
    }
  }
  &__position {
    font-family: 'headers', Arial, sans-serif;
    font-size: 2rem;
    background: $rk_white;
    border: 1px solid $rk_black;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1rem;
    left: -5px;
    @include mq(tablet-small) {
      height: 2rem;
      font-size: 1.5rem;
      width: 2rem;
    }
    .--yo & {
      color: $rk_black;
      background-color: $rk_horus06;
    }
  }
}
