
.main-header{
  position: sticky;
  top: 0;
  display: flex;
  height: 50px;
  background-color: $rk_grey8;
  z-index: z(level3);
  justify-content: space-between;
  align-items: center;
  padding:0 1%;
  font-size:1.3rem;
  border-width: 0 0 3px;
  border-style: solid;

  &:after{
    content: " ";
    position: absolute;
    width: 100%;
    height: 26px;
    background-image: linear-gradient(to bottom, $rk_white 75%, rgba($rk_white, 0) 100%);
    top: 50px;
    left: 0;
    z-index: z(bottom);
  }
  &.--noshadow:after{
    display: none;
  }
  &__info{
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 2;
    overflow: hidden;
    @include mq('tablet') {
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__logo{
    max-height: 90%;
    margin:20px 10px 20px 20px;
    padding-right:10px;
    border-width:  0 2px 0 0;
    border-style: solid;
    @include mq('desktop') {
      max-width: 55px;
      overflow-x: hidden;
    }
    @include mq('tablet') {
      padding-right:5px;
      min-width: 55px;
      margin:20px 10px 20px 5px;
    }
    &.--agen img {filter: hue-rotate(402deg) brightness(1.2)}
    &.--tele img {filter: hue-rotate(228deg) brightness(1.1)}
    &.--coor img {filter: hue-rotate(296deg)}
    &.--lega img {filter: hue-rotate(304deg) saturate(4)}
    &.--soci img {filter: hue-rotate(498deg)}
    &.--equi img {filter: hue-rotate(-21deg) saturate(2)}
    &.--admi img {filter: hue-rotate(440deg)}
  }
  &__hamburguer{
    flex-direction:column;
    min-width: 70px;
    max-width: 70px;
    cursor:pointer;
    transform: scale(.6);
    display:none;

    @include mq('tablet') {
      display:flex;
    }
    span{
      background: $rk_white;
      border-radius:10px;
      height:7px;
      margin: 7px 0;
      transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

      &:nth-of-type(1){
        width:50%;
      }

      &:nth-of-type(2){
        width:100%;
      }

      &:nth-of-type(3){
        width:75%;
      }
    }

    &.--checked span {
      &:nth-of-type(1){
        transform-origin:bottom;
        transform:rotate(45deg) translate(8px,0px)
      }

      &:nth-of-type(2){
        transform-origin:top;
        transform:rotate(-45deg)
      }

      &:nth-of-type(3){
        transform-origin:bottom;
        width:50%;
        transform: translate(30px,-11px) rotate(45deg);
      }
    }
  }
  figure{
    display: flex;
    align-items: center;
    @include mq('tablet-small') {
      margin-right: 5px;
    }
  }
  img{
    height: 30px;
  }
  horus-search-engine{
    flex-grow: 2;
    padding: 0 20px;
    max-width: 500px;
    max-height: 51px;
    @include mq('tablet-small') {
      padding: 0 5px;
    }
    label, .mat-mdc-input-element {
      color: $rk_grey3 !important;
    }
    label{
      @include mq('tablet') {
        max-width:65px;
        overflow-x: hidden;
      }
    }
  }

  &__nav{
    margin:20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    small{
      display: inline-block;
      padding:0 5px;
      color: $rk_horus02;
    }
    @include mq('tablet') {
      width: 100vw;
      min-height: 100vh;
      background: $rk_grey8;
      flex-direction: column;
      left: 0;
      position: fixed;
      margin: 0;
      top: -200vh;
      text-align: center;
      justify-content: flex-start;
      padding-top: 15vh;
      transition: top .3s linear;
      z-index: z(bottom);
      small {
        display: none;
      }

    }
    &:hover {
      .main-header__nav__text{
        opacity: 0;
      }
    }
    &__link{
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      margin:0 10px;
      font-size: 1.2rem;
      width:35px;
      padding-top:27px;
      overflow: visible;
      color:$rk_black;
      font-family: basic, Arial, sans-serif;
      @include mq('tablet') {
        flex-direction: row;
        padding-top:2vh;
        width: 100vw;
        padding-left: 20vw;
        color:$rk_grey2;
        font-size: 1.6rem;
        margin-bottom: 2vh;
      }
      &.active{
        span{
          font-family: headers, Arial, sans-serif;
          opacity: 1;
          @include mq('tablet') {
            color:$rk_white;
          }
        }
      }
      &:hover{
        span.main-header__nav__text{
          opacity: 1;
          color: $rk_grey7;
          @include mq('tablet') {
            color:$rk_white;
          }
        }
      }
    }
    &__img{
      width:30px;
      margin-bottom:15px;
      transition: filter .1s linear;
      @include mq('tablet') {
        width:40px;
        min-height:40px;
        margin-bottom: 0;
      }
    }
    &__text{
      transition: opacity .2s linear;
      opacity: 0;
      @include mq('tablet') {
        opacity: 1 !important;
        margin-left: 30px;
      }
    }
    &.--open-mobile{
      top: 0;
    }
  }

  &__alert {
    position: absolute;
    top:20px;
    right: -10px;
    height: 10px;
    width: 10px;
    filter: none !important;
    @include mq('tablet') {
      right: unset;
      left: 20px;
      height: 20px;
      width: 20px;
    }
  }
}
