.communications {
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    &.--with-plan {
      border-style: solid;
      border-image-slice: 1;
      border-width: 0 0 1px;
      border-image-source: linear-gradient(to right, rgba($rk_horus09, .3), rgba(0, 0, 0, 0));
      margin-bottom:30px;
      img{
        display: none;
      }
    }
  }
  .active {
    margin:10px;
    border: solid $rk_grey1;
    border-width: 0 1px;
    img {
      max-width: 30px;
      display: block;
    }
    h3{
      margin: 10px;
    }
  }
  &__item {
    width: 90%;
    max-width: 100px;
    margin: 20px;
    text-align: center;
    user-select: none;
  }
  &__icons-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__plans{
    &__title {
      font-size: 2rem;
      color: $rk_grey3;
      display: flex;
      align-items: center;
      .--link{
        width:40px;
        display: flex;
        align-items: center;
        border-left: 1px solid $rk_grey3;
        margin-left: 10px;
        padding-left: 10px;
        img{
          max-width: 100%;
        }
      }
    }
  }
}
.basic-plan {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &__left {
    width: 70%;
    padding:0 3% 0 0;
    @include mq('tablet') {
      width: 100%;
      padding:0 2%;
    }
  }
  &__right {
    width: 30%;
    padding:0 0 0 3%;
    border-left: 1px solid $rk_grey1;
    @include mq('tablet') {
      width: 100%;
      border: 0;
      padding: 0;
    }
  }
  &__title{
    font-size:1.5rem;
    color: $rk_grey4;
    margin:0 50px 30px 0;
    border-bottom: 1px solid $rk_grey05;
    width: 100%;
    @include mq('tablet-small') {
      width: 65%;
      min-height: 120px;
    }
    span{
      font-size:2.5rem;
      color: $rk_black;
      display: block;
      @include mq('tablet-small') {
        font-size:2rem;
      }
    }
  }
  &__subtitle{
    font-family: headers, Arial, sans-serif;
    font-size:2.2rem;
    color: $rk_grey3;
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    @include mq('tablet-small') {
      font-size:1.5rem;
    }
  }
  &__edition {
    border: 1px solid $rk_grey1;
    border-radius: 50%;
    min-height: 30px;
    min-width: 30px;
    max-width: 30px;
    max-height: 30px;
    display: inline-block;
    margin: 0 10px;
    .--active{
      filter: hue-rotate(250deg);
    }
  }
  &__total-score{
    position: absolute;
    top: -40px;
    right: 40px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    font-size: 2.5rem;
    font-family: headers, Arial, sans-serif;
    background: rgba($rk_horus05, .4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    small {
      font-size: .5em;
      font-family: basic, Arial, sans-serif;
    }
    @include mq('tablet') {
      top: 0;
      right: 10px;
    }
  }

  .loader__container{
    position: relative;
    min-height: 250px;
  }

}
