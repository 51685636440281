.partners{
  margin-top:30px;
  .--squares {
    &--item {
      border-width: 0 1px 0 0;
      border-radius: 0;
      align-items: center;
      transition: filter .2s linear;
      padding: 0 10px 0 0;
      &:hover {
        background: $rk_transparent;
        h3 {
          filter: brightness(.8);
        }
      }
    }
    h3{
      font-size: 2rem;
      color: $rk_role_administrador;
      filter: brightness(.5);
    }
  }
  &-manager{
    &__ranges{
      display: flex;
      flex-wrap: wrap;
      h4 {
        min-width: 100%;
        margin:10px 0 5px;
      }
      .mat-mdc-form-field {
        width: calc( 100% - 40px);
      }
      &__section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba($rk_loader1, .6);
      }
      .leads__emoji {
        position: relative;
        top: 0;
        left: 0;
        margin: 5px 5px 5px 0;
        width: 20px;
      }
    }
    &__licenses {
      flex-wrap: wrap;
      margin-bottom: 30px;
      border: solid $rk_grey1;
      border-width: 1px 0;
      padding: 20px 0;
      h2 {
        margin: 0;
        width: 100%;
        color: $rk_grey6;
      }
      .mat-mdc-form-field {
        width: 15%;
        margin: 10px 2% 0 0;
      }
      &-info {
        text-align: center;
        padding: 10px;
        margin: 0 10px 0 0;
        border-left: 1px solid $rk_grey3;
        &:nth-child(2) {
          border: none;
          padding-left: 0;
        }
      }
      .--limit {
        color: $rk_semaphoreRed;
      }
      strong {
        display: block;
        font-family: headers, Arial, Helvetica, sans-serif;
        font-size: 2.5rem;
        color: $rk_grey6;
        margin-bottom: 0;
      }
      small {
        font-weight: normal;
        font-family: special, Arial, Helvetica, sans-serif;
        font-size: .7em;
        color: $rk_grey4;
      }
      em {font-size: 1.1rem;}
    }
    &__banner {
      max-width: 100%;
      height: auto;
    }
    &__sign {
      min-height: 300px;
      font-size: 1rem !important;
      line-height: 1 !important;
      color: $rk_grey6 !important;
    }
  }
}
